import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonPrimary, ButtonSimple } from '../../../Components/Button/Buttoncontainer'
import formatDate from '../../../services/FormatDate'
import setTimeFormat from '../../../services/FormatTime'
import { RootState } from "./../../../store";
import './customerTrackLogsDetails.css'
import ToolTip from "../../../Components/ToolTip/ToolTip";
// import { BiGitBranch, BiImageAlt, BiUser } from 'react-icons/bi'
// import { Row, Col } from "react-bootstrap";
// import { useHistory } from 'react-router-dom';
import { setCustomerBookingTracking } from './../../../actions/customerBooking';
import { ResetCustomerTracking } from './../../../actions/trackingModule';
import { showLoader, hideLoader } from "./../../../actions/UserActions";
import { connect } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material"
import { BackIcon, CalendarIcon, CashIcon, DistanceIcon, DocketNumberIcon, GalleryIcon, HoursIcon, InfoIcon, LocationIcon, MapLocationIcon, PackageIcon, RupeeIcon, ServiceTypeIcon, SharpLocationIcon, TimeToReachIcon, TimelineIcon, UserIcon } from "../../../newSrc/constants/icons"
import remarks from "../../report/remarks"
import PageTitle from "../../../Components/Title/PageTitle"
import { Button } from "../../../newSrc/components/UI"

const CustomerTrackLogsDetails = (props:any) => {
  
  console.log(`CustomerTrackLogsDetails props` , props);
  
  const location = useLocation()
  // const history = useHistory();
  const navigate = useNavigate()
  const BranchData = location?.state?.BranchDetails;
  // const customerPropForSingleStatus:any = location?.state?.AllProps
  const [statusForOrder , setStatusForOrder] = useState("");
  // const [isResolveIssue, setIsResolveIssue] = useState(false);
  // const [loadingBranchData , setLoadingBranchData] = useState([]);

  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  const timeConvert = (time: any) => {
    const timeString12hr = new Date(time).toLocaleTimeString("en-US", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    });
    return timeString12hr;
  }
 
  const secondsToHms = (d:any) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    // var s = Math.floor(d % 3600 % 60);
  
    var hDisplay = h > 0 ? h + (h == 1 ? " Hr, " : " Hr, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " Min " : " Min ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " Second" : " Seconds") : "";
    return hDisplay + mDisplay; 
  }

  const backToOrigin = () => {
    console.log("RootState",props?.customerTrackingData?.source)
    // history.goBack();
    navigate(-1)
    // if(props?.customerTrackingData?.source === "Customer-Authenticated-CustomerOrder"){
    // }
    // else if(props?.customerTrackingData?.source === "Customer-Authenticated-BranchOrder"){
    //   history.goBack();
    // }
    // else if(props?.customerTrackingData?.source === "Customer-NotAuthenticated-CustomerOrder"){
    //   history.goBack();
    // }
    // else if(props?.customerTrackingData?.source === "Customer-NotAuthenticated-BranchOrder"){
    //   history.goBack();
    //   console.log("RootState backToOrigin",location)
    // }
    // else if(props?.customerTrackingData?.source === "Branch-Authenticated-CustomerOrder"){
    //   history.goBack();
    // }
    // else if(props?.customerTrackingData?.source === "Branch-Authenticated-BranchOrder"){
    //   history.goBack();
    // }
  }
  const ans = BranchData?.route && 
  BranchData?.route.sort((a:any,b:any)=>{
    return a?.placeIndex - b?.placeIndex
  }) || []
    
  useEffect(()=>{
    if(BranchData?.orderStatus === null){
      setStatusForOrder("Upcoming")
    }   
    if(BranchData?.orderStatus === "B" || BranchData?.orderStatus === "I"){
      setStatusForOrder("OnGoing")
    }   
    if(BranchData?.orderStatus === "D"){
      setStatusForOrder("Delivered")
    }   
  //   let newMap = new Map()
  //     const data = location.state.logsInfo
  //   data.map((singlelog:any)=>{
    
  //     let key = formatDate(singlelog?.trackingTime)

  //     if(newMap.has(key)){
  //         newMap.set(key,{
  //             data:[singlelog,...newMap.get(key).data]
  //         }) 
  //     }else{
  //         newMap.set(key,{
  //             data:[singlelog]
  //         })
  //     }
  //   })
  //  setDataDateWise(Array.from(newMap))
  },[])
  
  // const SingleStatus = ()=>{
  //   const [isDialogOpen,setIsDialogOpen] = useState(false);
  //   let lastStatus = customerPropForSingleStatus.statusArr && customerPropForSingleStatus.statusArr[customerPropForSingleStatus.statusArr.length - 1];
  //   const statusSplit = lastStatus.split("@");
  //   let ParcelReachedLastBranch:any = statusSplit[1];
  //   const steps = getSteps();
  //   function getSteps() {
  //     return [1,2,3,4,5];
  //   }
  
  //   let result, procStatus;
  
  //   switch (statusSplit[0]) {
  //     case "loading": {
  //       result = "In transit";
  //       procStatus = 1;
  //       break;
  //     }
  //     case "payment": {
  //       result = "Waiting for Delivery";
  //       procStatus = 3;
  //       // procStatus = "Waiting for Delivery";
  //       break;
  //     }
  //     case "unloading": {
  //       result = "Waiting for Delivery";
  //       procStatus = 2;
  //       // procStatus = "Waiting for Delivery";
  //       break;
  //     }
  //     case "booking": {
  //       result = "Booked";
  //       procStatus = 0;
  //       break;
  //     }
  //     case "delivery": {
  //       result = "Delivered";
  //       procStatus = 5;
  //       // procStatus = "Delivered";
  //       break;
  //     }
  //     case "cancel": {
  //       result = "Canceled";
  //       procStatus = 0;
  //       // procStatus = "Canceled";
  //       break;
  //     }
  //     case "cancelPending": {
  //       result = "Waiting for cancellation";
  //       // procStatus = "Waiting for cancellation";
  //       break;
  //     }
  //     case "review": {
  //       result = "Review";
  //       // procStatus = "Review";
  //       break;
  //     }
  //     case "missing": {
  //       result = "Missing";
  //       // procStatus = "Missing";
  //       break;
  //     }
  //     case "missingRequest": {
  //       result = "Missing Request";
  //       // procStatus = "Missing Request";
  //       break;
  //     }
  //     default: {
  //       result = "Booked";
  //       procStatus = 0;
  //     }
  //   }
  
  //   return(
  //     <div className="eachStatusContainerForLogs">
  //       <div className="singleStatusForBranchTracking">
  //         <p className="noOfPackagesForBookingTrack" onClick={()=>{setIsDialogOpen(true)}}>
  //           <div style={{marginTop:"7.5%",color:"black",fontSize:"small"}}><GoPackage style={{color:"rgb(92,14,225)"}} /> </div>
  //           <div> {customerPropForSingleStatus.totalPkg} </div>
  //         </p>
  //         <p className="materialTypeAndPackageType">
  //           {Array.from(customerPropForSingleStatus?.recordsMap).map(([, value]: any) => {
  //             const orderNum = value.filter((e: any) =>
  //             customerPropForSingleStatus?.PkgList.includes(e.order)
  //             );
  //             const statusSplit = lastStatus.split("@");
  
  //             switch (statusSplit[0]) {
  //               case "loading": {
  //                 result = "In transit";
  //                 break;
  //               }
  //               case "payment": {
  //                 result = "Waiting for Delivery";
  //                 break;
  //               }
  //               case "unloading": {
  //                 result = "Waiting for Delivery";
  //                 break;
  //               }
  //               case "booking": {
  //                 result = "Booked";
  //                 break;
  //               }
  //               case "delivery": {
  //                 result = "Delivered";
  //                 break;
  //               }
  //               case "cancel": {
  //                 result = "Canceled";
  //                 break;
  //               }
  //               case "cancelPending": {
  //                 result = "Waiting for cancellation";
  //                 break;
  //               }
  //               case "review": {
  //                 result = "Review";
  //                 break;
  //               }
  //               case "missing": {
  //                 result = "Missing";
  //                 break;
  //               }
  //               case "missingRequest": {
  //                 result = "Missing Request";
  //                 break;
  //               }
  //               default: {
  //                 result = "In transit";
  //               }
  //             }
  //             return (
  //               orderNum.length > 0 && (
  //                 <div className="ContainerForMaterial">
  //                   <div>
  //                     <div>
  //                       <span style={{marginTop:"1%",marginRight:"2%",color:"black",fontSize:"large"}}><AiOutlineDropbox style={{color:"#2720ff"}} /> </span>
  //                       {value.length > 0 &&
  //                         value[0] &&
  //                         value[0].materialType &&
  //                         value[0].materialType.name}
  //                     </div>
  //                   </div>
  //                   <div style={{marginLeft:"2.5%"}}>
  //                     <div>
  //                       <span style={{marginTop:"1%",marginRight:"2%",color:"black",fontSize:"large"}}><GoPackage style={{color:"#2720ff"}} /> </span>
  //                       {value.length > 0 &&
  //                         value[0] &&
  //                         value[0].packingType &&
  //                         value[0].packingType.name}
  //                     </div>
  //                   </div>
  //                 </div>
  //               )
  //             );
  //           })}
  //         </p>
  //         <p className="StatusConatinerForBookingTracking">
  //           <span><TimelineIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
  //           <div>
  //             {ParcelReachedLastBranch}
  //           </div>
  //         </p>
  //         <p className="progressBarDiv">
  //           <Stepper activeStep={procStatus} className={"singleProgressBar"}>
  //               {steps.map((label) => (
  //                 <Step key={label} className={"singleStepz"} >
  //                   <StepLabel></StepLabel>
  //                 </Step>
  //               ))}
  //               <p className="lastResultName">{result}</p>
  //           </Stepper>
  //         </p>
  //         <Dialog open={isDialogOpen} onClose={() => {setIsDialogOpen(false)}}>
  //           <DialogTitle style={{ cursor: "move" , textAlign:"center" }} id="draggable-dialog-title">
  //             List of Packge Numbers.
  //           </DialogTitle>
  //           <DialogContent>
  //             <div className="pkgDialogueContent">
  //               <div className="listOfPackages">
  //                 {customerPropForSingleStatus.PkgList.map((c: any) => {
  //                   return ( <div>{c}</div> )
  //                 })}
  //               </div>
  //             </div>
  //           </DialogContent>
  //           <DialogActions>
  //             <ButtonDefault onClick={()=>setIsDialogOpen(false)}>
  //               Cancel
  //             </ButtonDefault>
  //           </DialogActions>
  //         </Dialog>
  //         <>
  //           {/*
  //           <p>
  //             <select
  //               id="inputState"
  //               className="form-control citySelectForCustomer"
  //               // value={props?.customerBooking?.intraCity?.city}
  //               // onChange={(e) => {
  //               //   props.setIntraCity('city',e.target.value);
  //               //   getTempoTypeList(e.target.value);
  //               // }}
  //             >
  //               <option label="Packages List" value="" />
  //               {props.PkgList.map((c: any) => (
  //                 <option
  //                   key={c}
  //                   label={c}
  //                   value={c}
  //                 />
  //               ))}
  //             </select>
  //           </p> 
  //           */}
  //         </>
  //       </div>
  //     </div>
  //   )
  // }

  const HeadingDivForBranchOrder = ()=>{
    return(
      <div className="">
        <div className=''>
          <div className='four-column t-two-column m-one-column gap-1'>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><DocketNumberIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} /></span> 
              <p>
                {props?.customerTrackingData?.source ===  "Branch-Authenticated-BranchOrder" ? props?.customerTrackingData?.orderData?.docket.docketNumber : props?.customerTrackingData?.orderData?.docket.trackingNumber}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><CalendarIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
              <p>
                {formatDate(BranchData?.docket.createdAt)}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><TimelineIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
              <p>
                {BranchData?.docket.deliveredAt ? formatDate(BranchData?.docket.deliveredAt) : "In Transit"}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><CashIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
              <p>
                {" "} {BranchData?.docket.paymentMode}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><PackageIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
              <p>
                {BranchData?.docket.packages.length+1}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><LocationIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
              <p>
                {BranchData?.docket.originBranch.branchName}{" "}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><SharpLocationIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
              <p>
                {BranchData?.docket.destBranch.branchName}{" "}
              </p>
            </div>
            <div className="flex ai-center gap-4 flex-wrap">
              <span><RupeeIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
              <p>
                {" "} {BranchData?.docket.gross}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HeadingDivForCustomerOrder = ()=>{
    return(
      <div className="">
        <div className=''>
        <div className='log-history-general-info gap-1'>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><CalendarIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {formatDate(
                    BranchData?.createdAt)}
                </p>
              </div>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><DocketNumberIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} /></span> 
                <p>
                  {" "}{
                  BranchData?.shipmentNumber}
                </p>
              </div>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><ServiceTypeIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                {" "} {
                BranchData?.type}
                </p>
              </div>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span style={{fontSize:"x-large",padding:'1%',color:"#2728ff"}}><MapLocationIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {" "}{
                  BranchData?.route?.length}
                </p>
              </div>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><CashIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {" "} {
                  BranchData?.paymentMode ? 
                  BranchData?.paymentMode : "Cash" }
                </p>
              </div>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><HoursIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {timeConvert(
                    BranchData?.createdAt)}
                </p>
              </div>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><TimelineIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {" "}{statusForOrder}
                </p>
              </div>
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><TimeToReachIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {" "}{secondsToHms(ans[ans?.length-1]?.duration)}
                </p>
              </div>
              {BranchData?.route?.length > 0 &&
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><DistanceIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {" "} {ans[ans?.length-1]?.distance/1000} KM
                </p>
              </div>
              }
              <div className="flex ai-center gap-4 flex-wrap fs-link">
                <span><RupeeIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
                <p>
                  {" "} {BranchData?.gross}
                </p>
              </div>
              {/* <div className='firstLocation'>
                <i className='fas fa-map-marker-alt'/>
                <span>{location?.state?.data?.docketData?.custPackages[0]?.fromPlace?.formatted_address}</span>
              </div>
              <div className='anotherLocation'>
                <i className='fas fa-map-marker-alt'/>
                <span>{location?.state?.data?.docketData?.custPackages[0]?.toPlace?.formatted_address}</span>
              </div> */}
            </div>
        </div>
      </div>
    )
  }

  let currentDate: any = "";

  const handleSetDate = (data: any) => {
    currentDate = data;
    return <div className='max-content flex ai-center gap-4 bg-primary-50 text-primary-900 p-10 br-4 fs-link'><CalendarIcon />{data}</div>;
  };

  // const RawStatus = (props: any) => {
  //   const [fetch, setFetch] = useState(false);
  //   const [image, setImage] = useState<any>(null);
  //   const [showDeliveryImages, setShowDeliveryImages] = useState(false);
  //   const [showRemarkImages, setShowRemarkImages] = useState(false);
  //   const [compStatusDialog, setCompStatusDialog] = useState(false);
  //   // const [fromBranch , setFromBranch] = useState<any>("")
  
  //   console.log(props?.loadingBranchList,image);
  
  //   useEffect(() => {
  //     const { log } = props;
  //     if (fetch) {
  //       setFetch(false),
  //         () => {
  //           const arr = log.split("@");
  //           let remarkImage = arr[7];
  //           if (remarkImage && remarkImage.length > 20) {
  //             getImageService(remarkImage)
  //               .then((data: any) => {
  //                 setImage(data);
  //               })
  //               .catch((err: any) => {
  //                 console.log(err);
  //               });
  //           }
  //         };
  //     }

  //     // props?.loadingBranchList?.map((ele:any)=>{
  //     //   if(props?.verifyIndex === ele?.i){
  //     //     console.log("logsArr Not Possible",ele?.value)
  //     //     setFromBranch(ele?.value)
  //     //   }
  //     // })
  //   });
  
  //   const toggleRemark = () => {
  //     setShowRemarkImages(!showRemarkImages);
  //     setFetch(true);
  //   };
  
  //   const toggleDelivery = () => {
  //     setShowDeliveryImages(!showDeliveryImages);
  //   };
  
  //   const { log } = props;
  //   const arr = log.split("@");
  
  //   let [
  //     action,
  //     entity,
  //     user,
  //     time,
  //     pod,
  //     idProof,
  //     remarks,
  //     remarkImage,
  //     actualAction,
  //     secondVerified,
  //     secondActionBy,
  //     secondRemark,
  //     secondaryActionAt,
  //     isQrScan,
  //     fromBranch
  //   ] = arr;
  //   console.log("time fromBranch", fromBranch);
  
  //   console.log("arrrrrrrrrrrrr", arr);
  //   console.log("arrrrrrrrrrrrr props", props);
  //   const handleCompStatusDialog = () => {
  //     setCompStatusDialog(true);
  //   };
  
  //   const data = [
  //     {
  //       secondVerified,
  //       secondaryActionAt,
  //       secondActionBy,
  //       secondRemark,
  //     },
  //   ];
  
  //   const dataColumn = [
  //     {
  //       accessor: "secondaryActionAt",
  //       Header: "Verified At",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>{formatDate(props.value)}</div>
  //       ),
  //       width: 100,
  //     },
  //     {
  //       accessor: "secondActionBy",
  //       Header: "Verified By",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>{props.value}</div>
  //       ),
  //       width: 100,
  //     },
  //     {
  //       accessor: "secondRemark",
  //       Header: "Remarks",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>{props.value}</div>
  //       ),
  //       width: 100,
  //     },
  //     {
  //       accessor: "secondVerified",
  //       Header: "Status",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>
  //           {props.value === "A"
  //             ? "Approved"
  //             : props.value === "R"
  //             ? "Rejected"
  //             : "Pending"}
  //         </div>
  //       ),
  //       width: 100,
  //     },
  //   ];
  
  //   return (
  //     <div>
  //       <Dialog
  //         open={compStatusDialog}
  //         onClose={() => setCompStatusDialog(false)}
  //       >
  //         <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
  //           Complaint Verification Status
  //         </DialogTitle>
  //         <DialogContent>
  //           <ReactTable
  //             data={data}
  //             columns={dataColumn}
  //             // showPagination={false}
  //             // pageSize={data.length}
  //           />
  //         </DialogContent>
  //       </Dialog>
  
  //       <Dialog open={showRemarkImages} onClose={toggleRemark}>
  //         <div className="delivery_images">
  //           <div className="del_title">Remark Image</div>
  //           <br />
  //           <div className="del_id">
  //             <img src={remarkImage} height="150" width="inherit" />
  //           </div>
  //         </div>
  //       </Dialog>
  
  //       <Dialog open={showDeliveryImages} onClose={toggleDelivery}>
  //         <div className="delivery_images">
  //           <div className="del_title">Proof Of Delivery</div>
  //           <div className="del_pod">
  //             <img src={pod} height="150" width="inherit" />
  //           </div>
  
  //           <br />
  
  //           <div className="del_title">ID Proof</div>
  //           <div className="del_id">
  //             <img src={idProof} height="150" width="inherit" />
  //           </div>
  //         </div>
  //       </Dialog>
      
  //       <Row style={{ fontSize: "medium" }} className={"maincontainerOfRow"}>
  //         <Col md={1} sm={6} className={"commonColClassForLogs"}>
  //           {setTimeFormat(time)}
  //         </Col>
  //         <Col md={2} sm={6} className={"commonColClassForLogs"}>
  //           {actualAction && actualAction.toUpperCase()}{" "}
  //           {isQrScan === "YES" && (
  //             <i
  //               className={`fas fa-qrcode`}
  //               style={{ color: "blue", marginLeft: 5 }}
  //             ></i>
  //           )}
  //           {/* {actualAction === "loading" ? `(${fromBranch})` : ""} */}
  //         </Col>
  //         <Col md={3} sm={6} className={"commonColClassForLogs"}>
  //           at {entity}
  //         </Col>
  //         <Col md={2} sm={6} className={"commonColClassForLogs"}>
  //           {user}
  //         </Col>
  //         {props.isResolveIssue && (
  //           <Col md={1} sm={6} className={"commonColClassForLogs"}>
  //             <ToolTip
  //               title={
  //                 <div style={{ fontSize: "14px" }}>Complaint Verification</div>
  //               }
  //             >
  //               <i
  //                 onClick={() => handleCompStatusDialog()}
  //                 className="fa fa-info-circle"
  //                 style={{ color: "blue", fontSize: "20px" }}
  //               ></i>
  //             </ToolTip>
  //             <br />
  //             <p
  //               style={{
  //                 color:
  //                   secondVerified === "A"
  //                     ? "green"
  //                     : secondVerified === "R"
  //                     ? "red"
  //                     : "black",
  //               }}
  //             >
  //               {secondVerified === "A"
  //                 ? "Verified"
  //                 : secondVerified === "R"
  //                 ? "Rejected"
  //                 : "Verification Pending"}
  //             </p>
  //           </Col>
  //         )}
  //         <Col md={2} sm={6} className={"commonColClassForLogs"}>
  //           {action === "delivered" &&
  //             ((typeof pod == "string" && pod.length > 0 && pod != "DUMMY") ||
  //               (typeof idProof == "string" &&
  //                 idProof.length > 0 &&
  //                 idProof != "DUMMY")) && (
  //               <ButtonSimple onClick={toggleDelivery}>
  //                 Attachments
  //               </ButtonSimple>
  //             )}
  //         </Col>
  //         <Col md={1} className={"commonColClassForLogs"}>
  //           <ButtonSimple
  //             disabled={!(remarkImage && remarkImage.length > 20)}
  //             onClick={toggleRemark}
  //           >
  //             Image
  //           </ButtonSimple>
  //         </Col>
  //       </Row>
  //       <Row>
  //         <Col md={12} xs={12}>
  //           {remarks && remarks.length > 0 && remarks != "RTEXT" ? (
  //             <div className="track_remark_text"> Remarks: {remarks}</div>
  //           ) : null}
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // };

  // const RawStatusForMob = (props: any) => {
  //   const [fetch, setFetch] = useState(false);
  //   const [image, setImage] = useState<any>(null);
  //   const [showDeliveryImages, setShowDeliveryImages] = useState(false);
  //   const [showRemarkImages, setShowRemarkImages] = useState(false);
  //   const [compStatusDialog, setCompStatusDialog] = useState(false);
  //   // const [fromBranch , setFromBranch] = useState<any>("")
  
  //   console.log(props?.loadingBranchList,image);
  
  //   useEffect(() => {
  //     const { log } = props;
  //     if (fetch) {
  //       setFetch(false),
  //         () => {
  //           const arr = log.split("@");
  //           let remarkImage = arr[7];
  //           if (remarkImage && remarkImage.length > 20) {
  //             getImageService(remarkImage)
  //               .then((data: any) => {
  //                 setImage(data);
  //               })
  //               .catch((err: any) => {
  //                 console.log(err);
  //               });
  //           }
  //         };
  //     }

  //     // props?.loadingBranchList?.map((ele:any)=>{
  //     //   if(props?.verifyIndex === ele?.i){
  //     //     console.log("logsArr Not Possible",ele?.value)
  //     //     setFromBranch(ele?.value)
  //     //   }
  //     // })
  //   });
  
  //   const toggleRemark = () => {
  //     setShowRemarkImages(!showRemarkImages);
  //     setFetch(true);
  //   };
  
  //   const toggleDelivery = () => {
  //     setShowDeliveryImages(!showDeliveryImages);
  //   };
  
  //   const { log } = props;
  //   const arr = log.split("@");
  
  //   let [
  //     entity,
  //     user,
  //     time,
  //     pod,
  //     idProof,
  //     remarkImage,
  //     actualAction,
  //     secondVerified,
  //     secondActionBy,
  //     secondRemark,
  //     secondaryActionAt,
  //     isQrScan,
  //     fromBranch
  //   ] = arr;
  //   console.log("time fromBranch", fromBranch);
  
  //   console.log("arrrrrrrrrrrrr", arr);
  //   console.log("arrrrrrrrrrrrr props", props);
  
  //   const data = [
  //     {
  //       secondVerified,
  //       secondaryActionAt,
  //       secondActionBy,
  //       secondRemark,
  //     },
  //   ];
  
  //   const dataColumn = [
  //     {
  //       accessor: "secondaryActionAt",
  //       Header: "Verified At",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>{formatDate(props.value)}</div>
  //       ),
  //       width: 100,
  //     },
  //     {
  //       accessor: "secondActionBy",
  //       Header: "Verified By",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>{props.value}</div>
  //       ),
  //       width: 100,
  //     },
  //     {
  //       accessor: "secondRemark",
  //       Header: "Remarks",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>{props.value}</div>
  //       ),
  //       width: 100,
  //     },
  //     {
  //       accessor: "secondVerified",
  //       Header: "Status",
  //       Cell: (props: any) => (
  //         <div style={{ textAlign: "center" }}>
  //           {props.value === "A"
  //             ? "Approved"
  //             : props.value === "R"
  //             ? "Rejected"
  //             : "Pending"}
  //         </div>
  //       ),
  //       width: 100,
  //     },
  //   ];
  
  //   return (
  //     <div>
  //       <Dialog
  //         open={compStatusDialog}
  //         onClose={() => setCompStatusDialog(false)}
  //       >
  //         <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
  //           Complaint Verification Status
  //         </DialogTitle>
  //         <DialogContent>
  //           <ReactTable
  //             data={data}
  //             columns={dataColumn}
  //             // showPagination={false}
  //             // pageSize={data.length}
  //           />
  //         </DialogContent>
  //       </Dialog>
  
  //       <Dialog open={showRemarkImages} onClose={toggleRemark}>
  //         <div className="delivery_images">
  //           <div className="del_title">Remark Image</div>
  //           <br />
  //           <div className="del_id">
  //             <img src={remarkImage} height="150" width="inherit" />
  //           </div>
  //         </div>
  //       </Dialog>
  
  //       <Dialog open={showDeliveryImages} onClose={toggleDelivery}>
  //         <div className="delivery_images">
  //           <div className="del_title">Proof Of Delivery</div>
  //           <div className="del_pod">
  //             <img src={pod} height="150" width="inherit" />
  //           </div>
  
  //           <br />
  
  //           <div className="del_title">ID Proof</div>
  //           <div className="del_id">
  //             <img src={idProof} height="150" width="inherit" />
  //           </div>
  //         </div>
  //       </Dialog>
      
      
  //       <div className='branchOrderLogsContainer'>
  //         <div className='containerForTimeAndStatus'>
  //           <div className='commonLogstime'>
  //                 <span style={{fontSize:"large",padding:'1%'}}><HoursIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
  //                 {setTimeFormat(time)}
  //           </div>
  //           <div className='commonLogsAction'>
  //                 <span style={{fontSize:"large",padding:'1%'}}><TimelineIcon style={{width: "28px", height: "28px", color:"var(--clr-primary-400)"}} />{" "}</span>
  //                 {actualAction && actualAction.toUpperCase()}{" "}
  //                 {isQrScan === "YES" && (
  //                   <i
  //                     className={`fas fa-qrcode`}
  //                     style={{ color: "blue", marginLeft: 5 }}
  //                   ></i>
  //                 )}
  //           </div>
  //         </div>
  //         <div className='entityAndUserContainer'>
  //           <div className='commonLogsName'>
  //               <span style={{fontSize:"large",padding:'1%'}}><BiGitBranch style={{fontSize:"large",backgroundColor:"white",color:"#2728ff"}} />{" "}</span>
  //                 {entity}
  //           </div>
  //           <div className='commonLogsName'>
  //               <span style={{fontSize:"large",padding:'1%'}}><BiUser style={{fontSize:"large",backgroundColor:"white",color:"#2728ff"}} />{" "}</span>
  //                 {user}
  //           </div>
  //         </div>
  //         <div className='commonImageContainer'>
  //             <span style={{fontSize:"large",padding:'1%'}}><BiImageAlt style={{fontSize:"large",backgroundColor:"white",color:"#2728ff"}} />{" "}</span>
  //             <ButtonSimple
  //               disabled={!(remarkImage && remarkImage.length > 20)}
  //               onClick={toggleRemark}
  //             >
  //               Image
  //             </ButtonSimple>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const RawStatusForCustomer  = (props:any) => {
    return(
      <>
      <div className='grid fs-link'>
        <div className='flex-between gap-1 flex-wrap'>
          <div className="flex ai-center gap-4">
            <HoursIcon className="text-primary-400" />
            {setTimeFormat(props?.value?.trackingTime)}
          </div>
          <div className="flex ai-center gap-4 p-10 br-4 bg-info-50 text-info-900 fw-500">
            <TimelineIcon className="text-primary-400" />{" "}
            {props?.value?.action && `${props?.value?.action.toUpperCase()} `}
            {(props?.value?.action === "checkInAtLocation" || props?.value?.action === "checkOutAtLocation") && `At ${props?.value?.place?.name}`}
          </div>
        </div>
        <div className='flex ai-center gap-4'>
          <UserIcon className="text-primary-400" />
          {(props?.value?.action === "Booked" || props?.value?.action === "Accepted") ? props?.value?.customer?.name : props?.value?.user?.name?.fName }
        </div>
      </div>
      </>
        // <Row style={{ fontSize: "medium" }} className={"maincontainerOfRow"}>
        //     <Col md={1} sm={6} className={"commonColClassForLogs extraClassNAme"}>
        //       {setTimeFormat(props?.value?.trackingTime)}
        //     </Col>
        //     <Col md={2} sm={6} className={"commonColClassForLogs"}>
        //       {props?.value?.action && props?.value?.action.toUpperCase()}
        //     </Col>
        //     {/* <Col md={3} sm={6} className={"commonColClassForLogs"}>
        //       at {entity}
        //     </Col> */}
        //     <Col md={2} sm={6} className={"commonColClassForLogs"}>
        //       {props?.value?.action === "Booked" ? props?.value?.customer?.name : props?.value?.user?.name?.fName }
        //     </Col>  
        // </Row>
    )
  }

  const getLoadingBranchLoaction = () => {
    let TempArr:any = [];
    {location?.state?.logsInfo.map((ele:any,index:any)=>{
      if(ele?.action === "loading"){
        TempArr.push({i:index,value:ele?.branch?.branchName})
      }
    })}
    // setLoadingBranchData(TempArr)
  }

  useEffect(()=>{
    if(location.state.from === "Branch-Order-Track"){
      // setIsResolveIssue(
      //   BranchData?.reSolveIssues && BranchData?.reSolveIssues?.length > 0 ? true : false
      // );
    }else{
      getLoadingBranchLoaction();
    }
  },[])

  return (
    <div className="w-100 max-w-1440 p-1 mx-auto">
      {mobileView ? 
        <div>
          <div className="p-1 pt-0 bg-white-900 flex ai-center gap-8 relative fs-heading">
            <BackIcon className="text-primary-900" onClick={() => navigate(-1)} />
            <div className="" style={{position: "absolute", left: "50%", transform: "translateX(-50%)"}}>
                <PageTitle title="Logs History" className="bg-transparent text-primary-900" style={{fontSize: "clamp(1.75rem, 3vw, 2rem)"}}  />
            </div>          
          </div>

          {location?.state?.view === "Customer-Order" &&  
            <div className='paper'>
              {BranchData?.logs?.map((ele:any)=>{
                let time = ele?.trackingTime
                const date = new Date(time);
                return(
                  <div className='grid gap-10'>
                    <div>
                      {formatDate(date) !== currentDate
                        ? handleSetDate(formatDate(date))
                        : ""}
                    </div>
                    <div className='paper'>
                      <RawStatusForCustomer value={ele}/>            
                    </div>
                  </div>
                )
              })}
            </div>
          }
         
          {location?.state?.view === "Branch-Order" &&  
            <div className='paper grid gap-1 mt-1'>
              {location?.state?.logsInfo?.map((item: any) => {
                
                return (
                  <div className=''>
                    <div className="">
                      {formatDate(item?.trackingTime) !== currentDate
                        ? handleSetDate(formatDate(item?.trackingTime))
                        : ""}
                    </div>
                    <div className='paper mt-1 fs-link grid gap-8'>
                      <div className="flex-between gap-1 flex-wrap">
                       <span className="flex ai-center gap-4"><HoursIcon className="text-primary-400" />{setTimeFormat(item?.trackingTime)}</span>
                       <div className="span flex ai-center gap-4 bg-info-50 text-info-900 p-10 br-4 fw-500">
                        <TimelineIcon />
                        {item?.action && item?.action.toUpperCase()}{" "}
                        {item?.isQrScan && (
                          <i className={`fas fa-qrcode`} style={{ color: "var(--clr-info-900)"  }} ></i>
                        )}
                        </div>
                      </div>
                      <div className="one-column gap-1">
                        <span className="flex ai-center gap-4">
                          <UserIcon className="text-primary-400 flex-shrink-0" />
                          {item?.user?.name ? `${item?.user?.name?.fName} ${item?.user?.name?.lName}` : "No Name Found"}
                        </span>
                        <span className="flex ai-center gap-4"><LocationIcon className="text-primary-400 flex-shrink-0" />{item?.action === "loading" ? item?.regNumber : (item?.branch?.branchName ? item?.branch?.branchName : "No Branch Found")}</span>
                      </div>
                      <Button fullWidth variant="primary" className="flex-center" action="secondary" Icon={<GalleryIcon />}>Image</Button>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        </div>
        :
        <>
          <div className='flex-center flex-wrap gap-1'>
            <ButtonPrimary
              onClick={() => backToOrigin()}
              className="button-primary-primary fs-link py-8 min-w-auto"
            > 
              <BackIcon />
            </ButtonPrimary>
            <div className='titleMainDiv'>
              <PageTitle title={"Logs History"}/>
            </div>
          </div>

          {location?.state?.view === "Customer-Order" &&  <div className='p-1 br-10 shadow-small border-white mt-1'> <HeadingDivForCustomerOrder /> </div>}
          
          {location?.state?.view === "Branch-Order" &&  <div className='p-1 br-10 shadow-small border-white mt-1'> <HeadingDivForBranchOrder /> </div>}

          {location?.state?.view === "Customer-Order" &&  
            <div className='paper mt-1'>
              {BranchData?.logs?.map((ele:any)=>{
              console.log(`props?.location?.` , ele)
                let time = ele?.trackingTime
                const date = new Date(time);
                return(
                  <div className='containerForLogs'>
                    <div>
                      {formatDate(date) !== currentDate
                        ? handleSetDate(formatDate(date))
                        : ""}
                    </div>
                    <div className='paper mt-1'>
                      <RawStatusForCustomer value={ele}/>            
                    </div>
                  </div>
                )
              })}
            </div>
          }
          
          {location?.state?.view === "Branch-Order" &&  
          <div className='paper mt-1 none-if-empty'>
            {/* {customerPropForSingleStatus?.statusArr?.map((item: any, index: any) => {
              const arr = item.split("@");
              let [action, entity, user, time] = arr;
              console.log(action, entity, user, time);
              const date = new Date(time);
              console.log("60000 item",date , `item` , item)
              return (
                <div className='containerForLogs'>
                  <div>
                    {formatDate(date) !== currentDate
                      ? handleSetDate(formatDate(date))
                      : ""}
                  </div>
                  <div className='paper mt-1'>

                    <RawStatus
                      key={index}
                      log={item}
                      isResolveIssue={isResolveIssue}
                      loadingBranchList={loadingBranchData}
                      verifyIndex={index}
                      // branches={branches}
                    />
                  </div>
                </div>
              );
            })} */}
            {location?.state?.logsInfo?.map((item: any) => {
              console.log(`props?.location?.` , item)
              return (
                <div className='containerForLogs'>
                  <div>
                    {formatDate(item?.trackingTime) !== currentDate
                      ? handleSetDate(formatDate(item?.trackingTime))
                      : ""}
                  </div>
                  <div className='paper mt-1'>
                  {/* <Row style={{ fontSize: "medium" }} className={""}> */}
                    <div className="flex-between ai-center gap-1 flex-wrap">
                      <div className="flex-center gap-4">
                        <HoursIcon className="flex-shrink-0 text-primary-400" style={{width: "28px", height: "28px"}} />{setTimeFormat(item?.trackingTime)}
                      </div>
                      <div className="flex-center gap-4">
                        <TimelineIcon  className="flex-shrink-0 text-primary-400" style={{width: "28px", height: "28px"}} />{item?.action && item?.action.toUpperCase()}{" "}
                          {item?.isQrScan && (
                            <i
                              className={`fas fa-qrcode`}
                              style={{ color: "blue", marginLeft: 5 }}
                            ></i>
                          )}
                      </div>
                      <div className="flex-center gap-4">
                        <LocationIcon  className="flex-shrink-0 text-primary-400" style={{width: "28px", height: "28px"}} /> at {item?.action === "loading" ? item?.regNumber : (item?.branch?.branchName ? item?.branch?.branchName : "No Branch Found")}
                      </div>
                      <div className="flex-center gap-4">
                      <UserIcon className="flex-shrink-0 text-primary-400" style={{width: "28px", height: "28px"}} />{item?.user?.name ? `${item?.user?.name?.fName} ${item?.user?.name?.lName}` : "No Name Found"}

                      </div>
                      {props.isResolveIssue &&  <div className="flex-center gap-4">
                        <ToolTip
                          title={
                            <div style={{ fontSize: "14px" }}>Complaint Verification</div>
                          }
                        >
                          {/* <i
                            // onClick={() => handleCompStatusDialog()}
                            className="fa fa-info-circle"
                            style={{ color: "blue", fontSize: "20px" }}
                          ></i> */}
                          <InfoIcon className="flex-shrink-0 text-primary-400" style={{width: "28px", height: "28px"}} />
                        </ToolTip>
                      </div>}
                      {/* <br /> */}
                      {item?.action === "delivered" && <div>
                        <ButtonSimple onClick={() => console.log(`NOPOD`)}>
                          Attachments
                        </ButtonSimple>
                      </div>}
                      <div className="flex-center gap-4">
                        <GalleryIcon className="flex-shrink-0 text-primary-400" style={{width: "28px", height: "28px"}} />
                        <p
                          // disabled={!(remarkImage && remarkImage.length > 20)}
                          onClick={() => console.log(`NOPOD 2`)}
                        >
                          Image
                        </p>
                      </div>
                    </div>
                    {/* <Col md={1} sm={6} className={"flex-center gap-4"}>
                    </Col> */}
                    {/* <Col md={2} sm={6} className={"flex-center gap-4"}> */}
                      {/* {actualAction === "loading" ? `(${fromBranch})` : ""} */}
                    {/* </Col> */}
                    {/* <Col md={3} sm={6} className={"flex-center gap-4"}>
                    </Col> */}
                    {/* <Col md={2} sm={6} className={"flex-center gap-4"}>
                    </Col> */}
                    {/* {props.isResolveIssue && (
                      <Col md={1} sm={6} className={"commonColClassForLogs"}> */}
                        {/* <p
                          style={{
                            color:
                              secondVerified === "A"
                                ? "green"
                                : secondVerified === "R"
                                ? "red"
                                : "black",
                          }}
                        >
                          {secondVerified === "A"
                            ? "Verified"
                            : secondVerified === "R"
                            ? "Rejected"
                            : "Verification Pending"}
                        </p> */}
                      {/* </Col/>
                    )} */}
                    {/* {item?.action === "delivered" && <Col md={2} sm={6} className={"commonColClassForLogs"}> */}
                      {/* {item?.action === "delivered" && */}
                        {/* // ((typeof pod == "string" && pod.length > 0 && pod != "DUMMY") ||
                        //   (typeof idProof == "string" &&
                        //     idProof.length > 0 &&
                        //     idProof != "DUMMY")) && (
                        // ) */}
                        {/* } */}
                    {/* </Col>} */}
                    {/* <Col md={1} className={"flex-center gap-4"}>
                    </Col> */}
                  {/* </Row> */}
                  {remarks && remarks.length > 0 && 
                    <div className="track_remark_text"></div>
                  } 
                  {/* <Row>
                    <Col md={12} xs={12}>
                    </Col>
                  </Row> */}
                      {/* {remarks && remarks.length > 0 && remarks != "RTEXT" ? ( */}
                      {/* ) : null} */}

                  </div>
                </div>
              );
            })}
          </div>
          }
        </>
      }
    </div>
  )

}


const mapStateToProps = (state: RootState) => ({
  sub: state.user.loginType,
  entity:
    state.user.loginType === "B"
      ? state.user.opBranch._id
      : state.user.opFleet._id,
  customerTracking: state.customerBooking.tracking,
  user: state.customerLoginReducer,
  customerChatHistory: state.customerChatHistory,
  customerTrackingData: state.customerTracking,
  branchListData: state.branch.filteredBranches.data.Branches,
});

const mapDispatchToProps = {
  setCustomerBookingTracking,
  ResetCustomerTracking,
  showLoader,
  hideLoader
};

export default connect(mapStateToProps,mapDispatchToProps)(CustomerTrackLogsDetails);



// {dataDateWise.map(([date,data]:any,index:any)=>(
//   <div key={index} style={{fontWeight:"bold",width:"90%",padding:"0% 2.5%"}}>
//     <div style={{fontSize:"20px",textAlign:"left",marginTop:"1%"}}> {date}</div>
//       {data && data?.data && data.data.map((e:any)=>{
//         return(
//           <Paper elevation={2}>
//             <Grid
//             xs={12}
//             sm={12}
//             md={12}
//             lg={12}
//             xl={12} container style={{fontSize:"14px",marginTop:"1%",alignItems:"center",padding:"10px"}}>
//             <Grid
//             xs={2}
//             sm={2}
//             md={2}
//             lg={2}
//             xl={2} style ={{fontSize:"14px"}}>
//               {setTimeFormat(e?.trackingTime)}
//             </Grid>
//             <Grid
//             xs={3}
//             sm={3}
//             md={3}
//             lg={3}
//             xl={3}>
//               {e?.action}
//             </Grid>
//             <Grid
//             xs={4}
//             sm={4}
//             md={4}
//             lg={4}
//             xl={4} style ={{fontSize:"14px"}}>
  
//               {e?.action !=="Accepted "?
              
//                   <div style={{display:"flex",gap :"5px"}}>
//                 <div>{e?.customer?.name}</div>
//                 <div style={{color:"blue"}}>{e?.customer?.contact}</div>
//               </div>:
//               <div style={{display:"flex",gap :"5px"}}>
//               <div>{e?.user?.name?.fName}  {e?.user?.name?.lName}</div>
//               <div style={{color:"blue"}}>{e?.user?.contact}</div>
//             </div>    
//               }
//             </Grid>
//             <Grid
//             xs={3}
//             sm={3}
//             md={3}
//             lg={3}
//             xl={3} style ={{fontSize:"14px"}}>
            
//             <ButtonSimple
//                   disabled={true}
//                   onClick={()=>console.log("first")}
//                 >
//                   Image
//                 </ButtonSimple>
//             </Grid>
  
//             </Grid> 
//           </Paper> 
//         )
//       })}
//   </div>
// ))}