import React from 'react'
import './table.css'
// import {Grid} from '@mui/material'
import Grid from '../Grid/Grid';
  interface Iprops{
      label?:any;
      style?:React.CSSProperties;
  }
export default function Tableheader(props:Iprops) {
    return (
      
                  <strong style={props.style}>{props.label}</strong>
        
    )
}

interface IpropsHeader{
    style?:React.CSSProperties
}
export  function TableheaderDocketNumber(props:IpropsHeader){
    return (
        <Grid md={2} lg={2}>
            <Tableheader label="Docket Number"style={props.style} />
       </Grid>
    )
}
export  function TableheaderDestCity(props:IpropsHeader){
    return (
        <Grid md={1} lg={1}>
        <Tableheader label="Destination City"style={props.style} />
        </Grid>
    )
}
export  function TableheaderDestBranch(props:IpropsHeader){
    return (
        <Grid md={2} lg={2}>
        <Tableheader label="Destination Branch"style={props.style} />
        </Grid>
    )
}
export  function TableheaderMissingDays(props:IpropsHeader){
    return (
        <Grid md={1} lg={1}>
        <Tableheader label="Missing Days"style={props.style} />
        </Grid>
    )
}
export  function TableheaderDeliveryProcess(props:IpropsHeader){
    return (
        <Grid md={1} lg={1}>
        <Tableheader label="Delivery"style={props.style} />
        </Grid>
    )
}
export function  TableheaderSelectedPackages(props:IpropsHeader){
    return(
        <Grid md={1} lg={1}>
        <Tableheader label="Selected Packages"style={props.style} />
        </Grid>
    )
}
export  function TableheaderPriority(props:IpropsHeader){
    return (
        <Grid md={1} lg={1}>
        <Tableheader label="Priority"style={props.style} />
        </Grid>
    )
}
export  function TableheaderResolved(props:IpropsHeader){
    return (
        <Grid md={1} lg={1}>
        <Tableheader label="Resolved"style={props.style} />
        </Grid>
    )
}
export  function Tableheadertracking(props:IpropsHeader){
    return (
        <Grid md={1} lg={1}>
        <Tableheader label="Tracking"style={props.style} />
        </Grid>
    )
}