// react
import React, { useEffect, useState } from "react";
// react-router-dom
import { Navigate, useNavigate, useLocation } from "react-router-dom";
// ui components
import { Button, Dialog, Heading } from "../../../../components/UI";
// css
import "./login.css";
// constants (icon)
import {
  CallIcon,
  EyeIcon,
  EyeSlashIcon,
  LetsGoIcon,
  SignInIcon,
} from "../../../../constants/icons";
// components
import Footer from "../Footer/Footer";
// context
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
import { useLogin } from "./useLogin";
// services
import {
  sendOTP,
  setNewPassword,
  setPassword as setForgetPassword,
  verifyOTP,
  sendCustomerOTP,
  verifyCustomerOTP,
  setCustomerPassword,
} from "../../../../../services/auth";
// utils 
import showMessage, {
  showHttpError,
  success,
} from "../../../../../utils/message";
// partner-forms
import { OTPInput } from "../../partner-forms/OTPInput";
import { StateObjType } from "../../partner-forms/useCustomState";
import LoadingIndicator from "../../../../components/UI/LoadingIndicator";

// variables
const forms = ["Customer", "Transporter"] as const;

// FC: Login Component
const Login = () => {
  // State
  const {
    setActivePartner,
    setActivePage,
    login,
    user,
    customerLogin,
    customerReducer,
    // customerLoginWithToken,
  } = useLandingPageContext();
  const {
    activeForm,
    setActiveForm,
    setNumber,
    setPassword,
    setIsPasswordVisible,
    number,
    password,
    isPasswordVisible,
    passwordForm,
    setPasswordForm,
    oldPassword,
    setOldPassword,
    newPassword,
    setNewPassword: setStateNewPassword,
    confirmPassword,
    setConfirmPassword,
    frNewPassword,
    setFrNewPassword,
    frConfirmPassword,
    setFrConfirmPassword,
    isFrNewPasswordVisible,
    setIsFrNewPasswordVisible,
    isFrConfirmPasswordVisible,
    setIsFrConfirmPasswordVisible,
    isOtpVerified,
    setIsOtpVerified,
    isOldPasswordVisible,
    setIsOldPasswordVisible,
    isNewPasswordVisible,
    setIsNewPasswordVisible,
    isConfirmPasswordVisible,
    setIsConfirmPasswordVisible,
    otp,
    setOtp,
    isOtp,
    setIsOtp,
  } = useLogin();
  const [loading, setLoading] = useState(() => false);

  // useNavigate
  const navigate = useNavigate();
  // useLocation
  const locationDetails: any = useLocation();

  // does: handle the number input
  function handleNumber(e: React.ChangeEvent<HTMLInputElement>) {
    setNumber((prevNumber) => {
      return { ...prevNumber, value: e.target.value };
    });
  }
  // does: handle the password input
  function handlePassword(e: React.ChangeEvent<HTMLInputElement>) {
    setPassword((prevPassword) => {
      return { ...prevPassword, value: e.target.value };
    });
  }
  // does: handle the old password input
  function handleOldPassword(e: React.ChangeEvent<HTMLInputElement>) {
    setOldPassword((prevPassword) => {
      return { ...prevPassword, value: e.target.value };
    });
  }
  // does: handle the new password input
  function handleNewPassword(e: React.ChangeEvent<HTMLInputElement>) {
    setStateNewPassword((prevPassword) => {
      return { ...prevPassword, value: e.target.value };
    });
  }
  // does: handle the confirm password input
  function handleConfirmPassword(e: React.ChangeEvent<HTMLInputElement>) {
    setConfirmPassword((prevPassword) => {
      return { ...prevPassword, value: e.target.value };
    });
  }
  // does: handle the new password input
  function handleFrNewPassword(e: React.ChangeEvent<HTMLInputElement>) {
    setFrNewPassword((prevPassword) => {
      return { ...prevPassword, value: e.target.value };
    });
  }
  // does: handle the new password input
  function handleFrConfirmPassword(e: React.ChangeEvent<HTMLInputElement>) {
    setFrConfirmPassword((prevPassword) => {
      return { ...prevPassword, value: e.target.value };
    });
  }
  // does: redirect to direct sales agent form
  const signUpNow = () => {
    setActivePage("partners");
    setActivePartner("Direct Sales Agent");
    navigate("/");
  };

  const location = useLocation()

  // does: validate number
  function validateNumber(e: React.FocusEvent<HTMLInputElement, Element>) {
    if (e.target.value === "") return;
  }
  // does: validate password
  function validatePassword(e: React.FocusEvent<HTMLInputElement, Element>) {
    if (e.target.value === "") return;
  }

  // does: handle the otp
  function handleOtp(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!number.value) {
      return alert("Please provide number!");
    }
    if (activeForm === "Transporter") {
      sendOTP({ contact: Number(number.value) })
        .then(() => {
          setIsOtp(true);
          showMessage("OTP sent successfully!", success, 2000);
        })
        .catch((err) => {
          setIsOtp(false);
          showHttpError(err);
        });
    } else {
      sendCustomerOTP({ contact: Number(number.value) })
        .then(() => {
          setIsOtp(true);
          showMessage("OTP sent successfully!", success, 2000);
        })
        .catch((err) => {
          setIsOtp(false);
          showHttpError(err);
        });
    }
  }
  // does: handle the otp verification
  function handleOtpVerification() {
    if (typeof otp !== "object") return;
    if (!otp.value) {
      return alert("OTP is required!");
    }
    const payload = { contact: number.value, OTP: otp.value };
    if (activeForm === "Transporter") {
      verifyOTP(payload)
        .then(() => {
          setIsOtpVerified(true);
          setOtp(otp);
        })
        .catch((err) => {
          setIsOtpVerified(false);
          setOtp((prevOtp) => {
            const prev = prevOtp as StateObjType;
            return {
              ...prev,
              value: "",
            };
          });
          showHttpError(err);
        });
    } else {
      verifyCustomerOTP(payload)
        .then(() => {
          setIsOtpVerified(true);
          setOtp(otp);
        })
        .catch((err) => {
          setIsOtpVerified(false);
          setOtp((prevOtp) => {
            const prev = prevOtp as StateObjType;
            return {
              ...prev,
              value: "",
            };
          });
          showHttpError(err);
        });
    }
  }

  // does: handle the login submit
  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      let deviceId = localStorage.getItem("deviceId");
      if (activeForm === "Customer") {
        await customerLogin(
          Number(number.value),
          password.value,
          true,
          deviceId ? deviceId : ""
        );
        return;
      }
      await login(
        Number(number.value),
        password.value,
        "",
        deviceId ? deviceId : ""
      );
    } catch (error) {
      console.error("Error while login:", error);
    } finally {
      setLoading(false);
    }
  };
  // does: change the password
  function handleChangePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!oldPassword.value || !newPassword.value || !confirmPassword.value) {
      return alert("Please provide all fields!");
    }
    if (newPassword.value !== confirmPassword.value) {
      return alert("Passwords do not match!");
    }
    try {
      const data = {
        username: Number(number.value),
        password: oldPassword.value,
        newPassword: newPassword.value,
      };
      setNewPassword(data)
        .then((_response: any) => {
          showMessage("Password Changes successfully", success, 3000);
          navigate("/login");
        })
        .catch((err: any) => {
          console.log(err, "error");
          showHttpError(err);
        });
    } catch (error) {
      console.log("Login Component | Change Password:", error);
    }
    setPasswordForm("Login");
  }
  // does: forget the password
  function handleForgetPassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (typeof otp !== "object") return;
    if (!frNewPassword.value || !frConfirmPassword.value) {
      return alert("Please provide all fields!");
    }
    if (frNewPassword.value !== frConfirmPassword.value) {
      return alert("Passwords do not match!");
    }
    if (activeForm === "Transporter") {
      setForgetPassword({
        OTP: otp.value,
        password: frNewPassword.value,
        contact: Number(number.value),
      })
        .then(() => {
          showMessage("Password Changes successfully!", success, 3000);
        })
        .catch((err) => {
          setIsOtp(false);
          setIsOtpVerified(false);
          showHttpError(err);
        });
    } else {
      setCustomerPassword({
        OTP: otp.value,
        password: frNewPassword.value,
        contact: Number(number.value),
      })
        .then(() => {
          showMessage("Password Changes successfully!", success, 3000);
        })
        .catch((err) => {
          setIsOtp(false);
          setIsOtpVerified(false);
          showHttpError(err);
        });
    }
    setFrNewPassword((prev) => {
      return { ...prev, value: "" };
    });
    setFrConfirmPassword((prev) => {
      return { ...prev, value: "" };
    });
    setOtp((prevOtp) => {
      const prev = prevOtp as StateObjType;
      return {
        ...prev,
        value: "",
      };
    });
    setPasswordForm("Login");
  }

  useEffect(() => {
    if(location.state && location?.state?.action === "isBookedWithoutToken" && location?.state?.data?.contact){
      setActiveForm("Customer")
      setNumber((prevNumber) => {
        return { ...prevNumber, value: location?.state?.data?.contact };
      });
    }
  },[location?.state?.action,location?.state?.data,location?.state?.data?.contact])

  useEffect(() => {
    if(activeForm === "Customer"){
      sessionStorage.setItem("role","customer")
    }
    else{
      sessionStorage.setItem("role","transporter")
    }
  },[activeForm])
  
  // does: authenticates user tokens

  // does: if user as signed in as customer it will authenticate
  // const handleRefreshTokenLogin = async (token:string) => {
  //   await dispatch(customerLoginWithToken(token));
  // };

  // useEffect(() => {
  //   if (activeForm === "Customer" && isCustomer) {
  //     if (refreshTokenExpiresAt && new Date(refreshTokenExpiresAt).getTime() > new Date().getTime() && refreshToken) {
  //       handleRefreshTokenLogin(refreshToken);
  //     }
  //   }
  // }, [activeForm, isCustomer, refreshToken, refreshTokenExpiresAt]);

  // useEffect(() => {
  //   const handleStorageChange = (event:any) => {
  //     if (event.key === 'refreshToken' || event.key === 'refreshTokenExpiresAt' || event.key === 'isCustomer' || event.key === 'kabra:token') {
  //       if (activeForm === "Customer" && isCustomer) {
  //         if (refreshTokenExpiresAt && new Date(refreshTokenExpiresAt).getTime() > new Date().getTime() && refreshToken) {
  //           handleRefreshTokenLogin(refreshToken);
  //         }
  //         else{
  //           navigate("/login")
  //         }
  //       } else{
  //         navigate("/login")
  //       }
  //     } else{
  //       navigate("/login")
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, [isCustomer, refreshToken, refreshTokenExpiresAt]);

  // if (user.token && user.userName && sessionStorage.getItem("kabra:token")) {
  //   if (user.branches.length === 0 && user.fleets.length === 0) {
  //     return <Navigate to="/dashboard/operations/booking" />;
  //   } else {
  //     return <Navigate to="/dashboard/slider" state={{ from: "/login" }} />;
  //   }
  // }

  if (customerReducer && customerReducer?.token && customerReducer?.name && localStorage.getItem("kabra:customertoken") && activeForm === "Customer") {
    if (locationDetails.state?.from === "Payment_Success") {
      let finalAns = locationDetails.state.content.split("&");
      let finalAns2 = finalAns[finalAns.length - 1].split("=");

      return (
        <Navigate
          to="/CustomerDashboard/TempTracking"
          state={{
            from: "Customer-Authenticated-BranchOrder",
            backSource: locationDetails.pathname,
            docketNumber: { docket: finalAns2[1] },
            typeOfDocket: "Branch Docket",
          }}
        />
      );
    } else {
      return (
        <Navigate
          to="/customerDashboard/express-parcel"
          state={{ from: "login" }}
        />
      );
    }
  }

  // const handleRefreshTokenLogin = async (token:string) => {
  //   await dispatch(customerLoginWithToken(token));
  // };

  // useEffect(() => {
  //   if ((activeForm === "Customer" && isCustomer) || isFresh) {
  //     if (refreshTokenExpiresAt && new Date(refreshTokenExpiresAt).getTime() > new Date().getTime() && refreshToken) {
  //       handleRefreshTokenLogin(refreshToken);
  //     }
  //   }
  // }, [activeForm, isCustomer, refreshToken, refreshTokenExpiresAt]);


  // useEffect(() => {
  //   const handleStorageChange = (event:any) => {
  //     if (event.key === 'refreshToken' || event.key === 'refreshTokenExpiresAt') {
  //       if (isCustomer) {
  //         syncLoginState();
  //       } else {
  //         navigate("/login");
  //       }
  //     }
  //     else{
  //       navigate("/login");
  //     }
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, [isCustomer]);

  if (user.token && user.userName && sessionStorage.getItem("kabra:token") && activeForm === "Transporter") {
    if (user.branches.length === 0 && user.fleets.length === 0) {
      return <Navigate to="/dashboard/operations/booking" />;
    } else {
      return <Navigate to="/dashboard/slider" state={{ from: "/login" }} />;
    }
  }

  // does: redirect to the customer dashboard if login token is already exists
  // const handleRefreshTokenLogin = () => {
  //   customerLoginWithToken(localStorage?.refreshToken);
  // };

  // component return
  return (
    <main className="login-page-container | custom-scrollbar">
      <section className="login-page">
        <div className="login-page-left | relative">
          <div className="| flex-col gap-1">
            <Heading className="| fs-subheading text-white-900 text-left">
              Welcome to TapTap
            </Heading>
            <p className="| m-0 fs-tagline text-white-700 fw-600">
              Still don't have an account?
            </p>
            <Button
              variant="primary"
              action="secondary"
              Icon={<SignInIcon />}
              className="| as-start fs-button"
              onClick={signUpNow}
            >
              Join Us Now
            </Button>
          </div>
          <img loading="lazy" src="/assets/login/login.svg" alt="login" />
        </div>
        <div className="login-page-right | flex-center p-3">
          {passwordForm === "Login" && (
            <div className="| w-100 flex-col gap-2">
              <Heading className="| text-primary-400">Sign In</Heading>
              <div className="login-page-form | shadow-small br-4">
                <ul className="| m-0 two-column">
                  {forms.map((item) => (
                    <button
                      key={item}
                      className={`login-page-right-logintype | user-select-none pointer fs-link fw-500 ${item} ${
                        activeForm === item ? "active" : ""
                      } `}
                      onClick={() => setActiveForm(item)}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                        if (e.key === "Enter" || e.key === " ") {
                          e.preventDefault();
                          setActiveForm(item);
                        }
                      }}
                    >
                      {item}
                    </button>
                  ))}
                </ul>
                <form
                  className="login-page-form-content | p-2 flex-col gap-1"
                  onSubmit={handleLoginSubmit}
                >
                  <div className="login-page-form-group-with-error">
                    <div className="login-page-form-group">
                      <span className="| fs-icon p-10 text-primary-400">
                        <CallIcon />
                      </span>
                      <input
                        type="tel"
                        maxLength={10}
                        disabled={location?.state?.data?.contact}
                        value={number.value}
                        onChange={handleNumber}
                        onBlur={validateNumber}
                        placeholder="Phone Number"
                        required
                      />
                    </div>
                  </div>
                  <div className="login-page-form-group-with-error">
                    <div className="login-page-form-group">
                      <span
                        className="| fs-icon p-10 text-primary-400"
                        onClick={() => setIsPasswordVisible((prev) => !prev)}
                      >
                        {isPasswordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                      </span>
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        minLength={6}
                        value={password.value}
                        onChange={handlePassword}
                        onBlur={validatePassword}
                        placeholder="Password"
                        required
                      />
                    </div>
                  </div>
                  <Button
                    variant="primary"
                    action="primary"
                    Icon={loading ? <></> : <LetsGoIcon />}
                    disabled={loading}
                    className="| jc-center fs-button as-center"
                  >
                    <LoadingIndicator isLoading={loading} loadingText="Login...">Let's Begin</LoadingIndicator>
                  </Button>
                  <p className="| m-0 text-center text-black-100 fs-link m-fs-small fw-500 flex gap-4 ai-center jc-center">
                    <span>New on TapTap?</span>
                    <span
                      className="| text-primary-400 pointer hover-underline"
                      onClick={signUpNow}
                    >
                      SignUp Now
                    </span>
                  </p>
                  <p className="| m-0 line"></p>
                  <p className="| m-0 flex gap-4 jc-evenly text-black-100 fs-body m-fs-small fw-500">
                    <span
                      className="| pointer hover-underline text-center"
                      onClick={() => setPasswordForm("Change Password")}
                    >
                      Change Password
                    </span>
                    <span className="| flex-center">|</span>
                    <span
                      className="| pointer hover-underline text-center"
                      onClick={() => setPasswordForm("Forget Password")}
                    >
                      Forget Password
                    </span>
                  </p>
                </form>
              </div>
            </div>
          )}
          {passwordForm === "Change Password" && (
            <form
              className="flex-col gap-1 p-2 border-white br-1"
              onSubmit={(e) => {
                handleChangePassword(e);
              }}
            >
              <h1 className="m-0 fs-subheading text-center text-primary-400 fw-600">
                Change Password
              </h1>
              <div className="login-page-form-group-with-error">
                <div className="login-page-form-group">
                  <span className="| fs-icon p-10 text-primary-400">
                    <CallIcon />
                  </span>
                  <input
                    type="tel"
                    maxLength={10}
                    value={number.value}
                    onChange={handleNumber}
                    onBlur={validateNumber}
                    placeholder="Phone Number"
                    required
                  />
                </div>
              </div>
              <div className="login-page-form-group-with-error">
                <div className="login-page-form-group">
                  <span
                    className="| fs-icon p-10 text-primary-400"
                    onClick={() => setIsOldPasswordVisible((prev) => !prev)}
                  >
                    {isOldPasswordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                  </span>
                  <input
                    type={isOldPasswordVisible ? "text" : "password"}
                    minLength={6}
                    value={oldPassword.value}
                    onChange={handleOldPassword}
                    onBlur={validatePassword}
                    placeholder="Enter Old Password"
                    required
                  />
                </div>
              </div>
              <div className="login-page-form-group-with-error">
                <div className="login-page-form-group">
                  <span
                    className="| fs-icon p-10 text-primary-400"
                    onClick={() => setIsNewPasswordVisible((prev) => !prev)}
                  >
                    {isNewPasswordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                  </span>
                  <input
                    type={isNewPasswordVisible ? "text" : "password"}
                    minLength={6}
                    value={newPassword.value}
                    onChange={handleNewPassword}
                    onBlur={validatePassword}
                    placeholder="Enter New Password"
                    required
                  />
                </div>
              </div>
              <div className="login-page-form-group-with-error">
                <div className="login-page-form-group">
                  <span
                    className="| fs-icon p-10 text-primary-400"
                    onClick={() => setIsConfirmPasswordVisible((prev) => !prev)}
                  >
                    {isConfirmPasswordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                  </span>
                  <input
                    type={isConfirmPasswordVisible ? "text" : "password"}
                    minLength={6}
                    value={confirmPassword.value}
                    onChange={handleConfirmPassword}
                    onBlur={validatePassword}
                    placeholder="Confirm Password"
                    required
                  />
                </div>
              </div>
              <Button
                variant="primary"
                action="primary"
                Icon={<LetsGoIcon />}
                className="| jc-center fs-button as-center"
              >
                Change
              </Button>
              <p
                className="m-0 text-center fs-link text-primary-400 pointer"
                onClick={() => setPasswordForm("Login")}
              >
                Back to Login
              </p>
            </form>
          )}
          {passwordForm === "Forget Password" &&
            (!isOtpVerified ? (
              <form
                className="flex-col gap-1 p-2 border-white br-1"
                onSubmit={handleOtp}
              >
                <Dialog
                  header={<>Enter your OTP</>}
                  body={
                    <>
                      <OTPInput otp={otp} setOtp={setOtp} />
                    </>
                  }
                  footer={
                    <>
                      <Button
                        variant="primary"
                        action="secondary"
                        onClick={handleOtpVerification}
                        type="button"
                      >
                        Enter
                      </Button>
                    </>
                  }
                  state={isOtp}
                  setState={setIsOtp}
                  variant="primary"
                  size="small"
                  isOutsideClickOff={false}
                />
                <h1 className="m-0 fs-subheading text-center text-primary-400 fw-600">
                  Forget Password
                </h1>
                <div className="login-page-form-group-with-error">
                  <div className="login-page-form-group">
                    <span className="| fs-icon p-10 text-primary-400">
                      <CallIcon />
                    </span>
                    <input
                      type="tel"
                      maxLength={10}
                      value={number.value}
                      onChange={handleNumber}
                      onBlur={validateNumber}
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                </div>
                <Button
                  variant="primary"
                  action="primary"
                  Icon={<LetsGoIcon />}
                  className="| jc-center fs-button as-center"
                >
                  Send OTP
                </Button>
                <p
                  className="m-0 text-center fs-link text-primary-400 pointer"
                  onClick={() => setPasswordForm("Login")}
                >
                  Back to Login
                </p>
              </form>
            ) : (
              <form
                className="flex-col gap-1 p-2 border-white br-1"
                onSubmit={handleForgetPassword}
              >
                <h1 className="m-0 fs-subheading text-center text-primary-400 fw-600">
                  Update your Password
                </h1>
                <div className="login-page-form-group-with-error">
                  <div className="login-page-form-group">
                    <span
                      className="| fs-icon p-10 text-primary-400"
                      onClick={() => setIsFrNewPasswordVisible((prev) => !prev)}
                    >
                      {isFrNewPasswordVisible ? <EyeIcon /> : <EyeSlashIcon />}
                    </span>
                    <input
                      type={isFrNewPasswordVisible ? "text" : "password"}
                      minLength={6}
                      value={frNewPassword.value}
                      onChange={handleFrNewPassword}
                      onBlur={validatePassword}
                      placeholder="Enter New Password"
                      required
                    />
                  </div>
                </div>
                <div className="login-page-form-group-with-error">
                  <div className="login-page-form-group">
                    <span
                      className="| fs-icon p-10 text-primary-400"
                      onClick={() =>
                        setIsFrConfirmPasswordVisible((prev) => !prev)
                      }
                    >
                      {isFrConfirmPasswordVisible ? (
                        <EyeIcon />
                      ) : (
                        <EyeSlashIcon />
                      )}
                    </span>
                    <input
                      type={isFrConfirmPasswordVisible ? "text" : "password"}
                      minLength={6}
                      value={frConfirmPassword.value}
                      onChange={handleFrConfirmPassword}
                      onBlur={validatePassword}
                      placeholder="Enter New Password"
                      required
                    />
                  </div>
                </div>
                <Button
                  variant="primary"
                  action="primary"
                  Icon={<LetsGoIcon />}
                  className="| jc-center fs-button as-center"
                  type="submit"
                >
                  Update
                </Button>
                <p
                  className="m-0 text-center fs-link text-primary-400 pointer"
                  onClick={() => setPasswordForm("Login")}
                >
                  Back to Login
                </p>
              </form>
            ))}
        </div>
      </section>
      <div className="login-page-footer">
        <Footer />
      </div>
    </main>
  );
};

export default Login;
