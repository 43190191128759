// react
import React from 'react'
// ui comopnents
import { Heading, PageHeader } from "../UI"
// css
import './get-details.css';
// constants (icon)
import { CallIcon, PanCardIcon, SharpLocationIcon, SharpMailIcon } from "../../constants/icons";


// variables
const gstDetailsData = [
    {
        id: 0,
        state: "Gujarat",
        headOffice: "Bapunagar",
        gstin: "24AAGCK6871L1Z2",
    },
    {
        id: 1,
        state: "Maharashtra",
        headOffice: "Aurangabad",
        gstin: "27AAGCK6871L1ZW",
    },
    {
        id: 2,
        state: "Madhya Pradesh",
        headOffice: "Indore",
        gstin: "23AAGCK6871L1Z4",
    },
];
const linkIcons = [<SharpLocationIcon />, <SharpMailIcon />, <CallIcon />, <PanCardIcon />];

// FC: GST Details Component
const GSTDetails = () => {
    // component return
    return (
        <main>
            <section className="page-container-grid page-container-auto-grid">
                <PageHeader title="GST Details" imgSrc="gst-details/bg.jpg" />
                {/* Table to showcase the details */}
                <div className="gst-details-table-container | flex-center small-container py-2">
                    <table className="gst-details-table">
                        <thead>
                            <tr className="| fs-subheading fw-600">
                                <th>State</th>
                                <th>Head Office</th>
                                <th>GSTIN</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gstDetailsData.map((item, index) => (
                                <tr key={index} className="| fs-medium fw-500">
                                    <td>{item.state}</td>
                                    <td>{item.headOffice}</td>
                                    <td>{item.gstin}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            {/* Office Information */}
            <section className="page-container-grid | p-3 bg-primary-400 flex-col gap-2">
                <Heading className="| text-white-900">Office Information</Heading>
                <div className="| flex-center">
                    <ul className="| small-container two-column t-one-column gap-1 contact-info-list">
                        {/* Location */}
                        <li className="| flex-col-center gap-1 p-1 br-4 bg-primary-300 text-white-900">
                            <span className="| fs-icon">{linkIcons[0]}</span>
                            <p className="m-0 fs-link text-center fw-400">02/13 Vallabh Flats, Vijay Chowk, NR Sardar Patel Diamond Market, Bapunagar, Ahmedabad, Gujarat 380024</p>
                        </li>
                        {/* Mail */}
                        <li className="| flex-col-center gap-1 p-1 br-4 bg-primary-300 text-white-900">
                            <span className="| fs-icon">{linkIcons[1]}</span>
                            <a href="mailto:customersupport@kabraexpress.com" className="| m-0 fs-link text-center text-white-900 fw-400">customersupport@kabraexpress.com</a>
                        </li>
                        {/* Contact Number */}
                        <li className="| flex-col-center gap-1 p-1 br-4 bg-primary-300 text-white-900">
                            <span className="| fs-icon">{linkIcons[2]}</span>
                            <div className="| flex-col gap-4">
                                <a href="tel:7575857777" className="m-0 fs-link text-center text-white-900 fw-400">+917575857777</a>
                                <a href="tel:9099982886" className="m-0 fs-link text-center text-white-900 fw-400">+919099982886</a>
                            </div>
                        </li>
                        {/* Pan card Information */}
                        <li className="| flex-col-center gap-1 p-1 br-4 bg-primary-300 text-white-900">
                            <span className="| fs-icon">{linkIcons[3]}</span>
                            <p className="m-0 fs-link text-center fw-400">AAGCK6871L</p>
                        </li>
                    </ul>
                </div>
            </section>
        </main>
    )
}

export default GSTDetails