import React, { useState, Fragment, useEffect } from "react";
import ToolTip from "../../../Components/ToolTip/ToolTip";
import { showLoader, hideLoader } from "../../../actions/UserActions";
import { connect } from "react-redux";
import PackageDescriptionDialog from "../../generics/PackageDescriptionDialog";
import { getCustomerReport } from "../../../services/customerReport";
import formatDate from "../../../services/FormatDate";
import Report from "./Report";
import { Printd } from "printd";
import * as XLSX from 'xlsx';
import "./customer.css";
import { showHttpError } from "../../../utils/message";
import { isArrayCheck } from "../../generics/CheckArray";
import DocketNumberRedirect from "../../../Components/DocketNumber/DocketNumberRedirect";
import {
  ButtonSecondary,
} from "../../../Components/Button/Buttoncontainer";
import ReactTable from "../../../Components/ReactTable/ReactTable"
import { getAllBranches } from "../../../services/branchRank";
import { DownloadButton, RefreshButton } from "../../../Components/Button/AllButton";
import { Switch } from "../../../Components/Switch/Switch";
import { Filtervalue, FiltervalueContainer, FiltervalueDestinationBranch, FiltervalueOriginBranch } from "../../../Components/filtercomponents/Filtervalue";
import FilterDrawer from "../../../newSrc/components/Filter/filter-drawer";
import { TwoColumnSelectContainer } from "../../../newSrc/components/Filter/select-container";
import { Select } from "../../../newSrc/components/UI";
import OriginDestinationBranch from "../../../newSrc/components/Filter/origin-destination-branch";
// import { withRouter } from "../../../hoc/withRouter";

const react_table : any = `
        table, th, td {
            border: 1px solid black;
			border-collapse: collapse;
        }
        table *{
            padding: 3px;
        }
        .book_report_heading{
            font-size: 18px;
            font-weight: 900;
            margin: 10px 0px;
        }
    `;

const paymentTypeOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "ToPay",
    value: "topay",
  },
  {
    label: "Paid",
    value: "paid",
  },
  {
    label: "Credit",
    value: "credit",
  },
  {
    label: "FOC",
    value: "foc",
  },
  ];

const paymentModeOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Sent",
    value: "sent",
  },
  {
    label: "Received",
    value: "received",
  },
  {
    label: "Credited",
    value: "credited",
  },
]

const statusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Settled",
    value: true,
  },
  {
    label: "Unsettled",
    value: false,
  },
];

const canceledOptions = [
  {
    label: "All",
    value: false,
  },
  {
    label: "Canceled",
    value: true,
  },
];

const CustomerReport = (props: any) => {
  
  const yr = new Date().getFullYear();
  const [showModal, setShowModal] = useState(false);
  const [isConnectedCustomer, setIsConnectedCustomer] = useState(false);
  const [paymentMode, setPaymentMode] = useState({ label: "All", value: "" });
  const [month, setMonth] = useState<any>("");
  const [year, setYear] = useState<any>("");
  const [yearOptions, setYearOptions] = useState<any>("");
  const [type, setType] = useState({ label: "All", value: "" });
  const [settled, setSettled] = useState<any>("");
  const [onlyCanceled, setOnlyCanceled] = useState<any>(
    {
      label: "All",
      value: false,
    },
  );
  const [customerData, setCustomerData] = useState<any>("");
  const [showDialog, setShowDialog] = useState(false);
  const [allBranchList, setallBranchList] = useState<any>([]);
  const [fromBranch, setFromBranch] = useState<any>("");
  const [toBranch, setToBranch] = useState<any>("");
  const [materialAndPackingData, setMaterialAndPackingData] = useState<any>("");
  const [resetTimeStamp, setResetTimeStamp] = useState<Date>(new Date())

  const monthOptions = [
    { label: "All", value: "" },
    { label: "January", value: 1 },
    { label: "Februrary", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  useEffect(()=>{
    branchList();
  },[])

  useEffect(() => {
    let arr = [];
    for (let i = 2019; i <= yr; i++) {
      arr.push({ label: i, value: i });
    }
    setYearOptions([{ label: "All", value: "" }, ...arr]);
    fetchData();
  }, [props.customerId, resetTimeStamp]);
  const branchList = async () => {
    props?.showLoader();
    try {
      console.log(`1props` , props?.allbranches)
      if(props?.allbranches?.length > 0){
        setallBranchList([
          { label: "all", value: "" },
          ...props?.allbranches?.map((i: any) => ({
            label: i.branchName + ", " + i.address.city.name,
            value: i._id,
          })),
        ]);
        props?.hideLoader();
      }
      else{
        const companies:string[] = [props.companyId];
        const payload = {
          companyIds: companies,
          active: true
        }
        
        const branches = await getAllBranches(payload);
        setallBranchList([
          { label: "all", value: "" },
          ...branches.map((i: any) => ({
            label: i.branchName + ", " + i.address.city.name,
            value: i._id,
          })),
        ]);
        props?.hideLoader();
      }
    } catch (err : any) {
      props?.hideLoader();
      showHttpError(err);
    }
  };

  const fetchData = async () => {
    props.showLoader();

    try {
      let payload: any = {};      

      if (paymentMode.value !== "" && type.value !== "") {
        payload = {
          customer: props.customerId,
          paymentMode: paymentMode.value,
          type: type.value,
        };
        if (month && month.value) {
          payload.month = month.value;
        }
        if (year && year.value) {
          payload.year = year.value;
        }
      } else if (paymentMode.value !== "") {
        payload = {
          customer: props.customerId,
          paymentMode: paymentMode.value,
        };
        if (month && month.value) {
          payload.month = month.value;
        }
        if (year && year.value) {
          payload.year = year.value;
        }
      } else if (type.value !== "") {
        payload = {
          customer: props.customerId,
          type: type.value,
        };
        if (month && month.value) {
          payload.month = month.value;
        }
        if (year && year.value) {
          payload.year = year.value;
        }
      } else {
        payload = {
          customer: props.customerId,
        };
        if (month && month.value) {
          payload.month = month.value;
        }
        if (year && year.value) {
          payload.year = year.value;
        }
      }

      if (settled && settled.value) {
        payload.settled = settled.value;
      }

      if(fromBranch && fromBranch.value){
        payload.fromBranch = fromBranch.value;
      }

      if(toBranch && toBranch.value){
        payload.toBranch = toBranch.value;
      }
      if(isConnectedCustomer){
        payload.isConnectedCustomer = isConnectedCustomer;
      }
      if(onlyCanceled){
        payload.onlyCanceled = onlyCanceled.value
      }
      const response = await getCustomerReport(payload);
      setCustomerData(response);
      props.hideLoader();
    } catch (err : any) {
      props.hideLoader();

      showHttpError(err);
    }
  };

  const handleSubmit = () => {
    fetchData();
  };

  const print = () => {
    const d = new Printd();
    let printDoc: any = document.getElementById("customer_report_actual");
    d.print(
      printDoc,
      react_table
    );
  };

  const download = (records: any) => {
    let excelRecords = [
      [
        "Date",
        "Docket No",
        "Qty",
        "Sender",
        "Receiver",
        "Credit Customer",
        "Is Canceled",
        "Payment Mode",
        "Goods Value",
        "Gross Freight",
        "Handling Charges",
        "Builty Charges",
        "Gross",
        "Delivery Charge",
        "Discount",
        "Demurrage",
        "Gst Penalty",
        "Settled",
        "Settled Amount",
        "creditSettled",
      ],
      ...records.map((rec: any) => [
        formatDate(rec.createdAt),
        rec.docketNumber,
        rec.packages.reduce((a: any, b: any) => a + b.qty, 0),
        `${rec.senderName} - ${Number(rec.senderContact)}`,
        `${rec.receiverName} - ${Number(rec.receiverContact)}`,
        rec.creditCustomer
          ? `${rec.creditCustomer.name} - ${Number(rec.creditCustomer.contact)}`
          : "--",
        rec.isCanceled ? "Yes" : "No",
        rec.paymentMode,
        Number(rec.goods),
        Number(rec.grossFreight ? rec.grossFreight  : 0 ),
        Number(rec.handling ? rec.handling  : 0 ),
        Number(rec.bilty ? rec.bilty  : 0 ),
        Number(rec.gross ? rec.gross  : 0 ),
        Number(rec.deliveryCharge ? rec.deliveryCharge  : 0 ),
        Number(rec.discount ? rec.discount  : 0 ),
        Number(rec.paidDemurrage ? rec.paidDemurrage  : 0 ),
        Number(rec.gstCustodyPenalty ? rec.gstCustodyPenalty  : 0 ),
        String(rec.settled ? "true" : "false"),
        Number(rec.settledAmount ? rec.settledAmount : 0),
        String(rec.creditSettled ? "true" : "false"),
      ]),
    ];
    let xcel = {
      SheetNames: ["customer"],
      Sheets: {
        customer: XLSX.utils.aoa_to_sheet(excelRecords),
      },
    };
    XLSX.writeFile(xcel, "Customer_Report.xlsx");
  };

  const handleReset = () => {
    setMonth("");
    setYear("");
    setPaymentMode({ label: "All", value: "" });
    setType({ label: "All", value: "" });
    setSettled("");
    setCustomerData("");
    setOnlyCanceled({ label: "All", value: false })
    setResetTimeStamp(new Date())
  };

  const getDocketDetails = (row_data: any) => {
    setMaterialAndPackingData(
      row_data.packages.map((pck: any) => {
        return {
          slno: pck.qty,
          material: row_data.materialType.find(
            (_id: any) => _id._id === pck.materialType
          ).name,
          packing: row_data.packingType.find(
            (_id: any) => _id._id === pck.packingType
          ).name,
        };
      })
    );
    openDialog();
  };
  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };
  const columns : any = [
    {
      accessor: "createdAt",
      Header: "Date",
      Cell: (Props:any) => {
        const row = Props.row.original
        return <div>{formatDate(row.createdAt)}</div>;
      },
      sort: true,
    },
    {
      accessor: "docketNumber",
      Header: "Docket No",
      Cell: (Props:any) => {
        const row = Props.row.original
        return (
          <div>
            {" "}
            <DocketNumberRedirect docketNumber={row.docketNumber} />
          </div>
        );
      },
      sort: true,
    },
    {
      accessor: "originBranch",
      Header: "Origin Branch",
      Cell: (Props:any) => {
        const row = Props.row.original

        return <div>{row.originBranch && row.originBranch.branchName}</div>;
      },
      sort: true,
    },
    {
      accessor: "destBranch",
      Header: "Dest Branch",
      Cell: (Props:any) => {
        const row = Props.row.original

        return <div>{row.destBranch && row.destBranch.branchName}</div>;
      },
      sort: true,
    },
    {
      accessor: "packages",
      Header: "Qty",
      Cell: (Props:any) => {
        const row = Props.row.original

        return (
          <div className="pointer">
            <ToolTip title="Package Description" placement="bottom">
              <div onClick={() => getDocketDetails(row)}>
                {row.packages.reduce((a: any, b: any) => a + b.qty, 0)}
                <i className="fas fa-info-circle info_icon"></i>
              </div>
            </ToolTip>
          </div>
        );
      },
    },
    
    {
      accessor: "senderName",
      Header: "Sender",
      Cell: (Props:any) => {
        const row = Props.row.original

        return (
          <div>
            {row.senderName} - {row.senderContact}
          </div>
        );
      },
      sort: true,
    },
    {
      accessor: "receiverName",
      Header: "Receiver",
      Cell: (Props:any) => {
        const row = Props.row.original

        return (
          <div>
            {row.receiverName} - {row.receiverContact}
          </div>
        );
      },
      sort: true,
    },
    {
      accessor: "creditCustomer",
      Header: "Credit Customer",
      Cell: (Props:any) => {
        const row = Props.row.original

        return row.creditCustomer ? (
          <div>
            {row.creditCustomer.name} - {row.creditCustomer.contact}
          </div>
        ) : (
          <div> -- </div>
        );
      },
      sort: true,
    },
    {
      accessor: "isCanceled",
      style: { width: 30 },
      Header: "Is Canceled",
      Cell: (props:any) => {
        return props.value === true ? 'Yes' : 'No'
      },
    },
    {
      accessor: "paymentMode",
      style: { width: 30 },

      Header: "Payment Mode",
    },
    {
      accessor: "goods",
      Header: "Goods",
      style: { width: 30 },
      sort: true,
    },
    {
      accessor: "gross",
      style: { width: 30 },
      Header: "Gross",
      sort: true,
      Cell : (props: any) => {
        console.log(`Buitly Charges arawev` , props?.row?.original);
        return (
          <div>
            <div>{`Gross freight - ${props?.row?.original?.grossFreight ? props?.row?.original?.grossFreight : 0}` } </div>
            <div>{`Handling - ${props?.row?.original?.handling ? props?.row?.original?.handling : 0}`}</div>
            <div>{`Bilty - ${props?.row?.original?.bilty ? props?.row?.original?.bilty : 0}`}</div>
            <div>{`Gross - ${props?.row?.original?.gross ? props?.row?.original?.gross : 0}`}</div>
          </div>
        )
      } 
    },
    {
      accessor: "handling",
      Header: "Additional Charges",
      className: "center",
      Cell: (props: any) => {
        console.log(`deliveryCharge ` , props?.row?.original);
        return (
          <div>
            <div>{`Delivery - ${props?.row?.original?.deliveryCharge ? props?.row?.original?.deliveryCharge : 0}`}</div>
            <div>{`Discount - ${props?.row?.original?.discount ? props?.row?.original?.discount : 0}`}</div>
            <div>{`Demurrage - ${props?.row?.original?.paidDemurrage ? props?.row?.original?.paidDemurrage : 0}`}</div>
            <div>{`GST - ${props?.row?.original?.gstCustodyPenalty ? props?.row?.original?.gstCustodyPenalty : 0}`}</div>
            <br />
          </div>
        );
      },
    },
    {
      accessor: "settled",
      Header: "Settled",
      Cell: (Props:any) => {
        const row = Props.row.original

        return <div>{row.settled ? "true" : "false"}</div>;
      },
    },
    {
      accessor: "settled",
      id : "Sdcv",
      Header: "Settled Amount",
      Cell: (Props:any) => {
        const row = Props.row.original

        return <div>{row.settledAmount}</div>;
      },
    },
    {
      accessor: "creditSettled",
      style: { width: 30 },
      Header: "Credit Settled",
      Cell: (Props:any) => {
        const row = Props.row.original

        return <div>{row.creditSettled ? "true" : "false"}</div>;
      },
    },
  ];
  const hoverColumns : any = [
    {
      accessor: "slno",
      Header: "Quantity",
    },
    {
      accessor: "packing",
      Header: "Packaging Type",
    },
    {
      accessor: "material",
      Header: "Material Type",
    },
  ];

  let totalPackages = 0,
    totalDockets = 0,
    toPayDockets = 0,
    paidDockets = 0,
    creditDockets = 0,
    focDockets = 0,
    paidAmt = 0,
    toPayAmt = 0,
    creditAmt = 0,
    focAmt = 0;

  isArrayCheck(customerData)
    ? customerData.map((item: any) => {
        // use bookingDetails.packages
        const qty = item.packages.reduce((pv: any, cv: any) => pv + cv.qty, 0);
        totalPackages += qty;
        ++totalDockets;

        switch (item.paymentMode) {
          case "topay":
            ++toPayDockets;
            toPayAmt += item.gross;
            break;
          case "paid":
            ++paidDockets;
            paidAmt += item.gross;
            break;
          case "credit":
            ++creditDockets;
            creditAmt += item.gross;
            break;
          case "foc":
            ++focDockets;
            focAmt += item.gross;
            break;
          default:
            break;
        }
      })
    : "";

  return (
    <Fragment>
      <br />
      <div style={{ padding: "10px" }}>
        {/*<Grid container spacing={16}>
          <Select3
            options={[
              {
                label: "All",
                value: "",
              },
              {
                label: "ToPay",
                value: "topay",
              },
              {
                label: "Paid",
                value: "paid",
              },
              {
                label: "Credit",
                value: "credit",
              },
              {
                label: "FOC",
                value: "foc",
              },
            ]}
            value={paymentMode}
            onChange={(e: any) => setPaymentMode(e)}
          />
          <Select3
            placeholder="From Branch"
            value={fromBranch}
            options={allBranchList}
            onChange={(e: any) => setFromBranch(e)}
          />

          <Select3
            placeholder="To Branch"
            value={toBranch}
            options={allBranchList}
            onChange={(e: any) => setToBranch(e)}
          />

          <Select3
            options={[
              {
                label: "All",
                value: "",
              },
              {
                label: "Sent",
                value: "sent",
              },
              {
                label: "Received",
                value: "received",
              },
              {
                label: "Credited",
                value: "credited",
              },
            ]}
            value={type}
            onChange={(e: any) => setType(e)}
          />

          <Select3
            options={monthOptions}
            value={month}
            onChange={(e: any) => setMonth(e)}
          />
          <Select3
            options={yearOptions}
            value={year}
            onChange={(e: any) => setYear(e)}
          />
          
          <Select3
            placeholder="Status"
            options={[
              {
                label: "All",
                value: "",
              },
              {
                label: "Settled",
                value: true,
              },
              {
                label: "Unsettled",
                value: false,
              },
            ]}
            value={settled}
            onChange={(e: any) => setSettled(e)}
          />
           <Grid
            xl={6}
            lg={6}
            md={6}
            sm={6}
            xs={6}
            style={{ padding: 2, fontSize: 14 }}
          >
          </Grid> 
          <Grid xl={2} lg={2} md={2} sm={6} xs={6} alignItems="center" >
            <div style={{display:"flex",justifyContent: "center",alignItems: 'center',height:"100%"}}>
            <ButtonPrimary
              style={{ fontSize: 12 }}
              onClick={() => handleSubmit()}
            >
              Submit
            </ButtonPrimary>
            <ButtonSecondary
              style={{ fontSize: 12,margin:"0 0 0 15px"}}
              onClick={() => handleReset()}
            >
              Reset
            </ButtonSecondary>
            </div>
          </Grid>
        </Grid> */}

          <FiltervalueContainer>
              {paymentMode && paymentMode.value ? (
                <Filtervalue label="Payment Type" value={paymentMode.label} />
              ) : null}
              {type && type.value ? (
                <Filtervalue label="Payment Mode" value={type.label} />
              ) : null}
              {fromBranch && fromBranch.value ? (
                <FiltervalueOriginBranch value={fromBranch.label} />
              ) : null}
              {toBranch && toBranch.value ? (
                <FiltervalueDestinationBranch value={toBranch.label} />
              ) : null}
              {month && month.value ? (
                <Filtervalue label="Month" value={month.label} />
              ) : null}
              {year && year.value ? (
                <Filtervalue label="Year" value={year.label} />
              ) : null}
              {settled && settled.value ? (
                <Filtervalue label="Status" value={settled.label} />
              ) : null}
              {isConnectedCustomer ? (
                <Filtervalue label="Is Customer" value={isConnectedCustomer} />
              ) : null}
          </FiltervalueContainer>

          <div style={{display : "flex" , marginTop : "2.5%"}}>
            {/* Customer | Pask Booking Filter Drawer */}
            <FilterDrawer
              state={showModal}
              setState={setShowModal}
              drawerBody={
                <div className="grid gap-1">
                  <TwoColumnSelectContainer
                    label1="Payment Type"
                    label2="Payment Mode"
                    children1={
                      <Select
                        options={paymentTypeOptions}  
                          value={paymentMode}
                          onChange={(e: any) => setPaymentMode(e)}
                        />
                    }
                    children2={
                      <Select
                        options={paymentModeOptions}
                        value={type}
                        onChange={(e: any) => setType(e)}
                      />
                    }
                  />
                  <OriginDestinationBranch 
                    allBranchList={allBranchList}
                    originBranch={fromBranch}
                    destinationBranch={toBranch}
                    setOriginBranch={setFromBranch}
                    setDesitnationBranch={setToBranch}
                  />
                  <TwoColumnSelectContainer
                    label1="Month"
                    label2="Year"
                    children1={
                      <Select
                        options={monthOptions}
                        value={month}
                        onChange={(e: any) => setMonth(e)}
                      />
                    }
                    children2={
                      <Select
                        options={yearOptions}
                        value={year}
                        onChange={(e: any) => setYear(e)}
                        label={"Year"}
                      />
                    }
                  />
                  <TwoColumnSelectContainer
                    label1="Status"
                    label2="Canceled"
                    children1={
                      <Select
                        options={statusOptions}
                        value={settled}
                        onChange={(e: any) => setSettled(e)}
                      />
                    }
                    children2={
                      <Select
                        options={canceledOptions}
                        value={onlyCanceled}
                        onChange={(e: any) => setOnlyCanceled(e)}
                      />
                    }
                  />
                  <Switch
                    onChange={() => setIsConnectedCustomer(!isConnectedCustomer)}
                    onClick={() => setIsConnectedCustomer(!isConnectedCustomer)}
                    checked={isConnectedCustomer}
                    value={isConnectedCustomer}
                    name="Is Connected Contact"
                    label="Is Connected Contact"
                  />
                </div>
              }
              onApplyClick={() => {
                handleSubmit()
                setShowModal(false)
              }}
            />
            <br />
            <ButtonSecondary
              disabled={customerData && customerData.length > 0 ? false : true}
              onClick={() => print()}
            >
              Print
            </ButtonSecondary>
            {/* <ButtonSecondary
              disabled={customerData && customerData.length > 0 ? false : true}
              style={{ fontSize: 12 }}
              onClick={() => download(customerData)}
            >
              Download
            </ButtonSecondary> */}
            
            <DownloadButton
              onClick={() => download(customerData)}
              disabled={customerData && customerData.length > 0 ? false : true}
            />
            <RefreshButton onClick={handleReset} />
          </div>
        <br />
        <br />
        <div style={{ display: "none" }}>
          <PackageDescriptionDialog
            showDialog={showDialog}
            columns={hoverColumns}
            closeDialog={closeDialog}
            arr={materialAndPackingData}
          />
        </div>
        {customerData && customerData.length > 0 ? (
          <ReactTable
            data={customerData && customerData.length > 0 ? customerData : []}
            columns={columns}
          />
        ) : (
          <h3 style={{ textAlign: "center" }}>No data Found</h3>
        )}
        {customerData && customerData.length > 0 && (
          <div className="row margin20">
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 cost_card_booking">
              <div>
                <b>Total Dockets:</b>
                <br />
                <br />
                <p className="font20">{totalDockets}</p>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 cost_card_booking">
              <div>
                <b>Total Packages:</b>
                <p className="font20">{totalPackages}</p>
              </div>
            </div>

            <div className=" col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 cost_card_booking">
              <b>Paid</b>
              {/* <p className="font20"></p> */}
              <div>
                <p className="font20 simple_flex">{paidDockets} Nos</p>
                <p className="font20 simple_flex">Rs. {paidAmt}</p>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 cost_card_booking">
              <div>
                <b>Credit</b>
              </div>
              <div>
                <p className="font20 simple_flex">{creditDockets} Nos</p>
                <p className="font20 simple_flex">Rs. {creditAmt}</p>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 cost_card_booking">
              <div>
                <b>ToPay </b>
              </div>
              <div>
                <p className="font20 simple_flex">{toPayDockets} Nos </p>
                <p className="font20 simple_flex">Rs. {toPayAmt}</p>
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 cost_card_booking">
              <div>
                <b>FOC</b>
              </div>
              <div>
                <p className="font20 simple_flex">{focDockets} Nos</p>
                <p className="font20 simple_flex">Rs. {focAmt}</p>
              </div>
            </div>

            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-xs-12 cost_card_booking">
              <div>
                <b>Total Amount:</b>
                <br />
                <br />
                <p className="font20">
                  {paidAmt + toPayAmt + focAmt + creditAmt}
                </p>
              </div>
              {/* <div className="simple_flex"><h5><b>FOC Amount:</b></h5><p>{focAmt}</p></div> */}
            </div>
          </div>
        )}
        <div style={{ display: "none" }}>
          <Report records={customerData} />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state : any) => ({
  allbranches: state.branch.allbranches,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerReport);
