import { Theme } from "@mui/material";
export const styles : any = (theme: Theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
    formControl: {
      marginTop: theme.spacing(1),
      width: 300,
    },
    syncDialogContent: {
      height: '200px', // Adjust the height as needed
    },
    formControl_medium: {
      margin: "8px 0 0 8px",
      width: 210,
    },
    menuItem: {
      // width: '100%',
      // borderBottom: '1px solid grey'
    },
    formControl_small: {
      minWidth: 100,
    },
    expansionDetailsForOda: {
      flexDirection: "column",
    },
    expansionSummaryDesk: {
      padding: "10px 10px 10px 30px",
    },
    expansionSummaryMob: {
      padding: "10px",
    },
    FullWidthTextField: {
      width: "100%",
    },
    Width98TextField: {
      width: "98%",
    },
    Width60TextField: {
      width: "60%",
    },
    textField: {
      margin: 0,
      width: 250,
    },
    textField_small: {
      width: 100,
    },
    textField_medium: {
      width: 140,
    },
    tooltipWidth: {
      maxWidth: 500,
    },
    operating: {
      minWidth: 240,
    },
    paper: {
      position: "absolute",
      width: theme.spacing(50),
      height: theme.spacing(30),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    creditCheck: {
      padding: 0,
      color: "#ff2337",
    },
    sexyText: {
      fontFamily: "Roboto",
      fontSize: "1em",
    },
    sexyTotalText: {
      fontFamily: "Roboto",
      fontSize: "1.5em",
    },
    clickable: {
      cursor: "pointer",
    },
  });
