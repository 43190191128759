import React, { Fragment, useState } from "react";
// import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { showHttpError } from "../../utils/message";
// import { showMessage, success } from "../../utils/message";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { RootState } from "../../store";
import ImageInput from "../../Components/ImageInput";
import {
  createComplaintGuest,
  createComplaintUser,
} from "../../services/complaint";
import jsonToFormdata from "../../utils/jsonToFormdata";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../Components/Dialog/DialogComponent";
import {
  ButtonDefault,
  ButtonPrimary,
} from "../../Components/Button/Buttoncontainer";
import { Input } from "../../Components/Input/Inputcomponents";
import { Select } from "../../Components/Selectcomponents/Select";
import Grid from "../../Components/Grid/Grid";


const Complaint = (props: any) => {
  const [openComplaint, setOpenComplaint] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [details, setDetails] = useState("");
  const [image, setImage] = useState("");
  const [complaintType, setComplaintType] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [customerType, setCustomerType] = useState<any>("");
  const [packageOrder,setPackageOrder] = useState<any>("")
  const [feedBack, setFeedBack] = useState("");
  const [misBehaveUser, setMissBehaveUSer] = useState<any>("");
  const [missBehaveBranch, setMissBehaveBranch] = useState<any>("");
 
  
  const complaintOptions = [
    { label: "Late delivery", value: "LD" },
    { label: "Damage", value: "DMG" },
    // { label: "Receipt Missing", value: "RM" },
    { label: "Missing", value: "PM" },
    {
      label: "Pilferage",
      value: "PF",
    },
    {
      label: "Misbehavior",
      value: "MB",
    },
    // { label: "Late Pickup ", value: "LP" },
    // { label: "Other ", value: "OT" },
  ];

  const customerOptions = [
    { label: "Sender", value: "sender" },
    { label: "Receiver", value: "receiver" },
    { label: "Other", value: "other" },
  ];
 
  const handleSubmit = async () => {
    props.showLoader();
    try {
      if(details === ""){
        props.hideLoader();
        return showHttpError(`Remarks Should Not Be Empty !~`);
      }
      let payload: any = {
        feedbackType: "COMP",
        complaintType: complaintType && complaintType.value,
        docketNumber: props.docketNumber,
        contactType: customerType && customerType.value,
        contactNumber: contact,
        contactName: name,
        messageText: details,
      };
      if (missBehaveBranch && missBehaveBranch.value) {
        payload.misbehaveBranch = missBehaveBranch.value;
      }
      if (misBehaveUser && misBehaveUser.value) {
        payload.misbehaveBy = misBehaveUser.value;
      }
      if (image) {
        payload.media = image;
      }
      const response: any = props.user.uid
        ? await createComplaintUser(payload)
        : await createComplaintGuest(jsonToFormdata(payload));
      setFeedBack(response.feedbackNumber);
      setOpen(true);
      props.hideLoader();
    } catch (err : any) {
      showHttpError(err);
      props.hideLoader();
    }
  };

  const handleClose = () => {
    setOpen(false);
    handleComplaintClose();
  };

  const handleComplaintClose = () => {
    setOpenComplaint(false);
    setName("");
    setContact("");
    setDetails("");
    setComplaintType("");
    setImage("");
    setCustomerType("");
    setFeedBack("");
    setMissBehaveBranch("")
    setMissBehaveUSer("")
    setPackageOrder("")
    props.getGuestUpdatedData(props.docketID);
  };
 const showPkg = ["PM","DMG","PF"]

  return (
    <Fragment>
      <Dialog open={openComplaint} onClose={handleComplaintClose}>
        <DialogTitle>
          <h4 style={{ textAlign: "center" }}>Register Complaint</h4>
        </DialogTitle>
        <DialogContent>
          <div className="pt-1">
            <Grid container spacing={0}>
              <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                Select type of Complaint :{" "}
              </Grid>
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{ textAlign: "left", padding: "4px 0px" }}
              >
                <Select
                  value={complaintType}
                  options={complaintOptions}
                  onChange={(e: any) =>{ 
                    setComplaintType(e)
                    setMissBehaveUSer("");
                    setMissBehaveBranch("");
                    setPackageOrder("")
                  }}
                  placeholder="Select Complaint"
                />
              </Grid>
              {showPkg.includes(complaintType?.value) &&
              <>  
              <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                Select Packages :{" "}
              </Grid>
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{ textAlign: "left", padding: "4px 0px" }}
              >
                <Select
                  value={packageOrder}
                  options={props?.orderArrayOption ||[]}
                  onChange={(e: any) => setPackageOrder(e)}
                  placeholder="Select Packages"
                  isMulti={true}
                />
              </Grid>
              </>
              }
              <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                Contact Person :{" "}
              </Grid>
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{ textAlign: "left", padding: "4px 0px" }}
              >
                <Select
                  value={customerType}
                  options={customerOptions}
                  onChange={(e: any) => setCustomerType(e)}
                  placeholder="Select Customer"
                />
              </Grid>
              {customerType && customerType.value === "other" && (
                <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                  Name :{" "}
                </Grid>
              )}
              {customerType && customerType.value === "other" && (
                <Grid
                  lg={8}
                  md={8}
                  sm={8}
                  xs={8}
                  style={{ textAlign: "left", padding: "4px 0px" }}
                >
                  <Input
                    value={name}
                    placeholder="Name"
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </Grid>
              )}
              {customerType && customerType.value === "other" && (
                <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                  Mobile No. :{" "}
                </Grid>
              )}
              {customerType && customerType.value === "other" && (
                <Grid
                  lg={8}
                  md={8}
                  sm={8}
                  xs={8}
                  style={{ textAlign: "left", padding: "4px 0px" }}
                >
                  <Input
                    value={contact}
                    placeholder="Mobile Number"
                    onChange={(e: any) => setContact(e.target.value)}
                  />
                </Grid>
              )}
              <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                Detailed Information :{" "}
              </Grid>
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{ textAlign: "left", padding: "4px 0px" }}
              >
                <Input
                  multiline={true}
                  rows={3}
                  value={details}
                  placeholder="Provide detail information regarding your issue"
                  onChange={(e: any) => setDetails(e.target.value)}
                />
              </Grid>
              {complaintType && complaintType.value === "MB" && (
              <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                Select Branch
              </Grid>
            )}
            {complaintType && complaintType.value === "MB" && (
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{ textAlign: "left", padding: "4px 0px" }}
              >
                <Select
                  placeholder="Select Branch"
                  options={props?.branchOptions || []}
                  value={missBehaveBranch}
                  onChange={(e: any) => setMissBehaveBranch(e)}
                />
              </Grid>
            )}
            {complaintType && complaintType.value === "MB" && (
              <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                Select User
              </Grid>
            )}
            {complaintType && complaintType.value === "MB" && (
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{ textAlign: "left", padding: "4px 0px" }}
              >
                <Select
                  value={misBehaveUser}
                  placeholder="Select User"
                  options={props?.userOptions ||[]}
                  onChange={(e: any) => setMissBehaveUSer(e)}
                />
              </Grid>
            )}
    
              <Grid lg={4} md={4} sm={4} xs={4} style={{ paddingTop: "10px" }}>
                Attach Image :{" "}
              </Grid>
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{
                  textAlign: "left",
                  padding: "10px 0px",
                  fontSize: "28px",
                }}
              >
                <ImageInput
                  name="Add Image"
                  Icon={(props: any) => (
                    <i {...props} className={"fas fa-id-card file_icon"} />
                  )}
                  onChange={(e: any) => setImage(e)}
                  value={image}
                  extras={<div></div>}
                />
              </Grid>

              <Grid lg={4} md={4} sm={4} xs={4}></Grid>
              <Grid
                lg={8}
                md={8}
                sm={8}
                xs={8}
                style={{ textAlign: "left", padding: "4px 0px" }}
              ></Grid>
            </Grid>
          </div>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <ButtonPrimary className={`button-primary-secondary fs-link`} onClick={() => {
              if(details?.length <= 0 ){
                return showHttpError(`Kindly Enter Remarks regarding Complaint.`)
              }
              else{
                handleSubmit();
              }
          }}>Submit</ButtonPrimary>
          <ButtonDefault onClick={handleComplaintClose} className={`button-danger-secondary fs-link`}>Close</ButtonDefault>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          <div style={{ margin: "10px" }}>
            {
              "Your Complaint has been registered, we will get back to you shortly."
            }
            <br />
            Your Complaint Number is {feedBack}.
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonDefault className={`button-danger-secondary fs-link`} onClick={handleClose}>
            Close
          </ButtonDefault>{" "}
        </DialogActions>
      </Dialog>{" "}
      {(!props.chat || !props.chat.COMP) && (
        <ButtonPrimary onClick={() => setOpenComplaint(true)}>
          Register Complaint
        </ButtonPrimary>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});
const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(Complaint);
