import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import './button.css';

// type
type VariantType = "neutral" | "primary" | "secondary" | "accent" | "danger" | "warning" | "success" | "info" | "white" | "black";
type ActionType = "primary" | "secondary" | "tertiary";
type ButtonProps = {
    variant: VariantType;
    action: ActionType;
    shape?: "circle" | "oval" | "";
    className?: string;
    Icon?: ReactNode;
    textTransform?: "capitalize" | "lowercase" | "uppercase" | "";
    fullWidth?: boolean;
    center?: boolean;
    isHoverEffect?: boolean;
} & ComponentPropsWithoutRef<"button">;

// Custom Button Component
const Button = ({ children, variant = "primary", action = "primary", shape = "", className = "", Icon, textTransform = "", fullWidth = false, center = false, isHoverEffect = true, ...props }: ButtonProps) => {
    return (
        <button
            className={`taptap-btn px-1 py-8 br-4 fw-500 border-none outline-none fs-button ${action !== "tertiary" ? "button" : ""} ${textTransform} ${Icon ? "flex ai-center gap-4" : ""} ${shape} button-${variant}-${action} ${className} ${fullWidth ? "w-100" : "max-content"} ${center ? "mx-auto" : ""} ${isHoverEffect ? "hover-effect-on" : "hover-effect-off"}`}
            {...props}
        >
            {Icon && Icon}{children}
        </button>
    )
}

export default Button