import React from 'react';
import { withFormik, FormikProps, FormikErrors, Form, Field } from 'formik';
import "./contactDetailsForm.css"
// import { ErrorMessage } from 'formik';
import showMessage, { failed } from '../../../../utils/message';
import { success } from './../../../../utils/message';
import { customerData, getOtp } from '../../../../services/area';
import { toast } from 'react-toastify';
// import { useState } from 'react';
// import { useEffect } from 'react';

// Shape of form values
interface FormValues {
  firstName : string;
  // lastName : string;
  // email: string;
  password: string;
  contact: string;
  otp: string;
}

interface OtherProps {
  formTitle?: string;
  message: string;
  isSubmitting : boolean;
  className?: string;
  emailClass?: string;
  passClass?: string;
  firstnameClass?: string;
  lastnameClass?: string;
  contactClass?: string;
  otpClass?: string;
  submitBtn?: boolean;
  commonUsedForm?: boolean
}

// const isValidEmail = (email:any) => {
//     var emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
//     return emailRegex.test(email);
// }

// const isValidPassword = (pass:any) => {
//     // var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
//     // return regex.test(pass);
//     var newPassword = pass;
//     var passRegex  = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,15}$/;
//     if(!passRegex.test(newPassword)) {
//         return 1;
//     }
//     else{
//         return 0;
//     }
// }

const isValidPassword = (pass : any) => {

  const errors = [];

  if (pass.length < 6) {
      errors.push("Password should be 6 characters in length.");
  }

  if (!pass.match(/[0-9]/)) {
      errors.push("Password must contain at least one numerical digit (0-9).");
  }
  
  if (!pass.match(/[!@#$%^&*()_+[\]{};:'"|,.<>?]/)) {
    errors.push("Password must contain at least one special character.");
  }
  
  if (!pass.match(/[a-z]/)) {
      errors.push("Password must include at least one lowercase letter (a-z).");
  }

  if (!pass.match(/[A-Z]/)) {
      errors.push("Password must contain at least one uppercase letter (A-Z).");
  }

  if (pass.length === 0) {
    errors.push('Required');
  }

  console.log(`errors 4` , errors)

  return {
      isValid: errors.length > 0 ? false : true,
      errors: errors
  };
};


const isValidName = (name:any) => {
    var nameRegex = /^[A-Za-z ]{3,}$/;
    return nameRegex.test(name);
}

const isValidContact = (contact:any) => {
  if(contact.length > 10){
    return false;
  }
  var contactRegex = /^[0-9].{9,}$/
  return contactRegex.test(contact)
}

const isValidOtp = (otps:any) => {
    if(otps.length > 6){
        return false;
    }
    var otpRegex = /^[0-9].{5,}$/
    return otpRegex.test(otps)
}


const InnerForm:any = (props: OtherProps & FormikProps<FormValues>) => {
  console.log("final Props",props)
  const { touched, errors, isSubmitting, message, className , 
    emailClass , passClass , firstnameClass , contactClass , otpClass ,submitBtn , commonUsedForm} = props;

  return (
    <Form className={className}>
      {message && <h1>{message}</h1>}
      {/* <div className={commonUsedForm ? "commonEmailCss" : emailClass}>
        <Field type="email" name="email" placeholder="Email" className={commonUsedForm ? "commonEmailInputCss" : emailClass}/>
        {touched.email && errors.email && <div className='commonErrorCss'>*{errors.email}</div>}
      </div> */}

      <div className={commonUsedForm ? "commonContactCss" : contactClass}>
        <Field type="text" name="contact" placeholder="Contact No" className={commonUsedForm ? "commonContactInputCss" : emailClass}/>
        {touched.contact && errors.contact && <div className='commonErrorCss'>*{errors.contact}</div>}
      </div>

      <div className={commonUsedForm ? "commonPassCss" : passClass}>
        <Field type="password" name="password" placeholder="Password" className={commonUsedForm ? "commonPassInputCss" : emailClass}/>
        {touched.password && errors.password && <div className='commonErrorCss'>*{errors.password}</div>}
      </div>
      
      <div className={commonUsedForm ? "commonFirstNameCss" : firstnameClass}>
        <Field type="text" name="firstName" placeholder="Full Name" className={commonUsedForm ? "commonFirstNameInputCss" : emailClass}/>
        {touched.firstName && errors.firstName && <div className='commonErrorCss'>*{errors.firstName}</div>}
      </div>
      
      {/* <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
        <Field type="text" name="lastName" placeholder="Last Name" className={commonUsedForm ? "commonLastNameInputCss" : emailClass}/>
        {touched.lastName && errors.lastName && <div className='commonErrorCss'>*{errors.lastName}</div>}
      </div> */}
      

      {/* {getOtpTime ?  */}
        <div className='commonOtpCss'>
          <button className='commonOtpBtnCss' onClick={()=>{
            console.log("From OTP",props)
            if(props?.formTitle === "individualWFContactForm"){
              if(touched.contact && errors.contact){
                showMessage("Kindly Enter Valid Number",failed,2000)
              }
              if(!errors.password && !errors.firstName && !errors.contact){
                // setGetOtpTime(false);
                getOtp(+(props?.values?.contact));
                showMessage("Otp sent SuccesFully !",success,2000)
              }
            }
          }}>Get Otp</button>
        </div>
        {/* :
        <div className='commonOtpCss'>..... Wait For The Otp {counter}</div>
      } */}
      
      <div className={commonUsedForm ? "commonOTPCss" : otpClass}>
        <Field type="text" name="otp" placeholder="Enter OTP" className={commonUsedForm ? "commonOTPInputCss" : emailClass}/>
        {/* <ErrorMessage name="otp" className='commonErrorCss'/> */}
        {touched.otp && errors.otp && <div className='commonErrorCss'>*{errors.otp}</div>}
      </div>
      
      {submitBtn && 
        <div className='commonBtnCss'>
          <button type="submit" className='commonSubmitBtnCss' disabled={isSubmitting}>
            {props?.formTitle === "individualWFContactForm" ? "Sign Up" : "Submit"}
          </button>
        </div>
      }
    </Form>
  );
};

// The type of props MyForm receives
interface MyFormProps {
  formTitle?: string;
  initialEmail?: string;
  className?: string;
  emailClass?: string;
  passClass?: string;
  firstnameClass?: string;
  lastnameClass?: string;
  contactClass?: string;
  otpClass?: string;
  submitBtn?: boolean;
  commonUsedForm?: boolean;
  message?: string; // if this passed all the way through you might do this or make a union type
}

// Wrap our form with the withFormik HoC
const ContactDetailsForm = withFormik<MyFormProps, FormValues, OtherProps>({
  // Transform outer props into form values
  mapPropsToValues: () => {
    return {
      firstName: '',
      // lastName: '',
      // email: props.initialEmail || '',
      password: '',
      contact: '',
      otp: '',
    };
  },

  // Add a custom validation function (this can be async too!)
  validate: (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};

    if (!isValidName(values.firstName)) {
      console.log(`values.firstName` , values.firstName === "");
      errors.firstName = 'Name Should Be More then 3 Letters & Could not Contain Numericals';
    }
    // if (!isValidName(values.lastName)) {
    //   errors.lastName = 'Name Should Not Contain Numericals';
    // }
    // if (!values.email) {
    //   errors.email = 'Required';
    // }
    // if (!isValidEmail(values.email)) {
    //   errors.email = 'Invalid email address';
    // }
    
    // if (values.password.length < 6) {
    //   errors.password = 'Min Length 6 Is Required';
    // }
    console.log(`isValidPassword(values.password)` , isValidPassword(values.password))
    // if (isValidPassword(values.password) > 0) {
    //   errors.password = 'Password Requirements:\n' +
    //   '- Should be between 6 and 15 characters in length.\n' +
    //   '- Must contain at least one numerical digit (0-9).\n' +
    //   '- Must include at least one lowercase letter (a-z).\n' +
    //   '- Must contain at least one uppercase letter (A-Z).';
    // }
    
    const passwordValidation = isValidPassword(values.password);
    if (!passwordValidation.isValid) {
        console.log("isValidPassword(values.password) Invalid password. Errors:" , passwordValidation);
        passwordValidation.errors.forEach((error) => {
            console.log("- " + error);
            errors.password = error;
        });
    }
    
    if(!values.contact){
        errors.contact = 'Required';
    }
    if (!isValidContact(values.contact)) {
      errors.contact = 'Number Should Not Less / Greater Then 10 Nos.';
    }
    if(!values.otp){
        errors.otp = 'Required';
    }
    if (!isValidOtp(values.otp)) {
      errors.otp = 'OTP Should Not Less Then Or Greater Then 6 Nos.';
    }
    return errors;
  },

  handleSubmit: async (values,props) => {
    console.log("From resssssss=====", props);
    if(props?.props?.formTitle === "individualWFContactForm"){
      try {
        let payload = {
          contact: values?.contact,
          otp: values?.otp,
          // email: values?.email,
          name: values?.firstName,
          password: values?.password,
        };
        customerData(payload).then(() => {
          toast.success("Customer created successfully");
          props?.resetForm();
        }).catch((error : any) => {
          props?.resetForm();
          return showMessage(`${error?.message}`,failed,2000);
        })
        // history.push("/customerLogin")
      } catch (error) {
          showMessage(`${error}`,failed,2000);
      }
    }
  },
})(InnerForm);

export default ContactDetailsForm;
