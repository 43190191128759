import { fromJS, List, updateIn } from "immutable";
import {
  SET_COMPANIES,
  SET_BRANCHES,
  CHANGE_MANAGE_SIMPLE,
  CHANGE_RATE_SLAB,
  ADD_RATE_SLAB,
  DEL_RATE_SLAB,
  FRANCHISE_ADDED_SUCCESS,
  SET_FRANCHISES,
  REMOVE_FRANCHISER,
  ACTIVATE_OR_DEACTIVATE_FRANCHISER,
  SET_FRANCHISE_TO_EDIT,
  RESET_MANAGE,
  SET_ROUTES,
  RESET,
  SET_HEAD,
  FRANCHISE_HEAD_CREATED,
} from "../constants/franchiser";

import { toast } from "react-toastify";

const initState = fromJS<any>({
  companies: [],
  branches: [],
  company: null,
  branch: null,
  rateType: "flat",
  franchise: {},
  franchises: [],
  slab: [],
  booking: {
    enabled: false,
    rateType: "",
    slab: [],
    flat: "",
  },
  delivery: {
    enabled: false,
    rateType: "",
    slab: [],
    flat: "",
  },
  isBooking: false,
  isDelivery: false,
  routes: [],
  route: null,
});

const initFranchise = {
  _id: null,
  company: null,
  branch: null,
  booking: {},
  delivery: {},
};

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_COMPANIES: {
      return state.set("companies", List(action.payload));
    }

    case SET_BRANCHES: {
      return state.set("branches", List(action.payload));
    }

    case CHANGE_MANAGE_SIMPLE: {
      const { what, val } = action.payload;
      let newState = state.setIn(what, val);
      if (what[0] == "company") {
        newState = newState.set("head", null);
      }
      return newState;
    }

    case CHANGE_RATE_SLAB: {
      const { idx, where, what, val } = action.payload;

      return updateIn(state, where, (items:any) =>
        items.map((i: any, index: number) =>
          index == idx ? { ...i, [what]: val } : i
        )
      );
    }

    case ADD_RATE_SLAB: {
      const { what } = action.payload;
      const slabs = state.getIn([...what]);
      return state.setIn(
        what,
        slabs && slabs.size > 0 ? slabs.push({}) : List([{}])
      );
    }

    case DEL_RATE_SLAB: {
      const { what, idx } = action.payload;
      return updateIn(state, what, (items:any) =>
        items.filter((_item: any, index: number) => index != idx)
      );
    }

    case FRANCHISE_ADDED_SUCCESS: {
      toast.success("Franchise created successfully!");
      return state
        .set("company", null)
        .set("route", null)
        .set("isBooking", false)
        .set("isDelivery", false)
        .setIn(["booking"], {})
        .setIn(["delivery"], {});
    }

    case SET_FRANCHISES: {
      const { franchises, head } = action.payload;

      return state
        .set("franchises", List(franchises))
        .set("head", fromJS(head[0]));
    }
    case REMOVE_FRANCHISER: {
      const id = action.payload;

      return state.set(
        "franchises",
        state.get("franchises")
          ? state.get("franchises").filter((f: { _id: string }) => f._id !== id)
          : List([])
      );
      // .set('head', fromJS(head[0]))
    }
    case ACTIVATE_OR_DEACTIVATE_FRANCHISER: {
      const { id, active } = action.payload;

      return state.set(
        "franchises",
        state.get("franchises")
          ? state
              .get("franchises")
              .map((f: { _id: string }) =>
                f._id !== id ? f : { ...f, active }
              )
          : List([])
      );
      // .set('head', fromJS(head[0]))
    }
    case SET_FRANCHISE_TO_EDIT: {
      const { parentCompany, route, rateType } = action.payload;
      const { bookingCommission, deliveryCommission } = rateType;

      const addToState = { ...action.payload };

      addToState.isBooking = action.payload.bookingComm ? true : false;
      addToState.isDelivery = action.payload.deliveryComm ? true : false;

      addToState.booking = {
        rateType: isNaN(bookingCommission.flat) ? "slab" : "flat",
        ...rateType.bookingCommission,
        slab: isNaN(bookingCommission.flat)
          ? List(bookingCommission.slab)
          : null,
      };
      addToState.delivery = {
        rateType: isNaN(deliveryCommission.flat) ? "slab" : "flat",
        ...rateType.deliveryCommission,
        slab: isNaN(deliveryCommission.flat)
          ? List(deliveryCommission.slab)
          : null,
      };
      const elem: any = document.getElementById("manage_franchiser");
      if (typeof elem === null) {
      } else {
        elem.click();
        elem.scrollIntoView({ behavior: "smooth" });
      }

      return state
        .merge(addToState)
        .set("company", {
          label: parentCompany.companyName,
          value: parentCompany._id,
        })
        .set("route", { label: route.name, value: route._id })
        .set("rateType", rateType)
        .set("edit", true);
    }

    case RESET_MANAGE: {
      return state.merge(initFranchise);
    }

    case SET_ROUTES: {
      const { franchise } = action.payload;
      return state.set("routes", List(franchise));
    }

    case RESET: {
      return state
        .set("childCompany", null)
        .set("route", null)
        .set("isBooking", false)
        .set("isDelivery", false)
        .setIn(["booking"], fromJS({ rateType: "flat", flat: "" }))
        .setIn(["delivery"], fromJS({ rateType: "flat", flat: "" }));
    }

    case SET_HEAD: {
      const { amount, limit, id: head } = action.payload;

      return state.merge({ amount, limit, head });
    }

    case FRANCHISE_HEAD_CREATED: {
      toast.success("Franchise Head Created successfully!");
      return state.merge({
        head: null,
        amount: "",
        limit: "",
        company: null,
      });
    }

    default: {
      return state;
    }
  }
};
