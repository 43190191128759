import React, { Fragment } from "react";
import DatePicker from "../../../Components/Datecomponents/DatePicker";

import "react-datepicker/dist/react-datepicker.css";
import "../Incur.css";
import { AnyARecord } from "dns";
import { Select3 } from "../../../Components/Selectcomponents/Select";
import Grid from "../../../Components/Grid/Grid";
import showMessage, { failed } from "../../../utils/message";
// import {Grid} from '@mui/material'
let currentyear = new Date().getFullYear();
let date = new Date();
const currentMonth = date.getMonth();
const prevMonth = currentMonth === 0 ? 11 : currentMonth - 1;

export const years: { label: string; value: string }[] = [];
for (let i = 0; i < 20; i++) {
  years.push({
    label: String(currentyear - i),
    value: String(currentyear - i),
  });
}

export const months = [
  { label: "January", value: "0" },
  { label: "February", value: "1" },
  { label: "March", value: "2" },
  { label: "April", value: "3" },
  { label: "May", value: "4" },
  { label: "June", value: "5" },
  { label: "July", value: "6" },
  { label: "August", value: "7" },
  { label: "September", value: "8" },
  { label: "October", value: "9" },
  { label: "November", value: "10" },
  { label: "December", value: "11" },
];

const Dates = (props: any) => {
  const changeStartDate = (date: any) => {
    props.startDate(date);
    if(costType == "perMonth" && (isFleetWise || expense.sub == "B")) props.fetchFixedLimitBalance();
  };
  const changeEndDate = (date: AnyARecord) => {
    props.endDate(date);
  };

  const {
    monthly_duration,
    duration,
    setYear,
    setMonth,
    year,
    month,
    sub_val,
    fleet,
    start_date,
    end_date,
    isFleetWise,
    expense,
    costType
  } = props;

  let years = [];
  let salaryMonths: any = [];
  if (props.role == "A" || props.role == "S") {
    for (let i = 0; i < 20; i++) {
      years.push({
        label: String(currentyear - i),
        value: String(currentyear - i),
      });
    }
    salaryMonths = months;
  } else {
    for (let i = 0; i < (currentMonth === 0 ? 2 : 1); i++) {
      years.push({
        label: String(currentyear - i),
        value: String(currentyear - i),
      });
      salaryMonths = months.filter(
        (i) => Number(i.value) == prevMonth || Number(i.value) == currentMonth
      );
    }
  }

  return (
    <Fragment>
      {monthly_duration != true ? (
        duration == true ? (
          <>
            <Grid container lg={6} md={6} sm={12} xs={12}>
              {/* <div style={{ margin: '0' }}>
                                        <strong>Duration: </strong>
                                    </div> */}
              
                {/* <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={props.start_date}
                                            onChange={changeStartDate}
                                        /> */}
                <DatePicker
                  name="startdate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  minDate={
                    new Date(new Date(new Date().setDate(1)).setMonth(-12))
                  }
                  selected={new Date(props.start_date)}
                  onChange={(e: any) => {
                    changeStartDate(e)
                    const monthS = new Date(e).getMonth() + 1;
                    const yearS = new Date(e).getFullYear();
                    const dayS = new Date(e).getDate();
                    const monthE = new Date(end_date).getMonth() + 1;
                    const yearE = new Date(end_date).getFullYear();
                    const dayE = new Date(end_date).getDate();
                    if(monthS!=monthE || yearS!=yearE || dayE<dayS){
                      changeEndDate(e)
                    }
                  }}
                 
                />
              
              <div className="date_range">TO</div>
             
                <DatePicker
                  name="enddate"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  minDate={
                    new Date(new Date(new Date().setDate(1)).setMonth(-12))
                  }
                  selected={new Date(props.end_date)}
                  onChange={(e: any) => {

                    const monthS = new Date(start_date).getMonth() + 1;
                    const yearS = new Date(start_date).getFullYear();
                    const dayS = new Date(start_date).getDate();
                    const monthE = new Date(e).getMonth() + 1;
                    const yearE = new Date(e).getFullYear();
                    const dayE = new Date(e).getDate();
                    if(fleet && (monthS!=monthE || yearS!=yearE)){
                      showMessage("startDate and endDate need to be of same month and year",failed)
                    }
                    else if(dayE<dayS){
                      showMessage("endDate should be greater than startDate",failed)
                    }
                    else {
                      changeEndDate(e)
                      if(costType == "perMonth" && (isFleetWise || expense.sub == "B")) props.fetchFixedLimitBalance();
                    }
                  }}
               
                />
                {/* <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={props.end_date}
                                            onChange={changeEndDate}
                                        /> */}
             
            </Grid>
          </>
        ) : (
   
            <>
              <div>
                <strong>Date: </strong>
              </div>
              <div>
                <DatePicker
                  name="duration"
                  autoComplete="off"
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(props.start_date)}
                  onChange={changeStartDate}
                  minDate={
                    new Date(new Date(new Date().setDate(1)).setMonth(-12))
                  }
                />
              </div>
            </>
        )
      ) : (
        <>
         
            <Select3
              placeholder="Year"
              value={year}
              options={years}
              onChange={(e:any) => {
                setYear("year", e);
                if (year && month && sub_val) {
                  if(expense.sub == "B"){
                    if(costType == "perMonth")props.fetchFixedLimitBalance();
                  }
                  else props.fetchFixedLimitBalance();
                }else if(year && month && fleet && isFleetWise && costType == "perMonth"){
                  props.fetchFixedLimitBalance();
                }
              }}
            />
        
            <Select3
              placeholder="Month"
              value={month}
              options={salaryMonths}
              onChange={(e:any) => {
                setMonth("month", e);
                if (year && month && sub_val) {
                  if(expense.sub == "B"){
                    if(costType == "perMonth")props.fetchFixedLimitBalance();
                  }
                  else props.fetchFixedLimitBalance();
                }else if(year && month && fleet && isFleetWise && costType == "perMonth"){
                  props.fetchFixedLimitBalance();
                }
              }}
            />
         
        </>
      )}
    </Fragment>
  );
};

export default Dates;
