import { buildQueryString, ServiceMaker } from "./index";
import jsonToFormdata from "../utils/jsonToFormdata";

const qs = (obj: any) => {
  return Object.entries(obj)
    .map(([key, val]: any) => `${key}=${encodeURIComponent(val)}`)
    .join("&");
};

export const actionVerifyPOD = (data: any) =>
  ServiceMaker("verification/verifyPOD", "PATCH", data);

export const fetchUnverifiedDocket = (data: any) =>
  ServiceMaker(`verification/unverifiedPOD?${qs(data)}`, "GET");

export const fetchUnverifiedDocketCount = () =>
  ServiceMaker("verification/unverifiedPODCount", "GET");

export const payOnlineBycustomer = (data:any) =>
  ServiceMaker("customerBooking/customerBookingMakePayment", "PATCH",data);

export const listUnverifiedExpense = (
  page: number,
  pageSize: number,
  user: string,
  sub: "B" | "F",
  entity: string,
  status : string
) =>
  ServiceMaker(
    `verification/unverifiedExpense?${page ? "page=" + page + "&" : ""}${pageSize ? "pageSize=" + pageSize + "&" : ""
    }${user ? "user=" + user + "&" : ""}${sub ? "sub=" + sub + "&" : ""}${entity ? "entity=" + entity + "&" : ""
    }${status ? "status="+ status : ""}`,
    "GET"
  );

export const getUnverifiedExpenseCount = (data?: any) =>
  ServiceMaker(`verification/countUnverifiedExpense?${buildQueryString(data)}`, "GET");

export const verifyExpense = (id: number) =>
  ServiceMaker("verification/verifyExpense", "PATCH", { id });

export interface IUnverifiedPackage {
  docket: {
    paymentMode: string;
    gross: number;
    docketNumber: string;
    docketImages: string[];
  };
  packages: {
    materialType: string;
    packingType: string;
    pck: string[];
    qty: number;
    size: string;
  }[];
  count: number;
  pkgCount: number;
  totalQty: number;
  _id: string;
  checkVerifiedAt: string;
}

export const listUnverifiedPackages = (data:any) =>
  ServiceMaker(
    `package/unverifiedPackages?${buildQueryString(data)}`,
    "GET"
  );
  export const listverifiedPackagesCount = (data:any) =>
  ServiceMaker(
    `package/countVerifiedPackages`,"POST",
    data
    );
  export const listUnverifiedPackagesAll = (data:any)=>
  ServiceMaker(
    `package/unverifiedPackages?${buildQueryString(data)}`,
    "GET"
  );

export const verifyPackages = (
  packages: any,
  sub: "B" | "F",
  entity: string,
  remarkImage: Blob | string,
  remarkText?: string
) =>
  ServiceMaker(
    "package/remark",
    "POST",
    jsonToFormdata({
      packages,
      remarkType: "V",
      loginType: sub,
      [sub === "B" ? "branch" : "fleet"]: entity,
      remarkImage: remarkImage,
      remarkText,
    })
  );

  export const verifyStock = (
    packages: any,
    sub: "B" | "F",
    entity: string,
    remarkImage: Blob[] | any,
    remarkText?: string
  ) =>
    ServiceMaker(
      "package/verifyStock",
      "POST",
      jsonToFormdata({
        packages,
        // remarkType: "V",
        loginType: sub,
        [sub === "B" ? "branch" : "fleet"]: entity,
        remarkImages: remarkImage,
        remarkText,
      })
    );

    export const verifyStockByQr = (
      order: any,
      sub: "B" | "F",
      entity: string,
      isQrScan?: boolean,
      remarkText?: string
    ) =>
      ServiceMaker(
        "package/verifyStock",
        "POST",
        jsonToFormdata({
          order,
          // remarkType: "V",
          loginType: sub,
          [sub === "B" ? "branch" : "fleet"]: entity,
          isQrScan,
          remarkText,
        })
      );
