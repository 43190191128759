import React from "react";
import { FormHelperText } from "@mui/material";
// import SelectField from "react-select";
import Grid from "../Grid/Grid";
import ReactSelect from "../../newSrc/components/UI/select/react-select";

interface Ipropsselect {
    value?: any;
    options?: any;
    onChange?: any;
    label?: any;
    placeholder?: any;
    isMulti?: any;
    isDisabled?: any;
    width?: any;
    styles?: any;
    error?:any;
    message?:any;
    openMenuOnFocus?:any;
    theme?:any;
    onFocus?:any;
    isOptionDisabled?:any;
    closeMenuOnSelect?:any;
    onBlur?:any;
    id?:any;
    autoComplete?:any;
    isClearable?:any;
    isSearchable?:any;
    ref?:any;
    components?:any;
    isLoading?:any;
    menuContainerStyle?:any;
    inputId?:any;
    onKeyDown?:any;
    className?:any;
    name ?: any;
  }
  export function Select(props: Ipropsselect) {
    return (
      <div style={{"padding":"5px"}}>
    <ReactSelect
        //  ref={props.ref}
         onKeyDown={props.onKeyDown}
         isSearchable={props.isSearchable}
         id={props.id}
         inputId={props.inputId}
        //  autoComplete={props.autoComplete?props.autoComplete: "nope"}
        closeMenuOnSelect={props.closeMenuOnSelect}
          value={props.value}
          placeholder={props.placeholder}
          options={props.options}
          onChange={props.onChange}
          className={"heighten "+`${props.className}`}
          isMulti={props.isMulti ? props.isMulti : false}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          styles={props.styles}
          openMenuOnFocus={props.openMenuOnFocus}
          theme={props.theme}
          onFocus={props.onFocus}
          isOptionDisabled={props.isOptionDisabled}
          onBlur={props.onBlur}
          isClearable={props.isClearable}
          components={props.components}
          isLoading={props.isLoading}
          // menuContainerStyle={props.menuContainerStyle}
        />
         {(props.error&&props.message) ? (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {props.message}
                    </FormHelperText>
         ) : null}
      </div>
  
    );
  }
  
  export function Select2(props: Ipropsselect) {
    return (
      <Grid lg={2} md={2} sm={12} xs={12} style={{"padding":"5px"}}>
        <ReactSelect
    // ref={props.ref}
            isSearchable={props.isSearchable}
         id={props.id}
        inputId={props.inputId}
      onKeyDown={props.onKeyDown}
        //  autoComplete={props.autoComplete?props.autoComplete: "nope"}
     closeMenuOnSelect={props.closeMenuOnSelect}
          value={props.value}
          placeholder={props.placeholder}
          options={props.options}
          onChange={props.onChange}
          className={"heighten "+`${props.className}`}
          isMulti={props.isMulti ? props.isMulti : false}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          styles={props.styles}
          openMenuOnFocus={props.openMenuOnFocus}
          theme={props.theme}
          onFocus={props.onFocus}
          isOptionDisabled={props.isOptionDisabled}
          onBlur={props.onBlur}
          isClearable={props.isClearable}
          components={props.components}
          isLoading={props.isLoading}
          // menuContainerStyle={props.menuContainerStyle}
        />
         {(props.error&&props.message) ? (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {props.message}
                    </FormHelperText>
         ) : null}
      </Grid>
    );
  }
  export function Select3(props: Ipropsselect) {
    return (
      <Grid lg={3} md={3} sm={12} xs={12} style={{"padding":"5px"}}>
        <ReactSelect
    // ref={props.ref}
            isSearchable={props.isSearchable}
    id={props.id}
    onKeyDown={props.onKeyDown}
    inputId={props.inputId}
    // autoComplete={props.autoComplete?props.autoComplete: "nope"}
      closeMenuOnSelect={props.closeMenuOnSelect}
          value={props.value}
          placeholder={props.placeholder}
          options={props.options}
          onChange={props.onChange}
          className={"heighten "+`${props.className}`}
          isMulti={props.isMulti ? props.isMulti : false}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          styles={props.styles}
          
          openMenuOnFocus={props.openMenuOnFocus}
          theme={props.theme}
          onFocus={props.onFocus}
          isOptionDisabled={props.isOptionDisabled}
          onBlur={props.onBlur}
          isClearable={props.isClearable}
          components={props.components}
          isLoading={props.isLoading}
          // menuContainerStyle={props.menuContainerStyle}
        />
         {(props.error&&props.message) ? (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {props.message}
                    </FormHelperText>
         ) : null}
      </Grid>
    );
  }
  export function Select6(props: Ipropsselect) {
    return (
      <Grid lg={6} md={6} sm={12} xs={12} style={{"padding":"5px"}}>
        <ReactSelect
    // ref={props.ref}
            isSearchable={props.isSearchable}
    id={props.id}
    inputId={props.inputId}
    onKeyDown={props.onKeyDown}

    // autoComplete={props.autoComplete?props.autoComplete: "nope"}
     closeMenuOnSelect={props.closeMenuOnSelect}
          value={props.value}
          placeholder={props.placeholder}
          options={props.options}
          onChange={props.onChange}
          className={"heighten "+`${props.className}`}
          isMulti={props.isMulti ? props.isMulti : false}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          styles={props.styles}
          openMenuOnFocus={props.openMenuOnFocus}
          theme={props.theme}
          onFocus={props.onFocus}
          isOptionDisabled={props.isOptionDisabled}
          onBlur={props.onBlur}
          isClearable={props.isClearable}
          components={props.components}
          isLoading={props.isLoading}
          // menuContainerStyle={props.menuContainerStyle}
          name={props.name}
        />
         {(props.error&&props.message)? (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {props.message}
                    </FormHelperText>
         ) : null}
      </Grid>
    );
  }
  export function Select12(props: Ipropsselect) {
    return (
      <Grid lg={12} md={12} sm={12} xs={12} style={{"padding":"5px"}}>
        <ReactSelect
          //  ref={props.ref}
            isSearchable={props.isSearchable}
          id={props.id}
         onKeyDown={props.onKeyDown}
          inputId={props.inputId}
          // autoComplete={props.autoComplete?props.autoComplete: "nope"}
           closeMenuOnSelect={props.closeMenuOnSelect}
          value={props.value}
          placeholder={props.placeholder}
          options={props.options}
          onChange={props.onChange}
          className={"heighten "+`${props.className}`}
          isMulti={props.isMulti ? props.isMulti : false}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          styles={props.styles}
          openMenuOnFocus={props.openMenuOnFocus}
          theme={props.theme}
          onFocus={props.onFocus}
          isOptionDisabled={props.isOptionDisabled}
          onBlur={props.onBlur}
          isClearable={props.isClearable}
          components={props.components}
          isLoading={props.isLoading}
          // menuContainerStyle={props.menuContainerStyle}
          name={props.name}
        />
         {(props.error&&props.message) ? (
                    <FormHelperText style={{ color: "#f44336" }}>
                      {props.message}
                    </FormHelperText>
         ) : null}
      </Grid>
    );
  }