import React from 'react'
import {
  Accordion,
  AccordionSummary,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid from '../Grid/Grid';

export default function TableHeaderContainer(props: any) {
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  return (
    <div>
      {!mobileView && (
        <Accordion expanded={false}>
          <AccordionSummary style={{ "display": "flex", "alignItems": 'center', "justifyContent": 'flex-start' }}>
            <Grid container xs={12} sm={12} lg={12} md={12}>
              {props.children}
            </Grid>
          </AccordionSummary>
        </Accordion>)
      }
    </div>
  )
}
