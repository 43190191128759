import React, { ComponentProps } from "react";
import "./alert.css";
import { InfoIcon } from "../../../constants/icons";
import { IoWarningOutline as WarningIcon } from "react-icons/io5";
import { PiWarningCircleBold as DangerIcon } from "react-icons/pi";
import { FiCheckCircle as SuccessIcon } from "react-icons/fi";

export type AlertProps = {
  className?: string;
  severity?: "info" | "warning" | "danger" | "success";
  variant?: "outlined" | "filled" | "normal";
  isCenter?: boolean;
  isIcon?: boolean;
  maxWidth?:
    | "mobile"
    | "tablet"
    | "desktop"
    | "laptop"
    | "big-screen"
    | "auto"
    | "max-content";
  textAlignment?: "left" | "right" | "center";
} & ComponentProps<"div">;

const Alert = ({
  className = "",
  severity = "info",
  variant = "normal",
  children,
  isCenter = false,
  isIcon = true,
  maxWidth = "auto",
  textAlignment = "left",
  ...props
}: AlertProps) => {
  let icon;
  switch (severity) {
    case "success":
      icon = <SuccessIcon />;
      break;
    case "warning":
      icon = <WarningIcon />;
      break;
    case "danger":
      icon = <DangerIcon />;
      break;
    default:
      icon = <InfoIcon />;
  }
  return (
    <div
      className={`${className} gap-4 custom-alert custom-alert-${severity}-${variant} p-1 m-p-10 br-4 ${
        isCenter ? "mx-auto" : ""
      } custom-alert-${maxWidth} jc-${textAlignment}`}
      {...props}
    >
      {isIcon && <span className="flex-center">{icon}</span>}
      {children}
    </div>
  );
};

type NoDataAlertProps = AlertProps;

export const NoDataAlert = ({ ...props }: NoDataAlertProps) => {
  return (
    <Alert severity="danger" {...props}>
      No Data
    </Alert>
  );
};

type SelectTabAlertProps = AlertProps;
export const SelectTabAlert = ({ ...props }: SelectTabAlertProps) => {
  return (
    <Alert textAlignment="center" {...props}>
      Please select the above tab
    </Alert>
  );
};

export default Alert;
