import React , { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetCustomerTracking, SetCustomerTracking } from './../../actions/trackingModule';
import { connect } from "react-redux";
import { RootState } from "./../../store";
import { getCustomerdocketDataTrack, trackCustomerBooking } from './../../services/customersection';
import { fetchDocketDetails,fetchStrackDetailsForMsgLink } from '../../services/operations';
import { ResetCustomerBookingTracking } from '../../actions/customerBooking';
import { useMediaQuery, useTheme } from "@mui/material";
import { withRouter } from "../../hoc/withRouter";
// import {withRouter} from "react-router-dom";

const TrackAuth = (props:any) => {
  
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  const location:any = useLocation();
  const navigate = useNavigate()
  
  console.log("zsdnkn 61634165 location",location)
  console.log("zsdnkn 61634165 history",history)

  // const handleSerchDocket = async (docket:any)=>{
  //   try {
  //     let response  = await fetchStrackDetails(docket)
  //     console.log("9000000 Actual Response Search",response,"docketNumber 220",docket)
  //     props.SetCustomerTracking({"orderData" : response , "source": "Customer-NotAuthenticated-BranchOrder"});
  //     navigate("/Customer-track")
  //   } catch (error) {
  //     console.log("errro 200",error.message)
  //     alert("Your are Not Eligible To Track This Docket !")
  //     navigate("/")
  //   }
    
  // }

  const handleMsgLinkDocket = async (docket:any,check : any)=>{
    console.log("docketNumber 220",docket)
    try {
      let payload = { decoded : check }
      console.log("docketNumber 220",payload)
      let response  = await fetchStrackDetailsForMsgLink(docket,payload)
      console.log("9000000 Actual Response",response,"docketNumber 220",docket)
      props.SetCustomerTracking({"orderData" : response , "source": "Customer-NotAuthenticated-BranchOrder"});
      navigate("/Customer-track")
    } catch (error:any) {
      console.log("errro 200",error.message)
      alert("Your are Not Eligible To Track This Docket !")
      navigate("/")
    }
    
  }

  const GetTrackingData = async ()=>{
    
    if(location?.state?.from === "Branch-Authenticated-CustomerOrder"){
      try {
        console.log("5641351421 From First Main")
        const response:any = await trackCustomerBooking({shipmentNumber:location?.state?.docketNumber?.docket});
        console.log("5641351421 From First Main",response)
        if(response.bookingTracker.length > 0){
          props.SetCustomerTracking({"orderData" : response.bookingTracker[0] , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource}),
          navigate("/dashboard/Customer-track")
        }
        else{
          alert("Shipment Number is Incorrect !")
          navigate("/dashboard")
        }
      } catch (error:any) {
        console.log("errro 200",error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/dashboard")
      }
    }

    if(location?.state?.from === "Branch-Authenticated-BranchOrder"){
      try {
        let Payload = {
          docket : location?.state?.docketNumber?.docket,
          queryType : "tracking",
          old: false,
          sub: props.userDetails.loginType,
          entity : props.userDetails.opBranch._id
        }
        console.log("5641351421 From Second Main")
        const response:any = await fetchDocketDetails(Payload);
        console.log("5641351421 From Second Main",response)
        props.SetCustomerTracking({"orderData" : response , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource});
        navigate("/dashboard/Customer-track")
      } catch (error:any) {
        if(error.message === "docket is not exists"){
          console.log("errro 200",error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/dashboard")
        }
        if(error.message === "No docket found" || error.message === "No such docket available"){
          console.log("errro 200",error.message)
          alert("Docket Number Or Tracking Number is Not Exist !")
          navigate("/dashboard")
        }
      }
    }

    if(location?.state?.from === "Customer-Authenticated-CustomerOrder"){
      try {
        console.log("5641351421 From Third Main")
        const response:any = await trackCustomerBooking({shipmentNumber:location?.state?.docketNumber?.docket});
        console.log("5641351421 From Third Main",response)
        if(response.bookingTracker.length > 0){
          props.SetCustomerTracking({"orderData" : response.bookingTracker[0] , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource});
          navigate("/CustomerDashboard/Customer-track")
        }
        else{
          alert("Shipment Number is Incorrect !")
          navigate("/CustomerDashboard/pastBookings")
        }
      } catch (error:any) {
        console.log("errro 200",error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/CustomerDashboard/pastBookings")
      }
    }

    if(location?.state?.from === "Customer-Authenticated-BranchOrder"){
      try {
        console.log("5641351421 From Four Main")
        const response:any = await getCustomerdocketDataTrack({
          docket :location?.state?.docketNumber?.docket,
          customer:props.customerReducer._id,
          isFromMainPage : false
        });
        console.log("5641351421 From Four Main",response)
        props.SetCustomerTracking({"orderData" : response , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource});
        navigate("/CustomerDashboard/Customer-track")
      } catch (error:any) {
        if(error.message === "docket is not exists"){
          console.log("errro 200",error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/CustomerDashboard/pastBookings")
        }
      }
    }

    if(location?.state?.from === "Customer-NotAuthenticated-CustomerOrder"){
      try {
        console.log("5641351421 From Fifth Main")
        const response:any = await trackCustomerBooking({shipmentNumber:location?.state?.docketNumber?.docket});
        console.log("5641351421 From Fifth Main",response)
        if(response.bookingTracker.length > 0){
          props.SetCustomerTracking({"orderData" : response.bookingTracker[0] , "source": location?.state?.from});
          navigate("/Customer-track")
        }
        else{
          alert("Shipment Number is Incorrect !")
          navigate("/")
        }
      } catch (error:any) {
        console.log("errro 200",error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/")
      } 
    }

    if(location?.state?.from === "Customer-NotAuthenticated-BranchOrder"){
      try {
        console.log("5641351421 From Sixth Main")
        const response:any = await getCustomerdocketDataTrack({docket :location?.state?.docketNumber?.docket,isFromMainPage:true});
        console.log("5641351421 From Sixth Main",response)
        props.SetCustomerTracking({"orderData" : response , "source": location?.state?.from});
        navigate("/Customer-track")
      } catch (error:any) {
        if(error.message === "docket is not exists"){
          console.log("errro 200",error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/")
        }
      }
    }

  }

  const GetTrackingDataMobVersion = async ()=>{

    if(location?.state?.from === "Branch-Authenticated-CustomerOrder"){
      try {
        const response:any = await trackCustomerBooking({shipmentNumber:location?.state?.docketNumber?.docket});
        console.log("5641351421 First",response)
        if(response.bookingTracker.length > 0){
          props.SetCustomerTracking({"orderData" : response.bookingTracker[0] , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource});
          navigate("/dashboard/Customer-track")
        }
        else{
          alert("Shipment Number is Incorrect !")
          navigate("/dashboard")
        }
      } catch (error:any) {
        console.log("errro 200",error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/dashboard")
      }
    }

    if(location?.state?.from === "Branch-Authenticated-BranchOrder"){
      try {
        let Payload = {
          docket : location?.state?.docketNumber?.docket,
          queryType : "tracking",
          old: false,
          sub: props.userDetails.loginType,
          entity : props.userDetails.opBranch._id
        }
        const response:any = await fetchDocketDetails(Payload);
        console.log("5641351421 Second",response)
        props.SetCustomerTracking({"orderData" : response , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource});
        navigate("/dashboard/Customer-track")
      } catch (error:any) {
        if(error.message === "docket is not exists"){
          console.log("errro 200",error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/dashboard")
        }
        if(error.message === "No docket found" || error.message === "No such docket available"){
          console.log("errro 200",error.message)
          alert("Docket Number Or Tracking Number is Not Exist !")
          navigate("/dashboard")
        }
      }
    }

    if(location?.state?.from === "Customer-Authenticated-CustomerOrder"){
      try {
        const response:any = await trackCustomerBooking({shipmentNumber:location?.state?.docketNumber?.docket});
        console.log("5641351421 Third",response)
        if(response.bookingTracker.length > 0){
          props.SetCustomerTracking({"orderData" : response.bookingTracker[0] , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource});
          navigate("/CustomerDashboard/Customer-track")
        }
        else{
          alert("Shipment Number is Incorrect !")
          navigate("/CustomerDashboard/pastBookings")
        }
      } catch (error:any) {
        console.log("errro 200",error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/CustomerDashboard/pastBookings")
      }
    }

    if(location?.state?.from === "Customer-Authenticated-BranchOrder"){
      try {
        const response:any = await getCustomerdocketDataTrack({
          docket :location?.state?.docketNumber?.docket,
          customer:props.customerReducer._id,
          isFromMainPage : false
        });
        console.log("5641351421 Fourth",response)
        props.SetCustomerTracking({"orderData" : response , "source": location?.state?.from , "backToOriginPath" : location?.state?.backSource});
        navigate("/CustomerDashboard/Customer-track")
      } catch (error:any) {
        if(error.message === "docket is not exists"){
          console.log("errro 200",error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/CustomerDashboard/pastBookings")
        }
      }
    }

    if(location?.state?.from === "Customer-NotAuthenticated-CustomerOrder"){
      try {
        const response:any = await trackCustomerBooking({shipmentNumber:location?.state?.docketNumber?.docket});
        console.log("5641351421 Fifth",response)
        if(response.bookingTracker.length > 0){
          props.SetCustomerTracking({"orderData" : response.bookingTracker[0] , "source": location?.state?.from});
          navigate("/Customer-track")
        }
        else{
          alert("Shipment Number is Incorrect !")
          navigate("/")
        }
      } catch (error:any) {
        console.log("errro 200",error.message)
        alert("Your are Not Eligible To Track This Docket !")
        navigate("/")
      } 
    }

    if(location?.state?.from === "Customer-NotAuthenticated-BranchOrder"){
      try {
        const response:any = await getCustomerdocketDataTrack({docket :location?.state?.docketNumber?.docket,isFromMainPage:true});
        console.log("5641351421 Sixth",response)
        props.SetCustomerTracking({"orderData" : response , "source": location?.state?.from});
        navigate("/Customer-track")
      } catch (error:any) {
        if(error.message === "docket is not exists"){
          console.log("errro 200",error.message)
          alert("Your are Not Eligible To Track This Docket !")
          navigate("/")
        }
      }
    }

  } 

  useEffect(()=>{
    if(location &&
      location.pathname &&
      location.pathname.includes("Customer-track")){
        // showMessage("You are on A Right Path",success)
        const myArr = props.location.pathname.split("/");
        console.log("myArr 00 69",myArr);
        handleMsgLinkDocket(myArr[2],true)
      }
    if(location &&
      location.pathname &&
      location.pathname.includes("tracking")){
        // showMessage("You are on A Right Path 20",success)
        const myArr = props.location.pathname.split("/");
        handleMsgLinkDocket(myArr[2] , false)
        console.log("myArr 00",myArr);
      }
      if(location &&
        location.pathname &&
        location.pathname.includes("t")){
          const myArr = props.location.pathname.split("/");
          handleMsgLinkDocket(myArr[2] , false)
          console.log("myArr 00",myArr);
        }
  },[])

  useEffect(()=>{
    props.ResetCustomerTracking()
    props.ResetCustomerBookingTracking()
    if(mobileView){
      if(location?.state?.from){
        GetTrackingDataMobVersion();
      }
    }
    if(!mobileView){
      if(location?.state?.from){
        GetTrackingData();
      }
    }
  },[location?.state?.from])
  
  return (
    <>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    customerReducer: state.customerLoginReducer,
    userDetails : state.user
  };
};

const mapDispatchToProps = {
  SetCustomerTracking,
  ResetCustomerTracking,
  ResetCustomerBookingTracking
};

export default 
withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrackAuth)
  )