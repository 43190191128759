export const companyTheme = {
  select: {
    input: (provided: any) => ({
      ...provided,
      width: 150,
    }),
    control: (provided: any) => ({
      ...provided,
      widht: 150,
      height: 42,
      border: "none",
    }),
    container: (provided: any) => {
      const borderBottom = "1px solid grey";
      return { ...provided, border: "none", borderBottom };
    },
    indicatorSeparator: () => {
      return {
        display: "none",
      };
    },
    menu: (provided: any) => {
      return { ...provided, border: "none" };
    },
  },
};
export const selectStyle = (width: number) => ({
  input: (provided: any) => ({
    ...provided,
    width: width,
  }),
  control: (provided: any) => ({
    ...provided,
    widht: width,
    height: 42,
    border: "none",
  }),
  container: (provided: any) => {
    const borderBottom = "1px solid grey";
    return { ...provided, border: "none", borderBottom };
  },
  indicatorSeparator: () => {
    return {
      display: "none",
    };
  },
  menu: (provided: any) => {
    return { ...provided, border: "none" };
  },
});
