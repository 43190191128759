import { useMediaQuery, useTheme } from "@mui/material";
import React from 'react'

export const withMediaQuery = (Component: any) => (props: any) => {
    const theme = useTheme();
    const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
    const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
    const mobileView = isMobile1 || isMobile2
    return (
        <Component {...props} mobileView={mobileView}/>
    )
}
