import React, { Component, Fragment } from "react";
import { Accordion, AccordionSummary } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import classNames from "classnames";
import style from "./remarks.module.css";
// import { useHistory } from "react-router-dom";

import {
  changeUrgencyService,
  resolveIssueService,
} from "../../../services/report";
import IconLike from "../../generics/IconLike";
import { selectStyle } from "../../company/theme";
import selectTheme from "../../../static/js/reactSelectStyle";
import { isArrayCheck } from "../../generics/CheckArray";
import TrackIcon from "../../generics/TrackIcons";
import { styles } from "../../../static/js/materialStyle";
import { showMessage, success, failed } from "../../../utils/message";
import { Select } from "../../../Components/Selectcomponents/Select";
import {Switch} from '../../../Components/Switch/Switch'
import { withMediaQuery } from "../../../hoc/withMediaQuery";
import { useNavigate } from "react-router-dom";

interface IProps {
  showLoader: () => {};
  hideLoader: () => {};
  role: string;
  priorityArr: any;
  reolveArr: any;
  getRemarkRecords: any;
  // remarks:any;
  remarkFilter: any;
  priorityFilter: any;
  resolveFilter: any;
  dockets: any;
  width: any;
  classes: any;
  mobileView : any;
}
const Dockets: React.FC<IProps> = (props) => {
  const { dockets, mobileView } = props;
  // let history = useHistory();
  const navigate = useNavigate()


  //let keys;
  if (typeof dockets != "object" && !isArrayCheck(Object.keys(dockets)))
    return (
      <Fragment>
        <i className="fas fa-exclamation-triangle no_data_icon"></i>
        <p className="no_data_text">No Dockets Found</p>
      </Fragment>
    );

    let docketMapKeys = Object.keys(dockets)

  return (
    <div className="marginTop_10">
      {isArrayCheck(docketMapKeys) &&
        docketMapKeys.map((d: any, i: number) => {
          return (
            <Docket
              key={i}
              {...props}
              mobileView={mobileView}
              docket={dockets[d]}
              navigate={navigate}
            />
          );
        })}
    </div>
  );
};

class Docket extends Component {
  props: any;
  state: any;
  constructor(props: any) {
    super(props);
    this.state = {
      resolved: false,
      urgency: {},
    };
    this.track = this.track.bind(this);
    this.changeUrgency = this.changeUrgency.bind(this);
    this.resolveIssue = this.resolveIssue.bind(this);
  }
  track() {
    this.props.navigate(
      "operations/booking/track/" + this.props.docket.docket.docketNumber
    );
  }
  componentDidMount() {
    const { resolved, urgency } = this.props.docket;
    this.setState({
      urgency: {
        label: urgency.charAt(0).toUpperCase() + urgency.slice(1),
        value: urgency,
      },
      resolved,
    });
  }
  resolveIssue = (id: string) => {
    this.props.showLoader();
    if (window.confirm("Are you sure ?")) {
      this.setState({ resolved: !this.state.resolved }, async () => {
        try {
          await resolveIssueService({ id, resolved: this.state.resolved });
          await this.props.getRemarkRecords();
          await this.props.hideLoader();
          showMessage("Status Updated !", success);
        } catch (error) {
          await this.props.hideLoader();
          showMessage("Could Not Update Status !", failed);
        }
      });
    }
  };
  changeUrgency = async (e: any) => {
    if (window.confirm("Are You Sure ?")) {
      this.setState({
        urgency: {
          label: e.label,
          value: e.value,
        },
      });
      try {
        await changeUrgencyService({
          id: this.props.docket._id,
          urgency: e.value,
        });
        await this.props.getRemarkRecords();
        showMessage("Status Updated !", success);
      } catch (error) {
        showMessage("Could Not Update Status !", failed);
        console.log("error: ", error);
      }
    }
  };
  render() {
    const {
      docket,
      mobileView,
      classes,
      priorityArr,
      resolveFilter,
      priorityFilter,
      remarkFilter,
      role,
    } = this.props;
    const { urgency, resolved } = this.state;
    if (typeof docket != "object") return null;

    if (
      resolveFilter &&
      typeof resolveFilter == "object" &&
      typeof resolveFilter.value == "boolean" &&
      resolveFilter.value != docket.resolved
    )
      return null;
    if (
      priorityFilter &&
      typeof priorityFilter == "object" &&
      priorityFilter.value &&
      priorityFilter.value != docket.urgency
    )
      return null;

    if (
      remarkFilter &&
      isArrayCheck(docket.packages) &&
      typeof remarkFilter == "object" &&
      remarkFilter.value
    ) {
      let allow = false;
      docket.packages.forEach((p: any) => {
        // if(p.)
        switch (remarkFilter.value) {
          case "M":
            if (p.status == "M") {
              allow = true;
            }
            break;
          case "D":
            if (p.damaged == true) {
              allow = true;
            }
            break;
          case "P":
            if (p.pilferage == true) {
              allow = true;
            }
            break;
          default: {
            allow = false;
            break;
          }
        }
      });
      if (!allow) {
        return null;
      }
    }

    const { docketNumber } = docket.docket;
    if (resolveFilter && resolveFilter.value && docket.resolved) {
    }

    const isDamaged =
      isArrayCheck(docket.packages) &&
      docket.packages.reduce((acc: any, d: any) => d.damaged && acc, true);
    const isPilfrage =
      isArrayCheck(docket.packages) &&
      docket.packages.reduce((acc: any, d: any) => d.pilferage && acc, true);
    const isMissing =
      isArrayCheck(docket.packages) &&
      docket.packages.reduce(
        (acc: any, d: any) => d.status == "M" && acc,
        true
      );
    const roles = ["A", "S"];
    return (
      <div>
        <Accordion expanded={false}>
          <AccordionSummary
            classes={{
              root: mobileView
                ? classes.expansionSummaryMob
                : classes.expansionSummaryDesk,
            }}
          >
            <div className="simple_flex padding0">
              <div
                className={classNames([style.remarks_flex], {
                  flex_end: mobileView,
                })}
              >
                <div className=" align_center">
                  <strong>{docketNumber.toUpperCase()}</strong>
                </div>
                <div
                  className={classNames(
                    style.flex_only,
                    "align_center",
                    "width100px",
                    style.marginLeft20px
                  )}
                >
                  <div>
                    {isDamaged && (
                      <IconLike
                        mobileView={mobileView}
                        txt="D"
                        color="red"
                        title="Damaged"
                      />
                    )}
                  </div>
                  <div>
                    {isPilfrage && (
                      <IconLike
                        mobileView={mobileView}
                        txt="P"
                        color="red"
                        title="Pilferage"
                      />
                    )}
                  </div>
                  <div>
                    {isMissing && (
                      <IconLike
                        mobileView={mobileView}
                        txt="M"
                        color="red"
                        title="Missing"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={classNames({
                  [style.remarks_flex]: !mobileView,
                  simple_flex: mobileView,
                })}
              >
                <div className={style.width50px}>
                  <Select
                    styles={selectStyle(50)}
                    value={urgency}
                    options={priorityArr}
                    onChange={(e:any) => this.changeUrgency(e)}
                    theme={selectTheme(false)}
                    isDisabled={!roles.includes(role)}
                  />
                </div>
                <div className={style.width50px}>
          
                      <Switch
                        // onChange={(e) => this.onChange(['is_route'], e.target.checked)}
                        onClick={() => this.resolveIssue(docket._id)}
                        value={resolved}
                        checked={resolved}
                        disabled={!roles.includes(role)}
                         label="Resolved"
                      />
                    
                  
                </div>
              </div>
              {roles.includes(role) && (
                <div className={style.trackIcon}>
                  <TrackIcon
                    className={style.hoverscale}
                    style={{
                      size: "33px",
                      color: "#5cb85c",
                    }}
                    onClick={() => this.track()}
                  />
                </div>
              )}
            </div>
          </AccordionSummary>
          {/* <AccordionDetails></AccordionDetails> */}
        </Accordion>
      </div>
    );
  }
}

// export default withWidth()(withStyles(styles)(Dockets))

export default withMediaQuery(withStyles(styles)(Dockets))
