import createSocketIoMiddleware from 'redux-socket.io';
import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import io from "socket.io-client";
import { endPoint } from "../axios";
import logger from "redux-logger";
import asset from "../reducers/asset";
import userReducer from "../reducers/UserReducer";
import companyReducer from "../reducers/company";
import incomeReducer from "../reducers/income";
import fleetReducer from "../reducers/fleet";
import rightsReducer from "../reducers/rights";
import franchiseReducer from "../reducers/franchise";
import franchiser from "../reducers/franchiser";
import tallyReducer from "../reducers/tally";
import userForm from "../reducers/user";
import routeReducer from "../reducers/route";
import appActionsReducer from "../reducers/appActions";
import { REAUTH_SOCKET, REGISTER_SOCKET } from "../constants/auth";
import customerReducer from "../reducers/customer";
import sidebarBadgesReducer from "../reducers/sidebareBadges";
import appRightsReducer from "../reducers/appRights";
import BookingReducer from "../reducers/booking";
import branchReducer from "../reducers/branchReducer";
import expenseReducer from "../reducers/expense";
import memoReducer from "../reducers/memo";
import missingReducer from "../reducers/missingList";
import customerLoginReducer from "../reducers/customerLogin";
import qrReducer from "../reducers/qrReducer";
import { filterBookBranches, getAllBranchesByCompany } from "../services/branch";
import { getUserByContact } from "../services/user";
import {
  updateOperationsAllCount,
} from "../actions/sidebareBadges"
import customerBookingReducer from "../reducers/customerBooking"
import { getCustomerOrderQuickData } from "../actions/CustomerQuickOrderViewAction";
import liveLocation from "../reducers/liveLocation";
import { setCustomerDocketLocation } from "../actions/liveLocation";
import customerChat from './../reducers/customerChat';
import customerTracking from './../reducers/trackingModule';
import feedbackFilters from "../reducers/feedbackFilters";
import taskReducer from "../reducers/taskReducer";
import { APPEND_CUSTOMER_CHAT } from "../constants/customerChat";
import driverLogin from "../reducers/driverLogin";
import userLoginLocking from "../reducers/userLoginLocking";
import compalintChatListing from "../reducers/complaintListing";
import smsAndCallRecords from "../reducers/calllAndSmsRecords";
import listOfAllUser from "../reducers/userList";
import newRoute from "../reducers/newRoute";
import allGeneralSettings from "../reducers/alllGeneralSettings";
import allPlatformUsersList from "../reducers/allPlatformUsersList";
import paymentTypeReducer from "../reducers/paymentTypeList";
import { verifyStocksReducer } from "../reducers/verifyStocks";
import { UPDATE_CCAVENUE_TXNS_STATUS } from "../constants/paymentGateWay";
import paymentGateWayReducer from "../reducers/paymentGateWay";
import allPlatformDriverList from "../reducers/allPlatformDriverList";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { globalReducer } from "./global.reducer";

export const socket = io(endPoint, {
  transports: ["websocket", "polling"],        
});

const production = process.env.NODE_ENV == "production";
const token=sessionStorage.getItem("kabra:token")?sessionStorage.getItem("kabra:token"): localStorage.getItem("kabra:customertoken")
if(token){
const id = setInterval(() => {
  socket.emit("refresh",token);
}, 5000);
socket.on("connect", () => {
  socket.emit("action", {
    type: REGISTER_SOCKET,
    data: { token: token},
  });
});
socket.on("logout", () => {
  clearInterval(id);
  window.location.reload();
});
socket.on("reconnect", () => {
  socket.emit("action", {
    type: REAUTH_SOCKET,
    data: { token:token },
  });
  if(store.getState().user.opBranch._id){
  const {_id,branchName}=store.getState().user.opBranch
  socket.emit("branchLogin", {opBranch:  {_id,branchName}});
  }
});
}
socket.on("setFilteredBranches", async(data:any) => {
  
  let user = store && store.getState() && store.getState().user && store.getState().user
  let company = store && store.getState() && store.getState().user && store.getState().user.company &&  store.getState().user.company._id
  let isAuthenticated = store && store.getState() && store.getState().user && store.getState().user.socketLockAcquired
  
  if(isAuthenticated){
    try {
      store.dispatch({
        type: "SET_FILTERED_BRANCHES",
        payload: {data: await filterBookBranches({company}), query: company},
      })
      store.dispatch({
        type: "SET_BRANCHES",
        payload: await getAllBranchesByCompany(company),
      });
      if(user && user.branches && user.branches.length > 0 && user.branches.find((x:any) => x._id === data.updatedId)){
        let response = await getUserByContact(user.contact);

        store.dispatch({
          type: "SET_USER_BRANCHES",
          payload: response.branches
        })
      }
      if(user && user.opBranch && user.opBranch._id === data.updatedId){
        const { user } = store.getState();
        const payload = {
          user: user.uid,
          sub: "B",
          entity: data.updatedId,
          company: user.company._id,
        };
          socket.emit("branchLogin", payload);
        store.dispatch({ type: "SEL_OP_BRANCH", payload: {value: data.updatedId}, isSocketAction: true });
  
        store.dispatch(updateOperationsAllCount());
      }
    } catch (err : any) {}
  }
})

socket.on("setUpdatedBranch", async(data:any) => {
  let user = store && store.getState() && store.getState().user && store.getState().user

    try {
      if(user && user.branches && user.branches.length > 0 && user.branches.find((x:any) => x._id === data.updatedId)){
        let response = await getUserByContact(user.contact);

        await store.dispatch({
          type: "SET_USER_BRANCHES",
          payload: response.branches
        })
      }
    
      if(user && user.opBranch && user.opBranch._id === data.updatedId){
        const { user } = store.getState();
        const payload = {
          user: user.uid,
          sub: "B",
          entity: data.updatedId,
          company: user.company._id,
        };
          socket.emit("branchLogin", payload);
        store.dispatch({ type: "SEL_OP_BRANCH", payload: {value: data.updatedId},isSocketAction: true });
  
        store.dispatch(updateOperationsAllCount());
      }
    } catch (err : any) {
      console.log(err)
    }
})

socket.on("setAssignedBranches", async() => {
  let user = store && store.getState() && store.getState().user && store.getState().user

    try {
      let response = await getUserByContact(user.contact);

      await store.dispatch({
        type: "SET_USER_BRANCHES",
        payload: response.branches
      })
    } catch (err : any) {
      console.log(err)
    }
})

socket.on("setAssignedFleets", async() => {
  let user = store && store.getState() && store.getState().user && store.getState().user

    try {
      let response = await getUserByContact(user.contact);

      await store.dispatch({
        type: "SET_USER_FLEETS",
        payload: response.fleets
      })
    } catch (err : any) {
      console.log(err)
    }
})


socket.on('newCustomerBookingNotification', (data:any) => {
  store.dispatch(getCustomerOrderQuickData(data))

})

socket.on('receiveLiveDocketTracking', (data:any) => {
  store.dispatch(setCustomerDocketLocation(data))
})

// socket.on('updateChat', (data:any) => {
//   store.dispatch({ type: APPEND_CUSTOMER_CHAT, payload: data});
// })

socket.on('chatMessageUpdated', (data:any) => {
  store.dispatch({ type: APPEND_CUSTOMER_CHAT, payload: data});
})

// socket.on('branchOrderChat', (data:any) => {
//   store.dispatch({ type: APPEND_CUSTOMER_CHAT, payload: data});
// })
socket.on(UPDATE_CCAVENUE_TXNS_STATUS,(data:any)=>{
  store.dispatch({type:UPDATE_CCAVENUE_TXNS_STATUS,payload:data.payload})
})
const socketIOMiddleware : any = createSocketIoMiddleware(socket, "server/");

const reducers = combineReducers({
  user: userReducer,
  company: companyReducer,
  customer: customerReducer,
  income: incomeReducer,
  fleet: fleetReducer,
  userForm,
  asset,
  rights: rightsReducer,
  appRights: appRightsReducer,
  franchise: franchiseReducer,
  franchiser,
  tally: tallyReducer,
  route: routeReducer,
  newRoute: newRoute,
  appActions: appActionsReducer,
  sidebarBadges: sidebarBadgesReducer,
  booking: BookingReducer,
  branch: branchReducer,
  expense: expenseReducer,
  memo: memoReducer,
  missing: missingReducer,
  customerLoginReducer: customerLoginReducer,
  qr: qrReducer,
  customerBooking: customerBookingReducer,
  liveLocation: liveLocation,
  customerChatHistory : customerChat,
  customerTracking : customerTracking,
  feedbackFilter : feedbackFilters ,
  taskModule : taskReducer,
  driverLock : driverLogin,
  complaintChatList : compalintChatListing,
  userLocking : userLoginLocking,
  smsAndCallRecordsData : smsAndCallRecords,
  userList : listOfAllUser,
  allGeneralSettings : allGeneralSettings,
  allPlatformUsersList : allPlatformUsersList,
  driversList : allPlatformDriverList,
  paymentType : paymentTypeReducer,
  verifyStocks : verifyStocksReducer,
  paymentGateWay:paymentGateWayReducer,
  global: globalReducer
});

const persistConfig = {
  key: 'root',
  storage: storage,
  // blackList : ["companyReducer"]
  whitelist: ["customerLoginReducer","customerBooking"] // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers as any)

const store = !production
  ? createStore<any,any>(
      persistedReducer,
      composeWithDevTools(applyMiddleware(socketIOMiddleware, thunk, logger))
    )
  : createStore<any,any>(
      persistedReducer,
      composeWithDevTools(applyMiddleware(socketIOMiddleware, thunk))
    );
// const store = composeWithDevTools(applyMiddleware(socketIOMiddleware, thunk, promise(), logger)(createStore)(reducers))

export let persistor = persistStore(store)
export default store;
export type RootState = ReturnType<typeof reducers>;
export type AppDispatch = typeof store.dispatch
