import { ServiceMaker, buildQueryString } from ".";

interface IRatePayload {
  route?: string;
  sender?: number;
}
export const suggestRate = (ratePayload: IRatePayload) =>
  ServiceMaker("rate/get", "POST", ratePayload);

export const getDoorDeliveryRatesByCustomer = (data: any) =>
  ServiceMaker(`rate/getDoorDeliveryRateByCustomer?${buildQueryString(data)}`, "GET");
