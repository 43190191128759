import React, { Dispatch, SetStateAction } from "react";
import { ButtonPrimary } from "../../../Components/Button/Buttoncontainer";
import { Button } from "../UI";
import { FilterIcon } from "../../constants/icons";

type FilterButtonProps = {
  setShowModel: Dispatch<SetStateAction<boolean>>;
  newButton?: boolean;
};

const FilterButton = ({ setShowModel, newButton }: FilterButtonProps) => {
  return newButton ? (
    <Button
      variant="primary"
      action="primary"
      onClick={() => setShowModel(true)}
      Icon={<FilterIcon />}
    >
    </Button>
  ) : (
    <ButtonPrimary
      onClick={() => setShowModel(true)}
      style={{ height: 38, margin: "10px" }}
    >
      {" "}
      <i className="fas fa-filter filter" style={{ color: "white" }}></i>
    </ButtonPrimary>
  );
};

export default FilterButton;
