import React from 'react'
import { useMediaQuery, useTheme } from "@mui/material";

export default function FilterGridcontainer(props: any) {
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  if (!mobileView) {
    return (
      < >
        {props.children}
      </>
    )
  } else {
    return props.children

  }

}
