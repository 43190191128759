import React, { ComponentProps, Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react';
// css
import './dialog.css';
// icons
import { IoMdClose } from "react-icons/io";
import { createPortal } from "react-dom";

// type 
export type DialogProps = {
  header: ReactNode;
  body: ReactNode;
  footer: ReactNode;
  className?: string;
  variant?: "neutral" | "primary" | "secondary" | "accent" | "danger" | "warning" | "success" | "info";
  isBlur?: boolean;
  size?: "auto" | "small" | "regular" | "medium" | "large" | "extraLarge";
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  closeIcon?: boolean;
  isOverlay?: boolean;
  isOutsideClickOff?: boolean;
  activeZIndex?: number | string;
  isFullscreen?: boolean;
} & ComponentProps<"main">;

// Dialog component
const Dialog = ({ header, body, footer, className = "", variant = "primary", isBlur = false, size = "auto", state, setState, closeIcon = true, isOverlay = false, isOutsideClickOff = true, activeZIndex, isFullscreen = false, ...props }: DialogProps) => {
  let dialogSize;
  // creating a dialogRef to close it when you click outside
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const dialogContainer = document.getElementById("custom-dialog-container")!;

  // creating className according to dialog size
  switch (size) {
    case "auto":
      dialogSize = "auto-dialog";
      break;
    case "small":
      dialogSize = "small-dialog";
      break;
    case "regular":
      dialogSize = "regular-dialog";
      break;
    case "medium":
      dialogSize = "medium-dialog";
      break;
    case "large":
      dialogSize = "large-dialog";
      break;
    case "extraLarge":
      dialogSize = "extralarge-dialog";
      break;
    default:
      console.log("Default Case: Custom Dialog Component | switch-case");
      break;
  }

  // to close the dialog whenever user click outside the dialog box
  useEffect(() => {
    const handleEvents = (event: MouseEvent | KeyboardEvent) => {
      if (event.type === "mousedown" && isOutsideClickOff && dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        setState(false);
      }
      if (event.type === "keydown" && (event as KeyboardEvent).key === "Escape") {
        setState(false);
      }
    };
  
    if (state) {
      document.addEventListener("mousedown", handleEvents);
      document.addEventListener("keydown", handleEvents);
    }
  
    return () => {
      document.removeEventListener("mousedown", handleEvents);
      document.removeEventListener("keydown", handleEvents);
    };
  }, [state, isOutsideClickOff, setState]);

  // does: to add more accessibility inside the popup window
  useEffect(() => {
    if (state) {
      const focusableElements = dialogRef.current?.querySelectorAll<HTMLElement>(
        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      );

      const firstFocusableElement = focusableElements ? focusableElements[0] : null;
      firstFocusableElement?.focus();

      const handleTabKey = (e: KeyboardEvent) => {
        if (!focusableElements || focusableElements.length === 0) return;

        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.key === 'Tab') {
          if (e.shiftKey) {
            if (document.activeElement === firstElement) {
              e.preventDefault();
              lastElement.focus();
            }
          } else {
            if (document.activeElement === lastElement) {
              e.preventDefault();
              firstElement.focus();
            }
          }
        }
      };

      document.addEventListener('keydown', handleTabKey);

      return () => {
        document.removeEventListener('keydown', handleTabKey);
      };
    }
  }, [state]);  

  // return dialog of state of dialog is true
  if (state) {
    return createPortal(<main style={{zIndex: activeZIndex ? activeZIndex : "1000"}} className={`custom-dialog-container | flex-center custom-overlay custom-overlay-${isOverlay ? variant : ""} ${isBlur ? "custom-overlay-blur" : ""} ${className}`} {...props}>
      <div className={`custom-dialog-wrapper | ${dialogSize} ${isFullscreen ? "full-screen": ""}`} ref={dialogRef} role="dialog" aria-labelledby="custom-dialog-header" aria-describedby="customer-dialog-body">
        <header id="custom-dialog-header" className={`custom-dialog-header | relative bg-${variant}-400 p-1 text-center text-white-900 fw-500`}>
          <p className={`capitalize fs-medium text-center mx-auto`} style={{ marginBlock: "0" }}>{header}</p>
          {closeIcon && <button className={`custom-dialog-header-close-icon | flex-center border-none outline-none bg-${variant}-600 text-white-900`} onClick={() => setState(false)}>{<IoMdClose />}</button>}
        </header>
        <section id="customer-dialog-body" className="custom-dialog-body | bg-white-900 p-1 fs-small">{body}</section>
        <footer className={`custom-dialog-footer | bg-${variant}-400 px-1 py-8 flex jc-end ai-center fs-small`}>{footer}</footer>
      </div>
    </main>, dialogContainer)
  } else return null;
};

export default Dialog;
