import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
} from "../../Components/Dialog/DialogComponent";

interface IProps {
  title: string;
  info?: any;
}

const StatusHeader = (props: IProps) => {
  const [details, setDetails] = useState<any>(null);

  const handleOpen = () => {
    setDetails(props.info);
  };

  const handleClose = () => {
    setDetails(null);
  };

  return (
    <>
      <div
        style={{
          border: "1px solid var(--clr-primary-400)",
          padding: "6px",
          borderRadius: "4px",
          margin: "2px 4px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        {props.title}{" "}
        <i
          className="fa fa-info-circle"
          style={{ color: "var(--clr-primary-400)", fontSize: "18px" }}
          onClick={() => handleOpen()}
        ></i>
      </div>
      <Dialog
        open={Boolean(details)}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent style={{ fontSize: "18px" }}>
          {(props.title === "New Destination" ||
            props.title === "Back To Origin") && (
            <div>
              {props.title === "New Destination" ? (
                <div>
                  New Destination : {props.info.newDestination.branchName}
                </div>
              ) : (
                <div>Back To Origin</div>
              )}
              <div>Amount : {props.info.charge}</div>
              <div>
                Status:{" "}
                {props.info.status === "A"
                  ? "Approved"
                  : props.info.status === "R"
                  ? "Rejected"
                  : "Pending"}
              </div>
              <div>Remarks : {props.info.adminRemarks}</div>
            </div>
          )}

          {props.title === "Demurrage" && (
            <div>
              {props.info.acceptedAmount ? (
                <div>Accepted Demurrage: {props.info.acceptedAmount}</div>
              ) : (
                <div>Requested Demurrage: {props.info.requestedAmount}</div>
              )}
              <div>
                Status:{" "}
                {props.info.status === "A"
                  ? "Approved"
                  : props.info.status === "R"
                  ? "Rejected"
                  : "Pending"}
              </div>{" "}
              <div>Sender Remarks : {props.info.senderRemarks}</div>
            </div>
          )}
          {props.title === "Discount" && (
            <div>
              {props.info.acceptedDiscount ? (
                <div>Accepted Discount: {props.info.acceptedDiscount}</div>
              ) : (
                <div>Requested Discount: {props.info.discount}</div>
              )}
              <div>
                Status:{" "}
                {props.info.status === "A"
                  ? "Approved"
                  : props.info.status === "R"
                  ? "Rejected"
                  : "Pending"}
              </div>
              <div>Sender Remarks : {props.info.senderRemarks}</div>
            </div>
          )}
          {props.title === "Cancel Booking" && (
            <div>
              <div>Status: {props.info.status}</div>
              <div>Remarks: {props.info.remark}</div>
            </div>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default StatusHeader;
