import React, { useEffect, useState } from 'react';
import { withFormik, FormikProps, Form, Field } from 'formik';
import "./transportcompany.css"
import showMessage, { failed, showHttpError, success } from '../../../../../utils/message';
import { checkBranchExistance, companyData, getOtp, verifyOtpForReg } from '../../../../../services/area';
// import ImageInput from '../../../../../Components/ImageInput';
import CustomToolTip from '../../../../../Components/CustomToolTip/CustomToolTip';
// import { getCityByPincode } from '../../../../../services/area';
import { Select } from '../../../../../Components/Selectcomponents/Select';
import jsonToFormdata from '../../../../../utils/jsonToFormdata';
import AutoComplete from '../../../../../Components/AutoComplete';

interface FormValues {
  companyName : string;
  companyType : string;
  companyEmail : string;
  companyWebsite : string;
  firstName : string;
  lastName : string;
  email: string;
  password: string;
  contact: string;
  otp: string;
  address1: string;
  address2: string;
  pincode: number;
  city: string;
  bankACno: number;
  ifscCode: string;
  pancard: any;
  aadharcard: any;
  userProfile: any;
  branchName: any;
  shortName: any;
  state: any;
}

interface OtherProps {
  message: string;
  className?: string;
  emailClass?: string;
  passClass?: string;
  firstnameClass?: string;
  lastnameClass?: string;
  contactClass?: string;
  otpClass?: string;
  commonInputFieldCss?: string;
  messageClass?: string;
  commonUsedForm?: boolean
}

const InnerForm:any = (props: OtherProps & FormikProps<FormValues>) => {
  const [slideNo , setSlideNo] = useState(0); 
  const [selectedCompanyType  , setSelectedCompanyType] = useState<any>(null);
  // const [imageOfPan  , setImageOfPan] = useState<any>(null);
  // const [imageOfCompanyLogo  , setImageOfCompanyLogo] = useState<any>(null);
  const [updatedSlideNo , setUpdatedSlideNo] = useState<any>(null);
  const [pickupDropLocation, setPickupDropLocation] = useState<any>({
    lng : "",
    lat : "",
    name : "",
    area : "",
    city : "",
    state : "",
    pincode : "",
    placeId : "",
    placeValue : "",
    formatted_address : "",
  });
  const { touched, errors, message , 
          emailClass , passClass , 
          firstnameClass ,lastnameClass, 
          contactClass , otpClass , 
          commonUsedForm,messageClass,
          commonInputFieldCss, resetForm, initialValues
        } : any = props;

  const checkValues:any = props?.values;

  const verifyBranch = async () => {
    try {
      let payload = {
        shortName: checkValues?.shortName?.toUpperCase()
      }
      const response = await checkBranchExistance(payload);
      return response
    } catch (error) {
      showHttpError(error);
    }
  }
  
  // const verifyIfscCode = async (value:any) => {
  //   try {
  //     const response = await validateIfsc(value);
  //     return response
  //   } catch (error) {
  //     showHttpError(error);
  //   }
  // }
  
  const isValidEmail = (email:any) => {
    let error;
    if(!email){
      error = "Required";
    }
    else if (!/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(email)){
      error = "Invalid email address";
    }
    return error;
  }
    
  const isValidCompanyEmail = (email:any) => {
    let error;
    if(!email){
      error = "Required";
    }
    else if (!/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(email)){
      error = "Invalid email address";
    }
    return error;
  }
    
  const isValidCompanyWebsite = (web:any) => {
    let error;
    if (!/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i.test(web)){
      error = "Invalid Website Address Ex: `https://www.google.com/` ";
    }
    return error;
  }
    
  const isValidCompanyName = (name:any) => {
    let error;
    if(!name){
      error = "Required";
    }
    else if (!/^[A-Za-z ]+$/i.test(name)){
      error = "Name Should Not Contain Numericals";
    }
    return error;
  }

  const isValidCompanyType = (type:any) => {
    let error;
    if(!type){
      error = "Required";
    }
    return error;
  }
  
  const isValidPassword = (pass:any) => {
    let error;
    if(!pass){
      error = "Required";
    }
    else if (pass?.length < 6){
      error = "Min Length 6 Is Required";
    }
    else if (!/^[a-zA-Z0-9]{3,16}$/i.test(pass)){
      error = "1 AlphaBet & Special Letter Is Required";
    }
    else if (pass?.length > 16){
      error = "Pass Length Should Not Exceed 16 Letters";
    }
    return error;
  }
  
  const isValidName = (name:any) => {
    let error;
    if(!name){
      error = "Required";
    }
    else if (!/^[A-Za-z]+$/i.test(name)){
      error = "Name Should Not Contain Numericals";
    }
    return error;
  }
  
  const isValidContact = (contact:any) => {
    let error;
    if(!contact){
      error = "Required";
    }
    else if (!/^[0-9].{9,9}$/i.test(contact)){
      error = "Number Should Not be Less Then / Greater Then 10 Nos."
    }
    return error;
  }
  
  const isValidOtp = (otps:any) => {
      let error;
      if(!otps){
        error = "Required";
      }
      else if (!/^[0-9].{5,5}$/i.test(otps)){
        error = "Number Should Not be Less Then / Greater Then 6 Nos."
      }
      return error;
  }
  
  // const isValidPincode = (pincode:any) => {
  //     let error;
  //     if(!pincode){
  //       error = "Required";
  //     }
  //     else if (!/^[0-9].{5,5}$/i.test(pincode)){
  //       error = "Pincode Should Not be Less Then / Greater Then 6 Nos."
  //     }
  //     return error;
  // }
  
  const isValidAddress = (address:any) => {
    let error;
    if(!address){
      error = "Required";
    }
    else if (!/^[a-zA-Z3-9\s,'-]*.{3,}$/i.test(address)){
      error = "Address is not valid format Or Minimum Length Of Address Must be 3"
    }
    return error;
  }
  
  const isValidShortName = (name:any) => {
    let error;
    if(!name){
      error = "Required";
    }
    else if (!/\b[A-Z]{3,4}\b/i.test(name)){
      error = "Short Name Length Must be between 3-4 Only Leters"
    }
    return error;
  }
  
  // const isValidBanAcNo = (accountNo:any) => {
  //     let error;
  //     if(!accountNo){
  //       error = "Required";
  //     }
  //     else if (!/[0-9]{9,18}$/i.test(accountNo)){
  //       error = "Kindly enter valid account number"
  //     }
  //     return error;
  // }
  
  // const isValidIfscCode = (code:any) => {
  //     let error;
  //     if(!code){
  //       error = "Required";
  //     }
  //     else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/i.test(code)){
  //       error = "Kindly enter valid ifsc code"
  //     }
  //     return error;
  // }
  
  // const isValidAadharCard = (files:any) => {
  //   let error;
  //   if(!files){
  //     error = "Required";
  //   }
  //   else if (!/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i.test(files)){
  //     error = "Kindly Upload Valid Document Format"
  //   }
  //   return error;
  // }
    
  // const isValidPanCard = (files:any) => {
  //   let error;
  //   if(!files){
  //     error = "Required";
  //   }
  //   else if (!/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/i.test(files)){
  //     error = "Kindly Upload Valid Document Format"
  //   }
  //   return error;
  // }
  
  const handleNextBtnClick = async () => {
    if(slideNo === 0 && updatedSlideNo <= 0){
      if(isValidCompanyName(checkValues?.companyName) === undefined && 
        isValidCompanyEmail(checkValues?.companyEmail) === undefined &&
        isValidCompanyType(selectedCompanyType) === undefined 
        ){
          if(checkValues?.companyWebsite !== ''){
            if(isValidCompanyWebsite(checkValues?.companyWebsite) === undefined){
              setSlideNo(slideNo + 1)
              setUpdatedSlideNo(0);
            }
            else{
              return showMessage("Kindly Enter Valid Web Address",failed,2000);
            }
          }
          else{
            setSlideNo(slideNo + 1)
            setUpdatedSlideNo(0);
          }
      }
      else{
        return showMessage("Kindly Fill Up All The Deatils Or Select anyone Company Type",failed,2000);
      }
    }
    if(slideNo === 1 && updatedSlideNo === 0){
      if(isValidEmail(checkValues?.email) === undefined && 
        isValidPassword(checkValues?.password) === undefined &&
        isValidName(checkValues?.firstName) === undefined &&
        isValidName(checkValues?.lastName) === undefined &&
        isValidContact(checkValues?.contact) === undefined &&  
        isValidOtp(checkValues?.otp) === undefined 
        ){
        {
          let payload = {contact :  checkValues?.contact , otp : checkValues?.otp}
          let reasponse:any = await verifyOtpForReg(payload);
          if(reasponse){
            setSlideNo(slideNo + 1)
            setUpdatedSlideNo(1);
          }
          else{
            showHttpError("Otp MissMatched , Kindly Resend Otp Or Enter Valid Otp")
          }
        }
      }
      else{
        return showMessage("Kindly Fill Up All The Deatils",failed,2000);
      }
    }
    if(slideNo === 2 && updatedSlideNo === 1){
      if(isValidAddress(checkValues?.address1) === undefined && 
      isValidAddress(checkValues?.address2) === undefined
      ){
        setSlideNo(slideNo + 1)
        setUpdatedSlideNo(2)
      }
      else{
        return showMessage("Kindly Fill Up All The Deatils",failed,2000);
      }
    }
    // if(slideNo === 4 && updatedSlideNo === 3){
    //   if(isValidAddress(checkValues?.address1) === undefined && 
    //   isValidAddress(checkValues?.address2) === undefined &&
    //   isValidPincode(checkValues?.pincode) === undefined 
    //   ){
    //     setSlideNo(slideNo + 1)
    //     setUpdatedSlideNo(4)
    //   }
    //   else{
    //     return showMessage("Kindly Fill Up All The Deatils",failed,2000);
    //   }
    // }
  }

  const RegisterCompany = async () => {
    try {
      let payload:any = {
        companyName : checkValues?.companyName,
        kind : selectedCompanyType?.label,
        email : checkValues?.companyEmail,
        url : checkValues?.companyWebsite,
        contactPerson : {
          name : {
            fName : checkValues?.firstName,
            lName : checkValues?.lastName
          },
          contact : checkValues?.contact,
          password : checkValues?.password,
          email : checkValues?.email,
        },
        address : {
          l1 : checkValues?.address1,
          l2 : checkValues?.address2,
          pincode : +pickupDropLocation?.pincode,
          city : pickupDropLocation?.city,
        },
        otp : checkValues?.otp,
        shortName : checkValues?.shortName.toUpperCase(),
        branchName : checkValues?.branchName.toUpperCase(),
        state : pickupDropLocation?.state,
        coordinate: {
          latitude: pickupDropLocation?.lat,
          longitude: pickupDropLocation?.lng
        }
      }
      // if(imageOfCompanyLogo){
      //   payload.companyLogo = imageOfCompanyLogo
      // }
      // if(imageOfPan){
      //   payload.panCardImage = imageOfPan
      // }
      await companyData(jsonToFormdata(payload));
      setSlideNo(0);
      resetForm(initialValues);
      setSelectedCompanyType(null);
      setPickupDropLocation({
        lng : "",
        lat : "",
        name : "",
        area : "",
        city : "",
        state : "",
        pincode : "",
        placeId : "",
        placeValue : "",
        formatted_address : "",
      })
      showMessage(`Company Created Succesfully`,success,2000)
    } catch (error) {
      showHttpError(error);
    }
  }

  const handleSubmit = async () => {
    // if(imageOfCompanyLogo === null){
    //   return showHttpError("All Fields Are Mandatory !");
    // }
    // if(imageOfPan === null){
    //   return showHttpError("All Fields Are Mandatory !");
    // }
    
    if(slideNo === 3 && updatedSlideNo === 2){
      if(isValidShortName(checkValues?.shortName) === undefined){
        let res:any = await verifyBranch();
        if(res === true){
          RegisterCompany();
        }
        else{
          return showHttpError(`Kindly Choose Diffrent Unique Short Name`)
        }
      }
      else{
        return showMessage("Kindly Fill Up All The Deatils",failed,2000);
      }
    }
  }

  const multipleExist = (arr: any, values: any) => {
    return values.every((value: any) => {
      return arr.includes(value);
    });
  };
  
  const setLocationByTypingRes = (e:any) => {
    console.log("pickupDropLocation Auto Ans",e);
    let area = e.address_components.find((x: any) => {
      return multipleExist(x.types, [
        "sublocality_level_1",
        "sublocality",
        "political",
      ]);
    });

    let pincode = e.address_components.find((x:any) => {
      return multipleExist(x.types, [
        'postal_code'
      ]);
    })

    // if(pincode === undefined){
    //   resetSelectedFormLocation();
    //   return showHttpError("Kindly Select Location More Specific !")
    // }

    let city = e.address_components.find((x:any) => {
      return x.types.length === 2 && multipleExist(x.types, ["locality", "political"]);
    })

    let state = e.address_components.find((x:any) => {
      return multipleExist(x.types, ["administrative_area_level_1", "political"]);
    })

    setPickupDropLocation({...pickupDropLocation , 
      'placeId' : e.place_id , 
      'lat' : e.geometry.location.lat , 
      'lng' : e.geometry.location.lng , 
      'formatted_address' : e.formatted_address,
      'placeValue' : e.formatted_address,
      'name' : e.name,
      'area' : area.long_name,
      'pincode' : pincode.long_name,
      'city' : city.long_name,
      'state' : state.long_name                          
    })
  }
  
  const typesOfCompany = [{value:1,label:"LLC"},{value:2,label:"Pvt Ltd"},{value:3,label:"Sole Partnership"},{value:4,label:"LLP"},{value:5,label:"Public Ltd"}];
  
  useEffect(() => {
    console.log("pickupDropLocation 16",pickupDropLocation)
  } , [pickupDropLocation])
  
  return (
    <Form id={"transportCompany"}>
        {message && <div className={messageClass}>{message}</div>}
        <div className='dsaTitle'> Transport Company </div>
        {slideNo === 0 && 
          <div className='formDetailsForDSA'>

            <div className='commonTitleForHeading'>
              <span> Company Info </span>
              <span> {slideNo + 1} / 4 </span>
            </div>

            <div className={commonUsedForm ? "commonEmailCss" : emailClass}>
                <Field type="text" name="companyName" placeholder="Comapny Name" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} validate={isValidCompanyName}/>
                {touched.companyName && errors.companyName && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.companyName && errors?.companyName} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>

            <div className={commonUsedForm ? "commonPassCss" : passClass}>
                <div className='selectCompanyTypeforTCC'>
                  <Select
                    placeholder="Select Company Type"
                    value={selectedCompanyType}
                    options={typesOfCompany}
                    onChange={(e:any) => setSelectedCompanyType(e)}
                    className="selectForTCCompany"
                  />
                </div>
                {touched.companyType && errors.companyType && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.companyType && errors?.companyType} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>
            
            <div className={commonUsedForm ? "commonFirstNameCss" : firstnameClass}>
                <Field type="email" name="companyEmail" placeholder="Company Email" className={commonUsedForm ? "commonFirstNameInputCss" : commonInputFieldCss} validate={isValidCompanyEmail}/>
                {touched.companyEmail && errors.companyEmail && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.companyEmail && errors?.companyEmail} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>
            
            <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
                <Field type="text" name="companyWebsite" placeholder="Company Website (optional)" className={commonUsedForm ? "commonLastNameInputCss" : commonInputFieldCss}/>
                {touched.companyWebsite && errors.companyWebsite && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.companyWebsite && errors?.companyWebsite} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>
          </div>
        }
        {slideNo === 1 && 
          <div className='formDetailsForDSA'>

            <div className='commonTitleForHeading'>
              <span> General Info </span>
              <span> {slideNo + 1} / 4 </span>
            </div>

            <div className={commonUsedForm ? "commonEmailCss" : emailClass}>
                <Field type="email" name="email" placeholder="Email" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} validate={isValidEmail}/>
                {touched.email && errors.email && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.email && errors?.email} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>

            <div className={commonUsedForm ? "commonPassCss" : passClass}>
                <Field type="password" name="password" placeholder="Password" className={commonUsedForm ? "commonPassInputCss" : commonInputFieldCss} validate={isValidPassword}/>
                {touched.password && errors.password && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.password && errors?.password} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>
            
            <div className={commonUsedForm ? "commonFirstNameCss" : firstnameClass}>
                <Field type="text" name="firstName" placeholder="First Name" className={commonUsedForm ? "commonFirstNameInputCss" : commonInputFieldCss} validate={isValidName}/>
                {touched.firstName && errors.firstName && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.firstName && errors?.firstName} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>
            
            <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
                <Field type="text" name="lastName" placeholder="Last Name" className={commonUsedForm ? "commonLastNameInputCss" : commonInputFieldCss} validate={isValidName}/>
                {touched.lastName && errors.lastName && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.lastName && errors?.lastName} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>
            
            <div className={commonUsedForm ? "commonContactCss" : contactClass}>
                <Field type="number" name="contact" placeholder="Contact No" className={commonUsedForm ? "commonContactInputCss" : commonInputFieldCss} validate={isValidContact}/>
                {touched.contact && errors.contact && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.contact && errors?.contact} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>

            <div className='commonOtpCss'>
                <button className='commonOtpBtnCss' onClick={async ()=>{
                    if(touched.contact && errors.contact){
                      showMessage("Kindly Enter Valid Number",failed,2000)
                    }
                    if(!errors.email && !errors.password && !errors.firstName && !errors.lastName && !errors.contact){
                      await getOtp(+(props?.values?.contact));
                      showMessage("Otp sent SuccesFully !",success,2000)
                    }
                }}>Get Otp</button>
            </div>

            <div className={commonUsedForm ? "commonOTPCss" : otpClass}>
                <Field type="number" name="otp" placeholder="Enter OTP" className={commonUsedForm ? "commonOTPInputCss" : commonInputFieldCss} validate={isValidOtp}/>
                {touched.otp && errors.otp && 
                  <div className='commonErrorCss'>
                    <CustomToolTip title={errors && errors.otp && errors?.otp} ><i className='fa fa-info largeScal' /></CustomToolTip>
                  </div>
                }
            </div>
          </div>
        }
        {slideNo === 2 &&
          <div className='formDetailsForDSA'>

              <div className='commonTitleForHeading'>
                <span> Address Details </span>
                <span> {slideNo + 1} / 4 </span>
              </div>

              <div className={commonUsedForm ? "commonEmailCss" : emailClass}>
                  <div className='autoCompleteDiv'>
                    <AutoComplete value={pickupDropLocation?.placeValue}
                          onChange={(e:any)=>{setLocationByTypingRes(e)}}
                          className={"settledAddresses"}
                          general={false}
                    ></AutoComplete>
                  </div>
              </div>

              <div className={commonUsedForm ? "commonEmailCss" : emailClass}>
                  <Field type="text" name="address1" placeholder="Enter Address Line 1" className={commonUsedForm ? "commonPassInputCss" : commonInputFieldCss} validate={isValidAddress}/>
                  {touched.address1 && errors.address1 && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.address1 && errors?.address1} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>

              <div className={commonUsedForm ? "commonPassCss" : passClass}>
                  <Field type="text" name="address2" placeholder="Enter Address Line 2" className={commonUsedForm ? "commonPassInputCss" : commonInputFieldCss} validate={isValidAddress}/>
                  {touched.address2 && errors.address2 && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.address2 && errors?.address2} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>
              
              <div className={commonUsedForm ? "commonFirstNameCss" : firstnameClass}>
                  <input type="text" name="city" placeholder="City" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} value={pickupDropLocation?.city} disabled={true}/>
                  {touched.city && errors.city && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.city && errors?.city} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>
              
              <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
                  <input type="number" name="pincode" placeholder="Pincode" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} value={pickupDropLocation?.pincode} disabled={true}/>
                  {touched.pincode && errors.pincode && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.pincode && errors?.pincode} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>

              <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
                  <input type="text" name="state" placeholder="State" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} value={pickupDropLocation?.state} disabled={true}/>
                  {touched.state && errors.state && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.state && errors?.state} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>
          </div> 
        }
        {slideNo === 3 &&
          <div className='formDetailsForDSA'>
            
            <div className='commonTitleForHeading'>
              <span> Branch Details </span>
              <span> {slideNo + 1} / 4 </span>
            </div>

            <div className={commonUsedForm ? "commonImageCss" : emailClass}>
              
              <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
                  <Field type="text" name="branchName" placeholder="Enter Branch Name as per Your Area Name" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} />
                  {touched.branchName && errors.branchName && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.branchName && errors?.branchName} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>
              
              <div className={commonUsedForm ? "commonLastNameCss" : lastnameClass}>
                  <Field type="text" name="shortName" placeholder="Enter Suitable Unique Short Name" className={commonUsedForm ? "commonEmailInputCss" : commonInputFieldCss} validate={isValidShortName} />
                  {touched.shortName && errors.shortName && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.shortName && errors?.shortName} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>
              
            </div>
          </div> 
        }
        {/* {slideNo === 4 &&
          <div className='formDetailsForDSA'>
            
              <div className={commonUsedForm ? "commonImageCss" : emailClass}>
                  PanCard
                  <ImageInput
                    name="Pan Card"
                    Icon={(props: any) => (
                      <i {...props}  className="fas fa-id-card file_icon"></i>
                    )}
                    onChange={(e) => {
                      setImageOfPan(e);
                    }}
                    value={imageOfPan}
                    acceptPdf={true}
                    style={{border:"0.2px solid transparent",height:"50%",marginTop:"2.5%",marginLeft:"2.5%",textAlign:"center"}}
                  />
                   <Field name="pancard" validate={isValidPanCard}>
                      {() => (
                        <div>
                          Pan Card
                          <ImageInput
                            name="Pan Card"
                            Icon={(props: any) => (
                              <i {...props}  className="fas fa-id-card file_icon"></i>
                            )}
                            onChange={(e) => {
                              setImageOfPan(e);
                            }}
                            value={imageOfPan}
                            acceptPdf={true}
                            style={{border:"0.2px solid transparent",height:"50%",marginTop:"2.5%",marginLeft:"2.5%",textAlign:"center"}}
                          />
                          {touched.pancard && errors.pancard && 
                            <div className='commonErrorCss'>
                              <CustomToolTip title={errors && errors.pancard && errors?.pancard} ><i className='fa fa-info largeScal' /></CustomToolTip>
                            </div> 
                          }
                        </div>
                      )}
                    </Field> 
                  {touched.pancard && errors.pancard && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.pancard && errors?.pancard} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>

              <div className={commonUsedForm ? "commonImageCss" : passClass}>
                  Company Logo
                  <ImageInput
                    name="Company Logo"
                    Icon={(props: any) => (
                      <i {...props}  className="fas fa-id-card file_icon"></i>
                    )}
                    onChange={(e) => {
                      setImageOfCompanyLogo(e);
                    }}
                    value={imageOfCompanyLogo}
                    acceptPdf={true}
                    style={{border:"0.2px solid transparent",height:"50%",marginTop:"2.5%",marginLeft:"2.5%",textAlign:"center"}}
                  />
                  {touched.aadharcard && errors.aadharcard && 
                    <div className='commonErrorCss'>
                      <CustomToolTip title={errors && errors.aadharcard && errors?.aadharcard} ><i className='fa fa-info largeScal' /></CustomToolTip>
                    </div> 
                  }
              </div>

          </div> 
        } */}
        <div className='btnsForSlider'>
            {slideNo > 0 && <button className='nextBtnsCSS' onClick={() => {setSlideNo(slideNo - 1),setUpdatedSlideNo(updatedSlideNo-1)}}> Previous </button>}
            {slideNo <= 2 && 
              <button 
                className='prevBtnsCSS' 
                onClick={() => handleNextBtnClick()}
              > 
                Next 
              </button>
            }
            {slideNo > 2 && <button type='submit' className='prevBtnsCSS' onClick={() => handleSubmit()}> Submit </button>}
        </div>
    </Form>
  );
};

interface MyFormProps {
  initialEmail?: string;
  className?: string;
  emailClass?: string;
  passClass?: string;
  firstnameClass?: string;
  lastnameClass?: string;
  contactClass?: string;
  otpClass?: string;
  commonInputFieldCss?: string;
  messageClass?: string;
  commonUsedForm?: boolean;
  message?: string;
  submitFunc?: Function;
}

const TransportCompany = withFormik<MyFormProps, FormValues, OtherProps>({
  mapPropsToValues: (props : any) : any => {
    return {
      companyName : '',
      companyType : '',
      companyEmail : '',
      companyWebsite : '',
      firstName: '',
      lastName: '',
      email: props.initialEmail || '',
      password: '',
      contact: '',
      otp: '',
      address1: '',
      address2: '',
      pincode: null,
      city: '',
      bankACno: '',
      ifscCode: '',
      aadharcard: '',
      pancard: '',
      branchName: '',
      shortName: '',
      state: '',
    };
  },
  
  handleSubmit : (values:any) => {
    console.log("Final Submit Ans",values)
  } 

})(InnerForm);

export default TransportCompany;
