import { CONFIRMED_INTRACITY_BOOKING, RESET_INTERCITY, SET_INTRACITY_BOOKING_RESET_TRACKING, SET_INTRACITY_BOOKING_TRACKING, SET_INTRACITY, SET_INTRACITY_PACKAGES, SET_INTRACITY_PICKUP_DROP_LOCATION, SET_PARTLOAD, SET_PARTLOAD_PACKAGES, SET_PARTLOAD_PICKUP_DROP_LOCATION, SET_QUICK_ORDER, SET_PARTLOAD_BOOKING_TRACKING, SET_PARTLOAD_BOOKING_RESET_TRACKING, SET_DOCKET_FROM_PARTLOAD_EWAYBILL, SET_PARTLOAD_SHOW_EWAYBILL_LOADER, SET_PARTLOAD_HIDE_EWAYBILL_LOADER, RESET_PARTLOAD_EWAYBILL_DATA, ADD_PARTLOAD_EWAYBILL, SET_MANUAL_PARTLOAD_EWAYBILL, SET_PARTLOAD_BOOKING_TYPE, SET_PARTLOAD_GROSS, SET_PARTLOAD_PAYMENT_MODE, SET_CUSTOMER_BOOKING_TYPE, SET_FULL_LOAD_VEHICLE_DETAILS, SET_OUTSIDE_FORM_FILLED, ACCEPT_INTRACITY_BOOKING, INTRACITY_BOOKING_REQ_ID } from "../constants/customerBooking";

const initState = {
  intraCity: {
    type: "IntraCity",
    isBookedWithoutToken: false,
    lead : {},
    assetSubType:{
      
    },
    city: null,
    pickDropLocation: [
      {
        id: 0,
        place: {
          placeId: null,
          coordinate: {
            latitude: null,
            longitude: null
          },
          placeValue: '',
          city: '',
          area: '',
          name: '',
          pincode: '',
          formatted_address: ''
        },
        contactPerson: {
          name: '',
          contact: '',
          floor: 0,
          officeName: '',
          nearby_landmark: '',
          pincode: '',
          city: '',
          isSelfSelected: false
        },
        isSubmited: false,
      },
      {
        id: 1,
        place: {
          placeId: null,
          coordinate: {
            latitude: null,
            longitude: null
          },
          placeValue: '',
          city: '',
          area: '',
          name: '',
          pincode: '',
          formatted_address: ''
        },
        contactPerson: {
          name: '',
          contact: '',
          floor: 0,
          officeName: '',
          nearby_landmark: '',
          pincode: '',
          city: '',
          isSelfSelected: false
        },
        isSubmited: false,
      },
    ],
    packages: [
      {
        id: 0,
        materialType: null,
        qty: 0,
        codAmount: 0,
        orderNumber: '',
        // packingType: null,
        // size: 0,
        // unit: 0,
        // dimension: {
        //   l: 0,
        //   b: 0,
        //   h: 0
        // },
        // weight: 0,
        // stack: false,
        // haz: false,
        // frag: false,
        fromPlaceRef: 0,
        toPlaceRef: null,
      }
    ],
    paymentMode: "topay",
    intraCityRateType: "EXPRESS",
    booked: false
  },
  partLoad: {
    type: "partLoad",
    bookingType: "",
    city: null,
    lead : {},
    isBookedWithoutToken: false,
    isMultipleEWayBill: false,
    pickDropLocation: [
      {
        id: 0,
        place: {
          placeId: null,
          coordinate: {
            latitude: null,
            longitude: null
          },
          placeValue: '',
          city: '',
          area: '',
          name: '',
          pincode: '',
          formatted_address: ''
        },
        contactPerson: {
          name: '',
          gstin: '',
          contact: '',
          floor: 0,
          officeName: '',
          nearby_landmark: '',
          pincode: '',
          city: '',
          isSelfSelected: false
        },
        isSubmited: false,
      },
      {
        id: 1,
        place: {
          placeId: null,
          coordinate: {
            latitude: null,
            longitude: null
          },
          placeValue: '',
          city: '',
          area: '',
          name: '',
          pincode: '',
          formatted_address: ''
        },
        contactPerson: {
          name: '',
          gstin: '',
          contact: '',
          floor: 0,
          officeName: '',
          nearby_landmark: '',
          pincode: '',
          city: '',
          isSelfSelected: false
        },
        isSubmited: false,
      },
    ],
    packages: [
      {
        id: 0,
        materialType: null,
        qty: 0,
        codAmount: 0,
        orderNumber: '',
        packingType: null,
        size: 0,
        unit: 0,
        dimension: {
          l: 0,
          b: 0,
          h: 0
        },
        weight: 0,
        // stack: false,
        // haz: false,
        // frag: false,
        fromPlaceRef: 0,
        toPlaceRef: null,
      }
    ],
    eWayBillData: [
      {
        id: 0,
        eWbillNumber: "",
        partyBillNumber: "",
        partyGoodsVal: 0,
        isDataFromApiCall: false,
      }
    ],
    paymentMode: "topay",
    gross: 0,
    vehicleDetails: {
      assetType: '',
      assetSubType: '',
      vehicleModel: '',
      truckNos: ''
    }
  },
  quickOrder: {
    isOpen: false,
    data: ""
  },
  tracking: {
    route: [],
    estimation: []
  },
  ewayBillLoading: false,
  isOutsideFormFilled: { check: false, type: "" },
  intraCityAcceptedOrder:""
};

const customerBookingReducer = (
  state: any = initState,
  action: { [k: string]: any }
) => {
  switch (action.type) {
    case SET_INTRACITY_PICKUP_DROP_LOCATION: {
      return {
        ...state,
        intraCity: { ...state.intraCity, pickDropLocation: action.payload }
      }
    }
    case SET_INTRACITY_PACKAGES: {
      return {
        ...state,
        intraCity: { ...state.intraCity, packages: action.payload }
      }
    }
    case SET_INTRACITY: {
      return {
        ...state,
        intraCity: {
          ...state.intraCity,
          [action.payload.what]: action.payload.val
        }
      }
    }
    case RESET_INTERCITY: {
      return {
        ...state,
        intraCity: {
          ...initState.intraCity
        }
      }
    }
    case CONFIRMED_INTRACITY_BOOKING: {
      return {
        ...state,
        intraCity: {
          ...state.intraCity,
          [action.payload.what]: action.payload.val
        }
      }
    }
    case INTRACITY_BOOKING_REQ_ID: {
      return {
        ...state,
        intraCity: {
          ...state.intraCity,
          [action.payload.what]: action.payload.val
        }
      }
    }
    case SET_QUICK_ORDER: {
      return {
        ...state,
        quickOrder: {
          ...state.quickOrder,
          [action.payload.what]: action.payload.val
        }
      }
    }
    case SET_INTRACITY_BOOKING_TRACKING: {
      return {
        ...state,
        tracking: {
          ...state.tracking,
          ...action.payload
        }
      }
    }
    case SET_INTRACITY_BOOKING_RESET_TRACKING: {
      return {
        ...initState
      }
    }

    // PARTlOAD STARTS

    case SET_PARTLOAD_BOOKING_TRACKING: {
      return {
        ...state,
        tracking: {
          ...state.tracking,
          ...action.payload
        }
      }
    }

    case SET_PARTLOAD_BOOKING_TRACKING: {
      return {
        ...state,
        partLoad: { ...state.partLoad, fullLoadVehicleData: { ...action.payload } }
      }
    }

    case SET_CUSTOMER_BOOKING_TYPE: {
      console.log(`setCustomerBookingType Entry 2`, action.payload, `action.payload`, action)
      return {
        ...state,
        partLoad: { ...state.partLoad, type: action.payload }
      }
    }
    case SET_PARTLOAD_PICKUP_DROP_LOCATION: {
      return {
        ...state,
        partLoad: { ...state.partLoad, pickDropLocation: action.payload }
      }
    }
    case SET_DOCKET_FROM_PARTLOAD_EWAYBILL: {
      const val = action.payload;
      console.log(`val pickDropLocation`, val)
      return {
        ...state,
        partLoad: { ...state.partLoad, pickDropLocation: action.payload?.pickDropLocation, eWayBillData: action?.payload?.eWayBillData }
      }
    }
    case SET_MANUAL_PARTLOAD_EWAYBILL: {
      const val = action.payload;
      console.log(`val pickDropLocation DFVB`, val)
      return {
        ...state,
        partLoad: { ...state.partLoad, eWayBillData: val }
      }
    }
    case RESET_PARTLOAD_EWAYBILL_DATA: {
      return {
        ...state,
        partLoad: { ...state.partLoad, pickDropLocation: [], eWayBillData: [], bookingType: "" }
      }
    }
    case ADD_PARTLOAD_EWAYBILL: {
      console.log(`~zdf,vzdfvzmdf`, action)
      return {
        ...state,
        partLoad: { ...state.partLoad, eWayBillData: action.payload }
      }
    }
    case SET_PARTLOAD_BOOKING_TYPE: {
      return {
        ...state,
        partLoad: { ...state.partLoad, bookingType: action.payload }
      }
    }
    case SET_PARTLOAD_PAYMENT_MODE: {
      console.log(`dfrgsgsefv payment 02`, action)
      return {
        ...state,
        partLoad: { ...state.partLoad, paymentMode: action.payload }
      }
    }
    case SET_FULL_LOAD_VEHICLE_DETAILS: {
      console.log(`setFullLoadVehicleDetails data 2`, action)
      return {
        ...state,
        partLoad: {
          ...state.partLoad,
          vehicleDetails: { ...state.partLoad.vehicleDetails, ...action.payload }
        }
      }
    }
    case SET_PARTLOAD_GROSS: {
      console.log(`dfrgsgsefv gross 02`, action)
      return {
        ...state,
        partLoad: { ...state.partLoad, gross: action.payload }
      }
    }

    case SET_PARTLOAD_SHOW_EWAYBILL_LOADER: {
      return {
        ...state,
        ewayBillLoading: true,
      };
    }

    case SET_PARTLOAD_HIDE_EWAYBILL_LOADER: {
      return {
        ...state,
        ewayBillLoading: false,
      };
    }

    case SET_OUTSIDE_FORM_FILLED: {
      return {
        ...state,
        isOutsideFormFilled: { check: true, type: action.payload },
      };
    }

    case SET_PARTLOAD_PACKAGES: {
      return {
        ...state,
        partLoad: { ...state.partLoad, packages: action.payload }
      }
    }
    case SET_PARTLOAD: {
      return {
        ...state,
        partLoad: {
          ...state.partLoad,
          [action.payload.what]: action.payload.val
        }
      }
    }
    case SET_PARTLOAD_BOOKING_RESET_TRACKING: {
      return {
        ...initState
      }
    }
    case ACCEPT_INTRACITY_BOOKING:{
      console.log("ACCEPT_INTRACITY_BOOKING : ",action.payload)
      return{
        ...state,
        intraCityAcceptedOrder :action.payload?.shipmentNumber
      }
    }
    default: {
      return state;
    }
  }
};

export default customerBookingReducer;
