import React, { FunctionComponent } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { RootState } from "./store";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "./Components/Dialog/DialogComponent";
import { ButtonLight } from "./Components/Button/Buttoncontainer";

const connector = connect((state: RootState) => ({
  isDialogOpen: state.appActions.isReloadAppDialogOpen,
}));

interface Props extends ConnectedProps<typeof connector> {}

const ConfirmReloadDialog: FunctionComponent<Props> = (props) => {
  const dispatch : any = useDispatch();
  const handleClose = () => {
    dispatch({
      type: "SET_RELOAD_APP_DIALOG",
      payload: false,
    });
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div>
      <Dialog open={props.isDialogOpen} onClose={() => {}}>
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          New Version Available.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please reload to update the application version.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonLight autoFocus onClick={handleClose}>
            Cancel
          </ButtonLight>
          <ButtonLight onClick={handleReload}>Reload</ButtonLight>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default connector(ConfirmReloadDialog);
