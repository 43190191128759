import { Map as immutableMap } from "immutable";
import { toast } from "react-toastify";

import authReducer from "./auth";
import { UPDATE_COMPANY_DOC } from "../constants/company";
import { isArrayCheck } from "../containers/generics/CheckArray";
// import { updateDocketMap } from "../containers/operations/loadutils/filterUtil";
import { SET_COMPANY_ROUTES, GET_ROUTES } from "../constants/route";
import { showMessage, success, failed } from "../utils/message";
import {
  HIDE_LOADER_WITHOUT_BLUR,
} from "../constants/booking";
import { socket } from "../store";

export const isBox = function(p: any) {
  if (p && p.docketBox && p.docketBox.hasOwnProperty("boxNumber")) {
    return true;
  }
  return false;
};

declare var global: typeof globalThis & {
  utils?: {
    setupData?: () => void;
  };
};

const colReport = {
  bookings: [],
  expenses: [],
  deliveries: [],
  sumOfExpense: 0,
  sumOfGross: 0,
};

// const negotiationsSchema = {
//   company: "",
//   customer: "",
//   route: "",
//   material: "",
//   negType: "",
//   kgWise: [
//     {
//       weightMin: 10,
//       weightMax: 10,
//       weightPrice: 10,
//     },
//   ],
//   flat: "",
//   bids: [
//     {
//       kgWise: [
//         {
//           weightMin: "",
//           weightMax: "",
//           weightPrice: "",
//         },
//       ],
//       flat: "",
//       by: "",
//     },
//   ],
//   status: {
//     type: "",
//     default: false,
//   },
// };

const initState : any = {
  soundStatus: "STOPPED",
  pendingBalance: 0,
  trackingNumber: "",
  expenseHandlingData: "",
  maxBalance: 0,
  CCAvenueTxns : 0,
  allowAttendanceFromAnywhere: false,
  allowFlexibleHour: false,
  startLoader: false,
  verifyList: [],
  verifyRemarks: "",
  userShiftStart: null,
  userShiftEnd: null,
  thisMonthAttendanceCount: 0,
  attendance: false,
  latitude: null,
  longitude: null,
  attendancePopup: true,
  loginType: null,
  userType:null,
  userName: null,
  uid: null,
  token: null,
  contact: null,
  opBranch: {},
  opFleet: {},
  company: {
    id: null,
    name: null,
  },
  branches: null,
  rights: null,
  routes: null,
  allcities: null,
  assign: {
    branches: null,
    departments: null,
    fleets: null,
  },
  balanceDialog: false,
  docketMapRegNumWise: {},
  // negotiationsArr: [],
  // negotiations: negotiationsSchema,
  allBranchBalance: "",
  totalBalance: "",
  colMonth: 0,
  customPack: "null",
  docketMap: null,
  docketReRenderMap: immutableMap({}),

  // fedexPickupNumber: null,
  assignroute: "",
  docketNumber: "",
  colReport,
  remainingLimit: 0,
  loading: false,
  loaderAcrossAmount: false,
  loadingWithOutBlur: false,
  showAttachmentOptions: false,
  pendingPaidLeaveCount: 0,
  isBreakInBreakOutDialogOpen: false,
};
export const mockState = initState;
export const getDocketNumber = (order: string, splitted: any) => {
  let prefix = order.replace(/\d/g, "");
  let suffix = order.replace(/\D/g, "");
  let preSuffix = suffix.substring(0, 10);
  if (splitted) {
    splitted.prefix = prefix;
    splitted.suffix = preSuffix;
  }
  return prefix + preSuffix;
};

const makeDocketMapRegNumWise = (arr: any) => {
  const docketMap: any = {};
  // const fleetSet = new Set(fleets.map((f) => f.regNumber));
  arr.forEach((pack: any) => {
    const curr = pack.regNumber;
    // if (fleetSet.has(curr)) {
    if (!(curr in docketMap)) {
      docketMap[curr] = [pack];
    } else {
      docketMap[curr].push(pack);
    }
    // }
  });
  const newDocketMap: any = {};
  Object.keys(docketMap).forEach((d) => {
    newDocketMap[d] = {};
    let currDocs = docketMap[d];
    for (let i = 0; i < currDocs.length; i++) {
      const currDocket = currDocs[i].docket.docketNumber;
      if (!(currDocket in newDocketMap[d])) {
        newDocketMap[d][currDocket] = [{ ...currDocs[i] }];
      } else {
        newDocketMap[d][currDocket].push({ ...currDocs[i] });
      }
    }
  });

  return newDocketMap;
};

const reducer = (state: any = initState, action: any) => {
  if (action.type.includes("AUTH:")) {
    return authReducer(state, action);
  }

  switch (action.type) {
    //universal functionality
    case GET_ROUTES: {
      return {
        ...state,
        routes: action.payload,
      };
    }

    /**
     * @warning
     * do not change or change with server code. Action can be sent from server too.
     */

    /**
     * @warning
     * do not change or change with server code. Action can be sent from server too.
     */

    case "UPDATE_BALANCE": {
      if (isNaN(action.payload)) {
        return state;
      }
      let { options } = action;
      const { balance, handlingLogs } = options;

      let { loginType, opBranch, opFleet, allBalances } = state;

      if (balance && balance.sub && balance.entity) {
        return {
          ...state,
          allBalances:
          allBalances &&
          allBalances.map((b: any) =>
            b._id == balance.entity
              ? {
                  ...b,
                  balance:
                    balance.balance ||
                    action.payload + (balance.add ? b.balance : 0),
                }
              : b
          ),
          expenseHandlingData: handlingLogs ? handlingLogs : "",
        };
      }
      if (loginType == "B") {
        let allBalancesUpdated = allBalances.map((balanc: any) => {
          if (
            balanc &&
            balanc._id &&
            balanc._id.length == 24 &&
            balanc._id == opBranch._id
          ) {
            return {
              ...balanc,
              balance: action.payload,
            };
          }
          return { ...balanc };
        });
        return { ...state, allBalances: allBalancesUpdated };
      }
      if (loginType == "F") {
        let allBalancesUpdated = allBalances.map((balanc: any) => {
          if (
            balanc &&
            balanc._id &&
            balanc._id.length == 24 &&
            balanc._id == opFleet._id
          ) {
            return {
              ...balanc,
              balance: action.payload,
            };
          }
          return { ...balanc };
        });
        return { ...state, allBalances: allBalancesUpdated };
      }
      return {
        ...state,
      };
    }
    case "UPDATE_CCAVENUETXNS" : {
      return{
        ...state,
        CCAvenueTxns : action.payload
      }
    }
    case "UPDATE_INCENTIVE": {
      return {
        ...state,
        incentive: action.payload,
      };
    }
    case "CLEAR_ENTITY": {
      return {
        ...state,
        opBranch: {},
      };
    }
    case "INDIVISUAL_OP_BRANCH": {
      try {
        let opBranch: any = null;
        opBranch = action.payload;

        showMessage("Operating Branch Set : " + opBranch.branchName, success, 2000, { position: "bottom-center"});
        if (global.utils && global.utils.setupData) {
          setTimeout(global.utils.setupData, 500);
        }
        return {
          ...state,
          opBranch: opBranch,
          loginType: "B",
        };
      } catch (err : any) {
        console.log(err);
        return state;
      }
    }
    case "SEL_OP_BRANCH": {
      try {
        if (!state.branches) return state;
        let { value: id } = action.payload,
          opBranch: any = null;
        state.branches.forEach((br: any) => {
          if (br._id === id) {
            opBranch = br;
          }
        });
        if(!action.isSocketAction){
          showMessage("Operating Branch Set : " + opBranch.branchName, success, 2000, { position: "bottom-center"} )
        }
        if (global.utils && global.utils.setupData) {
          setTimeout(global.utils.setupData, 500);
        }
        console.log("opBranch ",opBranch)
        if(opBranch._id){
          const {_id,branchName}=opBranch
          socket.emit("branchLogin", {opBranch:{_id,branchName}});
        }
        return {
          ...state,
          opBranch: {
            ...opBranch,
            biltyPrintConfig: {
              margin: {
                top: opBranch?.biltyPrintConfig?.margin?.top || -0.2,
                bottom: opBranch?.biltyPrintConfig?.margin?.bottom || 12,
                left: opBranch?.biltyPrintConfig?.margin?.left || 0,
                right: opBranch?.biltyPrintConfig?.margin?.right || 0,
              }
            }
          },
          loginType: "B",
        };
      } catch (err : any) {
        console.log(err);
        return state;
      }
    }
    case "SEL_OP_FLEET": {
      try {
        let opFleet: any;
        state.fleets.forEach((fl: any) => {
          if (fl._id == action.payload.value) {
            opFleet = fl;
          }
        });
        if (global.utils && global.utils.setupData) {
          setTimeout(global.utils.setupData, 500);
        }
        showMessage("Operating Fleet Set : " + opFleet.regNumber, success, 2000, { position: "bottom-center" });
        
        console.log("fleetLogin", opFleet.allowedOFDCities)
        if(opFleet.allowedOFDCities && opFleet.allowedOFDCities.length > 0){
          socket.emit("fleetLogin", {allowedOFDCities: opFleet.allowedOFDCities});
        }
        return {
          ...state,
          opFleet,
          loginType: "F",
        };
      } catch (err : any) {
        console.log(err);
        return state;
      }
    }
    case "SET_ALLOWED_ATTENDANCE_FROM _ANYWHEWRE": {
      return {
        ...state,
        allowAttendanceFromAnywhere: action.payload,
      };
    }

    case "CHANGE_ATTENDANCE_COUNT": {
      return {
        ...state,
        thisMonthAttendanceCount: action.payload,
      };
    }
    case "SET_LATITUDE": {
      return {
        ...state,
        latitude: action.payload,
      };
    }
    case "SET_LONGITUDE": {
      return {
        ...state,
        longitude: action.payload,
      };
    }
    case "SET_ATTENDANCE": {
      return {
        ...state,
        attendance: action.payload,
      };
    }

    //login functionality
    case "LOGIN": {
      toast.success("Successfully signed-in!");
      sessionStorage.setItem("kabra:token", action.payload.data.accessToken);
      sessionStorage.setItem(
        "kabra:user",
        action.payload.data.response.user._id
      );
      const status = action.payload.data.status;
      if (status !== 1) {
        showMessage("Invalid mobile or password!", "#ef533e");
        return state;
      }
      const { role } = action.payload.data;
      const data = action.payload.data.response;
      return {
        ...state,
        role,
        todayCheckIn: data.todayCheckIn,
        thisMonthAttendanceCount: data.thisMonthAttendanceCount,
        userName: data.user.name.fName,
        fullName: data.user.name.fName + " " + data.user.name.lName,
        uid: data.user._id,
        contact: data.user.contact,
        maxBalance: data.user.maxBalance,
        token: "5b0ce34h6j",
        photoReq: data.user.photoReq,
        // rights: dep,
        branches: data.user.branches,
        fleets: data.user.fleets,
        userTypeForOrganogram: data.user.userType,
        userType: data.user.userType,
        userShiftStart: (data.user.userShiftDuty || {}).startTime,
        userShiftEnd: (data.user.userShiftDuty || {}).endTime,
        company: {
          id: data.user.company._id,
          name: data.user.company.companyName,
          // sizes: data.user.company.sizes,
          ...data.user.company,
        },
        documents:{
          user:{
            aadharCard:data?.user?.aadharCard ?? false,
            licence:data?.user?.licence ?? false,
            panCard:data?.user?.panCard ?? false
          },
          fleet:{
            rcBook:data?.user?.fleets && data?.user?.fleets?.length >0 ?  data?.user?.fleets[0]?.rcBook : false,
            insurance:data?.user?.fleets && data?.user?.fleets?.length >0 ?  data?.user?.fleets[0]?.insurance : false,
            allIndiaPermit:data?.user?.fleets && data?.user?.fleets?.length >0 ?  data?.user?.fleets[0]?.allIndiaPermit : false,
          }

        }
      };
    }
    case "SET_USER_BRANCHES": {
      return {
        ...state,
        branches: action.payload,
      };
    }
    case "SET_USER_FLEETS": {
      return {
        ...state,
        fleets: action.payload,
      };
    }
    //logout functionality
    case "LOG_OUT": {
      sessionStorage.clear();
      return initState;
    }
    case "LOGIN_FAIL": {
      showMessage(action.payload || "Invalid credentials!", failed);
      return state;
    }
    case "SET_CITIES": {
      const cities = action.payload;
      const citiesMap = new Map();
      if (isArrayCheck(cities)) {
        cities.forEach((c: any) => {
          if (!citiesMap.has(c._id)) {
            citiesMap.set(c._id, c);
          }
        });
      }
      return {
        ...state,
        allcities: cities,
        citiesMap,
      };
    }

    case "USER_ALL_BALANCES": {
      const { balances, branches, fleets } = action.payload;
      let { company } = state;
      let allBranchBalance;
      // totalBalance = 0;
      if (isArrayCheck(balances)) {
        allBranchBalance = balances
          .filter((b: any) =>
            b.company && company && company._id && company._id.length == 24
              ? b.company == company._id
              : true
          )
          .map((b: any) => {
            // totalBalance += b.balance;
            if (b.sub == "B") {
              let branch = branches.get(b.entity);
              return {
                balance: b.balance,
                entity: branch && branch.branchName ? branch.branchName : "NA",
                _id: b.entity,
                sub: b.sub
              };
            } else if (isArrayCheck(fleets) && b.sub == "F") {
              let fleet = fleets.find(
                (f: { _id: string }) => f._id == b.entity
              );
              return {
                balance: b.balance,
                entity: fleet && fleet.regNumber ? fleet.regNumber : "NA",
                _id: b.entity,
                sub: b.sub
              };
            } else {
              return {
                balance: b.balance,
                entity: "NA",
                _id: b.entity,
              };
            }
          }); //.filter(b => b && b.entity != null && b.entity != undefined)
      }
      return {
        ...state,
        allBalances: allBranchBalance,
      };
    }
    case "UPDATE_SOUND_START": {
      return {
        ...state,
        soundStatus: "PLAYING",
      };
    }
    case "UPDATE_SOUND_STOP": {
      return {
        ...state,
        soundStatus: "STOPPED",
      };
    }
    case "USER_ALL_BALANCE_UPDATE_FROM_SERVER_EVENT": {
      const pendingBalance = state.balance;
      const balances = isArrayCheck(action.payload)
        ? action.payload
        : action.payload.balances;
      const pendingBalanceData = isArrayCheck(action.payload)
        ? pendingBalance
        : action.payload.pendingBalance;

      const allBalances = state.allBalances;

      return {
        ...state,
        pendingBalance: pendingBalanceData,
        allBalances:
          allBalances &&
          allBalances.map((oldBalance: any) => {
            let entity = balances.find(
              (newBalance: { entity: string }) =>
                newBalance.entity == oldBalance._id
            );
            if (entity) {
              oldBalance.balance = entity.balance;
            }
            return oldBalance;
          }),
      };
    }

    // case "FEDEX_PICKUP_SUCCESS": {
    //   const { fedexPickupNumber } = action.payload;
    //   toast.success("Pickup Number generated successfully");
    //   let docketMap = Object.assign({}, state.docketMap);
    //   let criteria = (p: { selected: boolean }) => p.selected !== true;
    //   return {
    //     ...state,
    //     docketMap: updateDocketMap(docketMap, criteria, null, {
    //       filterData: true,
    //     }),
    //     fedexPickupNumber: fedexPickupNumber,
    //   };
    // }
    case "SHOW_BALANCE_DIALOG": {
      return {
        ...state,
        balanceDialog: true,
      };
    }
    case "HIDE_BALANCE_DIALOG": {
      return {
        ...state,
        balanceDialog: false,
      };
    }

    case "OUT_FOR_DELIVERY_PACKAGES_REGNUMWISE": {
      let { packages: arr } = action.payload;
      if (!arr || arr.length === 0)
        return {
          ...state,
          docketMapRegNumWise: null,
        };
      return {
        ...state,
        docketMapRegNumWise: makeDocketMapRegNumWise(arr),
      };
    }

    case "CLEAR_REGNUM_WISE_DOCKET": {
      return {
        ...state,
        docketMapRegNumWise: {},
      };
    }

    //expense module

    case "SET_COL_REPORT": {
      let colReport = Object.assign({}, state.colReport);
      Object.keys(action.payload).forEach((key) => {
        colReport[key] = action.payload[key];
      });
      return { ...state, colReport: colReport };
    }

    case "SET_TRACKING_NUMBER": {
      return {
        ...state,
        trackingNumber: action.payload,
      };
    }

    case UPDATE_COMPANY_DOC: {
      try {
        let company = state.company._id;
        if (action.data && company == action.data._id) {
          showMessage("Company Policy Updated !", success);
          return { ...state, company: action.data };
        } else throw "err";
      } catch (error : any) {
        console.log("error: ", error);
        return state;
      }
    }
    case "INIT:SET_LOADING_REPORTS": {
      return {
        ...state,
        pendingLoadingReports: action.payload,
      };
    }
    case "INIT:SET_FRANCHISE_REQUESTS": {
      return {
        ...state,
        franchiseRequests: action.payload,
      };
    }

    case "MISSING_REMARKS_CHANGE": {
      return {
        ...state,
        remarks: action.payload,
      };
    }
    case "SET_ATTENDENCE_POPUP": {
      return {
        ...state,
        attendancePopup: false,
      };
    }
    case "SET_BREAK_IN_BREAK_OUT_DIALOG": {
      return {
        ...state,
        isBreakInBreakOutDialogOpen: action.payload,
      };
    }
    case "START_LOADER": {
      return {
        ...state,
        startLoader: true,
      };
    }
    case "END_LOADER": {
      return {
        ...state,
        startLoader: false,
      };
    }
    case "SET_VERIFY_REMARKS": {
      return {
        ...state,
        verifyRemarks: action.payload,
      };
    }
    case "GET_VERIFY_LIST": {
      return {
        ...state,
        verifyList: action.payload,
      };
    }
    // report  module
    case SET_COMPANY_ROUTES: {
      return {
        ...state,
        routes: action.payload,
      };
    }
    case HIDE_LOADER_WITHOUT_BLUR: {
      return {
        ...state,
        loadingWithOutBlur: false,
      };
    }
    //could not match to any action
    default: {
      return state;
    }
  }
};

export default reducer;

export const makeReducer = (customInitState: any) => (
  state = customInitState,
  action: any
) => reducer(state, action);
