import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Select } from "../UI";
import { Ipropsselect } from "../UI/select/select";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import {
  getAssetTypeByCategoryList,
  listAssetSubType,
} from "../../../services/assetType";
import { hideLoader, showLoader } from "../../../actions/UserActions";

type VehicleOption = {
  _id: string;
  label: string;
  value: string;
};

type VehicleTypeProps = {
  vehicleTypeProps?: Ipropsselect;
  vehicleSubTypeProps?: Ipropsselect;
  vehicleTypeLabel?: string;
  vehicleSubTypeLabel?: string;
  vehicleTypePlaceholder?: string;
  vehicleSubTypePlaceholder?: string;
  vehicleTypeValue: any;
  vehicleSubTypeValue: any;
  onVehicleTypeChange: (value: any) => void;
  onVehicleSubTypeChange: (value: any) => void;
};

const VehicleType: React.FC<VehicleTypeProps> = ({
  vehicleTypeProps,
  vehicleSubTypeProps,
  vehicleTypeLabel,
  vehicleSubTypeLabel,
  vehicleTypePlaceholder = "Vehicle Type",
  vehicleSubTypePlaceholder = "Vehicle Sub Type",
  vehicleTypeValue,
  vehicleSubTypeValue,
  onVehicleTypeChange,
  onVehicleSubTypeChange,
}) => {
  const dispatch = useAppDispatch();
  const [assetTypeList, setAssetTypeList] = useState<Array<VehicleOption>>([]);
  const [assetSubTypeList, setAssetSubTypeList] = useState<
    Array<VehicleOption>
  >([]);

  // Fetch asset types based on category ID
  const fetchAssetTypes = useCallback(async () => {
    try {
      dispatch(showLoader());
      const response = await getAssetTypeByCategoryList({
        categoryId: "5c304e42296d79da335ada16",
      });
      const uniqueAssetTypes = response.reduce(
        (acc: VehicleOption[], currentValue: any) => {
          if (!acc.some((item) => item.label === currentValue.assetName)) {
            acc.push({
              _id: currentValue._id,
              label: currentValue.assetName,
              value: currentValue._id,
            });
          }
          return acc;
        },
        []
      );
      setAssetTypeList(uniqueAssetTypes);
      setAssetSubTypeList([]);
    } catch (error) {
      console.error("Error fetching asset types", error);
    } finally {
      dispatch(hideLoader());
    }
  }, [dispatch]);

  // Fetch and filter asset subtypes based on selected vehicle type
  const fetchAssetSubTypes = useCallback(async () => {
    if (!vehicleTypeValue) return; // Exit early if no vehicle type is selected

    try {
      dispatch(showLoader());
      const response = await listAssetSubType();
      const assetSubTypeOptions = response.reduce(
        (acc: VehicleOption[], currentValue: any) => {
          if (
            currentValue.assetType._id === vehicleTypeValue._id &&
            !acc.some((item) => item._id === currentValue.assetType._id)
          ) {
            acc.push({
              _id: currentValue._id,
              label: currentValue.name,
              value: currentValue._id,
            });
          }
          return acc;
        },
        []
      );
      setAssetSubTypeList(assetSubTypeOptions);
    } catch (error) {
      console.error("Error fetching asset subtypes", error);
    } finally {
      dispatch(hideLoader());
    }
  }, [vehicleTypeValue, dispatch]);

  useEffect(() => {
    fetchAssetTypes();
  }, [fetchAssetTypes]);

  useEffect(() => {
    fetchAssetSubTypes();
  }, [fetchAssetSubTypes]);

  const vehicleTypeOptions = useMemo(() => assetTypeList, [assetTypeList]);
  const vehicleSubTypeOptions = useMemo(
    () => assetSubTypeList,
    [assetSubTypeList]
  );

  return (
    <>
      <div className="flex-col gap-4">
        {vehicleTypeLabel && (
          <label htmlFor={vehicleTypeLabel} className="fw-500 m-0">
            {vehicleTypeLabel}
          </label>
        )}
        <Select
          placeholder={`Select ${vehicleTypePlaceholder ?? "Vehicle Type"}`}
          value={vehicleTypeValue}
          onChange={onVehicleTypeChange}
          options={vehicleTypeOptions}
          {...vehicleTypeProps}
        />
      </div>
      <div className="flex-col gap-4">
        {vehicleSubTypeLabel && (
          <label htmlFor={vehicleSubTypeLabel} className="fw-500 m-0">
            {vehicleSubTypeLabel}
          </label>
        )}
        <Select
          placeholder={`Select ${vehicleSubTypePlaceholder ?? "Vehicle Sub Type"}`}
          value={vehicleSubTypeValue}
          isDisabled={!vehicleTypeValue}
          onChange={onVehicleSubTypeChange}
          options={vehicleSubTypeOptions}
          {...vehicleSubTypeProps}
        />
      </div>
    </>
  );
};

export default VehicleType;
