import { FaUserPlus } from "react-icons/fa";
import { GoogleMap, Marker, DirectionsRenderer, Polyline, MarkerClusterer, } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { showHttpError } from "../../utils/message";
import { hideLoader, showLoader } from "../../actions/UserActions";
import "./viewShipmentTrack.css"
import { useGoogleMapJsLoader } from "../../hooks/useGoogleMapJsLoader";
  
  let lebelArr = ["A","B","C","D","E","F","G","H","I","J","K","L"]

  interface Iprops{
    Route?: any;
    from?:any;
    customeised?:any;
    encoded?:any;
    className?: string;
    mobileMapHeight?: string;
    style?: any;
    zoom?: number;
  }

  const  ViewShipmentTrack = ({zoom = 11, ...props} :Iprops) =>{
    // const { isLoaded ,loadError} = useJsApiLoader({
    //   googleMapsApiKey: "AIzaSyA5kw1jdPoWOGfUDL0o2vW0Ezf2Cvq12OU",
    //   nonce: "nonce",
    //   libraries: ["places"],
    // });
    const { isLoaded ,loadError} : any = useGoogleMapJsLoader()
    // let Origin = props?.Route && props?.Route[0]
    // let Dest = props?.Route && props?.Route[props?.Route.length -1]
    let WayPoint = props?.Route && props?.Route?.slice(1, -1);
    const [directionsResponse, setDirectionsResponse] = useState<any>(null);
    const [mypolyline,setMyPolyline]=useState<any>([]);
    // const [autoCompleteOrigin, setAutoCompleteOrigin] = useState<any>(null);
    // const [autoCompleteDest, setAutoCompleteDest] = useState<any>(null);    
    // const [origin, setOrigin] = useState<any>("");
    // const [destination, setDestionation] = useState<any>("");
    // const [wayPoint,setWayPoint]=useState<any>([]);
    // const onLoadOrigin = useCallback(function callback(autocomplete) {
    //   setAutoCompleteOrigin(autocomplete);
    // }, []);
  
    // const onLoadDest = useCallback(function callback(autocomplete) {
    //   setAutoCompleteDest(autocomplete);
    // }, []);

  useEffect(() => {
    if(props?.encoded) {console.log("decoded",props.encoded);  decodePolyLine(props.encoded?.points.toString());   }
    else calculateRoute();
  }, [isLoaded])

  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  
  const  calculateRoute = async() =>{
    try {
      showLoader()
      const directionsService = new window.google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: new window.google.maps.LatLng(
          parseFloat(props.Route[0]?.coordinate?.latitude),
          parseFloat(props.Route[0]?.coordinate?.longitude),
        ),
        destination: new window.google.maps.LatLng(
          parseFloat(props.Route[1]?.coordinate?.latitude),
          parseFloat(props.Route[1]?.coordinate?.longitude),
        ),
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: WayPoint.map((e:any)=>({location: new google.maps.LatLng(e?.coordinate?.latitude,e?.coordinate?.longitude)})),
        
        
        optimizeWaypoints:true,
        provideRouteAlternatives:true
      });
      hideLoader()
      console.log("result",results)

      setDirectionsResponse(results);
      // setDistance(results?.routes[0]?.legs[0]?.distance?.text ||"");
      // setDuration(results?.routes[0].legs[0].duration?.text|| "");

    } catch (error) {
      hideLoader()
      showHttpError(error)
    }
  }
  
  // function clearRoute() {
  //   setDirectionsResponse(null);
  //   setDistance("");
  //   setDuration("");
  // }

  function decodePolyLine(encoded:any) {
    if (!encoded) {
        return [];
    }
    var poly = [];
    var index = 0, len = encoded.length;
    var lat = 0, lng = 0;
    console.log("decoded pnts",encoded.length)
  
    while (index < len) {
        var b, shift = 0, result = 0;
  
        do {
            b = encoded.charCodeAt(index++) - 63;
            result = result | ((b & 0x1f) << shift);
            shift += 5;
        } while (b >= 0x20);
  
        var dlat = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
        lat += dlat;
  
        shift = 0;
        result = 0;
  
        do {
            b = encoded.charCodeAt(index++) - 63;
            result = result | ((b & 0x1f) << shift);
            shift += 5;
        } while (b >= 0x20);
  
        var dlng = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
        lng += dlng;
  
        var p = {
            lat: lat / 1e5,
            lng: lng / 1e5,
        };
        poly.push(p);
    }
    console.log("decoded polyline ans",poly)
    console.log("decoded polyline first last",poly[0].lat,poly[poly.length-1].lat)
    setMyPolyline(poly)
    // setWayPoint(props.Route.slice(1,-1))
    return poly;
  }
  
  if (!isLoaded) {
    return <FaUserPlus />;
  }

  const icon1 = {
    url: "https://img.icons8.com/ios-filled/50/007acc/marker.png",
    scaledSize: new window.google.maps.Size(15, 20),
    anchor: new window.google.maps.Point(10, 10), 
    scale: 3,
    strokeColor: "#FFFFFF",
    fillColor: "#0000FF",
    fillOpacity: 1,
    rotation: 215,
 
  };

  const icon2 = {
    url: "https://img.icons8.com/ios-filled/50/006bb3/marker.png",
    scaledSize: new window.google.maps.Size(15, 20),
    anchor: new window.google.maps.Point(10, 10),
    scale: 3,
    strokeColor: "#FFFFFF",
    fillColor: "#0000FF",
    fillOpacity: 1,
    rotation: 215,
   
  };
  
  console.log("nbkinfvkinjv customerTrackingMap mypolyline",mypolyline)
  return (
    <div className={`${props?.className} ${props?.from === "driver" ? "outerContainerForDriverModule" : (mobileView ? "mapContainerForViewShipmentTrackCustomerTrackingMobile h-100" : "mapContainerForViewShipmentTrackCustomerTracking")}`} style={props.style ? props.style : {}}>
      <Box sx={{border:"0.2px solid transparent",paddingTop:"1px",width:"100%",height: mobileView && props?.from === "customerTrackingMap" || props?.from === "customerBookingMap" ? "100%" : props.mobileMapHeight ? props.mobileMapHeight : "100%" ,display:"flex",justifyContent:"center"}}>
        {loadError && <p>{loadError}</p>}
        {!isLoaded && <p>Loading .. </p>}      
        <GoogleMap
          center= {props?.Route && props?.Route?.length > 0 ? 
              {lat: (props?.Route[0]?.coordinate?.latitude  +  props?.Route[1]?.coordinate?.latitude)/2 
              ,lng: (props?.Route[0]?.coordinate?.longitude + props?.Route[1]?.coordinate?.longitude)/2}
              :
              mypolyline && {lat: (mypolyline[0]?.lat  +  mypolyline[mypolyline.length-1].lat)/2 
              ,lng: (mypolyline[0]?.lng  +  mypolyline[mypolyline.length-1].lng)/2}
          }
          zoom={mobileView && props?.from === "customerBookingMap" ? 13 : zoom}
          mapContainerStyle={{ width: "100%", height: props?.from === "customerTrackingMap" ? "100%" : mobileView && props?.from === "customerBookingMap" ? "100%" : "100%" }}
          options={
              {
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                gestureHandling: 'cooperative', // Disable map gestures
                fullscreenControl: false,
                styles: [
                  // Customize map styles
                  {
                    featureType: 'poi',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                  },
                  {
                    featureType: 'transit',
                    elementType: 'labels',
                    stylers: [{ visibility: 'off' }],
                  },
                ],
                
              }
          }
          // onLoad={(map) => setMap(map)}
        >
        <>
        {console.log("check poli",mypolyline)}
        {(mypolyline && mypolyline.length>0)?  
          <>
            <Polyline
              path={mypolyline}
              options={{ strokeColor: "#007acc" }}
            />

          {/* {wayPoint && wayPoint.map((e:any,i:any)=>{
            {console.log("hello way",e?.coordinate?.latitude)}
            <Marker 
            position={{lat:e?.coordinate?.latitude
              ,lng:e?.coordinate?.longitude}}
            title='way point'
            label={{text:lebelArr[i+1],color:"white"}}
            icon={icon1} 
            // icon={{
            //   url:"http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            //   fillColor:"blue",
            //   scaledSize: new window.google.maps.Size(50, 50),

            // }}
            />
          })} */}
          {props?.customeised && 
            <div className="kmAndDistanceInMap">
                <span> {props?.customeised?.distance} Km </span>
                <span> {props?.customeised?.duration} </span>
              </div>
          }
            <Marker 
            position={{lat:mypolyline[0]?.lat
              ,lng:mypolyline[0]?.lng}}
            title='Your Driver'
            icon={icon1}            
            />
              <Marker 
              position={{lat:mypolyline[mypolyline.length-1].lat
                ,lng:mypolyline[mypolyline.length-1].lng}}
              title='Your Driver'
              icon={icon2}  >
              </Marker>

          </>
            
            : 
          <>
            <Polyline
              path={props?.Route}
              options={{ strokeColor: "#007acc" }}
            /> 
            * <Polyline
              path={props?.Route.slice(0,10)}
              options={{ strokeColor: "var(--clr-danger-400, red)" }}
            /> 
            {props?.customeised && 
              <div className="kmAndDistanceInMap">
                <span> {props?.customeised?.distance} Km </span>
                <span> {props?.customeised?.duration} </span>
              </div>
            }
            {/* <div className="mapSuggetions1" >
              {props?.Route[0]?.formatted_address}
            </div> */}
            <Marker 
            position={{lat:props?.Route[0]?.coordinate?.latitude
              ,lng:props?.Route[0]?.coordinate?.longitude}}
              title='Your Driver'
              icon={icon1}            
              />
            {/* <div className="mapSuggetions2" >
              {props?.Route[1]?.formatted_address}
            </div> */}
            <Marker 
            position={{lat:props?.Route[1]?.coordinate?.latitude
              ,lng:props?.Route[1]?.coordinate?.longitude}}
            title='Your Driver'
            icon={icon2}            
            />

            <MarkerClusterer>
            {(clusterer:any) =>(
            
            
              WayPoint &&WayPoint.length> 0 &&
                WayPoint.map((e:any,i:any)=>{
                  return(
                  <>
                  <Marker 
                  position={{lat:e.coordinate?.latitude
                  ,lng:e.coordinate?.longitude}}
                  title='Destination'
                  label={{text:lebelArr[i+1],color:"white"}}
                  clusterer = {clusterer}
                  icon={{
                    url:"http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    fillColor:"#007acc",
                    scaledSize: new window.google.maps.Size(50, 50),

                  }}
                />
                <Marker 
                position={{lat:props?.Route[0]?.coordinate?.latitude
                  ,lng:props?.Route[0]?.coordinate?.longitude}}
                title='Origin'
                label={{text:lebelArr[0],color:"white"}}
                
                />
                <Marker 
                position={{lat:props?.Route[1]?.coordinate?.latitude
                  ,lng:props?.Route[1]?.coordinate?.longitude}}
                title='Destination'
                label={{text:lebelArr[props?.Route.length - 1 ],color:"white"}}
                />
                    </>)
                  })
                )}
                </MarkerClusterer>
                {directionsResponse && (
                  <DirectionsRenderer 
                  directions={directionsResponse}
                  
                  options={{ suppressMarkers: true ,
                            polylineOptions:{
                              strokeOpacity: 2,
                              strokeColor: '#007acc',
                              strokeWeight:2
                              
                              
                            }
                  
                  }}
                  />
                )}
          </>

        }
          
        </>
        </GoogleMap>
      </Box>
    </div> 
  );
  }
  
  export default ViewShipmentTrack;
  