import React from "react";
import { GetDdMmYyyyFormatDate } from "../HelperDatesFunc/GetDdMmYyyyFormatDate";
import "./filter.css";

export function FiltervalueContainer(props: any) {
  return (
    <div className="">
      <div className="my-filter-value-container | my-10 paper flex gap-10 flex-wrap none-if-empty">{props.children}</div>
    </div>
  );
}

interface Ifiltervalue {
  label: any;
  value: any;
  onDelete?: any;
  style?: {};
}
export function Filtervalue(props: Ifiltervalue) {
  return (
    <div
      className="relative flex ai-center py-8 px-10 br-4 border-white"
      style={props.style}
    >
      <label className="m-0 fw-600 capitalize">{props.label}:</label>
      {Array.isArray(props.value) ? (
        props.value.map((v: any, index: any) => {
          return (
            <p className="capitalize">
              {v.label}
              {index !== props.value.length - 1 && ", "}
            </p>
          );
        })
      ) : (
        <span className="capitalize">{props.value}</span>
      )}
    </div>
  );
}

interface IPropsvalue {
  value: any;
  onDelete?: any;
}
export function FiltervalueCompany(props: IPropsvalue) {
  return <Filtervalue label="Company" value={props.value} />;
}
export function Filtervalueincentive(props: IPropsvalue) {
  return <Filtervalue label="Incentive" value={props.value} />;
}
export function FiltervalueUser(props: IPropsvalue) {
  return <Filtervalue label="User" value={props.value} />;
}
export function FiltervalueStartdate(props: IPropsvalue) {
  return <Filtervalue label="Start Date" value={GetDdMmYyyyFormatDate(props.value)} />;
}
export function FiltervalueEnddate(props: IPropsvalue) {
  return <Filtervalue label="End Date" value={GetDdMmYyyyFormatDate(props.value)} />;
}

export function FiltervalueStartTime(props: IPropsvalue) {
  return <Filtervalue label="Start Time" value={props.value} />;
}

export function FiltervalueEndTime(props: IPropsvalue) {
  return <Filtervalue label="End Time" value={props.value} />;
}

export function Filtervaluelt1Day(props: IPropsvalue) {
  return <Filtervalue label="<1 Day" value={props.value} />;
}

export function Filtervalue1to3Day(props: IPropsvalue) {
  return <Filtervalue label="1-3 Days" value={props.value} />;
}

export function Filtervaluegt3Day(props: IPropsvalue) {
  return <Filtervalue label="3> Days" value={props.value} />;
}

export function FiltervalueCustomer(props: IPropsvalue) {
  return <Filtervalue label="Customer" value={props.value} />;
}

export function FiltervalueCreatedByUser(props: IPropsvalue) {
  return <Filtervalue label="Created By" value={props.value} />;
}

export function FiltervalueAssignedToUser(props: IPropsvalue) {
  return <Filtervalue label="Assigned To" value={props.value} />;
}
export function FiltervalueBranch(props: IPropsvalue) {
  return <Filtervalue label="Branch" value={props.value} />;
}
export function FiltervalueDriver(props: IPropsvalue) {
  return <Filtervalue label="Driver" value={props.value} />;
}
export function FiltervalueFleet(props: IPropsvalue) {
  return <Filtervalue label="Fleet" value={props.value} />;
}
export function FiltervalueRoute(props: IPropsvalue) {
  return <Filtervalue label="Route" value={props.value} />;
}
export function FiltervalueExpenseType(props: IPropsvalue) {
  return <Filtervalue label="Expense Type" value={props.value} />;
}
export function FiltervalueVerified(props: IPropsvalue) {
  return <Filtervalue label="Verified" value={props.value} />;
}
export function FiltervalueDisallowed(props: IPropsvalue) {
  return <Filtervalue label="Disallowed" value={props.value} />;
}
export function FiltervalueType(props: Ifiltervaluepayment) {
  return <Filtervalue label="Type" value={props.value}  style={props?.style}/>;
}
export function FiltervalueStatus(props: Ifiltervaluepayment) {
  return <Filtervalue label="Status" value={props.value}  style={props?.style}/>;
}
export function FiltervalueDateRange(props: Ifiltervaluepayment) {
  return <Filtervalue label="Date Range" value={props.value}  style={props?.style}/>;
}
export function FiltervalueDate(props: Ifiltervaluepayment) {
  return <Filtervalue label="Date" value={GetDdMmYyyyFormatDate(props.value)} style={props?.style} />;
}
export function Filtervaluemonth(props: Ifiltervaluepayment) {
  return <Filtervalue label="Month" value={props.value}  style={props?.style}/>;
}
export function FiltervalueEntityType(props: IPropsvalue) {
  return <Filtervalue label="Type" value={props.value}/>;
}
interface Ifiltervaluepayment {
  value: any;
  label?: any;
  onDelete?: any;
  style?: any;
}
export function FiltervaluePayment(props: Ifiltervaluepayment) {
  return <Filtervalue label="Payment" value={props.value} style={props?.style} />;
}
export function FiltervaluePaymentstatus(props: Ifiltervaluepayment) {
  return <Filtervalue label="Payment Status" value={props.value} style={props?.style} />;
}

export function FiltervalueHeader(props: Ifiltervaluepayment) {
  return <Filtervalue label="Header" value={props.value} style={props?.style} />;
}
export function FiltervalueOriginBranch(props: Ifiltervaluepayment) {
  return <Filtervalue label="Origin Branch" value={props.value} style={props?.style} />;
}
export function FiltervalueDestinationBranch(props: Ifiltervaluepayment) {
  return <Filtervalue label="Destination Branch " value={props.value} style={props?.style} />;
}
export function FiltervalueRemarks(props: Ifiltervaluepayment) {
  return <Filtervalue label="Remarks " value={props.value} style={props?.style} />;
}
export function FiltervaluePriority(props: Ifiltervaluepayment) {
  return <Filtervalue label="Priority " value={props.value} style={props?.style} />;
}
export function FiltervalueResolve(props: Ifiltervaluepayment) {
  return <Filtervalue label="Resolved " value={props.value} style={props?.style} />;
}
export function FiltervalueSub(props: Ifiltervaluepayment) {
  return <Filtervalue label="Sub" value={props.value} style={props?.style} />;
}

export function FiltervalueSettled(props: Ifiltervaluepayment) {
  return <Filtervalue label="Settled" value={props.value} style={props?.style} />;
}
export function FiltervalueVendor(props: Ifiltervaluepayment) {
  return <Filtervalue label="Vendor" value={props.value} style={props?.style} />;
}
export function FiltervalueCreditor(props: Ifiltervaluepayment) {
  return <Filtervalue label="Creditor" value={props.value} style={props?.style} />;
}
export function FiltervalueAgent(props: Ifiltervaluepayment) {
  return <Filtervalue label="Agent" value={props.value} style={props?.style} />;
}
export function FiltervalueComplaintstatus(props: Ifiltervaluepayment) {
  return <Filtervalue label="Complaint Status" value={props.value} style={props?.style} />;
}
export function FiltervalueServicetype(props: Ifiltervaluepayment) {
  return <Filtervalue label="Service type" value={props.value} style={props?.style} />;
}
export function FiltervalueFeedbacktype(props: Ifiltervaluepayment) {
  return <Filtervalue label="Feedback type" value={props.value} style={props?.style} />;
}
export function FiltervalueComplainttype(props: Ifiltervaluepayment) {
  return <Filtervalue label="Complaint type" value={props.value} style={props?.style} />;
}
export function FiltervalueCity(props: Ifiltervaluepayment) {
  return <Filtervalue label="City" value={props.value} style={props?.style} />;
}
export function FiltervalueRequestType(props: Ifiltervaluepayment) {
  return <Filtervalue label="Request Type" value={props.value} style={props?.style} />;
}
export function FiltervaluePackageStatus(props: Ifiltervaluepayment) {
  return <Filtervalue label="Package Status" value={props.value} style={props?.style} />;
}
export function FiltervalueDestinationcity(props: Ifiltervaluepayment) {
  return <Filtervalue label="Destination city" value={props.value} style={props?.style} />;
}
export function FiltervalueColor(props: Ifiltervaluepayment) {
  return <Filtervalue label="Color" value={props.value} style={props?.style} />;
}
export function FiltervalueStage(props: Ifiltervaluepayment) {
  return <Filtervalue label="Stage" value={props.value} style={props?.style} />;
}
export function FiltervalueSize(props: Ifiltervaluepayment) {
  return <Filtervalue label="Size" value={props.value} style={props?.style} />;
}

export function FiltervalueGrossTotal(props: Ifiltervaluepayment) {
  return <Filtervalue label="Gross Freight" value={props.value} style={props?.style} />;
}

export function FiltervalueTotalDockets(props: Ifiltervaluepayment) {
  return <Filtervalue label="Dockets" value={props.value} style={props?.style} />;
}

export function FiltervalueTotalPackages(props: Ifiltervaluepayment) {
  return <Filtervalue label="Packages" value={props.value} style={props?.style} />;
}

export function FiltervalueGoodsTotal(props: Ifiltervaluepayment) {
  return <Filtervalue label="Goods Value" value={props.value} style={props?.style} />;
}

export function FiltervaluePaidTypeCount(props: Ifiltervaluepayment) {
  return <Filtervalue label="Paid Docket" value={props.value} style={props?.style} />;
}

export function FiltervalueToPayTypeCount(props: Ifiltervaluepayment) {
  return <Filtervalue label="To Pay Docket" value={props.value} style={props?.style} />;
}

export function FiltervalueCreditCount(props: Ifiltervaluepayment) {
  return <Filtervalue label="Credit Docket" value={props.value} style={props?.style} />;
}

export function FiltervalueNonCashCount(props: Ifiltervaluepayment) {
  return <Filtervalue label="Non Cash Docket" value={props.value} style={props?.style} />;
}

export function FiltervalueFocCount(props: Ifiltervaluepayment) {
  return <Filtervalue label="Foc Docket" value={props.value} style={props?.style} />;
}

export function FiltervalueTotalComplaintsCount(props: Ifiltervaluepayment) {
  return <Filtervalue label="Complaints" value={props.value} style={props?.style} />;
}

export function FiltervalueWithLabelForBooleanValues(props: Ifiltervaluepayment) {
  return <Filtervalue label={props?.label} value={props.value} style={props?.style} />;
}

interface IPropsvalueany {
  value: any;
  label: any;
  onDelete?: any;
}

export function FiltervalueAny(props: IPropsvalueany) {
  return <Filtervalue label={props.label} value={props.value} />;
}

// import { FiltervalueCompany, FiltervalueContainer, FiltervalueDestinationBranch, FiltervalueEnddate, FiltervalueOriginBranch, FiltervaluePayment, FiltervalueStartdate, FiltervalueUser } from "../../../Components/filtercomponents/Filtervalue";

// export function Filtervalueall(props:any){
//     return(
//         <FiltervalueContainer >
//         {companyId &&companyId.value ?(<FiltervalueCompany value={' '+companyId.label}
//           />
//         ):(null)}

//         { user && user.value  ?(<FiltervalueUser value={' '+user.label}
//        /> ):(null)}

//         { startDate ?( <FiltervalueStartdate value={' '+startDate.toLocaleDateString()}  />):(null)}

//         { endDate ?(<FiltervalueEnddate value={' '+endDate.toLocaleDateString()}  />):(null)}

//         {fromBranch && fromBranch.length>0?(<FiltervalueOriginBranch value={fromBranch}  name="fromBranch"
//         />):(null)}
//         {toBranch && toBranch.length>0?(<FiltervalueDestinationBranch value={toBranch} name="toBranch"
//         />):(null)}
//       {paymentMode  && paymentMode.length>0?(<FiltervaluePayment value={paymentMode}  name="paymentMode"
//         />):(null)}
//        {fleet &&fleet.value ?(<FiltervalueFleet value={fleet.label}
//           />
//         ):(null)}

//         </FiltervalueContainer >
//     )
// }
