import { createTheme, adaptV4Theme } from "@mui/material/styles";

export const theme = createTheme(adaptV4Theme({
  typography: {
    // useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
      contrastText: "white",
    },
    // hasRemark: {
    //     main: 'yellow',
    //     contrastText: 'white',
    // },
  },
}));
