import React, { Fragment } from "react";
import { AppBar, Drawer, IconButton, useMediaQuery, useTheme } from "@mui/material";
// import { Grid, withStyles } from "@mui/material";
import ToolTip from "../../Components/ToolTip/ToolTip";
import { Dialog, DialogTitle } from "../Dialog/DialogComponent";
import { ButtonPrimary } from "../Button/Buttoncontainer";
import Grid from "../Grid/Grid";
import { Button } from "../../newSrc/components/UI";
import { FilterIcon } from "../../newSrc/constants/icons";

const styles: any = {
  actionBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "white",
    padding: "8px",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  style: {
    position: "absolute",
    top: "30%",
    left: "30%",
    transform: "translate(20%, 20%)",
    width: 800,
    height: 500,
    border: "2px solid #000",
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    pt: 2,
    px: 4,
    pb: 3,
  },
};

interface Iprops {
  ismodelOpen?: any;
  showModal?: any;
  closeModal?: any;
  children?: any;
  // classes?: any;
  paddingBottom?: any;
  paddingLeft?: any;
  paddingRight?: any;
}
function Filtercontainer(props: Iprops) {
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2

  return <>
    {!mobileView ? (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ToolTip title={<div style={{ fontSize: "14px" }}>Filter</div>}>
            <ButtonPrimary
              onClick={props.showModal}
              style={{ height: 38, margin: "10px" }}
            >
              {" "}
              <i
                className="fas fa-filter filter"
                style={{ color: "white" }}
              ></i>
            </ButtonPrimary>
          </ToolTip>
        </div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={props.ismodelOpen}
          onClose={props.closeModal}
        >
          <DialogTitle>
            Filter
            <IconButton
              aria-label="close"
              onClick={props.closeModal}
              style={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              size="large">
              <i className="fas fa-times"></i>
            </IconButton>
          </DialogTitle>
          <Grid
            container
            lg={12}
            sm={12}
            xs={12}
            md={12}
            alignItems="center"
            style={{
              paddingBottom: props.paddingBottom
                ? props.paddingBottom
                : "100px",
              marginTop: "10px",
              paddingLeft: props.paddingLeft ? props.paddingLeft : "10px",
              paddingRight: props.paddingRight ? props.paddingRight : "10px",
            }}
          >
            {props.children}
          </Grid>
          <br />
          <br />
        </Dialog>
      </Fragment>
    ) : (
      <Fragment>
        <div>
          <AppBar position="fixed" style={styles.actionBar}>
            <div className="appBar">
              <div className="">
                <Button variant="primary" action="primary" Icon={<FilterIcon />} onClick={props.showModal}>
                  {/* <i className="fas fa-filter filter text-primary-400"></i> */}
                </Button>
              </div>
              {/* <div className="filter-btn">
                <ButtonSimple
                  disabled={true}
                  className="width100"
                  // onClick={this.openLoadModal}
                >
                  Action
                </ButtonSimple>
              </div> */}
            </div>
          </AppBar>
        </div>
        <Drawer
          anchor="top"
          open={props.ismodelOpen}
          onClose={props.closeModal}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Filter</h3>
          </div>
          <Grid
            container
            lg={12}
            sm={12}
            xs={12}
            md={12}
            alignItems="center"
            style={{ padding: "8px" }}
          >
            {props.children}
          </Grid>
        </Drawer>
      </Fragment>
    )}
  </>;
}

export default Filtercontainer;
