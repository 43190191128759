import { Dispatch, SetStateAction } from "react";
import {
  StateObjType,
  StateType,
} from "../pages/Landing/partner-forms/useCustomState";

export function customerDocketValidate(str: string) {
  const customerDocketRegex = /^[A-Z]{2,4}-[A-Z]{1,3}-[0-9]{1,5}$/;
  return customerDocketRegex.test(str);
}
export function branchDocketValidate(str: string) {
  const branchDocketRegex = /^[A-Z]{2,5}[1-9]{1}[0-9]{9}$/;
  return branchDocketRegex.test(str);
}
export function branchTrackingIdValidate(str: string) {
  const trakingIdRegex = /^[A-Z]{3,5}-[0-9]{3,5}-[0-9]{0,10}$/;
  return trakingIdRegex.test(str);
}

export function emailValidation(email: string): boolean {
  // Regular expression for basic email validation
  const emailRegex: RegExp =
    /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i;
  return emailRegex.test(email);
}

export function passwordValidation(password: string): boolean {
  // Check if password length is at least 6 characters
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'*+,-./:;<=>?@[\]^_`{|}~]).{6,16}$/.test(password);
}

export function contactNumberValidation(phoneNumber: string): boolean {
  // Regular expression for Indian phone numbers
  const phoneRegex: RegExp = /^[6-9]\d{9}$/;
  return phoneRegex.test(phoneNumber);
}

// does: validate first name
export function validateFirstName(
  firstName: StateType,
  setFirstName: Dispatch<SetStateAction<StateType>>
) {
  if (typeof firstName !== "object") return false;
  if (!firstName.value) {
    setFirstName((prevFirstName) => {
      const prev = prevFirstName as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  } else if (!/^[A-Za-z\s]+$/.test(firstName.value)) {
    setFirstName((prevFirstName) => {
      const prev = prevFirstName as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "First name can only contain letters!",
      };
    });
    return false;
  }
  return true;
}

// does: validate last name
export function validateLastName(
  lastName: StateType,
  setLastName: Dispatch<SetStateAction<StateType>>
) {
  if (typeof lastName !== "object") return false;
  if (!lastName.value) {
    setLastName((prevLastName) => {
      const prev = prevLastName as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  } else if (!/^[A-Za-z\s]+$/.test(lastName.value)) {
    setLastName((prevLastName) => {
      const prev = prevLastName as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Last name shouldn't contain digits!",
      };
    });
    return false;
  }
  return true;
}
// does: validate first name
export function validateFullName(
  fullName: StateType,
  setFullName: Dispatch<SetStateAction<StateType>>
) {
  if (typeof fullName !== "object") return false;
  if (!fullName.value) {
    setFullName((prevFullName) => {
      const prev = prevFullName as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  } else if (!/^[A-Za-z\s]{3,}$/.test(fullName.value)) {
    setFullName((prevFullName) => {
      const prev = prevFullName as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Name must contain three letters!",
      };
    });
    return false;
  } else if (!/^[A-Za-z\s]+$/.test(fullName.value)) {
    setFullName((prevFullName) => {
      const prev = prevFullName as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Name can only contain letters!",
      };
    });
    return false;
  }
  return true;
}
// does: validate email
export function validateEmail(
  email: StateType,
  setEmail: Dispatch<SetStateAction<StateType>>
) {
  if (typeof email !== "object") return false;
  if (!emailValidation(email.value)) {
    setEmail((prevEmail) => {
      const prev = prevEmail as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Invalid email address!",
      };
    });
    return false;
  }
  return true;
}
// does: validate password
export function validatePassword(
  password: StateType,
  setPassword: Dispatch<SetStateAction<StateType>>
) {
  if (typeof password !== "object") return false;
  if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&'*+,-./:;<=>?@[\]^_`{|}~]).{6,16}$/.test(password.value) === false) {
    setPassword((prevPassword) => {
      const prev = prevPassword as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "6-16 character, minimum 1 uppercase, lowercase, digit & special character",
      };
    });
    return false;
  }
  else if (password.value.length > 16) {
    setPassword((prevPassword) => {
      const prev = prevPassword as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Password length shouldn't exceed 16 letters!",
      };
    });
    return false;
  } else if (password.value.length < 6) {
    setPassword((prevPassword) => {
      const prev = prevPassword as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Password length must be more than 6 character!",
      };
    });
    return false;
  }
  return true;
}
// does: validate contact number
export function validateContactNumber(
  contactNumber: StateType,
  setContactNumber: Dispatch<SetStateAction<StateType>>
) {
  if (typeof contactNumber !== "object") return false;
  if (!contactNumberValidation(contactNumber.value)) {
    setContactNumber((prevContactNumber) => {
      const prev = prevContactNumber as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Invalid contact number!",
      };
    });
    return false;
  } else {
    setContactNumber((prevContactNumber) => {
      const prev = prevContactNumber as StateObjType;
      return {
        ...prev,
        error: false,
      };
    });
  }
  return true;
}
export function validateVehicleNumber(
  vehicleNumber: StateType,
  setVehicleNumber: Dispatch<SetStateAction<StateType>>
) {
  if(typeof vehicleNumber !== "object") return false;
  if(vehicleNumber.value === "" || vehicleNumber.value === undefined || vehicleNumber.value === null) {
    setVehicleNumber((prevVehicleNumber) => {
      const prev = prevVehicleNumber as StateObjType;
      return { ...prev, error: true, errorMessage: "Vehicle Number is required(Ex:- GJ01XX1234), All uppercase!" };
    })
    return false;
  }
  if(/^[A-Z]{2}\d{2}[A-Z]{2}\d{4}$/.test(vehicleNumber.value) === false) {
    setVehicleNumber((prevVehicleNumber) => {
      const prev = prevVehicleNumber as StateObjType;
      return {
       ...prev,
        error: true,
        errorMessage: "Invalid Vehicle Number(Ex:- GJ01XX1234), All uppercase!",
      };
    });
    return false;
  }
}
// does: validate address
export function validateAddress1(
  addressLine1: StateType,
  setAddressLine1: Dispatch<SetStateAction<StateType>>
) {
  if (typeof addressLine1 !== "object") return false;
  if (!addressLine1.value) {
    setAddressLine1((prevAddressLine1) => {
      const prev = prevAddressLine1 as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  } else if (!/^[a-zA-Z3-9\s,'-]*.{3,}$/i.test(addressLine1.value)) {
    setAddressLine1((prevAddressLine1) => {
      const prev = prevAddressLine1 as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Minimum length of address must be 3!",
      };
    });
    return false;
  }
  return true;
}
// does: validate address
export function validateAddress2(
  addressLine2: StateType,
  setAddressLine2: Dispatch<SetStateAction<StateType>>
) {
  if (typeof addressLine2 !== "object") return;
  if (!addressLine2.value) {
    setAddressLine2((prevAddressLine2) => {
      const prev = prevAddressLine2 as StateObjType;

      return { ...prev, error: true };
    });
    return false;
  } else if (!/^[a-zA-Z3-9\s,'-]*.{3,}$/i.test(addressLine2.value)) {
    setAddressLine2((prevAddressLine2) => {
      const prev = prevAddressLine2 as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Minimum length of address must be 3!",
      };
    });
    return false;
  }
  return true;
}
// does: validate pincode
export function validatePincode(
  pincode: StateType,
  setPincode: Dispatch<SetStateAction<StateType>>
) {
  if (typeof pincode !== "object") return;
  if (!pincode.value) {
    setPincode((prevPincode) => {
      const prev = prevPincode as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  } else if (!/^[0-9].{5,5}$/i.test(pincode.value)) {
    setPincode((prevPincode) => {
      const prev = prevPincode as StateObjType;
      return {
        ...prev,
        error: true,
        errorMessage: "Pincode must be of 6 number!",
      };
    });
    return false;
  }
  return true;
}
// does: validate city
export function validateCity(
  city: StateType,
  setCity: Dispatch<SetStateAction<StateType>>
) {
  if (typeof city !== "object") return;
  if (!city.value) {
    setCity((prevCity) => {
      const prev = prevCity as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  }
  return true;
}
// does: validate state
export function validateState(
  state: StateType,
  setState: Dispatch<SetStateAction<StateType>>
) {
  if (typeof state !== "object") return;
  if (!state.value) {
    setState((prevState) => {
      const prev = prevState as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  }
  return true;
}
// does: validate city
export function validateBranchName(
  branchName: StateType,
  setBranchName: Dispatch<SetStateAction<StateType>>
) {
  if (typeof branchName !== "object") return;
  if (!branchName.value) {
    setBranchName((prevBranchName) => {
      const prev = prevBranchName as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  }
  return true;
}
// does: validate state
export function validateShortName(
  shortName: StateType,
  setShortName: Dispatch<SetStateAction<StateType>>
) {
  if (typeof shortName !== "object") return;
  if (!shortName.value) {
    setShortName((prevShortName) => {
      const prev = prevShortName as StateObjType;
      return { ...prev, error: true };
    });
    return false;
  }
  return true;
}
// does: validate company name
export function validateCompanyName(
  companyName: StateType,
  setCompanyName: Dispatch<SetStateAction<StateType>>
) {
  if (typeof companyName !== "object") return false;
  if (!/^[A-Za-z ]+$/i.test(companyName.value)) {
    setCompanyName((prevCompanyName) => {
      const prev = prevCompanyName as StateObjType;
      return {
        ...prev,
        error: true,
      };
    });
    return false;
  }
  return true;
}
// does: validate company type
export function validateCompanyType(
  companyType: StateType,
  setCompanyType: Dispatch<SetStateAction<StateType>>
) {
  if (typeof companyType !== "object") return false;
  if (!companyType.value) {
    setCompanyType((prevCompanyType) => {
      const prev = prevCompanyType as StateObjType;
      return {
        ...prev,
        error: true,
      };
    });
    return false;
  }
  return true;
}
// does: validate company email
export function validateCompanyEmail(
  companyEmail: StateType,
  setCompanyEmail: Dispatch<SetStateAction<StateType>>
) {
  if (typeof companyEmail !== "object") return false;
  if (
    !/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(
      companyEmail.value
    )
  ) {
    setCompanyEmail((prevCompanyEmail) => {
      const prev = prevCompanyEmail as StateObjType;
      return {
        ...prev,
        error: true,
      };
    });
    return false;
  }
  return true;
}
