import React from "react";
import ReactSelect, { Props as SelectProps } from "react-select";
import makeAnimated from "react-select/animated";

// Define the props type using generics with a default value for OptionType
type TSelectProps = SelectProps & {
  // below are custom select props that can also be added
  errorMessage?: string;
  label?: string;
};

/**
 * A custom select input tag that act as a wrapper of react-select.
 * 
 * You can use all the props provided by react-select to customize the select tag according to
 * 
 * @prop {string} [errorMessage] - Displays an error message below the select input if present.
 * @prop {string} [label] - Displays a label above the select input if provided.
 *
 * @example
 * ```typescript
 * import React from 'react';
 * import Select from "component-path";
 * 
 * const MyComponent: React.FC = () => {
 *
 *   return (
*       <Select
            placeholder="placeholder for select"
            options={selectOptions}
            value={selectValue}
            onChange={onSelectChange}
          />
 *   );
 * };
 * ```
 */
export default function Select({
  isMulti,
  components,
  errorMessage,
  label,
  ...props
}: TSelectProps) {
  // if we are getting the comopnents from the above then we'll accept that otherwise we'll check if there is an isMulti true and no components are provided then we'll return makeAnimated() so that it will animated and at last if there is no component or isMutli true then we'll make components to undefined
  const animatedComponents =
    components ?? (isMulti ? makeAnimated() : undefined);

  const content = (
    <ReactSelect isMulti={isMulti} components={animatedComponents} {...props} />
  );

  // if there is a label or an error message to show then we want to wrap in parent with some styles otherwise return the select
  if (label || errorMessage) {
    return (
      <div className="flex flex-col gap-4">
        {label && <label className="fw-500 m-0">{label}</label>}
        {content}
        {errorMessage && (
          <span className="text-red-500 text-xs">{errorMessage}</span>
        )}
      </div>
    );
  }

  // component return
  return content;
}
