import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { hideLoader, showLoader } from "../actions/UserActions";
import { isArrayCheck } from "../containers/generics/CheckArray";
import { getAutoCompleteGeneralUsage, getAutoCompleteOptions, getPlaceData } from "../services/autoComplete";
import { RootState } from "../store";
import { showHttpError } from "../utils/message";
import { useAppSelector } from "../hooks/useAppSelector";

// interface IProps{
//   onChange?:any;
//   isDisabled?:any;
//   addressSuggestion?:any;
//   placeHolder?:any;
//   value?:any;
//   className?:any;
//   setLatitudeLongitude?:any;
//   destCity?:any;
//   where? : any;
//   general : any;
// }

const AutoComplete = (props:any) => {
  const [value, setValue] = useState<any>("");
  const [options, setOptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    intracity
  } = useAppSelector(state => {
    return {
      intracity: state.customerBooking?.intraCity
    }
  })

  useEffect(() => {
    if(props.addressSuggestion && props.addressSuggestion.length > 0){
      const key = '_id';
      const arrayUniqueByKey = [...new Map(props.addressSuggestion.map((item:any) =>
      [item[key], item])).values()];
      let list = arrayUniqueByKey.map((x:any , index : any) => {
        if(props?.where === "Customer"){
          if(index === 0){
            return {
              label: `${x?.ans}`,
              value: x,
              isSuggetion: true
            }
          }else{
            return {
              label: `${x?.places?.placeValue} ${x?.places?.area?.name}, ${x?.city?.name} , ${x?.pincode}`,
              value: x,
              isSuggetion: true
            }
          }
        }
        else{
          return {
            label: `${x?.officeName} ${x?.floor} ${x?.tower}, ${x?.nearby_landmark || ''}, ${x?.city?.name} , ${x?.pincode}`,
            value: x,
            isSuggetion: true
          }

        }
      })

      setOptions(list)
    }
    else if(props.addressSuggestion){
      let list = [{
          label: `${props.addressSuggestion?.place?.name} ${props.addressSuggestion?.place?.area?.name}, ${props.addressSuggestion?.city?.name}, ${props.addressSuggestion?.pincode}`,
          value: props.addressSuggestion,
          isSuggetion: true
        }]
      setOptions(list)
    }
  }, [props.addressSuggestion])

  const fetchList:any = async (search?: any) => {
    try {
      if(!search){
        return
      }
      let payload: any = {
        text: search,
      };
      if(props?.general){
        var res = await getAutoCompleteOptions(payload);
      }else{
        var res = await getAutoCompleteGeneralUsage(payload);
      }
      setIsLoading(false)
      return {
        options: isArrayCheck(res)
          ? res.map((i: any) => ({
              label: i.description,
              value: i.place_id,
            }))
          : [],
      };
    } catch (err : any) {
      showHttpError(err);
      return {
        options: [],
      };
    }
  };

  const multipleExist = (arr: any, values: any) => {
    return values.every((value: any) => {
      return arr.includes(value);
    });
  };

  const fetchPlaceData = async() => {
    try {
      if(!value || !value.value){
        return
      }
        let payload: any = {
          input: value.value,
        };
        let res = await getPlaceData(payload);
        // console.log(res)
        props.onChange(res)
        let pincode = res?.address_components.find((x:any) => {
          return multipleExist(x.types, [
            'postal_code'
          ]);
        })
        if(pincode === undefined){
          setValue("")
        }
        const information ={
          latitude: res.geometry.location.lat,
          longitude: res.geometry.location.lng
        }
        if(props.setLatitudeLongitude)  {
          props.setLatitudeLongitude(information)
        }   
        
        // let city = res.address_components.find((x: any) => {
        //   return (
        //     x.types.length === 2 &&
        //     multipleExist(x.types, ["locality", "political"])
        //   );
        // });
        
        // console.log("resrescity",city.short_name,props.destCity)
        // if(city.short_name != props.destCity) {setValue("");  showHttpError("City is not same"); }

      } catch (err : any) {
        showHttpError(err);
        return {
          options: [],
        };
      }
  }

  // const customStyles = {
  //   control: (provided:any, state:any) => ({
  //       ...provided,
  //       borderRadius: '5px',
  //       border: '2px solid #ccc',
  //       boxShadow: state.isFocused ? '0 0 0 2px #3699FF' : null,
  //   }),
  //   option: (provided:any, state:any) => ({
  //       ...provided,
  //       backgroundColor: state.isFocused ? '#3699FF' : null,
  //       color: state.isFocused ? 'white' : null,
  //   }),
  // }

  useEffect(() => {
    // if(!intracity?.isBookedWithoutToken){
      !value?.avoidFetchPlaceData && fetchPlaceData()
    // }
  }, [value.value,intracity?.isBookedWithoutToken])

  useEffect(() => {
    if(props.value){
      setValue({value: props.value, label: props.value, avoidFetchPlaceData: true})
    }
  }, [props.value])

  return (
    <div>
      {
             options && options.length > 0 &&
             <AsyncPaginate
             debounceTimeout={1000}
             className={props.className}
             options={options}
             isDisabled={props.isDisabled}
               loadOptions={fetchList}
               placeholder={props.placeHolder ? props.placeHolder : "Search Your Location"}
               value={value}
               onChange={(e: any) => {
                 if(e.isSuggetion){
                   props.onChange({...e.value, isSuggetion: true})
                   setValue(e)
                 }
                 else{
                   setValue(e)
                 }
               }}
               isLoading={isLoading}
               additional={{
                 page: 1,
               }}
              //  styles={customStyles}
             />
      }
    {
             !options || options.length === 0 &&
             <AsyncPaginate
             debounceTimeout={1000}
             isDisabled={props.isDisabled}
             className={props.className}
               loadOptions={fetchList}
               placeholder={props.placeHolder ? props.placeHolder : "Search Your Location"}
               value={value}
               onChange={(e: any) => {
                if(e.isSuggetion && value!==""){
                  setValue(e)
                  props.onChange({...e.value, isSuggetion: false})
                }else{
                  setValue(e)
                }
               }}
               isLoading={isLoading}
               additional={{
                 page: 1,
               }}
              //  styles={customStyles}
             />
      }
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoComplete);
