import React, { useState } from 'react';
import { Formik, Form, Field, useFormikContext } from 'formik';
import AutoComplete from "../../../Components/AutoComplete";
import { setPartLoadPickupDropLocation, setFullLoadVehicleDetails, setOutSideFormFilled } from './../../../actions/customerBooking';
import { connect } from "react-redux";
import { Dialog, DialogActions, DialogContent } from "../../../Components/Dialog/DialogComponent";
import { Link } from "react-router-dom";
import { ButtonDefault, ButtonPrimary } from "../../../Components/Button/Buttoncontainer";
import { getOtp } from "../../../services/area";
import { showHttpError } from "../../../utils/message";
import { customerLogin } from './../../../actions/customerLogin';
import { showLoader, hideLoader } from './../../../actions/UserActions';
import { setPartLoadPackages } from './../../../actions/customerBooking';
import { Select12 } from "../../../Components/Selectcomponents/Select";
import { useEffect } from 'react';
import { getMaterialList } from "../../../services/intraCityRate";
import { listAssetSubType } from "../../../services/assetType";
import CustomToolTip from "../../../Components/CustomToolTip/CustomToolTip";

// Assuming AutoComplete is a custom component that works with Formik
const AutoCompleteSourceField = ({ field, form, placeholder, ...props }: any) => (
    <AutoComplete
        value={field.value?.formatted_address}
        onChange={(e: any) => {
            console.log(e, "eeee");
            console.log("020230", e);
            form.setFieldValue(field.name, e);
            props?.setLocationByTypingRes(e, 0);
        }}
        placeHolder={placeholder}
        where={"Customer"}
        general={false}
    >
        {/* {console.log(`props AutoCompleteField`, props, field)} */}
        <input
            type='text'
            className="form-control form_one"
            value={props?.values?.pickDropLocation[0]?.place?.formatted_address}
        />
    </AutoComplete>
);

const AutoCompleteDestField = ({ field, form, placeholder, ...props }: any) => (
    <AutoComplete
        value={field.value?.formatted_address}
        onChange={(e: any) => {
            console.log(e, "eeee");
            console.log("020230", e);
            form.setFieldValue(field.name, e);
            props?.setLocationByTypingRes(e, 1);
        }}
        placeHolder={placeholder}
        where={"Customer"}
        general={false}
    >
        {/* {console.log(`props AutoCompleteField`, props)} */}
        <input
            type='text'
            className="form-control form_one"
            {...field}
            value={props?.values?.pickDropLocation[0]?.place?.formatted_address}
        />
    </AutoComplete>
);

const MyPartLoadForm = (props: any) => {
    console.log(`MyPartLoadForm props`, props)
    const [tabValue, setTabValue] = useState(0);
    const [assetTypeList, setAssetTypeList] = useState([])
    const [contactNo, setContactNo] = useState(0);
    const [allAssetTypeList, setAllAssetTypeList] = useState([])
    const [materialTypeList, setMaterialTypeList] = useState([]);
    const [assetSubTypeList, setAssetSubTypeList] = useState([])
    const [vehicleModelTypeList, setVehicleModelTypeList] = useState([])
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [customerPassword, setCustomerPassword] = useState();
    const [customerMobileNo, setCustomerMobileNo] = useState();

    const initialValues = {
        sourceLocation: '',
        destLocation: '',
        date: '',
        qty: '',
        materialOption: '',
        kg: '',
        assetTypeOption: '',
        vehicleNameOption: '',
        VehicleModelOption: '',
        vehicleNos: ''
    };

    const handleLogin = async () => {
        props?.showLoader();
        try {
            await props.customerLogin(customerMobileNo, customerPassword, false, localStorage.getItem("deviceId"), localStorage.getItem("latitude"), localStorage.getItem("longitude"))
            await props?.setOutSideFormFilled(props?.from);
            props?.hideLoader();
        } catch (error) {
            props?.hideLoader();
            alert("Contact No / Password is Incorrect !")
        }
    }

    const handelgetOtp = async () => {
        props?.showLoader();
        try {
            await getOtp(contactNo);
            props?.hideLoader();
        } catch (error) {
            props?.hideLoader();
            showHttpError(error);
        }
    };

    const intracityMaterialTypeList = async () => {
        props?.showLoader();
        try {
            let payload: any = { type: "IntraCityMaterial" }
            let response = await getMaterialList("", payload)
            setMaterialTypeList(response?.map((x: any) => {
                return { value: x._id, label: x.name }
            }))
            console.log("response response", response)
            props?.hideLoader();
        } catch (error) {
            showHttpError(error);
            props?.hideLoader();
        }
    }

    const defineAssetType = async () => {
        props?.showLoader();
        try {
            let response: any = await listAssetSubType();
            setAllAssetTypeList(response);
            let ans: any = [];
            response && response?.length > 0 && response?.map((x: any) => { ans?.push({ label: x?.assetType?.assetName, value: x?.assetType?._id }) });
            setAssetTypeList(ans);
            console.log(`listassetType response`, response);
            props?.hideLoader();
        } catch (error) {
            props?.hideLoader();
            showHttpError(error);
        }
    }

    const findVehicleName = () => {
        const ans: any = [];
        allAssetTypeList?.map((v: any) => {
            if (v?.assetType?._id === props?.customerBooking?.partLoad?.vehicleDetails?.assetType?.value) {
                ans?.push(v);
            }
        })
        console.log(`assetType zsddkcvjsdcksmdcsdcmdc`, ans);
        let finalAns = ans?.length > 0 && ans?.map((x: any) => { return { label: x?.assetName, value: x?._id } })
        setAssetSubTypeList(finalAns)
    }

    const findVehicleModel = () => {
        let ans: any;
        allAssetTypeList?.map((v: any) => {
            if (v?.assetType?._id === props?.customerBooking?.partLoad?.vehicleDetails?.assetType?.value) {
                ans = v?.bodyTypes
            }
        })
        console.log(`assetType zsddkcvjsdcksmdcsdcmdc`, ans);
        let finalAns = ans?.length > 0 && ans?.map((x: any, index: any) => { console.log(`indexsdekrvzsdvnjh`, x, `zsdv`, index); return { label: x, value: x } })
        console.log(`assetType zsddkcvjsdcksmdcsdcmdc zsdfvzxcvmnv`, ans, `finalAns`, finalAns);
        setVehicleModelTypeList(finalAns)
    }

    const validate = (values: any) => {
        const errors: any = {};

        if (values.kg) {
            props?.setPartLoadPackages("weight", values.kg, 0)
        }

        if (values.qty) {
            props?.setPartLoadPackages("qty", values.qty, 0)
        }

        if (props?.from === "FullLoad") {
            if (values.vehicleNos) {
                props?.setFullLoadVehicleDetails({ "truckNos": values.vehicleNos });
            }
        }

        console.log(`values asm`, values);

        if (!values.sourceLocation) {
            errors.sourceLocation = 'Source Location is required';
        }

        if (!values.destLocation) {
            errors.destLocation = 'Destination Location is required';
        }

        if (!values.date) {
            errors.date = 'Date is required';
        }

        if (!values.materialOption || values.materialOption === '') {
            errors.materialOption = 'Any one Option is required';
        }

        if (!values.qty) {
            errors.qty = 'Quantity is required';
        }

        if (!values.kg) {
            errors.kg = 'Weight in ( KG ) is required';
        }

        if (props?.from === "FullLoad") {
            if (!values.vehicleNos) {
                errors.vehicleNos = 'No Of Vehicles is required';
            }
            if (!values.assetTypeOption || values.assetTypeOption === '') {
                errors.assetTypeOption = 'Any one Option is required';
            }
            if (!values.vehicleNameOption || values.vehicleNameOption === '') {
                errors.vehicleNameOption = 'Any one Option is required';
            }
            if (!values.VehicleModelOption || values.VehicleModelOption === '') {
                errors.VehicleModelOption = 'Any one Option is required';
            }
        }

        return errors;
    };

    const handleSubmit = (values: any, { setSubmitting }: any) => {
        // Handle form submission logic here
        setIsDialogOpen(true);
        console.log('Form values:', values);

        // After handling submission, you might want to reset the form or perform other actions
        setSubmitting(false);
    };

    const multipleExist = (arr: any, values: any) => {
        return values.every((value: any) => {
            return arr.includes(value);
        });
    };

    const setLocationByTypingRes = (e: any, index: any) => {
        let area = e.address_components.find((x: any) => {
            return multipleExist(x.types, [
                "sublocality_level_1",
                "sublocality",
                "political",
            ]);
        });

        let pincode = e.address_components.find((x: any) => {
            return multipleExist(x.types, [
                'postal_code'
            ]);
        })

        // if(pincode === undefined){
        //   resetSelectedFormLocation();
        //   return showHttpError("Kindly Select Location More Specific !")
        // }

        let city = e.address_components.find((x: any) => {
            return x.types.length === 2 && multipleExist(x.types, ["locality", "political"]);
        })

        // if (index > 0 && getData?.pickDropLocation[0]?.contactPerson?.city !== "") {
        //   if (getData?.pickDropLocation[0]?.contactPerson?.city !== city?.long_name) {
        //     return showHttpError("Location Should be in same City as per Lcoation No 1 !")
        //   }
        // }

        let state = e.address_components.find((x: any) => {
            return multipleExist(x.types, ["administrative_area_level_1", "political"]);
        })

        props.setPartLoadPickupDropLocation('place.placeId', e.place_id, index)
        props.setPartLoadPickupDropLocation('place.coordinate.latitude', e.geometry.location.lat, index)
        props.setPartLoadPickupDropLocation('place.coordinate.longitude', e.geometry.location.lng, index)
        props.setPartLoadPickupDropLocation('place.placeValue', e.name, index)
        props.setPartLoadPickupDropLocation('place.formatted_address', e.formatted_address, index)
        props.setPartLoadPickupDropLocation('place.name', e.name, index)
        props.setPartLoadPickupDropLocation('place.area', area.long_name, index)
        props.setPartLoadPickupDropLocation('place.pincode', pincode.long_name, index)
        props.setPartLoadPickupDropLocation('place.city', city.long_name, index)
        props.setPartLoadPickupDropLocation('place.state', state.long_name, index)
        // props.setPartLoadPickupDropLocation('contactPerson.state', state.long_name, index)
        // props.setPartLoadPickupDropLocation('contactPerson.pincode', pincode.long_name, index)
        // props.setPartLoadPickupDropLocation('contactPerson.city', city.long_name, index)
        // props.setPartLoadPickupDropLocation('contactPerson.isDisabled', state.long_name && city.long_name && pincode.long_name ? true : false, index)
        // props.setPartLoadPickupDropLocation('contactPerson.floor', "", index)
        // props.setPartLoadPickupDropLocation('contactPerson.officeName', "", index)
        // props.setPartLoadPickupDropLocation('contactPerson.nearby_landmark', "", index)
    }

    const SelectField = ({ field, form, placeholder, ...props }: any) => (
        <Select12
            value={props?.values?.packages[0]?.materialType}
            placeholder={placeholder}
            onChange={(e: any) => {
                form.setFieldValue(field.name, e);
                props.setPartLoadPackages("materialType", e, 0)
            }}
            options={materialTypeList}
        />
    );

    const SelectAssetTypeField = ({ field, form, placeholder, ...props }: any) => (
        <Select12
            value={props?.values?.vehicleDetails?.assetType}
            placeholder={placeholder}
            onChange={(e: any) => {
                form.setFieldValue(field.name, e);
                props.setFullLoadVehicleDetails({ "assetType": e })
            }}
            options={assetTypeList}
        />
    );

    const SelectVehicleNameField = ({ field, form, placeholder, ...props }: any) => (
        <Select12
            value={props?.values?.vehicleDetails?.assetSubType}
            placeholder={placeholder}
            onChange={(e: any) => {
                form.setFieldValue(field.name, e);
                props.setFullLoadVehicleDetails({ "assetSubType": e })
            }}
            isDisabled={props?.values?.vehicleDetails?.assetType ? false : true}
            options={assetSubTypeList}
        />
    );

    const SelectVehicleModelField = ({ field, form, placeholder, ...props }: any) => (
        <Select12
            value={props?.values?.vehicleDetails?.vehicleModel}
            placeholder={placeholder}
            onChange={(e: any) => {
                form.setFieldValue(field.name, e);
                props.setFullLoadVehicleDetails({ "vehicleModel": e })
            }}
            isDisabled={(props?.values?.vehicleDetails?.assetType && props?.values?.vehicleDetails?.assetSubType) ? false : true}
            options={vehicleModelTypeList}
        />
    );

    const CustomErrorMessage = ({ name, className }: any) => {
        const { errors, touched }: any = useFormikContext();
        return (
            <div className={className}>
                {touched[name] && errors[name] && (
                    <CustomToolTip title={errors && errors[name] && errors[name]} ><i className='fa fa-info largeScal' style={{ fontSize: "large" }} /></CustomToolTip>
                )}
            </div>
        );
    };

    useEffect(() => {
        defineAssetType();
        intracityMaterialTypeList();
    }, [])

    useEffect(() => {
        { console.log(`assetType`, props?.customerBooking?.partLoad?.vehicleDetails?.assetType, `assetTypeList`, assetTypeList) }
        findVehicleName();
    }, [props?.customerBooking?.partLoad?.vehicleDetails?.assetType])

    useEffect(() => {
        { console.log(`assetType`, props?.customerBooking?.partLoad?.vehicleDetails?.assetType, `assetTypeList`, assetTypeList) }
        findVehicleModel();
    }, [props?.customerBooking?.partLoad?.vehicleDetails?.assetSubType])

    return (
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validate}>
                <Form>
                    <div style={{ width: "100%", display: "flex" }}>
                        <div style={{ width: "50%", marginTop: "2.5%" }}>

                            <div style={{ width: "100%", margin: "auto", marginTop: "1.5%", display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "50%", marginLeft: "0.5%" }}>
                                    <Field
                                        type="date"
                                        id="date"
                                        name="date"
                                        className={"form-control"}
                                    />
                                </div>
                                <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                    {/* <ErrorMessage name="date" component="div" className="error" />  */}
                                    <CustomErrorMessage name="date" className="error" />
                                </div>
                            </div>

                            <div style={{ width: "100%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "50%", marginLeft: "0.5%" }}>
                                    <Field
                                        type="text"
                                        id="sourceLocation"
                                        name="sourceLocation"
                                        component={AutoCompleteSourceField}
                                        placeholder="Source Location"
                                        values={props?.customerBooking?.partLoad}
                                        setLocationByTypingRes={setLocationByTypingRes}
                                    />
                                </div>
                                <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                    <CustomErrorMessage name="sourceLocation" className="error" />
                                </div>
                            </div>

                            <div style={{ width: "100%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "50%", marginLeft: "0.5%" }}>
                                    <Field
                                        type="text"
                                        id="destLocation"
                                        name="destLocation"
                                        component={AutoCompleteDestField}
                                        placeholder="Destination Location"
                                        values={props?.customerBooking?.partLoad}
                                        setLocationByTypingRes={setLocationByTypingRes}
                                    />
                                </div>
                                <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                    {/* <ErrorMessage name="destLocation" component="div" className="error" />  */}
                                    <CustomErrorMessage name="destLocation" className="error" />
                                </div>
                            </div>

                            {props?.from === "FullLoad" &&
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", width: "80%", margin: 'auto' }}>
                                        <div style={{ width: "50%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                            <div style={{ width: "100%", marginLeft: "0.5%" }}>
                                                <Field
                                                    type="text"
                                                    id="assetTypeOption"
                                                    name="assetTypeOption"
                                                    component={SelectAssetTypeField}
                                                    placeholder="Select Vehicle Type"
                                                    setFullLoadVehicleDetails={props?.setFullLoadVehicleDetails}
                                                    values={props?.customerBooking?.partLoad}
                                                />
                                            </div>
                                            <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                                {/* <ErrorMessage name="assetTypeOption" component="div" className="error" />  */}
                                                <CustomErrorMessage name="assetTypeOption" className="error" />
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                            <div style={{ width: "100%", marginLeft: "0.5%" }}>
                                                <Field
                                                    type="text"
                                                    id="vehicleNameOption"
                                                    name="vehicleNameOption"
                                                    component={SelectVehicleNameField}
                                                    placeholder="Select Vehicle Name"
                                                    setFullLoadVehicleDetails={props?.setFullLoadVehicleDetails}
                                                    values={props?.customerBooking?.partLoad}
                                                />
                                            </div>
                                            <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                                {/* <ErrorMessage name="vehicleNameOption" component="div" className="error" />  */}
                                                <CustomErrorMessage name="vehicleNameOption" className="error" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", width: "80%", margin: 'auto' }}>
                                        <div style={{ width: "50%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                            <div style={{ width: "100%", marginLeft: "0.5%" }}>
                                                <Field
                                                    type="text"
                                                    id="VehicleModelOption"
                                                    name="VehicleModelOption"
                                                    component={SelectVehicleModelField}
                                                    placeholder="Select Vehicle Model"
                                                    setFullLoadVehicleDetails={props?.setFullLoadVehicleDetails}
                                                    values={props?.customerBooking?.partLoad}
                                                />
                                            </div>
                                            <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                                {/* <ErrorMessage name="assetTypeOption" component="div" className="error" />  */}
                                                <CustomErrorMessage name="assetTypeOption" className="error" />
                                            </div>
                                        </div>
                                        <div style={{ width: "50%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                            <div style={{ width: "100%", marginLeft: "0.5%" }}>
                                                <Field
                                                    type="number"
                                                    id="vehicleNos"
                                                    name="vehicleNos"
                                                    className={"form-control"}
                                                    placeholder="Vehicle Nos"
                                                />
                                            </div>
                                            <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                                {/* <ErrorMessage name="vehicleNos" component="div" className="error" />  */}
                                                <CustomErrorMessage name="vehicleNos" className="error" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div style={{ width: "80%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                <div style={{ width: "50%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                    <div style={{ width: "100%", marginLeft: "0.5%" }}>
                                        <Field
                                            type="text"
                                            id="materialOption"
                                            name="materialOption"
                                            component={SelectField}
                                            placeholder="Select Material Type"
                                            setPartLoadPackages={props?.setPartLoadPackages}
                                            values={props?.customerBooking?.partLoad}
                                        />
                                    </div>
                                    <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                        {/* <ErrorMessage name="materialOption" component="div" className="error" />  */}
                                        <CustomErrorMessage name="materialOption" className="error" />
                                    </div>
                                </div>

                                <div style={{ width: "25%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                    <div style={{ width: "100%", marginLeft: "0.5%" }}>
                                        <Field
                                            type="number"
                                            id="qty"
                                            name="qty"
                                            className={"form-control"}
                                            placeholder="Quantity"
                                        />
                                    </div>

                                    <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                        {/* <ErrorMessage name="qty" component="div" className="error" />  */}
                                        <CustomErrorMessage name="qty" className="error" />
                                    </div>
                                </div>

                                <div style={{ width: "25%", margin: "auto", marginTop: "0.5%", display: "flex", justifyContent: "center" }}>
                                    <div style={{ width: "100%", marginLeft: "0.5%" }}>
                                        <Field
                                            type="number"
                                            id="kg"
                                            name="kg"
                                            className={"form-control"}
                                            placeholder="Weight in ( KG )"
                                        />
                                    </div>
                                    <div style={{ width: "5%", marginLeft: "0.5%", textAlign: "center", color: "red", padding: "1%" }}>
                                        {/* <ErrorMessage name="kg" component="div" className="error" />  */}
                                        <CustomErrorMessage name="kg" className="error" />
                                    </div>
                                </div>
                            </div>

                            <div style={{ width: "100%", margin: "auto", marginTop: "0.5%", textAlign: "center" }}>
                                <button
                                    type="submit"
                                    className="btn btn-primary-one text-white"
                                >
                                    Get Details
                                </button>
                            </div>
                        </div>

                        <div style={{ width: "50%" }}>
                            <div className="col-lg-6" data-wow-duration="2s" data-wow-delay="0.2s" style={{ marginLeft: "-3%" }}>
                                <img src="img/21.png" alt="" />
                            </div>
                        </div>
                    </div>

                </Form>
            </Formik>
            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                maxWidth={"lg"}
            >
                <div className="SigninPopupcontainer">
                    <div className='defineUsersContainer'>
                        <div className={tabValue === 0 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(0)}>Registered User</div>
                        <div className={tabValue === 1 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(1)}>New User</div>
                    </div>
                    <DialogContent>
                        {tabValue === 0 ?
                            <div className="userDetialsforHomePopup">
                                <input className='form-control' type={"email"} value={customerMobileNo} placeholder='Contact No' onChange={(e: any) => setCustomerMobileNo(e.target.value)} />
                                <br />
                                <input className='form-control' type={"password"} value={customerPassword} placeholder='Password' onChange={(e: any) => setCustomerPassword(e.target.value)} />
                            </div>
                            :
                            <div className="userDetialsforHomePopup">
                                <input className='form-control' type={"text"} placeholder='First Name' />
                                <br />
                                <input className='form-control' type={"text"} placeholder='Last Name' />
                                <br />
                                <input className='form-control' type={"email"} placeholder='E-mail' />
                                <br />
                                <input className='form-control' type={"password"} placeholder='Password' />
                                <br />
                                <input className='form-control' type={"number"} placeholder='Contact No' onChange={(e: any) => { setContactNo(e) }} />
                                <br />
                                <button className='btn btn-primary otpButton' onClick={() => { handelgetOtp() }}>Get Otp</button>
                                <br />
                                <input className='form-control otpInput' type={"number"} placeholder='Enter Otp' />
                            </div>
                        }
                        <br />
                        {tabValue === 0 &&
                            <div className="createAccount">
                                <Link to="/customerLogin/forgotPassword" className="fp-span">
                                    Forgot Password ?
                                </Link>
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <div className='dflex w100 btns'>
                            {tabValue === 0 ?
                                <ButtonPrimary className="mr5 loginForHomePopup" onClick={() => { handleLogin() }}> Login </ButtonPrimary>
                                :
                                <ButtonPrimary className="mr5 loginForHomePopup"> Sign Up </ButtonPrimary>
                            }
                            <ButtonDefault className="mr5 closeForHomePopup" onClick={() => setIsDialogOpen(false)}> close </ButtonDefault>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        customerReducer: state.customerLoginReducer,
        customerBooking: state.customerBooking,
    };
};

const mapDispatchToProps = {
    customerLogin,
    hideLoader,
    showLoader,
    setPartLoadPickupDropLocation,
    setPartLoadPackages,
    setFullLoadVehicleDetails,
    setOutSideFormFilled
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPartLoadForm)
