import { ServiceMaker, buildQueryString } from ".";

/**
 * User Module
 * @param {pincode}
 * @return city object
 */

export const getCityByPincode = (pincode: any) =>
  ServiceMaker(`area/city/${pincode}`, "GET");

export const getStateList = () => ServiceMaker(`state/list`, "GET");

export const updateStateByCity = (data: { cityId: string; stateId: string }) =>
  ServiceMaker(`city/updateStateByCity`, "POST", data);

export const getCityList = () => ServiceMaker(`city/list`, "GET");

export const getOtp = (contact: number) =>
  ServiceMaker(`registration/otp?contact=${contact}`, "GET");

export const verifyOtpForReg = (data: any) =>
  ServiceMaker(`registration/otpVerify?${buildQueryString(data)}`, "GET");

export const individualData = (data: any) =>
  ServiceMaker("registration/individual", "POST", data);

export const companyData = (data: any) =>
  ServiceMaker("registration/company", "POST", data);
  
export const checkBranchExistance = (data: any) =>
  ServiceMaker(`registration/checkBranchExistance?${buildQueryString(data)}`, "GET");
  
export const customerData = (data: any) =>
  ServiceMaker("registration/customer", "POST", data);
  
export const assetTypeListForOutSide = (data: any) =>
  ServiceMaker(`registration/type/list?${buildQueryString(data)}`, "GET");
