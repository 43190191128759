import React, { useState , useRef , useEffect } from "react";
import MyPartLoadForm from "./PartLoadFormik";
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import { Dialog, DialogActions, DialogContent } from "../../../Components/Dialog/DialogComponent";
import { setPartLoadPickupDropLocation } from './../../../actions/customerBooking';
import { ButtonDefault, ButtonPrimary } from "../../../Components/Button/Buttoncontainer";
import { Link, useNavigate } from "react-router-dom";
import { customerLogin } from "../../../actions/customerLogin";
import { getOtp } from "../../../services/area";
import { connect } from "react-redux";
import { showHttpError } from "../../../utils/message";
import AutoComplete from "../../../Components/AutoComplete";
import { useMediaQuery, useTheme } from "@mui/material";
import { hideLoader, showLoader } from "../../../actions/UserActions";
import FAQ from "../../../Components/FAQ/FAQ";
import OurFeatures from "../../../Components/Features/OurFeatures";

const PartloadForm = (props: any) => {

  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2
  console.log(`PartloadForm OutSide Form`, props);

  const navigate = useNavigate();
  const destIn: any = useRef();
  const [date, setDate] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [contactNo, setContactNo] = useState(0);
  const [tabActive, setTabActive] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [customerPassword, setCustomerPassword] = useState();
  const [customerMobileNo, setCustomerMobileNo] = useState();
  const [pickupDropLocation, setPickupDropLocation] = useState<any>({})

  const handleLogin = async () => {
    props?.showLoader();
    try {
      await props.customerLogin(customerMobileNo, customerPassword, false, localStorage.getItem("deviceId"), localStorage.getItem("latitude"), localStorage.getItem("longitude"))
      props?.hideLoader();
    } catch (error) {
      props?.hideLoader();
      alert("Contact No / Password is Incorrect !")
    }
  }

  const handelgetOtp = async () => {
    props?.showLoader();
    try {
      await getOtp(contactNo);
      props?.hideLoader();
    } catch (error) {
      props?.hideLoader();
      showHttpError(error);
    }
  };

  const topFunction2 = (props: any) => {
    document.body.scrollTop = 0;
    {
      props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
        props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0
    }
  }

  const multipleExist = (arr: any, values: any) => {
    return values.every((value: any) => {
      return arr.includes(value);
    });
  };

  const setLocationByTypingRes = (e: any, index: any) => {
    let area = e.address_components.find((x: any) => {
      return multipleExist(x.types, [
        "sublocality_level_1",
        "sublocality",
        "political",
      ]);
    });

    let pincode = e.address_components.find((x: any) => {
      return multipleExist(x.types, [
        'postal_code'
      ]);
    })

    // if(pincode === undefined){
    //   resetSelectedFormLocation();
    //   return showHttpError("Kindly Select Location More Specific !")
    // }

    let city = e.address_components.find((x: any) => {
      return x.types.length === 2 && multipleExist(x.types, ["locality", "political"]);
    })

    // if (index > 0 && getData?.pickDropLocation[0]?.contactPerson?.city !== "") {
    //   if (getData?.pickDropLocation[0]?.contactPerson?.city !== city?.long_name) {
    //     return showHttpError("Location Should be in same City as per Lcoation No 1 !")
    //   }
    // }

    let state = e.address_components.find((x: any) => {
      return multipleExist(x.types, ["administrative_area_level_1", "political"]);
    })

    props.setPartLoadPickupDropLocation('place.placeId', e.place_id, index)
    props.setPartLoadPickupDropLocation('place.coordinate.latitude', e.geometry.location.lat, index)
    props.setPartLoadPickupDropLocation('place.coordinate.longitude', e.geometry.location.lng, index)
    props.setPartLoadPickupDropLocation('place.placeValue', e.name, index)
    props.setPartLoadPickupDropLocation('place.formatted_address', e.formatted_address, index)
    props.setPartLoadPickupDropLocation('place.name', e.name, index)
    props.setPartLoadPickupDropLocation('place.area', area.long_name, index)
    props.setPartLoadPickupDropLocation('place.pincode', pincode.long_name, index)
    props.setPartLoadPickupDropLocation('place.city', city.long_name, index)
    props.setPartLoadPickupDropLocation('place.state', state.long_name, index)
    props.setPartLoadPickupDropLocation('contactPerson.state', state.long_name, index)
    props.setPartLoadPickupDropLocation('contactPerson.pincode', pincode.long_name, index)
    props.setPartLoadPickupDropLocation('contactPerson.city', city.long_name, index)
    props.setPartLoadPickupDropLocation('contactPerson.isDisabled', state.long_name && city.long_name && pincode.long_name ? true : false, index)
    props.setPartLoadPickupDropLocation('contactPerson.floor', "", index)
    props.setPartLoadPickupDropLocation('contactPerson.officeName', "", index)
    props.setPartLoadPickupDropLocation('contactPerson.nearby_landmark', "", index)
  }

  const partLoad = {
    "HowItWorks": [{ headings: ["Enter your details", "Get the instant pricing", "Stay in Touch"] },
    {
      img: ["how-it-works-img1.png",
        "how-it-works-img2.png",
        "how-it-works-img3.png"]
    }],
    "Features": [
      {
        img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
      },
      { headings: ["Wide service offering", "Multiple quotations", "Flexible transportation", "Adjustable validity dates", "Email it to anyone", "Easy administration"] },
      {
        content: ["Choose from 140+ services and 600 ports worldwide.",
          "Submit up to 10 port-pair combinations at once with our multilane feature.",
          "Select port-to-port or door-to-door – or whatever combination you need.",
          "Choose your validity date for more security down the road with our valid-from option.",
          "Send an email copy to yourself, colleagues, or customers.",
          "Access all quotations via My Quotations."]
      }],
    "FAQ": [
      {
        question: ["How would I know my fare charges & bill amount ?",
          "Why do I need to pay a token amount for booking confirmation ?",
          "What are the different modes of payment accepted by you ?",
          "Can I place a booking with multi-point pick-up and drop ?",
          "How can I cancel my booking? Will I be charged a cancellation fee ?",
          "Who will Generate an e-Way Bill ?"]
      },
      {
        answer: ["Once our system receives your booking, you will be notified of the approximate distance that your goods will travel, and accordingly, your bill amount will be calculated. With our fare calculator, we instantaneously give you the best rate online.",
          "We take a token amount for booking confirmation for assurance that our Truck will be loaded once reached the client's pickup location.",
          "Customers can make payments via NEFT / IMPS / PAYTM / UPI. We accept all types of cards, so you can easily make a payment online.",
          "You can do so. However, extra costs for the extra point of delivery or pick-up will be levied accordingly. Our team will provide you with the additional cost incurred at the time of booking.",
          "Currently, you are required to raise a cancellation request through the customer care number available on our website. Cancellation will then be approved as per the terms and conditions. A cancellation fee would also be applicable if our Truck has reached the client's pickup location.",
          "Consignor or Consignee before movement of goods needs to Generate Part A & B e-way bill. EwayBill is not required for household items"
        ]
      }],
    "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
    {
      feedback: ["We have been using Kabra Express for our PTL freight needs and have been extremely happy with their service. They are very reliable and their rates are very competitive.We would highly recommend their services to anyone looking for a PTL freight company.",
        "Kabra Express provides excellent PTL freight services at reasonable rates. Their focus on the B2B express segment is commendable, and their services are designed to meet the regular and seasonal freight requirements across the country. I am highly impressed with their pan-India network of professional truckload partners and their ability to provide e-commerce equivalent turnaround times.",
        "Kabra Express has been a boon for my business. Their PTL freight services are among the most competitive in the market, and their network coverage is excellent. Their shared network allows them to offer e-commerce equivalent turnaround times and direct reach across their entire network to PTL freight customers. This has helped my business immensely in reducing costs and increasing efficiency."
      ]
    }]
  }

  if (props?.customerReducer && props?.customerReducer?.token && props?.customerReducer?.name && localStorage.getItem("kabra:customertoken")) {
    navigate("/customerDashboard/booking",{
      state: {
        from: "customerLogin" 
      }
    })
  }
  
  useEffect(() => {
    console.log(`AutoCompletezdva`, props?.customerBooking?.partLoad.pickDropLocation, `date`, date)
    setPickupDropLocation(props?.customerBooking?.partLoad.pickDropLocation);
  }, [props?.customerBooking?.partLoad.pickDropLocation])

  return (
    <>
    {mobileView ? (
        <div className="ml-5percentage">
          <div className="partload">
            <section id="intro" className="intro">
              <div className="intro-content mt85Mobile">
            <h1 style={{textAlign: "center", fontSize: "24px", marginBottom: "0", paddingBlock: "1rem"}}>Get Estimate</h1>

                <div className="container">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="logo_1">
                        <img src="img/21.png" className="img-responsive mt-55" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 firm_1">
                      <div className="row form_page">
                        <div className="tab-content">
                          <div className="tab-pane active" id="my_1">

                            <div className="row ml-170">
                              <AutoComplete
                                value={pickupDropLocation?.place?.placeValue}
                                onChange={(e: any) => {
                                  console.log(e, "eeee");
                                  console.log("020230", e);
                                  setLocationByTypingRes(e, 0);
                                }}
                                className={"settledAddresses"}
                                where={"Customer"}
                                general={false}
                              ></AutoComplete>
                            </div>

                            <div className="row dflex ml-170 mt10Mobile">
                              <div className="mt-10 ml170">
                                <AutoComplete
                                  value={pickupDropLocation?.place?.placeValue}
                                  onChange={(e: any) => {
                                    console.log(e, "eeee");
                                    console.log("020230", e);
                                    setLocationByTypingRes(e, 1);
                                  }}
                                  className={"settledAddresses"}
                                  where={"Customer"}
                                  general={false}
                                >
                                  <input
                                    type='text'
                                    placeholder='Destination Location'
                                    className="form-control form_one"
                                    ref={destIn}
                                  />
                                </AutoComplete>
                              </div>
                            </div>
                          </div>

                          <div className="row ml-170 mt10Mobile">
                            <input
                              type="date"
                              onChange={(eve) => {
                                setDate(eve.target.value);
                              }}
                              className="form-control form_one mt0 ml170"
                              id="inputZip"
                              required
                            />
                          </div>
                          <div className="row ml-170 mt10Mobile">
                            <button
                              className="btn btn-primary-one text-white form_one ml170 mt0"
                              onClick={() => setIsDialogOpen(true)}
                            >
                              Get Details
                            </button>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-up" data-aos-delay="800"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="feturesTabs partloadFeturesTabs">
            <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Our Features</h2>
              </div>
              <div className="centalisedFeatures">
                <OurFeatures data={partLoad} />
              </div>
            </div>
            <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>How It Works</h2>
              </div>
              <div className="centalisedHowItWorks">
                <HowItWorks data={partLoad} />
              </div>
            </div>
            <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Testimonials</h2>
              </div>
              <div className="centalisedTestimonials">
                <Testimonials data={partLoad} />
              </div>
            </div>
            <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
              <section className="faq">
                <div className="container">
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>FAQ</h2>
                  </div>
                  <FAQ data={partLoad} />
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="partLoadContainer">
            <div className="scrollableContainer">
              <div className="sideNav" onMouseEnter={() => document.body.style.overflowY = 'hidden'} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
                <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Book Now</div>
                <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
                <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
                <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
                <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
              </div>
              <div className="partload">
                {/* <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "50%", marginLeft: "2.5%", marginTop: "3.5%" }}>
                    <div style={{ width: "50%", margin: "auto", marginTop: "5%" }}>
                      <AutoComplete
                        value={pickupDropLocation?.place?.placeValue}
                        onChange={(e: any) => {
                          console.log(e, "eeee");
                          console.log("020230", e);
                          setLocationByTypingRes(e, 0);
                        }}
                        where={"Customer"}
                        general={false}
                      >
                        <input
                          type='text'
                          placeholder='Source Location'
                          className="form-control form_one"
                          ref={destIn}
                        />
                      </AutoComplete>
                    </div>

                    <div style={{ width: "50%", margin: "auto", marginTop: "2.5%" }}>
                      <AutoComplete
                        value={pickupDropLocation?.place?.placeValue}
                        onChange={(e: any) => {
                          console.log(e, "eeee");
                          console.log("020230", e);
                          setLocationByTypingRes(e, 1);
                        }}
                        where={"Customer"}
                        general={false}
                      >
                        <input
                          type='text'
                          placeholder='Destination Location'
                          className="form-control form_one"
                          ref={destIn}
                        />
                      </AutoComplete>
                    </div>

                    <div style={{ width: "50%", margin: "auto", marginTop: "2.5%" }}>
                      <input
                        type="date"
                        onChange={(eve) => {
                          setDate(eve.target.value);
                        }}
                        className="form-control"
                        id="inputZip"
                        required
                      />
                    </div>

                    <div style={{ textAlign: "center", marginTop: "2.5%" }}>
                      <button
                        className="btn btn-primary-one text-white"
                        onClick={() => setIsDialogOpen(true)}
                      >
                        Get Details
                      </button>
                    </div>
                  </div>

                  <div>
                    <img src="img/21.png" alt="" />
                  </div>
                </div>*/}
                <MyPartLoadForm  from={"PartLoad"} />
                {/* <div className="intro-content mt85Mobile formAndPhoto commonBoxShadow" onMouseOver={() => { setTabActive(0) }}>
                  <div className="col-lg-6 firm_1 wow" data-wow-delay="0.1s" >
                    <div className="row ml-170">
                      <div className="mt-10 ml185">
                        <AutoComplete
                          value={pickupDropLocation?.place?.placeValue}
                          onChange={(e: any) => {
                            console.log(e, "eeee");
                            console.log("020230", e);
                            setLocationByTypingRes(e, 0);
                          }}
                          where={"Customer"}
                          general={false}
                        >
                          <input
                            type='text'
                            placeholder='Source Location'
                            className="form-control form_one"
                            ref={destIn}
                          />
                        </AutoComplete>
                      </div>
                    </div>

                    <div className="row dflex ml-170 mt10Mobile">
                      <div className="mt-10 ml185">
                        <AutoComplete
                          value={pickupDropLocation?.place?.placeValue}
                          onChange={(e: any) => {
                            console.log(e, "eeee");
                            console.log("020230", e);
                            setLocationByTypingRes(e, 1);
                          }}
                          where={"Customer"}
                          general={false}
                        >
                          <input
                            type='text'
                            placeholder='Destination Location'
                            className="form-control form_one"
                            ref={destIn}
                          />
                        </AutoComplete>
                      </div>
                    </div>

                    <div className="row ml-170 mt10Mobile">
                      <input
                        type="date"
                        onChange={(eve) => {
                          setDate(eve.target.value);
                        }}
                        className="form-control form_one mt0 ml185"
                        id="inputZip"
                        required
                      />
                    </div>

                    <div className="row ml-170 mt10Mobile">
                      <button
                        className="btn btn-primary-one text-white form_one ml185 mt0"
                        onClick={() => setIsDialogOpen(true)}
                      >
                        Get Details
                      </button>
                    </div>

                    <div data-aos="fade-up" data-aos-delay="800"></div>
                  </div>
                  <div className="col-lg-6" data-wow-duration="2s" data-wow-delay="0.2s">
                    <img src="img/21.png" alt="" />
                  </div>
                </div> */}
                <div className="feturesTabs">
                  <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>Our Features</h2>
                    </div>
                    <div className="centalisedFeatures">
                      <OurFeatures data={partLoad} />
                    </div>
                  </div>
                  <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>How It Works</h2>
                    </div>
                    <div className="centalisedHowItWorks">
                      <HowItWorks data={partLoad} />
                    </div>
                  </div>
                  <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
                    <div className="section-title" data-wow-delay="0.1s">
                      <h2>Testimonials</h2>
                    </div>
                    <div className="centalisedTestimonials">
                      <Testimonials data={partLoad} />
                    </div>
                  </div>
                  <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
                    <section className="faq">
                      <div className="container">
                        <div className="section-title wow" data-wow-delay="0.1s">
                          <h2>Frequently Asked Questions</h2>
                        </div>
                        <FAQ data={partLoad} />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                maxWidth={"lg"}
              >
                <div className="SigninPopupcontainer">
                  <div className='defineUsersContainer'>
                    <div className={tabValue === 0 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(0)}>Registered User</div>
                    <div className={tabValue === 1 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(1)}>New User</div>
                  </div>
                  <DialogContent>
                    {tabValue === 0 ?
                      <div className="userDetialsforHomePopup">
                        <input className='form-control' type={"email"} value={customerMobileNo} placeholder='Contact No' onChange={(e: any) => setCustomerMobileNo(e.target.value)} />
                        <br />
                        <input className='form-control' type={"password"} value={customerPassword} placeholder='Password' onChange={(e: any) => setCustomerPassword(e.target.value)} />
                      </div>
                      :
                      <div className="userDetialsforHomePopup">
                        <input className='form-control' type={"text"} placeholder='First Name' />
                        <br />
                        <input className='form-control' type={"text"} placeholder='Last Name' />
                        <br />
                        <input className='form-control' type={"email"} placeholder='E-mail' />
                        <br />
                        <input className='form-control' type={"password"} placeholder='Password' />
                        <br />
                        <input className='form-control' type={"number"} placeholder='Contact No' onChange={(e: any) => { setContactNo(e) }} />
                        <br />
                        <button className='btn btn-primary otpButton' onClick={() => { handelgetOtp() }}>Get Otp</button>
                        <br />
                        <input className='form-control otpInput' type={"number"} placeholder='Enter Otp' />
                      </div>
                    }
                    <br />
                    {tabValue === 0 &&
                      <div className="createAccount">
                        <Link to="/customerLogin/forgotPassword" className="fp-span">
                          Forgot Password ?
                        </Link>
                      </div>
                    }
                  </DialogContent>
                  <DialogActions>
                    <div className='dflex w100 btns'>
                      {tabValue === 0 ?
                        <ButtonPrimary className="mr5 loginForHomePopup" onClick={() => { handleLogin() }}> Login </ButtonPrimary>
                        :
                        <ButtonPrimary className="mr5 loginForHomePopup"> Sign Up </ButtonPrimary>
                      }
                      <ButtonDefault className="mr5 closeForHomePopup" onClick={() => setIsDialogOpen(false)}> close </ButtonDefault>
                    </div>
                  </DialogActions>
                </div>
              </Dialog>

            </div>
          </div>
        </>
      )}
    </>

  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    customerReducer: state.customerLoginReducer,
    customerBooking: state.customerBooking,
  };
};

const mapDispatchToProps = {
  customerLogin,
  showLoader,
  hideLoader,
  setPartLoadPickupDropLocation
  // removeIntraCityPickupDropLocation,
  // setIntraCityPickupDropLocation,
  // setIntraCity
};

export default connect(mapStateToProps, mapDispatchToProps)(PartloadForm)
