import React, { ReactNode } from "react";

type SelectContainerProps = {
  label: string;
  children: ReactNode;
  className?: string;
};

export const SelectContainer = ({ label, children, className }: SelectContainerProps) => {
  return (
    <div className={`${className} flex-col gap-4`}>
      <label htmlFor={label} className="fw-500 m-0">
        {label}
      </label>
      {children}
    </div>
  );
};

type TwoColumnSelectContainerProps = {
  label1: string;
  label2: string;
  children1: ReactNode;
  children2: ReactNode;
};

export const TwoColumnSelectContainer = ({
  label1,
  label2,
  children1,
  children2,
}: TwoColumnSelectContainerProps) => {
  return (
    <div className="two-column m-one-column gap-1">
      <SelectContainer label={label1} children={children1} />
      <SelectContainer label={label2} children={children2} />
    </div>
  );
};

type TwoColumnContainerProps = {
  children1: ReactNode;
  children2: ReactNode;
}

export const TwoColumnContainer = ({
  children1,
  children2,
}: TwoColumnContainerProps) => {
  return (
    <div className="two-column m-one-column gap-1">
      {children1}
      {children2}
    </div>
  );
};
