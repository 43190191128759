import { ServiceMaker, buildQueryString } from "./index";

export const createFleetService = (data: any) =>
  ServiceMaker("fleet/create", "POST", data);

export const createDooreFleetService = (data: any) =>
  ServiceMaker("fleet/fleetOrder/doorDeliveryFleetOrder", "POST", data);

export const fetchAllFleetsService = () => ServiceMaker("fleet/filter", "GET");

export const fetchAllFranchiseFleetsService = (data?: any) =>
  ServiceMaker(`fleet/franchise/filter?${buildQueryString(data)}`, "GET");

export const fetchFleetByNumber = (data: any) =>
  ServiceMaker("fleet/filter/by/regnum", "POST", data);

export const updateFleetService = (id: string, data: any) =>
  ServiceMaker(`fleet/update/${id}`, "PUT", data);

export const addDistanceTracking = (data: any) =>
  ServiceMaker("fleet/addDistanceTracking", "POST", data);

export const listDistanceTracking = (data?: any) =>
  ServiceMaker(`fleet/listDistanceTracking?${buildQueryString(data)}`, "GET");

export const getPreviousTrackingDetails = (fleetId: any) =>
  ServiceMaker(`fleet/previousTrackingData/${fleetId}`, "GET");

export const getFleetOrderList = (data: any) =>
  ServiceMaker(`fleet/fleetOrder/list`, "POST", data);

export const updateFleetOrderList = (id: string, data: any) =>
  ServiceMaker(`fleet/fleetOrder/update/${id}`, "PATCH", data);
export const getDetailedFleetList = (data?: any) =>
  ServiceMaker(
    `fleet/filterbycompany/detailed?${buildQueryString(data)}`,
    "GET"
  );

export const fleetOrderCount = (data:any) =>
  ServiceMaker("fleet/fleetOrder/count", "POST", data);

export const unlockFormGstCustody = (id:string,data:any) =>
  ServiceMaker(`fleet/fleetOutFromGstCustody/${id}`, "PATCH",data) 
  
export const getDoorDeliveryFleetOrder = (data:any) =>
  ServiceMaker(`fleet/getDoorDeliveryFleetOrder?${buildQueryString(data)}`, "GET") 
  
  export const CreateCustomerFleetOrder = (data:any) =>
    ServiceMaker(`/fleet/fleetOrder/customerBooking/fleetOrder`, "POST",data) 
 
 
    export const getOutForDeliveryData = (data:any) =>
    ServiceMaker(`fleet/outForDeliveryFleet?${buildQueryString(data)}`, "GET") 
 
    export const customerBookingFleetCheckInCheckOut = (data:any) =>
    ServiceMaker(`fleet/customerBooking/FleetCheckInCheckOut`, "POST",data) 
    
    export const doordeliveryFleetCheckInCheckOut = (data:any) =>
    ServiceMaker(`fleet/doorDelivery/fleetCheckInCheckOut`, "PATCH",data) 
  
    export const customerBookingStartOrder = (data:any) =>
    ServiceMaker(`fleet/customerBooking/startOrder`, "PATCH",data) 
   
    export const updateDoorDelFleetOrderStatus = (data:any) =>
    ServiceMaker(`fleet/updateDoorDelFleetOrderStatus`, "PATCH",data) 

    export const getFleetTyrePositions = (data:any) => 
    ServiceMaker(`fleet/tyrePositions?${buildQueryString(data)}`, "GET")

    export const skipCurrentOrder = (data:any) =>
    ServiceMaker(`fleet/doorDelivery/skipDoorDeliveryRoute`, "PATCH",data) 

    export const updateRoute = (data:any) =>
    ServiceMaker(`fleet/doorDelivery/optimizeRoute`, "PATCH",data) 

    export const getCompletedDoorDeliveryOrder = (data:any) => 
    ServiceMaker(`fleet/doorDelivery/listFleetOrder?${buildQueryString(data)}`, "GET")

    export const customerBookingEndOrder = (data:any) =>
    ServiceMaker(`fleet/customerBooking/completeOrder`, "PATCH",data) 
  