// react
import React, {
  Dispatch,
  SetStateAction,
  useState,
  createContext,
  ReactNode,
  useRef,
} from "react";
// react-redux
import { connect } from "react-redux";
// constants (data)
import {
  BranchSubHistoryNavItem,
  CustomerBookingNavItemType,
  CustomerHistoryNavItem,
  CustomerSubHistoryNavItem,
  LandingPageMainNavItem,
  LandingPagePartnersNavItem
} from "../constants/data";
// actions
import { login } from "../../actions/UserActions";
import {
  customerLogin,
  customerLoginWithToken,
} from "../../actions/customerLogin";
import { ActiveDeliveryType } from "../pages/Landing/get-estimate-forms/ExpressParcel";

// variables
export const loadTypes = ["Part Load", "Full Load"] as const;

// type
export type LoadType = (typeof loadTypes)[number];
export type ActivePageType = "services" | "partners" | "customer";
export type CustomerBookingNavItem = CustomerBookingNavItemType;
type LandingPageContextType = {
  activeService: LandingPageMainNavItem;
  setActiveService: Dispatch<SetStateAction<LandingPageMainNavItem>>;
  activeSubService: {module: string,isActive: boolean,data : any};
  setActiveSubService: Dispatch<SetStateAction<{
    module : string,
    isActive: boolean,
    data : any;
  }>>;
  setActiveHistoryName: Dispatch<SetStateAction<CustomerHistoryNavItem>>;
  activeHistoryName: CustomerHistoryNavItem;
  activeBranchSubHistoryName: BranchSubHistoryNavItem;
  setActiveBranchSubHistoryName: Dispatch<
    SetStateAction<BranchSubHistoryNavItem>
  >;
  activeCustomerSubHistoryName: CustomerSubHistoryNavItem;
  setActiveCustomerSubHistoryName: Dispatch<
    SetStateAction<CustomerSubHistoryNavItem>
  >;
  activePartner: LandingPagePartnersNavItem;
  setActivePartner: Dispatch<SetStateAction<LandingPagePartnersNavItem>>;
  activePage: ActivePageType;
  setActivePage: Dispatch<SetStateAction<ActivePageType>>;
  mainRef: React.MutableRefObject<HTMLDivElement | null>;
  mobileLandingPageRef: React.MutableRefObject<HTMLElement | null>;
  mobileFormPageRef: React.MutableRefObject<HTMLDivElement | null>;
  isScrolled: boolean;
  setIsScrolled: Dispatch<SetStateAction<boolean>>;
  login: any;
  customerLogin: any;
  user: any;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  customer: any;
  customerReducer: any;
  customerLoginWithToken: any;
  activeCustomerBookingNavItem: CustomerBookingNavItem;
  setActiveCustomerBookingNavItem: Dispatch<
    SetStateAction<CustomerBookingNavItem>
  >;
  activeExpressParcelDeliveryType: ActiveDeliveryType;
  setActiveExpressParcelDeliveryType: Dispatch<
    SetStateAction<ActiveDeliveryType>
  >;
  activeLoadType: LoadType;
  setActiveLoadType: Dispatch<SetStateAction<LoadType>>;
  isSearchDocket: boolean, 
  setIsSearchDocket: Dispatch<SetStateAction<boolean>>;
  
};
type LandingPageContextProviderType = {
  children: ReactNode;
  login: any;
  user: any;
  customerLogin: any;
  customer: any;
  customerReducer: any;
  customerLoginWithToken: any;
};

// Context
export const LandingPageContext = createContext<LandingPageContextType | null>(
  null
);

const LandingPageContextProvider = ({
  login,
  customerLogin,
  user,
  children,
  customer,
  customerReducer,
}: LandingPageContextProviderType) => {
  // State: Active Service
  const [activeService, setActiveService] = useState<LandingPageMainNavItem>(
    () => "Intra City"
  );
  const [activeSubService, setActiveSubService] = useState<{module : string,isActive : boolean,data : any}>({
    module : "",
    isActive : false,
    data : null,
  });
  const [activeCustomerBookingNavItem, setActiveCustomerBookingNavItem] =
    useState<CustomerBookingNavItem>("Intra City");
  const [activeHistoryName, setActiveHistoryName] =
    useState<CustomerHistoryNavItem>(() => "Online");
  const [activeCustomerSubHistoryName, setActiveCustomerSubHistoryName] =
    useState<CustomerSubHistoryNavItem>(() => "Upcoming");
  const [activeBranchSubHistoryName, setActiveBranchSubHistoryName] =
    useState<BranchSubHistoryNavItem>(() => "OnGoing");
  // State: Active Partners
  const [activePartner, setActivePartner] =
    useState<LandingPagePartnersNavItem>(() => "Direct Sales Agent");
  // State: Current Page
  const [activePage, setActivePage] = useState<ActivePageType>(
    () => "services"
  );
  const [activeExpressParcelDeliveryType, setActiveExpressParcelDeliveryType] =
    useState<ActiveDeliveryType>(() => "Branch to Branch");
  const [activeLoadType, setActiveLoadType] = useState<LoadType>(
    () => "Part Load"
  );
  // State: for scrolling
  const [isScrolled, setIsScrolled] = useState(false);
  // taking ref of main content of the landing page
  const mainRef = useRef<HTMLDivElement | null>(null);
  const mobileLandingPageRef = useRef<HTMLElement | null>(null);
  // State: To Open the Mobile Menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(
    () => false
  );
  // Mobile Landing Page Scrollable Div Ref
  const mobileFormPageRef = useRef<HTMLDivElement | null>(null);
  // Dialog for customer search and complaint
  const [isSearchDocket, setIsSearchDocket] = useState<boolean>(() => false);

  // component return
  return (
    <LandingPageContext.Provider
      value={{
        activePage,
        setActivePage,
        activeService,
        setActiveService,
        activeHistoryName,
        activeCustomerSubHistoryName,
        activeBranchSubHistoryName,
        setActiveHistoryName,
        setActiveCustomerSubHistoryName,
        setActiveBranchSubHistoryName,
        activePartner,
        setActivePartner,
        mainRef,
        mobileLandingPageRef,
        isScrolled,
        setIsScrolled,
        login,
        customerLogin,
        user,
        isMobileMenuOpen,
        setIsMobileMenuOpen,
        customer,
        customerReducer,
        customerLoginWithToken,
        mobileFormPageRef,
        activeCustomerBookingNavItem,
        setActiveCustomerBookingNavItem,
        activeExpressParcelDeliveryType,
        setActiveExpressParcelDeliveryType,
        activeLoadType,
        setActiveLoadType,
        activeSubService,
        setActiveSubService,
        isSearchDocket,
        setIsSearchDocket
      }}
    >
      {children}
    </LandingPageContext.Provider>
  );
};

// does: map all the state to props
const mapStateToProps = (state: any) => ({
  user: state?.user,
  customerReducer: state.customerLoginReducer,
  customer: state?.customer,
});
// does: map dispatch to props
const mapDispatchToProps = {
  login,
  customerLogin,
  customerLoginWithToken,
};

// connecting LandingPageContextProvider to react redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageContextProvider);
