import React, { ReactNode } from "react";
import { LoaderIcon } from "../../constants/icons";

type LoadingIndicatorProps = {
  isLoading?: boolean;
  // size?: "small" | "medium" | "large";
  loadingText?: string;
  children?: ReactNode;
};

const LoadingIndicator = ({
  isLoading,
  loadingText,
  children,
}: LoadingIndicatorProps) => {
  return (
    <>
      {isLoading ? (
        <span className="flex ai-center gap-4">
          <LoaderIcon className="loading-spinner-animation" />
          {loadingText}
        </span>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingIndicator;
