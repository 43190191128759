import React, { Fragment, useState, useEffect } from "react";
import fetchImage from "../../services/MyImage";
import { showLoader, hideLoader } from "../../actions/UserActions";
import { connect } from "react-redux";
import getImageUrl from "../../utils/getImageUrl";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ToolTip from "../../Components/ToolTip/ToolTip";
import "./image.css";
import { ButtonDefault, ButtonPrimary, ButtonSecondary } from "../../Components/Button/Buttoncontainer";
import Dialog from "../../Components/ReactModel/Dialog";
import { DialogActions, DialogContent } from "../../Components/Dialog/DialogComponent";
import { Input12 } from "../../Components/Input/Inputcomponents";
import { listBranchActivities, processBranchActivity } from "../../services/dashboard2";
import showMessage, { showHttpError, success } from "../../utils/message";

const MyImage = (props) => {
  const [image, setImage] = useState("");
  const [rotateDeg, setRotateDeg] = useState(0);
  const [zoomDisplay, setZoomDisplay] = useState(false);
  const [status,setStatus] = useState("")
  const [adminRemark,setAdminRemark] = useState("")
  const [isProcessDialog,setIsProcessDialog] = useState(false)
  const [prevPage , setPrevPage] = useState(0)
  const [branchActivityId, setBranchActivityId] = useState("");
  
  const setImageUrl = async () => {
    setImage("");
    props.showLoader();
    try {
      if (props.isSigned) {
        const image = await getImageUrl(props.objectID);
        setImage(image);
      } else {
        const response = await fetchImage(props.objectID);
        const image = await getImageUrl(response);
        setImage(image);
      }

      props.hideLoader();
    } catch (err) {
      props.hideLoader();
    }
  };
  useEffect(() => {
    if (props.objectID) {
      setImageUrl();
    }
  }, [props.objectID]);

  const rotateRight = () => {
    let x = rotateDeg;
    x += 90;
    setRotateDeg(x);
  };

  const rotateLeft = () => {
    let x = rotateDeg;
    x -= 90;
    setRotateDeg(x);
  };

  const zoom = () => {
    setZoomDisplay(!zoomDisplay);
  };

  const handleClear = () => {
    props.setAction("");
    setBranchActivityId("");
    setStatus("");
    setIsProcessDialog(false);
    setAdminRemark("");
  };

  const getCameraActiveData = async () => {
    const payload = {
      action: "CameraStatus",
    };
    try {
       showLoader() 
        if (props.cameraStatusDate) {
            let startDate = new Date(props.cameraStatusDate);
            startDate.setHours(0, 0, 0, 0);
            let endDate = new Date(props.cameraStatusDate);
            endDate.setDate(endDate.getDate() + 1);
            endDate.setHours(0, 0, 0, 0);
            payload.startDate = startDate;
            payload.endDate = endDate;
        }
        const response = await listBranchActivities(payload);
        
        props.setCameraActiveSatusData(response);
        hideLoader()
    } catch (error) {
        hideLoader()
        showHttpError(error)
    }
  };
  const handleProcessBranchAct = async () => {
    showLoader();
    let PrevPage  = prevPage
   try {
     const payload = {
       actId: props.branchActivityId,
       status: status,
       action: props.action,
       adminRemark: adminRemark,
     };
     await processBranchActivity(payload);
    props.setImageData(null)
    getCameraActiveData()
    showMessage("Remark Added Successfully", success);
    handleClear();
     setIsProcessDialog(false)
     setPrevPage(PrevPage)
     hideLoader();
   } catch (err) {
     handleClear();
     showHttpError(err);
     hideLoader();
   }
 };

  return (
    <Fragment>
      {/\.pdf$/i.test(props.objectID) ? (
        <object
          data={image}
          type="application/pdf"
          height={props.height ? props.height : "800px"}
          width={props.width ? props.width : "500px"}
        ></object>
      ) : (
        <div>
          <div style={{ }}>
            <img
              className={zoomDisplay ? "zoom" : ""}
              src={image}
              height={props.height ? props.height : "300px"}
              width="auto"
              style={{ objectFit:"contain",transform: `rotate(${rotateDeg}deg)`, ...(props.height ? {height: props.height} : {}), ...(props.width ? {width: props.width} : {}) }}
              onDoubleClick={zoom}
            />
          </div>
          {
            props.rotate !== false &&
            <div
            style={{
              marginTop: "20px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <ToolTip title="Rotate left">
              <RotateLeftIcon onClick={rotateLeft} />
            </ToolTip>

            <ToolTip title="Rotate right">
              <RotateRightIcon onClick={rotateRight} />
            </ToolTip>
          </div>
          
          }
          {
            props.pagination !== false &&
            <div
            style={{
              marginTop: "20px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <ToolTip title="Rotate left">
              <KeyboardArrowLeftIcon onClick={() => props.paginateNext()} />
            </ToolTip>

            <ToolTip title="Rotate right">
              <KeyboardArrowRightIcon onClick={() => props.paginateBack()} />
            </ToolTip>
          </div>
          }
          {
            props?.approveReject && (!(props?.userRowData?.processStatus === "A" || props.userRowData.processStatus === "R")  && <div style={{
              display:"flex",
              alignItems:"center",
              justifyContent:"center"
            }}>
                      <ToolTip
                        title={<div style={{ fontSize: "14px" }}>Approve</div>}
                      >
                        <span>
                          <ButtonPrimary
                            onClick={() => {
                              setIsProcessDialog(true);
                              setAdminRemark("");
                              // setAction(
                              //   props.row.original && props.row.original.action
                              // );
                              // setBranchActivityId(
                              //   props.row.original && props.row.original._id
                              // );
                              setStatus("A");
                            }}
                            style={{
                              marginLeft: "10px",
                              color: "white",
                              inlineSize: "fit-content",
                            }}
                          >
                            <i className="fa fa-check" aria-hidden="true"></i>
                          </ButtonPrimary>
                        </span>
                      </ToolTip>
                      <ToolTip title={<div style={{ fontSize: "14px" }}>Reject</div>}>
                        <span>
                          <ButtonSecondary
                            onClick={() => {
                              setIsProcessDialog(true);
                              setAdminRemark("");
                              // setAction(
                              //   props.row.original && props.row.original.action
                              // );
                              // setBranchActivityId(
                              //   props.row.original && props.row.original._id
                              // );
                              setStatus("R");
                            }}
                            style={{
                              marginLeft: "10px",
                              color: "white",
                              inlineSize: "fit-content",
                            }}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>
                          </ButtonSecondary>
                        </span>
                      </ToolTip>
                    </div> 
                    // : <p style={{textAlign:"center"}}>Already Given Status</p>
                    )
          }
          
        </div>
      )}
          <Dialog
        open={isProcessDialog}
        maxWidth="sm"
        onClose={() => setIsProcessDialog(false)}
      >
        <DialogContent>
          <Input12
            placeholder="Admin Remark"
            value={adminRemark}
            onChange={(e) => setAdminRemark(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <ButtonPrimary onClick={() => handleProcessBranchAct()}>
            Submit
          </ButtonPrimary>
          <ButtonDefault onClick={() => setIsProcessDialog(false)}>
            Close
          </ButtonDefault>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showLoader,
  hideLoader,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyImage);
