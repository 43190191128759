import React from "react";
import { Button } from "@mui/material";
import ToolTip from "../../Components/ToolTip/ToolTip";
import Grid from "../Grid/Grid";

interface Ipropssubmit {
  onClick: any;
  disabled ?: any;
}
export function SubmitButton(props: Ipropssubmit) {
  return (
    <Grid
      sm={3}
      xs={3}
      lg={1}
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Button
        variant="contained"
        style={{ height: 38, margin: "10px" }}
        color="primary"
        onClick={props.onClick}
      >
        Submit
      </Button>
    </Grid>
  );
}

export function ApplyButton(props: Ipropssubmit) {
  return (
    <Grid
      sm={3}
      xs={3}
      lg={2}
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Button
        variant="contained"
        style={{ height: 38, margin: "10px" }}
        color="primary"
        onClick={props.onClick}
        type="submit"
      >
        Apply
      </Button>
    </Grid>
  );
}
export function MonthWiseButton(props: Ipropssubmit) {
  return (
    <Grid
      sm={3}
      xs={3}
      lg={2}
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Button
        variant="contained"
        style={{ height: 38, margin: "10px" }}
        color="primary"
        onClick={props.onClick}
        type="submit"
      >
        MonthWise
      </Button>
    </Grid>
  );
}

interface Ipropsprint {
  onClick: any;
  disabled?: any;
}
export function PrintButton(props: Ipropsprint) {
  return (
    <ToolTip title={<div style={{ fontSize: "14px" }}>Print</div>}>
      <Button
        variant="contained"
        style={{ height: 38, margin: "10px" }}
        color="secondary"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <i className="fas fa-print" style={{ fontSize: "20px" }}></i>
      </Button>
    </ToolTip>
  );
}
export function ExportButton(props: Ipropssubmit) {
  return (
    <Button
      variant="contained"
      style={{ height: 38, margin: "10px" }}
      color="secondary"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      Export
    </Button>
  );
}
interface IpropsDownload {
  onClick: any;
  disabled?: any;
}

export function DownloadButton(props: IpropsDownload) {
  return (
    <ToolTip title={<div style={{ fontSize: "14px" }}>download</div>}>
      <Button
        className="marginRight10"
        variant="contained"
        disabled={props.disabled}
        color="secondary"
        style={{ height: 38, margin: "10px" }}
        onClick={props.onClick}
      >
        <i className="fas fa-file-download download_font"></i>
      </Button>
    </ToolTip>
  );
}
//<i className="fas fa-file-download download_font"></i>
interface IpropsPrintSummary {
  onClick: any;
}
export function PrintSummaryButton(props: IpropsPrintSummary) {
  return (
    <Grid
      sm={3}
      xs={3}
      lg={1}
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Button
        variant="contained"
        style={{ height: 38, margin: "10px" }}
        color="secondary"
        onClick={props.onClick}
      >
        Print Summary
      </Button>
    </Grid>
  );
}

interface Ipropstrefresh {
  onClick: any;
}

export function RefreshButton(props: Ipropstrefresh) {
  return (
    <ToolTip title={<div style={{ fontSize: "14px" }}>Reset</div>}>
      <Button
        className="marginRight10"
        variant="contained"
        color="secondary"
        style={{ height: 38, margin: "10px" }}
        onClick={props.onClick}
      >
        <i className="fas fa-sync" style={{ fontSize: "20px" }}></i>
      </Button>
    </ToolTip>
  );
}
// <i className="fas fa-sync"></i>
interface Ipropssort {
  onClick: any;
}

export function SortButton(props: Ipropssort) {
  return (
    <Button
      color="primary"
      variant="contained"
      className="marginRight10"
      style={{ height: 38, margin: "10px" }}
      onClick={props.onClick}
    >
      Sort
    </Button>
  );
}

export function FetchRecordsButton(props: Ipropssort) {
  return (
    <Button
      variant="contained"
      style={{ height: 38, margin: "10px" }}
      color="primary"
      onClick={props.onClick}
    >
      Fetch Records
    </Button>
  );
}

export function AddNewButton(props: Ipropssort) {
  return (
    <Button
      variant="contained"
      color="secondary"
      style={{ height: 38, margin: "10px" }}
      onClick={props.onClick}
    >
      Add New
    </Button>
  );
}

interface Ipropstsent {
  onClick: any;
  color: any;
}

export function SentButton(props: Ipropstsent) {
  return (
    <Button
      className="marginRight10"
      variant="contained"
      color={props.color}
      onClick={props.onClick}
      style={{ height: 38, margin: "10px" }}
    >
      Sent
    </Button>
  );
}

export function ReceivedButton(props: Ipropstsent) {
  return (
    <Button
      variant="contained"
      style={{ height: 38, margin: "10px" }}
      color={props.color}
      onClick={props.onClick}
      // color="secondary"
      // onClick={print.bind(this)}
    >
      Received
    </Button>
  );
}

//<i className="fas fa-print print30px"></i>
