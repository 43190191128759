import React,{ useCallback, useEffect } from "react"
import PortalModal from "./ReactPortal"
import "./reactmodel.css"




interface Props {
	open: boolean;
    onClose?: () => void;
	children: JSX.Element | JSX.Element[];
    fullWidth?:boolean;
    maxWidth?:'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | false
    | string;
    TransitionComponent?: any;
    style?:any;
    fullScreen?:boolean
}

const Dialog = ({ children, open, onClose,fullWidth = false,maxWidth="sm" ,style ={},fullScreen=false}: Props) => {
	// const modalRef = useRef<HTMLDivElement>(null)

	// handle what happens on click outside of modal
	const handleClickOutside = (event:any) => {
        if(event.target === event.currentTarget) {
            onClose && onClose()
         }
    }
    
	// handle what happens on key press
	const handleKeyPress = useCallback((event: KeyboardEvent) => {
		if (event.key === "Escape")onClose&& onClose()
	}, [])

const classNameStr =fullScreen?"modal_fullscreen": fullWidth?`modal_fullwidth modal_${maxWidth}`: `modal_${maxWidth}`
	useEffect(() => {
		if (open) {
			// attach the event listener if the modal is shown
			document.addEventListener("keydown", handleKeyPress)
			// remove the event listener
			return () => {
				document.removeEventListener("keydown", handleKeyPress)
			}
		}
       
	}, [handleKeyPress, open])
   
    useEffect(() => {
        if(open){
            document.body.style.overflow = 'hidden';
        }
        return ()=> {
            const element = document.getElementsByClassName("TapTap-Modal-Root")
            if(!element ||element == null || element.length === 0)
            document.body.style.overflow = '';
        }
     }, [open]);
   
	return (
        <>
        {open &&
            (
				<PortalModal wrapperclass='TapTap-Modal-Root'>
                    <div className="modal_backdrop"/>
                    <div  className="modal_wrapper_container"  onClick={handleClickOutside} style={style?{...style}:{}}>
                        <div className={`modal_innner_cotainer ${classNameStr}`}>
                        {children}
                        </div>
                        </div>

				</PortalModal>
			)}
		</>
	)
}

export default Dialog