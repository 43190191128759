// react
import React, { useEffect, useState } from "react";
// ui component
import { Button, Dialog, Select } from "../../../components/UI";
// constants (icons)
import { GetDetailsIcon } from "../../../constants/icons";
import AutoComplete from "../../../../Components/AutoComplete";
import {
  getIntraCityBookingPriceEstimated,
  getIntraCityRates,
} from "../../../../services/intraCityRate";
import { showHttpError } from "../../../../utils/message";
import IntraCityRates, {
  IntraCityRateEstimateItemType,
} from "./IntraCityRates";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import ContactOTPForm from "../components/CommonForms/ContactOTPForm";
import { hideLoader, showLoader } from "../../../../actions/UserActions";
import { createIntracityLead, sendOTPForLandingForms, verifyContact } from "../../../../services/landingPageForms";
import { StateType } from "../partner-forms/useCustomState";
import { useNavigate } from "react-router-dom";
import { SET_INTRACITY } from "../../../../constants/customerBooking";
import { toastMessage } from "../../../../Components/showMessages/toastMessage";
import { setIntraCityPickupDropLocation } from "../../../../actions/customerBooking";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { customerLoginByOTP } from "../../../../actions/customerLogin";
import { useWidth } from "../../../hooks/useMobile";

// FC: Intra City Get Estimate Component
export default function IntraCityForm() {
  const [cities, setCities] = useState<any>(null);
  const { isMobile } = useWidth();
  const [selectedCity, setSelectedCity] = useState<any>(null);
  const [sourceLocation, setSourceLocation] = useState<any>(null);
  const [destinationLocation, setDestinationLocation] = useState<any>(null);
  const [isIntraCityRateDialogOpen, setIsIntraCityRateDialogOpen] =
    useState<boolean>(() => false);
  const [
    intraCityBookingPriceEstimateArray,
    setIntraCityBookingPriceEstimateArray,
  ] = useState<IntraCityRateEstimateItemType[]>(() => []);
  const [selectedEstimatedRate, setSelectedEstimatedRate] =
    useState<number>(-1);
  const [selectedAssetType, setSelectedAssetType] =
    useState<IntraCityRateEstimateItemType>({
      assetSubType: {
        name: "",
        assetType: {
          assetName: "",
          _id: "",
        }
      },
      availableFreeMinutes: 0,
      perMinuteCharge: 0,
      price: 0,
});
  const [isOTPModal, setIsOTPModal] =
    useState<boolean>(false);
    const dispatch = useAppDispatch()
    const [contact, setContact] = useState<StateType>("");
    const [otp, setOTP] = useState<StateType>("");
    const [name, setName] = useState<StateType>("");
    const [selectedDate,setSelectedDate] = useState<string>("")
    const [isOTPClick,setIsOTPClick] = useState<boolean>(false)
    const [isCustomer,setIsCustomer] = useState<boolean>(false)
    // const { setActivePage } = useLandingPageContext();
    const navigate = useNavigate()
    const {
      intracity
    } = useAppSelector(state => {
      return {
        intracity: state.customerBooking?.intraCity
      }
    })

  // find cities options
  const citiesOption = Array.from(new Set(cities?.map((e: any) => e.name))).map(
    (name) => {
      const city = cities.find((e: any) => e.name === name);
      return {
        label: city.name,
        value: city._id,
      };
    }
  );

  // does: get the city list
  async function getCityList() {
    try {
      let payload = { type: "EXPRESS" };
      const responseForCity = await getIntraCityRates(payload);
      // Using a Map to ensure uniqueness based on _id
      const cityMap = new Map();
      responseForCity.forEach((item: any) => {
        // Skip the item if city is undefined
        if (item.city) {
          const city = item.city;
          if (!cityMap.has(city._id)) {
            cityMap.set(city._id, { _id: city._id, name: city.name });
          }
        }
      });
      // Converting the Map values to an array
      const uniqueCities = Array.from(cityMap.values());
      setCities(uniqueCities);
    } catch (error) {
      showHttpError(error);
    }
  }

  // does: handle submit
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    dispatch(showLoader())
    try {
      e.preventDefault()
      if (!sourceLocation || !destinationLocation || !selectedDate || !selectedCity.label) {
        return alert("All details are required!");
      }
      // const payload = {
      //   city: selectedCity.label,
      //   toPlace: "ChIJD21ZqI-GXjkRBbphR_iIwd4",
      //   fromPlace: "ChIJ5TEbRcmGXjkR3nDjntT5teI",
      // };
      const payload = {
        city: selectedCity?.label,
        toPlace: destinationLocation?.place_id,
        fromPlace: sourceLocation?.place_id,
      };
      const response = await getIntraCityBookingPriceEstimated(payload);
      console.log(response);
      const data: IntraCityRateEstimateItemType[] = response.price.map(
        (item: any) => {
          return {
            assetSubType:item.assetSubType,
            price: item.price,
            perMinuteCharge: item.perMinuteCharge,
          };
        }
      );
      setIntraCityBookingPriceEstimateArray(data);
      if (response) {
        setIsIntraCityRateDialogOpen(true);
      }
    } catch (error) {
      console.log(error, "error1111111111111111111")
      alert("Intra city booking rate error");
    }
    finally {
      dispatch(hideLoader())
    }
  }

  const handleContactSubmit = async () => {
    dispatch(showLoader());

    const payload = { contact };

    try {
      const res = await verifyContact(payload);

      if (res) {
        const leadPayload = {
          city: selectedCity?.value,
          fromPlace: { ...intracity?.pickDropLocation?.[0]?.place },
          toPlace: { ...intracity?.pickDropLocation?.[1]?.place },
          date: selectedDate,
          assetType: selectedAssetType?.assetSubType?.assetType?._id,
          contact
        }
        await sendOTPForLandingForms(payload)

        await createIntracityLead(leadPayload);

        dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "isBookedWithoutToken",
            val: true,
          },
        });
        dispatch({
          type: SET_INTRACITY,
          payload: {
            what: "lead",
            val: leadPayload,
          },
        });
        // navigate("/login", {
        //   state: {
        //     action: "isBookedWithoutToken",
        //     data: { contact },
        //   },
        // });
        toastMessage("OTP sent successfully", {
          theme: "colored",
          type: "success",
          autoClose: 1000
        });
        // } else {
      }
      else{
        // setActivePage("customer");
        await sendOTPForLandingForms(payload)
        setIsCustomer(true)
        toastMessage("Please Register Customer", {
          theme: "colored",
          type: "error",
        });
      }

    } catch (error) {
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };

  const verifyOTP = async () => {
    dispatch(showLoader());
    let deviceId = localStorage.getItem("deviceId");
    const payload: {
      contact: number,
      OTP: string,
      rememberMe: boolean,
      deviceId? : string,
      name ?: string
    } = { 
        contact : Number(contact),
        OTP : otp as string,
        rememberMe: true
     };

    if (deviceId) {
      payload.deviceId = deviceId
     }
     if(name){
      payload.name = name as string
     }
    
    try {
      // const res = await verifyOTPLandingForms(payload);
      const res : any = await dispatch(customerLoginByOTP(Number(contact),otp as  string,true,deviceId ? deviceId : undefined,name ? name as string : ""))

      if(res.data?.response?.accessToken && res.data?.response?.customer){
        console.log(res,"afsfdgasdgacsdgcasgdcas")
        navigate("/customerDashboard/booking")
        setIsOTPModal(false)
        setIsOTPClick(false)
        setIsCustomer(false)
      }
    } catch (error) {
      navigate("/")
      showHttpError(error);
      return error;
    } finally {
      dispatch(hideLoader());
    }
  };


  const backButtonClick = () => {
    setIsOTPModal(false)
    setContact("")
  }

  // useEffect
  // does: get the city list and vechile type list
  useEffect(() => {
    getCityList();
  }, []);

  useEffect(() => {
    if (isOTPClick) {
      handleContactSubmit()
    }
  }, [isOTPClick])

  const multipleExist = (arr: any, values: any) => {
    return values.every((value: any) => {
      return arr.includes(value);
    });
  };

  const setLocationByTypingRes = (e: any, index: number) => {
    let area = e.address_components.find((x: any) => {
      return multipleExist(x.types, [
        "sublocality_level_1",
        "sublocality",
        "political",
      ]);
    });

    const setPickDropFeature = (name: any, value: any, index: any) => {
      dispatch(setIntraCityPickupDropLocation(name, value, index));
    };

    let pincode = e.address_components.find((x: any) => {
      return multipleExist(x.types, ["postal_code"]);
    });

    // if(pincode === undefined){
    //   resetSelectedFormLocation();
    //   return showHttpError("Kindly Select Location More Specific !")
    // }

    let city = e.address_components.find((x: any) => {
      return (
        x.types.length === 2 && multipleExist(x.types, ["locality", "political"])
      );
    });

    // if (index > 0 && formData?.pickDropLocation[0]?.contactPerson?.city !== "") {
    //   if (formData?.pickDropLocation[0]?.contactPerson?.city !== city?.long_name) {
    //     return showHttpError("Location Should be in same City as per Lcoation No 1 !")
    //   }
    // }

    let state = e.address_components.find((x: any) => {
      return multipleExist(x.types, ["administrative_area_level_1", "political"]);
    });

    setPickDropFeature("place.placeId", e.place_id, index);
    setPickDropFeature(
      "place.coordinate.latitude",
      e.geometry.location.lat,
      index
    );
    setPickDropFeature(
      "place.coordinate.longitude",
      e.geometry.location.lng,
      index
    );
    setPickDropFeature("place.placeValue", e.name, index);
    setPickDropFeature("place.formatted_address", e.formatted_address, index);
    setPickDropFeature("place.name", e.name, index);
    setPickDropFeature("place.area", area.long_name, index);
    setPickDropFeature("place.pincode", pincode.long_name, index);
    setPickDropFeature("place.city", city.long_name, index);
    setPickDropFeature("place.state", state.long_name, index);
    setPickDropFeature("contactPerson.state", state.long_name, index);
    setPickDropFeature("contactPerson.pincode", pincode.long_name, index);
    setPickDropFeature("contactPerson.city", city.long_name, index);
    setPickDropFeature(
      "contactPerson.isDisabled",
      state.long_name && city.long_name && pincode.long_name ? true : false,
      index
    );
    setPickDropFeature("contactPerson.floor", "", index);
    setPickDropFeature("contactPerson.officeName", "", index);
    setPickDropFeature("contactPerson.nearby_landmark", "", index);
  };

  // component return
  return (
    <>
      <form onSubmit={handleSubmit} className={`| ${isMobile ? "one-column p-1 border-white shadow-small" : "five-column"} flex-center  gap-1 w-100`}>
        {isMobile && <h1 className="| fs-heading fw-900 text-primary-400 text-left m-text-center m-0 capitalize">
          Get an Estimate
        </h1>}
        <Select
          isClearable={true}
          isSearchable={true}
          options={citiesOption}
          value={selectedCity}
          onChange={(e: any) => setSelectedCity(e)}
          placeholder={"Select Your City"}
        />
        {/* <Select isClearable={true} isSearchable={true} options={options} placeholder={"Source Location"} /> */}
        <AutoComplete
          placeHolder={"Source Location"}
          value={sourceLocation && sourceLocation.name}
          onChange={(e: any) => {
            console.log(e, "afdsxdfsxafsxdafxdfas")
            setLocationByTypingRes(e, 0)
            setSourceLocation({ name: e.name, place_id: e.place_id, data: e });
          }}
        ></AutoComplete>
        <AutoComplete
          value={destinationLocation && destinationLocation.name}
          placeHolder={"Destination Location"}
          onChange={(e: any) => {
            console.log(e, "afdsxdfsxafsxdafxdfas")
            // setLocationByAddressSuggestions(e);
            setLocationByTypingRes(e, 1)
            setDestinationLocation({ name: e.name, place_id: e.place_id, data: e });
          }}
        ></AutoComplete>
        {/* <Select isClearable={true} isSearchable={true} options={options} placeholder={"Destination Location"} /> */}
        {/* <div className="flex | gap-10">
      {intraCityBookingPriceEstimateArray &&
        intraCityBookingPriceEstimateArray.length > 0 &&
        intraCityBookingPriceEstimateArray.map((item, index: number) => {
          return (
            <div
              key={index}
              className={`paper fs-link grid gap-4 pointer three-column ${
                selectedEstimatedRate === index ? "bg-primary-100 text-white-900" : "bg-primary-50 text-primary-900"
              }`}
              onClick={() => {
                setSelectedEstimatedRate(index);
                dispatch(
                  setIntraCity("assetType", {
                    label: item?.name,
                    value: item?.name,
                  })
                );
                dispatch(setIntraCity("gross", item.price));
                // if (onItemClick) {
                //   onItemClick(item);
                // }
              }}
            >
              <span className="fw-600">
                  {item?.assetTypes?.name || item.name || "Vehicle"}
              </span>
            </div>
          );
        })}
        </div> */}
        <input type="date" className="date-select" value={selectedDate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSelectedDate(e.target.value)} />
        <Button
          type="submit"
          variant="secondary"
          action="secondary"
          Icon={<GetDetailsIcon />}
          className={`as-start fs-button ${isMobile ? "flex-center" : ""}`}
          fullWidth={isMobile ? true : false}
        >
          Book Now
        </Button>

      {/* Dialog for display Intra City Rates */}
      <Dialog
        header={!isOTPModal ? "Intra City Rates" : "Verify Contact"}
        body={
          !isOTPModal ? 
          <IntraCityRates
            arr={intraCityBookingPriceEstimateArray}
            selectedItem={selectedEstimatedRate}
            setSelectedItem={setSelectedEstimatedRate}
            selectedAssetType={selectedAssetType}
            setSelectedAssetType={setSelectedAssetType}
          /> : 
          <ContactOTPForm 
            isOTPModal={isOTPModal} 
            contact={contact} 
            setContact={setContact} 
            otp={otp} 
            setOTP={setOTP}
            name={name} 
            setName={setName} 
            isCustomer={isCustomer}
            setIsCustomer={setIsCustomer}
            isOTPClick={isOTPClick}
            setIsOTPClick={setIsOTPClick}
          />
        }
        footer={
          <div className="flex gap-1">
          <div className="flex gap-1">
          {
            isOTPModal ? <> <div className="flex gap-1">
            <Button onClick={() => {
              backButtonClick()
            }} variant="info" action="secondary" type="button">
              Back
            </Button>
          </div>
          {
            isOTPClick ? <div className="flex gap-1">
            <Button onClick={() => {
              if((isCustomer ? name : true) && contact && otp){
                verifyOTP()
              }
              else{
                toastMessage("All Fields are Required",{
                  theme:"colored",
                  type:"error",
                  autoClose : 1000
                })
              }
              }} variant="primary" action="secondary" type="button">
                verify
              </Button>
          </div> : null
          }
            </>  : <Button onClick={() => {
              setIsOTPModal(true)
            }} variant="primary" action="secondary" type="button">
              Book
            </Button>
          }   
          </div>
         </div>
        }
        size="regular"
        state={isIntraCityRateDialogOpen}
        setState={setIsIntraCityRateDialogOpen}
        closeIcon
      ></Dialog>
    </form>
    </>
  );
}
