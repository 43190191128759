import React, { Fragment, useEffect, useState } from "react";
import {
  // Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Paper } from "../../Components/Paper/Paper";
import ToolTip from "../../Components/ToolTip/ToolTip";
import { Row, Col } from "react-bootstrap";
import { getImageService } from "../../services/utils";
import TrackBar from "../generics/TrackBar";
import ImageInput from "../../Components/ImageInput";
import {
  setMissingPackages,
  setFoundPackages,
  sendBackToNewDestination,
  sendBackToOrigin,
  setReceivedPackages,
} from "../../services/bookTrack";
import { connect } from "react-redux";
import { showHttpError } from "../../utils/message";
import { showMessage, success } from "../../utils/message";
import { showLoader, hideLoader, setCities } from "../../actions/UserActions";
import getImageUrl from "../../utils/getImageUrl";
import trackStyles from "./bookutils/static/track.module.css";
// import verifyFilled from "./deliveryutils/verify_filled.svg";
// import verify from "./deliveryutils/verify.svg";
import BuiltyPrint from "./bookutils/BuiltyPrint";
import $ from "jquery";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { RootState } from "../../store";
import { fetchStrackDetails } from "../../services/operations";
import "./bookingTrack.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { filterBookBranches } from "../../services/branch";
import Complaint from "../Complaint/complaint";
import GuestReply from "../Complaint/guestReply";
import { getGuestComplaint } from "../../services/complaint";
import RatingReview from "../Complaint/ratingReview";
import jsonToFormdata from "../../utils/jsonToFormdata";
import { Helmet } from "react-helmet";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import MyImage from "../Image/MyImage";
import Header from "../web/Components/Header";
import Footer from "../web/Components/Footer";
import {
  Dialog,
  DialogContent,
  DialogActions,
} from "../../Components/Dialog/DialogComponent";
import {
  ButtonDefault,
  ButtonPrimary,
  ButtonSecondary,
  ButtonSimple,
} from "../../Components/Button/Buttoncontainer";
import { Input12 } from "../../Components/Input/Inputcomponents";
import { Select12 } from "../../Components/Selectcomponents/Select";
import Grid from "../../Components/Grid/Grid";
import DoorDeliveryDialog from "./Booking/DoorDeliveryDialog";
import { withRouter } from "../../hoc/withRouter";
// import {withRouter} from "react-router-dom";
// import ShareToWhatsapp from "../sharing/WhatsAppSharing";

const giveUser = function(user: any) {
  if (
    user &&
    typeof user === "object" &&
    user.hasOwnProperty("name") &&
    user.name.fName &&
    user.name.lName
  ) {
    return [user.name.fName, user.name.lName].join(" ");
  }
  return "Anonymous";
};

const processLogs = (packages: any) => {
  let uniqueLogs: any = {},
    packageMap: any = {},
    global: any = {};
  packages.forEach((item: any) => {
    let { logs: tLogs, pod, idProof } = item;

    let current = "";
    if (tLogs) {
      tLogs.forEach((item: any) => {
        let {
          regNumber,
          branch,
          trackingTime,
          user,
          action,
          remarkImage,
          remarkText,
        } = item;

        let entity = regNumber || (branch ? branch.branchName : "ENTITY");

        current +=
          [
            action,
            entity,
            giveUser(user),
            trackingTime,
            pod || "NOPOD",
            idProof || "NOID",
            remarkText || "RTEXT",
            remarkImage || "RIMAGE",
          ].join("@") + ";";
      });
      if (current in global) {
        packageMap[current].push(item.order);
      } else {
        global[current] = 1;
        uniqueLogs[current] = current;
        packageMap[current] = [item.order];
      }
    }
  });
  return {
    uniqueLogs,
    packageMap,
    global,
  };
};

const RawStatus = (props: any) => {
  const [fetch, setFetch] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [showDeliveryImages, setShowDeliveryImages] = useState(false);
  const [showRemarkImages, setShowRemarkImages] = useState(false);

  useEffect(() => {
    const { log } = props;
    if (fetch) {
      setFetch(false),
        () => {
          const arr = log.split("@");
          let remarkImage = arr[7];
          if (remarkImage && remarkImage.length > 20) {
            getImageService(remarkImage)
              .then((data: any) => {
                setImage(data);
              })
              .catch((err: any) => {
                console.log(err);
              });
          }
        };
    }
  });

  const toggleRemark = () => {
    setShowRemarkImages(!showRemarkImages);
    setFetch(true);
  };

  const toggleDelivery = () => {
    setShowDeliveryImages(!showDeliveryImages);
  };

  const { log } = props;
  const arr = log.split("@");

  let [
    action,
    entity,
    user,
    time,
    pod,
    idProof,
    remarkText,
    //  remarkImage
  ] = arr;
  const date = new Date(time);

  return (
    <div>
      <Dialog open={showRemarkImages} onClose={toggleRemark}>
        <div className="delivery_images">
          <div className="del_title">Remark Image</div>
          <br />
          <div className="del_id">
            <img src={image} height="150" width="inherit" />
          </div>
        </div>
      </Dialog>

      <Dialog open={showDeliveryImages} onClose={toggleDelivery}>
        <div className="delivery_images">
          <div className="del_title">Proof Of Delivery</div>
          <div className="del_pod">
            <img src={pod} height="150" width="inherit" />
          </div>

          <br />

          <div className="del_title">ID Proof</div>
          <div className="del_id">
            <img src={idProof} height="150" width="inherit" />
          </div>
        </div>
      </Dialog>

      <Paper>
        <Row style={{ padding: "16px", fontSize: "14px" }}>
          <Col md={2} sm={6}>
            {date.toLocaleString()}
          </Col>
          <Col md={1} sm={6}>
            {action}
          </Col>
          <Col md={3} sm={6}>
            at {entity}
          </Col>
          <Col md={3} sm={6}>
            {user}
          </Col>
          <Col md={2} sm={6}>
            {action === "delivered" &&
              ((typeof pod == "string" && pod.length > 0 && pod != "DUMMY") ||
                (typeof idProof == "string" &&
                  idProof.length > 0 &&
                  idProof != "DUMMY")) && (
                <ButtonSimple onClick={toggleDelivery}>
                  Attachments
                </ButtonSimple>
              )}
          </Col>
          {/* <Col md={1}>
            <Button
              disabled={!(remarkImage && remarkImage.length > 20)}
              onClick={toggleRemark}
            >
              Image
            </Button>
          </Col> */}
        </Row>
        <Row>
          <Col md={5}></Col>
          <Col md={7}>
            {remarkText && remarkText.length > 0 && remarkText != "RTEXT" ? (
              <div className="track_remark_text">{remarkText}</div>
            ) : null}
          </Col>
        </Row>
      </Paper>
    </div>
  );
};

const Status = RawStatus;

const statuses = [
  "Booked",
  "In transit",
  "Waiting for Delivery",
  "Delivered",
  "Review",
];

const Title = (props: any) => {
  let { status, num, entity, canceled, cancelPending } = props;
  const statusSplit = status.split("@");
  let result, procStatus;

  switch (statusSplit[0]) {
    case "loading": {
      result = "In transit";
      procStatus = "In transit";
      break;
    }
    case "unloading": {
      result = "In transit";
      procStatus = "In transit";
      break;
    }
    case "booking": {
      result = "Booked";
      procStatus = "Booked";
      break;
    }
    case "delivery": {
      result = "Delivered";
      procStatus = "Delivered";
      break;
    }
    case "cancel": {
      result = "Canceled";
      procStatus = "Canceled";
      break;
    }
    case "cancelPending": {
      result = "Waiting for cancellation";
      procStatus = "Waiting for cancellation";
      break;
    }
    case "review": {
      result = "Review";
      procStatus = "Review";
      break;
    }
    default: {
      result = "In transit";
      procStatus = "In transit";
    }
  }
  return (
    <Row className="trackhead">
      <Col md={1}>
        <h2 style={props.titleStyle}>{num}</h2>
      </Col>
      <Col md={3}>
        <h2 onClick={props.onClick} style={props.titleStyle}>
          {result}
          {entity ? <span> in {entity}</span> : null}
        </h2>
      </Col>
      <Col md={8}>
        <TrackBar
          canceled={canceled}
          cancelPending={cancelPending}
          statuses={statuses}
          active={procStatus}
        />
      </Col>
    </Row>
  );
};

const Statuses = (props: any) => {
  const [height, setHeight] = useState<any>(0);

  const toggle = () => {
    setHeight(height == 0 ? "auto" : 0);
  };

  // const { props } =
  //   { user } = props;
  let {
    num,
    statusArr,
    canceled,
    cancelPending,
    remarks,
    deliveryRemarks,
    recordsMap,
    allowedOrders,
  } = props;

  let lastStatus = statusArr[statusArr.length - 1];
  const statusSplit = lastStatus.split("@");

  let entity = statusSplit[1];

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{ width: "100%" }}
      >
        <Title
          canceled={canceled}
          cancelPending={cancelPending}
          status={lastStatus}
          num={num}
          entity={entity}
          onClick={toggle.bind}
          titleStyle={{ color: "#222" }}
        />
      </AccordionSummary>

      <AccordionDetails style={{ width: "100%", flexDirection: "column" }}>
        {Array.from(recordsMap).map(([, value]: any) => {
          const orderNum = value.filter((e: any) =>
            allowedOrders.includes(e.order)
          );
          return (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={0}>
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={6}
                    xs={6}
                    style={{ alignSelf: "center" }}
                  >
                    {value.length > 0 &&
                      value[0] &&
                      value[0].materialType &&
                      value[0].materialType.name}
                  </Grid>
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={6}
                    xs={6}
                    style={{ alignSelf: "center" }}
                  >
                    {value.length > 0 &&
                      value[0] &&
                      value[0].packingType &&
                      value[0].packingType.name}
                  </Grid>
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{ alignSelf: "center" }}
                  >
                    <i className="fas fa-weight-hanging weightIcon"></i>{" "}
                    {value.length > 0 && orderNum.length}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails
                style={{ width: "100%", flexDirection: "column" }}
              >
                <Grid container spacing={0}>
                  {value.length > 0 &&
                    value.map(
                      (i: any) =>
                        allowedOrders.includes(i.order) && (
                          <Grid
                            xl={1}
                            lg={1}
                            md={1}
                            sm={6}
                            xs={6}
                            style={{ alignSelf: "center" }}
                          >
                            <div className="inner_order">{i.order}</div>
                          </Grid>
                        )
                    )}
                </Grid>{" "}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <br />
        <div className="detailed_statuses">
          {statusArr.map((item: any, index: any) => {
            return (
              <Status
                key={index}
                log={item}
                // branches={branches}
              />
            );
          })}
          <br />
          {remarks && (
            <div className="row">
              <div className="col-md-2">
                <strong>Booking Remarks :</strong>
              </div>
              <div className="col-md-10">
                <strong>{remarks}</strong>
              </div>
            </div>
          )}

          <br />
          {deliveryRemarks && (
            <div className="row">
              <div className="col-md-2">
                <strong>Delivery Remarks :</strong>
              </div>
              <div className="col-md-10">
                <strong>{deliveryRemarks}</strong>
              </div>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const STracking = (props: any) => {
  const [docket, setDocket] = useState<any>("");
  const [deliveryRemarks, setDeliveryRemarks] = useState<any>("");
  const [isDelivered, setIsDelivered] = useState<any>("");
  const [uniqueLogs, setUniqueLogs] = useState<any>("");
  const [packageMap, setPackageMap] = useState<any>({});
  const [idImage, setIdImage] = useState<any>("");
  const [podImage, setPodImage] = useState<any>("");
  //   const [openIdImage, setOpenIdImage] = useState(false);
  //   const [openPodImage, setOpenPodImage] = useState(false);
  const [docketNumber, setDocketNumber] = useState<any>("");
  const [packages, setPackages] = useState<any>([]);
  const [openAction, setOpenAction] = useState<any>(null);
  const [reason, setReason] = useState("");
  const [packageStatus, setPackageStatus] = useState<any>(null);
  const [newDestOpen, setNewDestOpen] = useState(false);
  const [backToOriginOpen, setBackToOriginOpen] = useState(false);
  const [btoAmount, setbtoAmount] = useState<any>("");
  const [NDAmount, setNDAmount] = useState<any>("");
  const [selectedND, setSelectedND] = useState<any>("");
  const [newDestOptions, setNewDestOptions] = useState<any>([]);
  const [feedbackResponse, setFeedbackResponse] = useState<any>("");
  const [Image, setImage] = useState<any>("");
  const [trackingCode, setTrackingCode] = useState<string>("");
  const [imageData, setImageData] = useState<any>(null);
  const [trackType, setTrackType] = useState<string>("");
  const [doorDeliveryData, setDoorDeliveryData] = useState<any>({})
  const [openDoorDelDialog, setOpenDoorDelDialg] = useState(false)

  console.log(idImage, podImage);
  console.log(props);

  const handleOpen = (data: any) => {
    setImageData(data);
  };

  const handleClose = () => {
    setImageData(null);
  };

  useEffect(() => {
    if (
      (props.location &&
        props.location.pathname &&
        props.location.pathname.includes("strack")) ||
      (props.location &&
        props.location.pathname &&
        props.location.pathname.includes("ptrack"))
    ) {
      const myArr = props.location.pathname.split("/");
      console.log(myArr);
      setTrackType(myArr[myArr.length - 2]);
      setupData(props.params.trackingCode);
      setTrackingCode(props.params.trackingCode);
    }
    props.setCities()
    // getBranchList();
  }, []);

  console.log(setNewDestOptions);

  //   const getBranchList = async () => {
  //     const response = await filterBookBranches();
  //     setNewDestOptions([
  //       ...response.map((i: any) => ({
  //         label: i.branchName,
  //         value: i._id,
  //       })),
  //     ]);
  //   };

  const setupData = async (trackingCode: string) => {
    props.showLoader();
    // let payload = {
    //   docket: docketNumber,
    //   sub: props.sub,
    //   entity: props.entity,
    //   queryType: "tracking",
    // };

    fetchStrackDetails(trackingCode)
      .then(async (data) => {
        let {
          docket,
          packages,
          logImages,
          consigneeIdImage,
          podImage: delPOD,
          doorDeliveryDocket,
          receiverDoorDeliveryAddr
        } = data;

        let isDelivered = false;
        let resPackages = packages.map((p: any, i: any) => {
          isDelivered = isDelivered || p.status == "D";
          return {
            ...p,
            logs: p.logs.map((l: any, li: any) => ({
              ...l,
              remarkImage: logImages[i][li],
            })),
          };
        });
        const { uniqueLogs, packageMap } = processLogs(resPackages);

        let idImage, podImage;
        try {
          idImage =
            consigneeIdImage && consigneeIdImage.length > 0
              ? await getImageUrl(consigneeIdImage)
              : "";
          podImage =
            delPOD && delPOD.length > 0 ? await getImageUrl(delPOD) : "";
        } catch (err : any) {
          console.log("could not fetch images:--", err);
        }
        setPackages(packages.map((r: any) => ({ ...r, checked: false })));
        setDocket(docket);
        setDocketNumber(docket.docketNumber);
        setIsDelivered(isDelivered);
        setPackageMap(packageMap);
        setUniqueLogs(uniqueLogs);
        setIdImage(idImage);
        setPodImage(podImage);
        setDoorDeliveryData({...doorDeliveryDocket, ...doorDeliveryDocket.doorDelAddress, ...{
          placeId: doorDeliveryDocket?.doorDelAddress?.place?.placeId, 
          coordinate: doorDeliveryDocket?.doorDelAddress?.place?.coordinate,
          name: doorDeliveryDocket?.doorDelAddress?.place?.name,
          placeValue: doorDeliveryDocket?.doorDelAddress?.place?.placeValue,
          formatted_address: doorDeliveryDocket?.doorDelAddress?.place?.formatted_address,
          area: doorDeliveryDocket?.doorDelAddress?.place?.area,
          state: doorDeliveryDocket?.doorDelAddress?.place?.city?.state?.name,
          city: doorDeliveryDocket?.doorDelAddress?.place?.city?.name,
          deliveryCharge: doorDeliveryDocket.doorDelCharges,
          addressSuggestion: receiverDoorDeliveryAddr,
        }})
        docket && setDeliveryRemarks(docket.deliveryRemarks);
        getGuestUpdatedData(docket._id);

        props.hideLoader();
        showMessage("Succesfull", success, 2000);
      })
      .catch((err) => {
        props.hideLoader();
        showHttpError(err);
      });
  };
  const getGuestUpdatedData = async (docket: any) => {
    let feedBackPayload = {
      docketId: docket,
    };
    getGuestComplaint(feedBackPayload)
      .then((feedbackRes: any) => {
        setFeedbackResponse(feedbackRes);
      })
      .catch(() => setFeedbackResponse(""));
  };
  //   const trackClick = async (data: any) => {
  //     setupData(data);
  //   };

  const handleReset = () => {
    setDocket("");
    setIsDelivered("");
    setPackageMap({});
    setUniqueLogs("");
    // setIdImage("");
    // setPodImage("");
    setDeliveryRemarks("");
    setPackages([]);
    setPackageStatus(null);
    handleDialogClose();
    setFeedbackResponse("");
  };

  const downloadBuilty = async () => {
    try {
      const elem: any = document.getElementById("track_builty_download");

      if (typeof elem === null || elem === "undefined") {
      } else {
        elem.style.opacity = 1;

        let mm: any = $("#track_builty_download");

        if (typeof mm === null || mm === "undefined") {
        } else {
          let HTML_Width = mm.width();
          let HTML_Height = mm.height();

          html2canvas($("#track_builty_download")[0]).then(function(canvas) {
            let imgData = canvas.toDataURL("image/jpeg", 1);

            let pdf = new jsPDF("p", "pt", [
              HTML_Width + 400,
              HTML_Height + 100,
            ]);
            pdf.addImage(imgData, "JPG", 0, 0, HTML_Width, HTML_Height);

            pdf.save("Invoice.pdf");
            elem.style.opacity = 0;
          });
        }
      }
    } catch (err : any) {
      console.log("got error while printing:---", err);
      const elem: any = document.getElementById("track_builty_download");

      if (typeof elem === null || elem === "undefined") {
      } else {
        elem.style.opacity = 0;
      }
    }
  };

  //   const printBuilty = async () => {
  //     try {
  //       const elem: any = document.getElementById("track_builty_download");

  //       if (typeof elem === null || elem === "undefined") {
  //       } else {
  //         elem.style.opacity = 1;
  //         let style = `
  //                     table, th, td {
  //                         border: 1px solid black;
  //                         border-collapse: collapse;
  //                     }
  //                     th, td {
  //                         text-align: justify;
  //                         padding: 5px;
  //                     }`;
  //         let divContents = elem.innerHTML;
  //         var a: any = window.open("", "", "");
  //         a.document.write("<head><style>" + style + "</style>");
  //         a.document.write('<body onload="window.print()">');
  //         a.document.write(divContents);
  //         a.document.write("</body>");
  //         a.document.write("</head>");
  //         a.document.close();
  //         elem.style.opacity = 0;
  //       }
  //     } catch (err : any) {
  //       const elem: any = document.getElementById("track_builty_download");

  //       if (typeof elem === null || elem === "undefined") {
  //       } else {
  //         elem.style.opacity = 0;
  //         console.log("eror:--", err);
  //       }
  //     }
  //   };

  const handleMissingSubmit = async () => {
    props.showLoader();
    try {
      if(reason === ""){
        props.hideLoader();
        return showHttpError(`Remarks Should Not Be Empty !~`);
      }
      let payload: { [k: string]: any } = {
        packages: packages
          .filter((r: any) => r.checked === true)
          .map((r: any) => r._id),
        remarkType: "M",
        loginType: props.sub,
        branch: props.entity,
        remarkText: reason,
      };

      if (Image) {
        payload.remarkImage = Image;
      }

      await setMissingPackages(jsonToFormdata(payload));

      await setupData(trackingCode);
      handleDialogClose();
      setPackageStatus(null);
      setImage("");
      props.hideLoader();
    } catch (err : any) {
      props.hideLoader();
      showHttpError(err);
    }
  };

  const handleFoundSubmit = async () => {
    props.showLoader();
    try {
      const payload = {
        packages: packages
          .filter((r: any) => r.checked === true)
          .map((r: any) => r._id),
        sub: props.sub,
        entity: props.entity,
        remarkText: reason,
      };
      await setFoundPackages(payload);
      await setupData(trackingCode);
      handleDialogClose();
      setPackageStatus(null);
      props.hideLoader();
    } catch (err : any) {
      props.hideLoader();
      showHttpError(err);
    }
  };

  const handleDialogClose = () => {
    setOpenAction(null);
    setReason("");
  };

  let totalPackages = 0;

  let isAnySelected = false;
  packages.map((i: any) => {
    if (i.checked === true) {
      isAnySelected = isAnySelected || i.checked;
    }
  });

  !packageStatus &&
    packages.length > 0 &&
    isAnySelected &&
    packages.map((i: any) => {
      if (i.checked === true) {
        if (i.status === "M" || i.status === "MR") {
          setPackageStatus("M");
        } else if (i.status === "INIT") {
          setPackageStatus("INIT");
        } else {
          setPackageStatus("K");
        }
      }
    });

  packageStatus &&
    packages.length > 0 &&
    !isAnySelected &&
    setPackageStatus(null);

  let recordsMap = new Map();
  packages.map((d: any) => {
    if (recordsMap.has(`${d.materialType.name}${d.packingType.name}`)) {
      recordsMap.get(`${d.materialType.name}${d.packingType.name}`).push(d);
    } else {
      recordsMap.set(`${d.materialType.name}${d.packingType.name}`, [d]);
    }
  });

  const handleBTOClose = () => {
    setBackToOriginOpen(false);
    setbtoAmount("");
  };

  const handleNDClose = () => {
    setNewDestOpen(false);
    setNDAmount("");
    setSelectedND("");
  };

  const handleSubmitBTO = async () => {
    props.showLoader();
    try {
      const payload = {
        docket: docket._id,
        branch: props.entity,
        BTOCharge: btoAmount,
      };
      await sendBackToOrigin(payload);
      props.hideLoader();
      showMessage("Successfull !!", success);
      handleBTOClose();
      handleReset();
    } catch (err : any) {
      props.hideLoader();
      showHttpError(err);
    }
  };

  const handleSubmitND = async () => {
    props.showLoader();
    try {
      const payload = {
        docket: docket._id,
        branch: props.entity,
        NDCharge: NDAmount,
        newDestination: selectedND && selectedND.value,
      };
      await sendBackToNewDestination(payload);
      props.hideLoader();
      showMessage("Successfull !!", success);
      handleNDClose();
      handleReset();
    } catch (err : any) {
      props.hideLoader();
      showHttpError(err);
    }
  };

  const handleReceived = async () => {
    props.showLoader();
    try {
      const payload = {
        packages: packages
          .filter((r: any) => r.checked === true)
          .map((r: any) => r._id),
        sub: props.sub,
        entity: props.entity,
        remarkText: reason,
      };
      await setReceivedPackages(payload);
      await setupData(trackingCode);
      handleDialogClose();
      setPackageStatus(null);
      props.hideLoader();
    } catch (err : any) {
      props.hideLoader();
      showHttpError(err);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content="Welcome To TapTap" />
        <meta property="og:description" content="Docket Details" />
        <meta
          property="og:image"
          content={
            docket && docket.docketImages
              ? docket.docketImages[0]
              : docket && docket.podImage
              ? docket.podImage
              : docket && docket.verificationImages
              ? docket.verificationImages[0]
              : ""
          }
        />
        <meta property="og:url" content={props.location.pathname} />
      </Helmet>
      <Header /> <br />
      <br />
      <br />
      <br />
      <div>
        {docket && trackType === "strack" && (
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
              <ButtonPrimary onClick={downloadBuilty}>Download</ButtonPrimary>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6">
              <Complaint
                chat={feedbackResponse}
                getGuestUpdatedData={getGuestUpdatedData}
                docketID={docket._id}
                docketNumber={docket.docketNumber}
              />{" "}
            </div>
            <div>
            <DoorDeliveryDialog
                       open={openDoorDelDialog}
                       setOpen={setOpenDoorDelDialg}
          update={true}

                      docket={docket.docketNumber}
                      doorDeliveryPaymentMode={doorDeliveryData.paymentMode}
                      deliveryCharge={doorDeliveryData.doorDelCharges}
                      officeName={doorDeliveryData.officeName}
                      floor={doorDeliveryData.floor}
                      tower={doorDeliveryData.tower}
                      nearby_landmark={doorDeliveryData.nearby_landmark}
                      city={doorDeliveryData.city}
                      state={doorDeliveryData.state}
                      pincode={doorDeliveryData.pincode}
                      onChange={(what:any, val:any) => setDoorDeliveryData({...doorDeliveryData, [what]: val})}
                      locationType={doorDeliveryData.locationType}
                      paymentOptions={[
                        { value: "topay", label: "topay" },
                      ]}
                      placeValue={doorDeliveryData.placeValue}
                      placeId={doorDeliveryData.placeId}
                      name={doorDeliveryData.name}
                      coordinate={doorDeliveryData.coordinate}
                      formatted_address={doorDeliveryData.formatted_address}
                      area={doorDeliveryData.area}
                      addressSuggestion={doorDeliveryData.addressSuggestion}
                  >
                {parseInt(doorDeliveryData.doorDelCharges) > 0 && (
                          <ButtonDefault style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                            Door Delivery Charge {doorDeliveryData?.paymentMode?.value}{" "}
                            {doorDeliveryData.doorDelCharges > 0 && doorDeliveryData.doorDelCharges}
                          </ButtonDefault>
                        )}
                        {!(parseInt(doorDeliveryData.doorDelCharges) > 0) && (
                          <ButtonPrimary style={{ marginLeft: 25 }} onClick={() => setOpenDoorDelDialg(true)}>
                              Door Delivery Charge {doorDeliveryData?.paymentMode?.value}{" "}
                            {doorDeliveryData.doorDelCharges > 0 && doorDeliveryData.doorDelCharges}
                          </ButtonPrimary>
                        )}
                  </DoorDeliveryDialog>
            </div>
          </div>
        )}

        <br />

        {docket && (
          <Accordion expanded={false}>
            <AccordionSummary>
              <Grid container spacing={0}>
                <Grid
                  xl={2}
                  lg={2}
                  md={2}
                  sm={12}
                  xs={12}
                  style={{ alignSelf: "center" }}
                >
                  <h4 className="trackTitle center">Docket : {docketNumber}</h4>
                </Grid>
                <Grid
                  xl={1}
                  lg={1}
                  md={1}
                  sm={12}
                  xs={12}
                  style={{ alignSelf: "center", textAlign: "center" }}
                >
                  <i className="fas fa-weight-hanging weightIcon"></i>{" "}
                  {packages.length}
                </Grid>
                {docket &&
                  docket.originBranch &&
                  docket.originBranch.branchName && (
                    <Grid
                      xl={2}
                      lg={2}
                      md={2}
                      sm={12}
                      xs={12}
                      style={{ alignSelf: "center" }}
                    >
                      <h5 className="trackTitle center">
                        {" "}
                        From:{" "}
                        {docket &&
                          docket.originBranch &&
                          docket.originBranch.branchName}{" "}
                      </h5>
                    </Grid>
                  )}
                {docket && docket.destBranch && docket.destBranch.branchName && (
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{ alignSelf: "center" }}
                  >
                    <h5 className="trackTitle center">
                      To:{" "}
                      {docket &&
                        docket.destBranch &&
                        docket.destBranch.branchName}{" "}
                    </h5>
                  </Grid>
                )}

                {docket && docket.eWayBill && (
                  <Grid
                    xl={2}
                    lg={2}
                    md={2}
                    sm={12}
                    xs={12}
                    style={{ alignSelf: "center" }}
                  >
                    <h5 className="trackTitle center">
                      E-Way Bill : {docket && docket.eWayBill}
                    </h5>
                  </Grid>
                )}
                <Grid
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ alignSelf: "center" }}
                >
                  {isDelivered && (
                    <div className={trackStyles.deliveryWrap}>
                      <h2 style={{ color: "#222" }}>{totalPackages}</h2>

                      {docket.billImage && (
                        <div>
                          {" "}
                          {/\.pdf$/i.test(docket.billImage) ? (
                            <ToolTip title="Bill Image">
                              <PictureAsPdfIcon
                                color="secondary"
                                onClick={() => handleOpen(docket.billImage)}
                              />
                            </ToolTip>
                          ) : (
                            <ToolTip title="Bill Image">
                              <ImageIcon
                                color="primary"
                                onClick={() => handleOpen(docket.billImage)}
                              />
                            </ToolTip>
                          )}{" "}
                        </div>
                      )}

                      {docket.idImage && (
                        <div>
                          {" "}
                          {/\.pdf$/i.test(docket.idImage) ? (
                            <ToolTip title="id Image">
                              <PictureAsPdfIcon
                                color="secondary"
                                onClick={() => handleOpen(docket.idImage)}
                              />
                            </ToolTip>
                          ) : (
                            <ToolTip title="id Image">
                              <ImageIcon
                                color="primary"
                                onClick={() => handleOpen(docket.idImage)}
                              />
                            </ToolTip>
                          )}{" "}
                        </div>
                      )}

                      {docket.docketImages && docket.docketImages[0] && (
                        <div>
                          {" "}
                          {/\.pdf$/i.test(docket.docketImages[0]) ? (
                            <ToolTip title="docket Images">
                              <PictureAsPdfIcon
                                color="secondary"
                                onClick={() =>
                                  handleOpen(docket.docketImages[0])
                                }
                              />
                            </ToolTip>
                          ) : (
                            <ToolTip title="docket Images">
                              <ImageIcon
                                color="primary"
                                onClick={() =>
                                  handleOpen(docket.docketImages[0])
                                }
                              />
                            </ToolTip>
                          )}{" "}
                        </div>
                      )}

                      {docket.podImage && (
                        <div>
                          {" "}
                          {/\.pdf$/i.test(docket.podImage) ? (
                            <ToolTip title="pod Image">
                              <PictureAsPdfIcon
                                color="secondary"
                                onClick={() => handleOpen(docket.podImage)}
                              />
                            </ToolTip>
                          ) : (
                            <ToolTip title="pod Image">
                              <ImageIcon
                                color="primary"
                                onClick={() => handleOpen(docket.podImage)}
                              />
                            </ToolTip>
                          )}{" "}
                        </div>
                      )}

                      {docket.consigneeIdImage && (
                        <div>
                          {" "}
                          {/\.pdf$/i.test(docket.consigneeIdImage) ? (
                            <ToolTip title="consignee Id Image">
                              <PictureAsPdfIcon
                                color="secondary"
                                onClick={() =>
                                  handleOpen(docket.consigneeIdImage)
                                }
                              />
                            </ToolTip>
                          ) : (
                            <ToolTip title="consignee Id Image">
                              <ImageIcon
                                color="primary"
                                onClick={() =>
                                  handleOpen(docket.consigneeIdImage)
                                }
                              />
                            </ToolTip>
                          )}{" "}
                        </div>
                      )}

                      {docket.transactionImage && (
                        <div>
                          {" "}
                          {/\.pdf$/i.test(docket.transactionImage) ? (
                            <ToolTip title="transaction Image">
                              <PictureAsPdfIcon
                                color="secondary"
                                onClick={() =>
                                  handleOpen(docket.transactionImage)
                                }
                              />
                            </ToolTip>
                          ) : (
                            <ToolTip title="transaction Image">
                              <ImageIcon
                                color="primary"
                                onClick={() =>
                                  handleOpen(docket.transactionImage)
                                }
                              />
                            </ToolTip>
                          )}{" "}
                        </div>
                      )}

                      {docket.verificationImages &&
                        docket.verificationImages[0] && (
                          <div>
                            {" "}
                            {/\.pdf$/i.test(docket.verificationImages[0]) ? (
                              <ToolTip title="verification images">
                                <PictureAsPdfIcon
                                  color="secondary"
                                  onClick={() =>
                                    handleOpen(docket.verificationImages[0])
                                  }
                                />
                              </ToolTip>
                            ) : (
                              <ToolTip title="verification images">
                                <ImageIcon
                                  color="primary"
                                  onClick={() =>
                                    handleOpen(docket.verificationImages[0])
                                  }
                                />
                              </ToolTip>
                            )}{" "}
                          </div>
                        )}

                      {/* <div onClick={() => setOpenPodImage(true)}>
                       {podImage ? (
                         <img
                           src={verifyFilled}
                           className={trackStyles.image_icon}
                         />
                       ) : (
                         <img
                           src={verify}
                           className={trackStyles.image_icon}
                         />
                       )}
                     </div>
                     <Dialog
                       open={openPodImage}
                       onClose={() => setOpenPodImage(false)}
                     >
                       <div className={trackStyles.podImageWrap}>
                         <img
                           src={podImage}
                           className={trackStyles.podImage}
                         />
                       </div>
                     </Dialog>

                     <div onClick={() => setOpenIdImage(true)}>
                       <i
                         style={{
                           color: idImage ? "#2126ff" : "#999",
                           fontSize: "25px",
                           marginTop: "2px",
                         }}
                         className={`fas fa-id-card ${trackStyles.podImage}`}
                       ></i>
                     </div>
                     <Dialog
                       open={openIdImage}
                       onClose={() => setOpenIdImage(false)}
                     >
                       <div className={trackStyles.podImageWrap}>
                         <img src={idImage} className={trackStyles.podImage} />
                       </div>
                     </Dialog> */}
                      {docket && docket.deliveredTo && (
                        <div style={{ fontSize: "20px", marginLeft: "20px" }}>
                          <strong>Delivered to:</strong> {docket.deliveredTo}
                        </div>
                      )}
                    </div>
                  )}
                </Grid>
                {/* <Grid
                  xl={1}
                  lg={1}
                  md={1}
                  sm={6}
                  xs={6}
                  style={{ alignSelf: "center" }}
                >
                  {props.appRights &&
                    props.appRights.operation &&
                    props.appRights.operation.booking &&
                    props.appRights.operation.booking.viewAll &&
                    docket &&
                    ((docket.destBranch &&
                      docket.destBranch._id === props.entity) ||
                      (docket.originBranch &&
                        docket.originBranch._id === props.entity)) && (
                      <Button
                        onClick={downloadBuilty}
                        variant="contained"
                        color="primary"
                      >
                        Download
                      </Button>
                    )}
                </Grid> */}
                {/* <Grid
                  xl={1}
                  lg={1}
                  md={1}
                  sm={6}
                  xs={6}
                  style={{ alignSelf: "center", textAlign: "right" }}
                >
                  {props.appRights &&
                    props.appRights.operation &&
                    props.appRights.operation.booking &&
                    props.appRights.operation.booking.viewAll &&
                    docket &&
                    ((docket.destBranch &&
                      docket.destBranch._id === props.entity) ||
                      (docket.originBranch &&
                        docket.originBranch._id === props.entity)) && (
                      <Button
                        onClick={printBuilty}
                        variant="contained"
                        color="primary"
                      >
                        Print
                      </Button>
                    )}
                </Grid> */}
              </Grid>
            </AccordionSummary>
            <AccordionDetails
              style={{ width: "100%", flexDirection: "column" }}
            ></AccordionDetails>
          </Accordion>
        )}

        {Object.keys(uniqueLogs).map((key, index) => {
          let item = uniqueLogs[key];
          let arr = item.split(";"),
            num = packageMap[key] ? packageMap[key].length : 0;
          totalPackages += num;
          arr.splice(arr.length - 1, 1);
          return (
            <Statuses
              canceled={!docket.status}
              cancelPending={docket && docket.cancelRequested}
              key={index}
              // branches={user.allbranches}
              statusArr={arr}
              allowedOrders={
                packageMap[key] && packageMap[key].length > 0
                  ? packageMap[key]
                  : []
              }
              packageMap={packageMap}
              uniqueLogs={uniqueLogs}
              num={num}
              remarks={docket.remarks}
              deliveryRemarks={deliveryRemarks}
              recordsMap={recordsMap}
            />
          );
        })}
        {/**
         * @deprecated
         * check the effect of below removed code
         */}
        {/* <div className="display_none">
          {docket && (
            <Builty
              address={{
                l1: address.l1,
                l2: address.l2,
                pin: address.pincode,
                city: address.city ? address.city.name : "",
              }}
              senderName={docket.senderName}
              receiverName={docket.receiverName}
            />
          )}
        </div> */}

        {docket && (
          <RatingReview
            isDelivered={isDelivered}
            docketNumber={docket.docketNumber}
            chat={feedbackResponse}
            docketID={docket._id}
            getGuestUpdatedData={getGuestUpdatedData}
          />
        )}

        {docket && (
          <GuestReply
            chat={feedbackResponse}
            docketID={docket._id}
            getGuestUpdatedData={getGuestUpdatedData}
          />
        )}
        {docket && (
          <div
            id="track_builty_download"
            style={{ width: "auto", height: "auto", opacity: 0 }}
          >
            <BuiltyPrint
              docket={docket}
              isDocket={docketNumber.includes("-") ? false : true}
            />
          </div>
        )}
      </div>
      {isAnySelected && (
        <div className="fixed">
          {packageStatus === "M" && (
            <ButtonSecondary onClick={() => setOpenAction("F")}>
              Found
            </ButtonSecondary>
          )}

          {packageStatus === "K" && (
            <ButtonSecondary onClick={() => setOpenAction("M")}>
              Missing
            </ButtonSecondary>
          )}

          {packageStatus === "INIT" && (
            <ButtonSecondary onClick={() => setOpenAction("INIT")}>
              Received
            </ButtonSecondary>
          )}
        </div>
      )}
      <Dialog
        open={Boolean(openAction)}
        onClose={() => handleDialogClose()}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          Image :
          <ImageInput
            name="Upload Image"
            Icon={(props: any) => (
              <i {...props} className={"fas fa-id-card file_icon"} />
            )}
            onChange={setImage}
            value={Image}
            extras={<div></div>}
          />
          <br />
          <Input12
            label="Remarks"
            value={reason}
            onChange={(e: any) => setReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <ButtonPrimary
            onClick={() =>
              openAction === "M"
                ? handleMissingSubmit()
                : openAction === "F"
                ? handleFoundSubmit()
                : openAction === "INIT"
                ? handleReceived()
                : {}
            }
          >
            Submit
          </ButtonPrimary>
          <ButtonDefault onClick={() => handleDialogClose()}>
            Cancel
          </ButtonDefault>
        </DialogActions>
      </Dialog>
      <Dialog
        open={backToOriginOpen}
        onClose={() => handleBTOClose()}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <Input12
            label="Back to origin charges"
            value={btoAmount}
            onChange={(e: any) => setbtoAmount(e.target.value)}
            helperText={`Back to origin charges must be greater than ${docket.gross -
              1}`}
          />
        </DialogContent>
        <DialogActions>
          <ButtonPrimary
            onClick={() => handleSubmitBTO()}
            disabled={btoAmount > docket.gross - 1 ? false : true}
          >
            Submit
          </ButtonPrimary>
          <ButtonDefault onClick={() => handleBTOClose()}>Cancel</ButtonDefault>
        </DialogActions>
      </Dialog>
      <Dialog
        open={newDestOpen}
        onClose={() => handleNDClose()}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <Select12
            options={newDestOptions}
            value={selectedND}
            onChange={(e: any) => setSelectedND(e)}
          />
          <br />
          <Input12
            label="New Destination Charges"
            value={NDAmount}
            onChange={(e: any) => setNDAmount(e.target.value)}
            placeholder={`${docket.gross}`}
            style={{ zIndex: 0 }}
          />
          <br />
        </DialogContent>
        <DialogActions>
          <ButtonPrimary onClick={() => handleSubmitND()}>Submit</ButtonPrimary>
          <ButtonDefault onClick={() => handleNDClose()}>Cancel</ButtonDefault>
        </DialogActions>
      </Dialog>
      <Dialog open={Boolean(imageData)} onClose={handleClose}>
        <DialogContent>
          <MyImage objectID={imageData} />
        </DialogContent>
        <DialogActions>
          <ButtonDefault onClick={handleClose}>Close</ButtonDefault>
        </DialogActions>
      </Dialog>
      <br />
      <Footer />
    </Fragment>
  );
};

const mapStateToProps = (state: RootState) => ({
  sub: state.user.loginType,
  entity:
    state.user.loginType === "B"
      ? state.user.opBranch._id
      : state.user.opFleet._id,
  appRights: state.appRights,
  assignedBranches: state.user.branches,
  assignedFleets: state.user.fleets,
});
const mapDispatchToProps = {
  showLoader,
  hideLoader,
  setCities
};

export default 
withRouter(
  connect(mapStateToProps, mapDispatchToProps)(STracking)
);
