import React from "react";
// import { Grid } from "@mui/material";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Grid from "../Grid/Grid";
import "./Selects.css";

const containerlg = 6;
const containermd = 6;
const containersm = 12;
const containerxs = 12;
const labellg = 5;
const labelmd = 5;
const labelsm = 5;
const labelxs = 5;
const selectlg = 7;
const selectmd = 7;
const selectsm = 7;
const selectxs = 7;

interface Ipropscomman {
  value: any;
  placeholder?:any;
  options: any;
  onChange: any;
  isDisabled?: any;
  isMulti?: any;
}

interface Ipropsselect {
  value?: any;
  options?: any;
  onChange?: any;
  label?: any;
  placeholder?: any;
  isMulti?: any;
  isDisabled?: any;
}

export function Selectcomponents(props: Ipropsselect) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>{props.label} :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          placeholder={props.placeholder}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
          isMulti={props.isMulti ? props.isMulti : false}
          isDisabled={props.isDisabled ? props.isDisabled : false}
        />
      </Grid>
    </Grid>
  );
}

interface Ipropsuser {
  user: any;
  optionsuser: any;
  onChangeuser: any;
  isDisabled?: any;
  isMulti?: any;
  onChangeVerifiedBy?: any;
  placeHolder?: any;
}

interface IpropsverifiedBy {
  user: any;
  optionsuser: any;
  isDisabled?: any;
  isMulti?: any;
  onChangeVerifiedBy?: any;
}

export function Selectuser(props: Ipropsuser) {
  return (
    <Grid
      container
      alignItems="center"
      md={containermd}
      lg={containerlg}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>{props.placeHolder ? props.placeHolder : "User:"}</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.user}
          isMulti={props.isMulti ? props.isMulti : false}
          // components={makeAnimated()}
          placeholder={props.placeHolder ? props.placeHolder : "Select User"}
          options={props.optionsuser}
          onChange={props.onChangeuser}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function SelectVerifiedBy(props: IpropsverifiedBy) {
  return (
    <Grid
      container
      alignItems="center"
      md={containermd}
      lg={containerlg}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Verified By :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.user}
          isMulti={props.isMulti ? props.isMulti : false}
          // components={makeAnimated()}
          placeholder="Select Verified By User"
          options={props.optionsuser}
          onChange={props.onChangeVerifiedBy}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function SelectCustomer(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      md={containermd}
      lg={containerlg}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Customer :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          isMulti={props.isMulti ? props.isMulti : false}
          // components={makeAnimated()}
          placeholder="Select Customer"
          options={props.options}
          onChange={props.onChange}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}
export function Selectdriver(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      md={containermd}
      lg={containerlg}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Driver :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          placeholder="Select Driver"
          options={props.options}
          onChange={props.onChange}
          className="heighten"
          isDisabled={props.isDisabled ? props.isDisabled : false}
        />
      </Grid>
    </Grid>
  );
}

interface Ipropspayment {
  paymentMode: any;
  onChangePayment: any;
  paymentModes: any;
  isMulti?: any;
}
export function Selectpayment(props: Ipropspayment) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      xs={containerxs}
      sm={containersm}
      md={containermd}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>payment :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.paymentMode}
          isMulti={props.isMulti ? props.isMulti : false}
          placeholder="Payment Mode"
          options={props.paymentModes}
          components={makeAnimated()}
          onChange={props.onChangePayment}
        />
      </Grid>
    </Grid>
  );
}

interface Ipropscompany {
  companyId: any;
  companyList: any;
  onChangecompany: any;
  isDisabled?: any;
}
export function Selectcompany(props: Ipropscompany) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Company :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.companyId}
          placeholder="Company"
          options={props.companyList}
          onChange={props.onChangecompany}
          className="heighten"
          isDisabled={props.isDisabled ? props.isDisabled : false}
        />
      </Grid>
    </Grid>
  );
}
interface Ipropsrootcompany {
  companyId: any;
  companyList: any;
  onChangecompany: any;
}
export function Selectroutecompany(props: Ipropsrootcompany) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Route Company :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.companyId}
          placeholder="Route Company"
          options={props.companyList}
          onChange={props.onChangecompany}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

interface Ipropsbranchcompany {
  companyId: any;
  companyList: any;
  onChangecompany: any;
}
export function Selectbranchcompany(props: Ipropsbranchcompany) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Branch Company :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.companyId}
          placeholder="Branch Company"
          options={props.companyList}
          onChange={props.onChangecompany}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

interface Ipropsfleet {
  fleet: any;
  optionsfleet: any;
  onChangefleet: any;
}
export function Selectfleet(props: Ipropsfleet) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Fleet number :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.fleet}
          placeholder="Select Fleet"
          options={props.optionsfleet}
          onChange={props.onChangefleet}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

interface Ipropsroute {
  route: any;
  optionsroute: any;
  onChangeroute: any;
}
export function Selectroute(props: Ipropsroute) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Route :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Route"
          value={props.route}
          options={props.optionsroute}
          onChange={props.onChangeroute}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

interface Ipropsbranch {
  branch: any;
  onChangebranch: any;
  optionsbranch: any;
  isDisabled?: any;
  isMulti?: any;
}
export function Selectbranch(props: Ipropsbranch) {
  return (
    <Grid
      container
      lg={containerlg}
      md={containermd}
      sm={containersm}
      xs={containerxs}
      alignItems="center"
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Branch :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.branch}
          placeholder="Select Branch"
          // components={makeAnimated()}
          options={props.optionsbranch}
          onChange={props.onChangebranch}
          isDisabled={props.isDisabled ? props.isDisabled : false}
          isMulti={props.isMulti ? props.isMulti : false}
        />
      </Grid>
    </Grid>
  );
}

interface Ipropscity {
  value: any;
  onChange: any;
  options: any;
  isDisabled?: any;
}
export function Selectcity(props: Ipropscity) {
  return (
    <Grid
      container
      lg={containerlg}
      md={containermd}
      sm={containersm}
      xs={containerxs}
      alignItems="center"
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>City :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          placeholder="Select City"
          // components={makeAnimated()}
          options={props.options}
          onChange={props.onChange}
          isDisabled={props.isDisabled ? props.isDisabled : false}
        />
      </Grid>
    </Grid>
  );
}

interface Ipropsexpensetype {
  expense: any;
  onChangetype: any;
  optionstypes: any;
}
export function Selectexpensetype(props: Ipropsexpensetype) {
  return (
    <Grid
      container
      lg={containerlg}
      md={containermd}
      sm={containersm}
      xs={containerxs}
      alignItems="center"
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Expense Type:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.expense}
          placeholder="Expense Type"
          // components={makeAnimated()}
          options={props.optionstypes}
          onChange={props.onChangetype}
        />
      </Grid>
    </Grid>
  );
}

interface Ipropsincometype {
  type: any;
  onChangetype: any;
  optionstypes: any;
}
export function Selectincometype(props: Ipropsincometype) {
  return (
    <Grid
      container
      lg={containerlg}
      md={containermd}
      sm={containersm}
      xs={containerxs}
      alignItems="center"
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Income Type:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.type}
          placeholder="Income Type"
          // components={makeAnimated()}
          options={props.optionstypes}
          onChange={props.onChangetype}
        />
      </Grid>
    </Grid>
  );
}

export function SelectVerifiedtype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Verified:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function SelectDisallowedtype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Disallowed :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectcomplaintstatus(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Complaint status :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder={props.placeholder}
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
          isMulti={props.isMulti ? props.isMulti : false}
        />
      </Grid>
    </Grid>
  );
}

export function Selectservicetype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Service Type :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder={props.placeholder}
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectfeedbacktype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Feedback Type :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Feedback Type"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectcomplainttype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Complaint Type :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectnewold(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label> Select Range :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectpackagestatus(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Package status:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Status"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function SelectdateRange(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label> Date Range:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select DateRange"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}
export function Selectsortingbyname(props: Ipropscomman) {
  return (
    <Grid
      alignItems="center"
      lg={2}
      xs={5}
      sm={5}
      md={2}
      style={{ marginTop: "10px" }}
    >
      <Select
        placeholder="Sorting"
        value={props.value}
        options={props.options}
        onChange={props.onChange}
        className="heighten"
      />
    </Grid>
  );
}

export function Selectsoldstatus(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label> Sold status:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Sold Status"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectcolor(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label> Color:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Color"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function SelectAttendencestatus(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Status:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select status"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}
export function SelectAttendencetype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Type:</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select status"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectstatus(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Status :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select status"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          isMulti={props.isMulti}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectrequesttype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Request Type :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select RequestType"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectpaymentstatus(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Status :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Status"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectstage(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Stage :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Status"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectdepartment(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Department :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Department"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selectincentive(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label>Incentive :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Incentive"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function Selecttype(props: Ipropscomman) {
  return (
    <Grid
      container
      alignItems="center"
      lg={containerlg}
      md={containermd}
      xs={containerxs}
      sm={containersm}
      style={{ marginBottom: "5px" }}
    >
      <Grid
        sm={labelsm}
        xs={labelxs}
        lg={labellg}
        md={labelmd}
        className="Selects_label"
      >
        <label> Type :</label>
      </Grid>
      <Grid sm={selectsm} xs={selectxs} lg={selectlg} md={selectmd}>
        <Select
          placeholder="Select Type"
          value={props.value}
          options={props.options}
          onChange={props.onChange}
          className="heighten"
        />
      </Grid>
    </Grid>
  );
}

export function SelectSetteled(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Settled"
      placeholder="Select Settled"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}

export function SelectCreditor(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Creditor"
      placeholder="Select Creditor"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
export function SelectRemarks(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Remarks"
      placeholder="Select Remarks"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
export function SelectPriority(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Priority"
      placeholder="Select Priority"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
export function SelectResolved(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Resolved"
      placeholder="Select Resolved"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
export function SelectVendor(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Vendor"
      placeholder="Select Vendor"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
export function SelectSub(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Sub"
      placeholder="Select Sub"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
export function SelectAgent(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Agent"
      placeholder="Select Agent"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
    />
  );
}
export function SelectOriginBranch(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Origin Branch"
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      isDisabled ={props.isDisabled}
    />
  );
}
export function SelectDestBranch(props: Ipropscomman) {
  return (
    <Selectcomponents
      label="Destination Branch"
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      isDisabled ={props.isDisabled}
    />
  );
}