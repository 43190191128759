import { Accordion, AccordionSummary } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ButtonPrimary } from "./Button/Buttoncontainer";
import { getSingleImageUrl } from "../services/imageLink";
import ImageInput from "./ImageInput";
import { showHttpError } from "../utils/message";
import jsonToFormdata from "../utils/jsonToFormdata";
import CheckIcon from "@mui/icons-material/Check";
import Grid from "./Grid/Grid";

interface IProps {
  packages: any;
  showLoader: () => void;
  hideLoader: () => void;
  reqType: "BTO" | "ND" | "BTH" | "DR";
  addImagedPackage: any;
}

const ImageInputPackgeWise: React.FC<IProps> = ({
  packages,
  reqType,
  showLoader,
  hideLoader,
  addImagedPackage,
}) => {
  const [packagesData, setPackagesData] = useState<any>([]);
  const [packageImages, setPackageImages] = useState<any>([]);

  useEffect(() => {
    console.log("useeffect called");
    setPackagesData(packages);
  }, []);

  useEffect(() => {
    let newArr: any = [];
    packagesData.map((r: any, p1: number) =>
      packageImages.map((ele: any, p2: number) =>
        p1 === p2
          ? newArr.push({ ...r, packageImage: ele, verified: false })
          : newArr.push({ ...r })
      )
    );
    console.log("newArr", newArr);
    packageImages.length > 0 && setPackagesData(newArr);
  }, [packageImages]);

  const handleSave = async (ele: any, i: number) => {
    showLoader();
    try {
      const payload = {
        reqType: reqType,
        docketImages: ele.packageImage,
      };
      const response = await getSingleImageUrl(jsonToFormdata(payload));

      const Verifieddata = [...packagesData];
      const x = { ...Verifieddata[i] };
      x.verified = true;
      Verifieddata[i] = x;
      setPackagesData(Verifieddata);

      const data: { [k: string]: any } = {
        packageId: ele._id,
        order: ele.order,
        image: response,
      };
      if (reqType === "BTH") {
        data.docketId = ele.docketId;
      }
      addImagedPackage(data);
      console.log(data);
      hideLoader();
    } catch (err : any) {
      showHttpError(err);
      hideLoader();
    }
  };

  const handleuploadImage = (e: any, i: number) => {
    const data = [...packageImages];
    data[i] = e;
    setPackageImages(data);
  };

  console.log("packagesData", packagesData);

  return (
    <div>
      {packagesData.map((ele: any, i: number) => (
        <Accordion expanded={false}>
          <AccordionSummary>
            <Grid container spacing={0}>
              <Grid xs={8} md={4} item>
                {ele.order}
              </Grid>
              <Grid xs={4} md={4} item>
                <ImageInput
                  name={`Package Image ${i + 1}`}
                  Icon={(props: any) => (
                    <i {...props} className="fas fa-id-card file_icon"></i>
                  )}
                  onChange={(e: any) => handleuploadImage(e, i)}
                  value={ele.packageImage}
                  acceptPdf={false}
                />
              </Grid>
              <Grid xs={8} md={2} item>
                <ButtonPrimary
                  onClick={() => handleSave(ele, i)}
                  disabled={!ele.packageImage}
                >
                  Save
                </ButtonPrimary>
              </Grid>
              <Grid xs={4} md={2} item>
                {ele.verified && <CheckIcon style={{ color: "green" }} />}
              </Grid>
            </Grid>
          </AccordionSummary>
        </Accordion>
      ))}
    </div>
  );
};

export default ImageInputPackgeWise;
