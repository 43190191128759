import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getRemarks } from "../../../services/report";
import { ThemeProvider,StyledEngineProvider } from "@mui/material/styles";
import Dockets from "./Dockets";
import { showLoader, hideLoader } from "../../../actions/UserActions";
import { theme } from "../../../static/js/muiTheme";
import { RootState } from "../../../store";
import FilterGridcontainer from "../../../Components/filtercomponents/FilterGridcontainer";
import Filtercontainer from "../../../Components/filtercomponents/Filtercontainer";
import {
  SelectPriority,
  SelectRemarks,
  SelectResolved,
} from "../../../Components/Selectcomponents/Selects";
import {
  FiltervalueContainer,
  FiltervaluePriority,
  FiltervalueRemarks,
  FiltervalueResolve,
} from "../../../Components/filtercomponents/Filtervalue";
import { RefreshButton } from "../../../Components/Button/AllButton";
import {
  TableheaderDocketNumber,
  TableheaderPriority,
  TableheaderResolved,
  Tableheadertracking,
} from "../../../Components/Table/Tableheader";
import TableHeaderContainer from "../../../Components/Table/TableHeaderContainer";
import Grid from "../../../Components/Grid/Grid";
import PageTitle from "../../../Components/Title/PageTitle";


const remarksArr = [
  { label: "Missing", value: "M" },
  { label: "Damaged", value: "D" },
  { label: "Pilferage", value: "P" },
];
const priorityArr = [
  { label: "High", value: "high" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
];
const reolveArr = [
  { label: "Resolved", value: true },
  { label: "Unresolved", value: false },
];

interface IProps {
  company: any;
  showLoader: any;
  hideLoader: any;
  user: any;
}

const Remarks: React.FC<IProps> = (props) => {
  const [remarks, setRemarks] = useState<any>([]);
  const [remarkFilter, setRemarkFilter] = useState<any>(null);
  const [priorityFilter, setPriorityFilter] = useState<any>(null);
  const [resolveFilter, setResolveFilter] = useState<any>(null);

  // state = {
  //   remarks: [],
  //   remarkFilter: null,
  //   priorityFilter: null,
  //   resolveFilter: null,
  // };

  useEffect(() => {
    getRemarkRecords();
  }, []);

  const getRemarkRecords = async () => {
    try {
      props.showLoader();
      const data = await getRemarks(props.company);
      let docketMap: any = {};
      data.forEach((d: any) => {
        let docketNumber = d.docket.docketNumber;
        docketMap[docketNumber] = d;
      });
      setRemarks(docketMap);
      props.hideLoader();
    } catch (err : any) {
      console.log(err);

      props.hideLoader();
    }
  };
  const reset = () => {
    setRemarkFilter(null);
    setPriorityFilter(null);
    setResolveFilter(null);
  };

  const { user } = props;
  const [showModel, setshowModel] = useState(false);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <PageTitle title="Remarks" />
        <FiltervalueContainer>
          {remarkFilter && remarkFilter.value ? (
            <FiltervalueRemarks value={remarkFilter.label} />
          ) : (
            <FiltervalueRemarks value="All" />
          )}

          {priorityFilter && priorityFilter.value ? (
            <FiltervaluePriority value={priorityFilter.label} />
          ) : (
            <FiltervaluePriority value="All" />
          )}

          {resolveFilter && resolveFilter.value ? (
            <FiltervalueResolve value={resolveFilter.label} />
          ) : (
            <FiltervalueResolve value="All" />
          )}
        </FiltervalueContainer>

        <Grid container lg={12} sm={12} md={12} xs={12}>
          <FilterGridcontainer>
            <Filtercontainer
              ismodelOpen={showModel}
              showModal={() => setshowModel(true)}
              closeModal={() => setshowModel(false)}
            >
              <SelectRemarks
                options={remarksArr}
                value={remarkFilter}
                onChange={(e: any) => setRemarkFilter(e)}
              />
              <SelectPriority
                options={priorityArr}
                value={priorityFilter}
                onChange={(e: any) => setPriorityFilter(e)}
              />
              <SelectResolved
                options={reolveArr}
                value={resolveFilter}
                onChange={(e: any) => setResolveFilter(e)}
              />
            </Filtercontainer>
          </FilterGridcontainer>
          <RefreshButton
            onClick={() => {
              reset();
            }}
          />
        </Grid>

        <TableHeaderContainer>
          <TableheaderDocketNumber />
          <Grid lg={5} md={5}></Grid>
          <TableheaderPriority />

          <TableheaderResolved />
          <Grid lg={2} md={2}></Grid>
          <Tableheadertracking />
        </TableHeaderContainer>
        <div>
          <Dockets
            priorityArr={priorityArr}
            reolveArr={reolveArr}
            showLoader={showLoader}
            hideLoader={hideLoader}
            role={user.role}
            getRemarkRecords={getRemarkRecords}
            // remarks={remarks}
            remarkFilter={remarkFilter}
            priorityFilter={priorityFilter}
            resolveFilter={resolveFilter}
            dockets={remarks}
          />
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const mapStateWithProps = (state: RootState) => {
  return {
    company: state.user.company.id,
    user: state.user,
  };
};
const mapDispatchToProps = {
  showLoader,
  hideLoader,
};
export default connect(mapStateWithProps, mapDispatchToProps)(Remarks);
