import { ADD_SUBTASK, REMOVE_SUBTASK, RESET_TASK_MODULE, SET_TASK_MODULE } from "../constants/task";

const initState:any = {
  title : "",
  description : "",
  dueDate : new Date(),
  estimatedHour : 0,
  estimatedMin : 0,
  estimatedOutput : null,
  user : null,
  status : "",
  _id : "",
  isFromTaskList : 0,
  subTasks : [{
    id: 0,
    title : "",
    description : "",
    estimatedHour : 0,
    estimatedMin : 0,
    estimatedCost : 0,
    estimatedOutput : null,
    actualHours : 0,
    actualMin : 0,
    actualCost : 0,
    actualOutput : null,
    user : null,
    iSubmited : false,
    _id : "",
    actionType : "",
    lastStarted : ""
  }]
}

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case SET_TASK_MODULE: {
          if(action.type !== "ADD_SUBTASK"){
            var {what, val} = action.payload
          }
          else{
            var Payload = action.payload 
          }
        return {
          ...state,
          [what]: val,
        };
      }
      case ADD_SUBTASK: {
        return {
          ...state,
          Payload,
        };
      }
      case REMOVE_SUBTASK: {
        return {
          ...state,
        };
      }
      case RESET_TASK_MODULE: {
        return {
          ...initState,
          subTasks : [{
            id: 0,
            title : "",
            description : "",
            estimatedHour : 0,
            estimatedMin : 0,
            estimatedCost : 0,
            estimatedOutput : null,
            actualHours : 0,
            actualMin : 0,
            actualCost : 0,
            actualOutput : null,
            user : null,
            iSubmited : false,
            _id : "",
            actionType : "",
            lastStarted : ""
          }]
        };
      }
      default:
        return state;
    }
};
  