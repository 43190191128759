import React from "react";
import classNames from "classnames";
import "../operations/loadutils/loading.css";
import ToolTip from "../../Components/ToolTip/ToolTip";

const IconLike = (props: any) => {
  const { mobileView, color, txt, title } = props;
  return (
    <ToolTip title={title}>
      <div
        style={{ border: `1px solid ${color}` }}
        className={
          txt === "BTH" || txt === "ND" || txt === "BTO"
            ? classNames("icon_like_sp")
            : classNames("icon_like", { icon_like_mob: mobileView == true })
        }
      >
        <strong
          style={{ color: color }}
          className={classNames("icon_text", {
            icon_text_mob: mobileView == true,
          })}
        >
          {txt}
        </strong>
      </div>
    </ToolTip>
  );
};
export default IconLike;
