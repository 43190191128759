import React, { Dispatch, SetStateAction } from "react";
// import { CapacityIcon, DimesionsIcon } from "../../../constants/icons";
import { setIntraCity } from "../../../../actions/customerBooking";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";

// type
export type IntraCityRateEstimateItemType = {
  assetSubType: {
    name: string;
    assetType: {
      assetName: string;
      _id: string;
      sideImage?: string;
      topImage?: string;
    }
  },
  availableFreeMinutes: number;
  perMinuteCharge: number;
  price: number;
};

type IntraCityRatesProps = {
  arr: IntraCityRateEstimateItemType[];
  selectedItem: number;
  setSelectedItem: Dispatch<SetStateAction<number>>;
  // onItemClick?: (item: IntraCityRateEstimateItemType) => void;
  setSelectedAssetType?:Dispatch<SetStateAction<IntraCityRateEstimateItemType>>;
  selectedAssetType?:IntraCityRateEstimateItemType;
};

const IntraCityRates = ({
  arr,
  selectedItem,
  setSelectedItem,
  // onItemClick,
  setSelectedAssetType
}: IntraCityRatesProps) => {
  const dispatch = useAppDispatch<any>();

  console.log("arr::", arr);

  return (
    <div className="grid gap-1">
      {arr &&
        Array.isArray(arr) &&
        arr.length > 0 &&
        arr.map((item, index: number) => {
          return (
            <div
              key={index}
              className={`intra-city-rate-estimated-item | paper fs-link grid gap-4 pointer ${
                selectedItem === index ? "active" : ""
              }`}
              onClick={() => {
                setSelectedItem(index);
                if(setSelectedAssetType) {
                  setSelectedAssetType(item)
                }
                dispatch(
                  setIntraCity("assetSubType", {
                    ...item?.assetSubType
                  })
                );
                dispatch(setIntraCity("gross", item.price));
              }}
            >
              <div className="flex-between flex-wrap">
                <span className="fw-600">
                  {item?.assetSubType?.assetType?.sideImage && <img src={item?.assetSubType?.assetType?.sideImage} alt="Image" style={{width: "100px"}} />} {item?.assetSubType?.name || "Vehicle"} | {item?.assetSubType?.assetType?.assetName || ""}
                </span>
                <strong className="px-1 py-4 oval bg-success-50 text-success-900 shadow-small fs-subheading2">
                  ₹{item.price}
                </strong>
              </div>
              {/* // ATTENTION: Max Capacity & Dimensions are not included in response */}
              {/* <div className="flex flex-wrap ai-center gap-8">
                {(item?.assetTypes?.maxCapacity || item.maxCapacity) && (
                  <p>
                    <CapacityIcon /> {item?.assetSubType.assetType?.maxCapacity || item.maxCapacity || 0}kg
                  </p>
                )}

                {item?.assetSubType.assetType?.dimensions && (
                  <p>
                    <DimesionsIcon /> {item?.assetSubType.assetType?.dimensions?.l}
                    <span>L</span>x{item?.assetSubType.assetType?.dimensions?.b}
                    <span>B</span>x
                    <span>{item?.assetSubType.assetType?.dimensions?.h}H</span>
                  </p>
                )}
              </div> */}
              {item.perMinuteCharge && (
                <p className="per-min-charge-text text-black-100">
                  First {item.availableFreeMinutes} min free, then{" "}
                  <span className="per-min-charge text-primary-400">
                    {item.perMinuteCharge}₹/min
                  </span>
                </p>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default IntraCityRates;
