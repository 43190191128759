import React from "react";
import { IconContext } from "react-icons";
import { FaCrosshairs } from "react-icons/fa";

const Icon = (props: any) => (
  <IconContext.Provider value={props.style}>
    <div
      className={props.className}
      onClick={() => {
        if (!props.disabled) props.onClick();
      }}
    >
      <FaCrosshairs />
    </div>
  </IconContext.Provider>
);

export default Icon;
