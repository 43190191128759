const ReactSelectTheme = (error: boolean) => (theme: any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#9ec4ff",
    primary: error == true ? "#ff2337" : "#2126ff",
  },
});

export default ReactSelectTheme;
