// react
import React, { useRef, useState } from "react";
// constants (data | images)
import { Heading } from "../../../../components/UI";
import { useLandingPageContext } from "../../../../hooks/useLandingPageContext";
import { howItWorks, howItWorksData } from "./data";

// FC: HowItWorks Component
const HowItWorks = () => {
  // State
  const [currentImage, setCurrentImage] = useState(0);
  const {
    activePage,
    activeService,
    activePartner,
    activeExpressParcelDeliveryType,
  } = useLandingPageContext();
  // Ref
  const howItWorksRef = useRef<HTMLElement | null>(null);
  // data
  const data =
    howItWorksData.find((howItWork) => {
      if(howItWork.service === "Express Parcel") {
        return howItWork.expressParcelType === activeExpressParcelDeliveryType;
      }
      if (howItWork.page === activePage) {
        if (howItWork.partner === activePartner) {
          return true;
        }
        if (howItWork.service === activeService) {
          return true;
        }
      }
      return false;
    })?.howItWorks || howItWorks;

  // does: scroll to top
  const scrollToTop = () => {
    if (howItWorksRef.current) {
      howItWorksRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // component return
  return (
    <main className="how-it-works | p-3 bg-white-800" ref={howItWorksRef}>
      <section className="| my-container flex-col gap-component">
        <Heading className="text-primary-400">How It Works?</Heading>
        <div className="how-it-works-container">
          <img
            loading="lazy"
            src={data[currentImage].img}
            alt="how-it-works"
            style={{ maxHeight: "650px" }}
          />
          <ul className="| flex-col-center gap-1">
            {data &&
              Array.isArray(data) &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <li
                    key={item.id}
                    className={`how-it-works-item | flex gap-1 bg-white-900 text-neutral-900 br-4 py-1 px-20 pointer shadow-small ${
                      index === currentImage ? "active" : ""
                    }`}
                    onClick={() => {
                      setCurrentImage(index);
                      scrollToTop();
                    }}
                  >
                    <span className="how-it-works-number | p-1 fs-list-item-heading fw-600 circle bg-white-600">
                      {index + 1}
                    </span>
                    <div className="| flex-col gap-8">
                      <span className="| fs-list-item-heading fw-600">
                        {item.heading}
                      </span>
                      <p
                        className="| m-0 fs-list-item-text fw-400"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </section>
    </main>
  );
};

export default HowItWorks;
