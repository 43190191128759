import { useEffect, useState } from "react";

/**
 * A custom React hook that provides the dimensions of the browser window.
 *
 * @returns An object containing the width and height of the window.
 *
 * @example
 * ```typescript
 * import React from 'react';
 * import { useWindow } from './useWindow';
 *
 * const MyComponent: React.FC = () => {
 *   const { width, height } = useWindow();
 *
 *   return (
 *     <div>
 *       <p>Window dimensions: {width}px x {height}px</p>
 *     </div>
 *   );
 * };
 * ```
 */
export function useWindow() {
    // Initialize state with the current window dimensions
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
  
    // Add an event listener for the 'resize' event
    useEffect(() => {
      const handleResize = () => {
        // Update the state with the new window dimensions
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
      };
  
      window.addEventListener("resize", handleResize);
  
      // Remove the event listener when the component is unmounted
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    // Return the current window dimensions
    return { width, height };
  }