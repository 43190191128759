import React, { useEffect, useState } from "react";
import { getCityList } from "../../../services/area";
import { listSourceBranches } from "../../../services/inquiry";
import Loader from "@mui/material/CircularProgress";
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import 'reactjs-popup/dist/index.css';
import HowItWorks from '../../../Components/How It Works/HowItWorks';
import FAQ from '../../../Components/FAQ/FAQ';
import Testimonials from "../../../Components/Tetimonials/Testimonials";
import OurFeatures from "../../../Components/Features/OurFeatures";
import { Dialog, DialogActions, DialogContent } from "../../../Components/Dialog/DialogComponent";
import { ButtonDefault } from "../../../Components/Button/Buttoncontainer";
import { ButtonPrimary } from '../../../Components/Button/Buttoncontainer';
import { Link, useNavigate } from "react-router-dom";
import { getOtp } from "../../../services/area";
import { connect } from 'react-redux';
// import { useHistory } from "react-router-dom";
import AutoComplete from "../../../Components/AutoComplete";
import { addIntraCityPickupDropLocation, removeIntraCityPickupDropLocation, setIntraCity, setIntraCityPickupDropLocation } from "../../../actions/customerBooking";
import { customerLogin } from "../../../actions/customerLogin";
import { showHttpError } from "../../../utils/message";
import { useMediaQuery, useTheme } from "@mui/material";
import { showLoader, hideLoader } from './../../../actions/UserActions';

const IntraCityForm = (props: any) => {

  console.log(`IntraCityForm OutSide Form`, props);

  const navigate = useNavigate();
  const [loading] = useState(false);
  const [result] = useState<any>([]);
  const [cities, setCities] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [contactNo, setContactNo] = useState(0);
  const [tabActive, setTabActive] = useState(0);
  const [tempoType, setTempoType] = useState("");
  const [tempoTypeList, setTempoTypeList] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [destinations, setDestinations] = useState<any>([])
  const [customerPassword, setCustomerPassword] = useState();
  const [customerMobileNo, setCustomerMobileNo] = useState();
  
  const theme = useTheme();
  const isMobile1 = useMediaQuery(theme.breakpoints.down('sm')); // Change the breakpoint as needed
  const isMobile2 = useMediaQuery(theme.breakpoints.down('xs')); // Change the breakpoint as needed
  const mobileView = isMobile1 || isMobile2


  const topFunction2 = (props: any) => {
    document.body.scrollTop = 0;
    {
      props === "book" ? document.documentElement.scrollTop = 0 : props === "feature" ? document.documentElement.scrollTop = 515 : props === "how" ? document.documentElement.scrollTop = 1040 :
        props === "test" ? document.documentElement.scrollTop = 1575 : props === "faq's" ? document.documentElement.scrollTop = 2100 : 0
    }
  }


  const defineTempoType = () => {
    const TempoTypesArr: any = [{ id: 1, name: "TATA ACE" }, { id: 2, name: "ASHOK LEYLAND DOST" }, { id: 3, name: "MAHINDRA BOLERO" }, { id: 4, name: "TATA 407" }]
    setTempoTypeList(TempoTypesArr)
  }


  const setUpData = async () => {
    props?.showLoader();
    try {
      const [cityList, resourcesBranch] = await Promise.all([
        getCityList(),
        listSourceBranches(),
      ]);
      let ans: any = [{ name: "Ahmedabad", _id: "5bb083d7296d79da33653fb6" }, { name: "Surat", _id: "5bb083d7296d79da33653f3e" }]
      console.log(`getCityList`, cityList, resourcesBranch);
      setCities(ans);
      props?.hideLoader();
    } catch (err : any) {
      props?.hideLoader();
      showHttpError(err);
    }
  };

  const handleAdd = () => {
    setDestinations([...destinations, ""])
  }

  const handleRemove = (i: any) => {
    const newDestinations = [...destinations]
    newDestinations.splice(i, 1)
    setDestinations(newDestinations)
  }

  const handleChangeDestinations = (e: any, i: any) => {
    let updated = destinations.map((xx: any, ii: any) => {
      if (ii === i) {
        return e.formatted_address;
      } else {
        return xx;
      }
    });
    setDestinations(updated)
  }

  const handelgetOtp = async () => {
    props?.showLoader();
    try {
      await getOtp(contactNo);
      props?.hideLoader();
    } catch (error) {
      props?.hideLoader();
      showHttpError(error);
    }
  };

  const intraCity = {
    "HowItWorks": [{ headings: ["Get quote", "Choose the vehicle", "Real-time tracking"] },
    {
      img: ["how-it-works-img1.png",
        "how-it-works-img2.png",
        "how-it-works-img3.png"]
    }],
    "Features": [
      {
        img: ["https://img.icons8.com/bubbles/80/000000/cash-in-hand.png",
          "https://img.icons8.com/bubbles/80/000000/security-checked.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/lock--v1.png",
          "https://img.icons8.com/bubbles/80/000000/conference-call.png"]
      },
      { headings: ["On time, every time", "Quality Matters", "Convenient Delivery", "Cash-on-delivery", "Notification at every stage", "CCTV surveillance"] },
      {
        content: ["Our priority is your time. We know how important it is for you to get your things on time, every time.",
          "We only work with the best quality products to ensure that you get the best experience possible.",
          "We deliver to your doorstep, so you can just sit back and relax.",
          "We provide you with the option to pay so that you can pay us only when you receive your product.",
          "You will be updated and notified at every stage of the packing and moving process so that you know where your product is at all times.",
          "We have set up in our facility so that your product is always safe and secure."]
      }],
    "FAQ": [
      {
        question: ["How early should I book a courier ?",
          "Can I choose a specific courier for my delivery ?",
          "Can I order a specific vehicle ?",
          "Can I cancel or change my order ?",
          "Why hasn't anyone been assigned to my order yet ?",
          "How do I know if the courier has delivered the parcel ?"]
      },
      {
        answer: ["You only need to book 30 minutes in advance if the first delivery is within travel zone 1, one hour in advance if in travel zone 2 and 1.5–2 hours if further out.",
          "When you fill in the form, you can use the free Preferred Courier service in your online account. You can choose couriers you have used before and whom you have given a 5-star rating. Your chosen courier will receive a message that you would like them to deliver your order. If the courier wants to take the order, he/she clicks on it.",
          "We don’t know in advance what car the courier is driving. To make sure you get a courier whose car is big enough, specify the dimensions and weight of the item. You will get responses from couriers who have cars big enough to fulfill your order.",
          "If you change the order after the courier has set off, you will be charged ₹ 200. You can make changes through your online account or by phone, but these changes have to be agreed upon with the courier.",
          "Our system works based on responses. So far, no couriers have responded to your order. Depending on the day and the route, it can take from one minute to an hour to find a courier.",
          "You will receive a text message or email. The recipient will also receive a text message notifying them that the courier is coming (unless the delivery is a surprise)."
        ]
      }],
    "Testimonials": [{ img: [] }, { name: [] }, { possition: [] }, { company: [] },
    {
      feedback: ["I have been using Kabra Express for a few months now and I am very happy with the service. The delivery is always on time and the drivers are very polite. I would recommend it to anyone looking for a reliable and convenient delivery service.",
        "I have been using Kabra express for the past few months and I am very impressed with their service. I love that I can get all my groceries, food, and even gifts delivered to my doorstep with just a few clicks. Kabra express has made my life so much easier and hassle-free!",
        "I was so skeptical about ordering my groceries online but Kabra Express has changed my mind. They are prompt and efficient & the products are always fresh. I don't have to worry about anything, they take care of it all. Highly recommend!"
      ]
    }]
  }

  const handleLogin = async () => {
    props?.showLoader();
    try {
      await props.customerLogin(customerMobileNo, customerPassword, false, localStorage.getItem("deviceId"), localStorage.getItem("latitude"), localStorage.getItem("longitude"))
      props?.hideLoader();
    } catch (error) {
      props?.hideLoader();
      alert("Contact No / Password is Incorrect !")
    }
  }

  const multipleExist = (arr: any, values: any) => {
    return values.every((value: any) => {
      return arr.includes(value);
    });
  };

  if (props?.customerReducer && props?.customerReducer?.token && props?.customerReducer?.name &&
    localStorage.getItem("kabra:customertoken")
  ) {
    navigate("/customerDashboard/booking",{
      state: { from: "customerLogin" }
    })
  }

  useEffect(() => {
    setDestinations([""])
    defineTempoType();
    setUpData();
  }, [])

  return (
    <>
    {mobileView ? (
      <div className="">
      <div className="intraCity">
        <section id="intro" className="intro">
          <div className="intro-content">
            <h1 style={{textAlign: "center", fontSize: "24px", marginBottom: "0", paddingBlock: "1rem"}}>Get Estimate</h1>
            <div className="container ml-25Mobile">
              <div className="row">
                <div className="col-lg-6 firm_1">
                  <div className="col-lg-6">
                    <div className="logo_1 mt-25">
                      <img src="img/21.png" className="img-responsive" alt="" />
                    </div>
                  </div>
                  <div className="mt50">
                    <div className="row form_page">
                      <div className="tab-content">
                        <div className="tab-pane active" id="my_1">
                            
                            <div className="ownBtnMobileVersion ml-3Mobile">
                              <button className={props?.customerBooking?.intraCity?.assetType === "635f8465e50d888000e48839" ? "activeButton" : ""} onClick={()=> props.setIntraCity("assetType", "635f8465e50d888000e48839")}> Bike </button>
                              <button className={props?.customerBooking?.intraCity?.assetType === "5f0326401bc08749e8a3a6bc" ? "activeButton" : ""} onClick={()=> props.setIntraCity("assetType", "5f0326401bc08749e8a3a6bc")}> Tempo </button>
                            </div>
                            
                            <div className="row ml-155">
                              <select
                                id="inputState"
                                className="form-control form_one ml170 mb0"
                                value={props?.customerBooking?.intraCity?.city}
                                onChange={(e) => {
                                  props.setIntraCity('city',e.target.value);

                                  }}
                                >
                                  <option label="Select Your City" value="" />
                                  {cities.map((c: any) => (
                                    <option
                                      key={c._id}
                                      label={c.name}
                                      value={c._id}
                                    />
                                  ))}
                                </select>
                              </div>


                              <div className="row ml-170">
                                <div className="autoSearchLocation">
                                  <AutoComplete onChange={(e: any) => {
                                    let area = e.address_components.find((x: any) => {
                                      return multipleExist(x.types, [
                                        "sublocality_level_1",
                                        "sublocality",
                                        "political",
                                      ]);
                                    });

                                    let pincode = e.address_components.find((x: any) => {
                                      return multipleExist(x.types, [
                                        'postal_code'
                                      ]);
                                    })

                                    let city = e.address_components.find((x: any) => {
                                      return x.types.length === 2 && multipleExist(x.types, ["locality", "political"]);
                                    })

                                    let state = e.address_components.find((x: any) => {
                                      return multipleExist(x.types, ["administrative_area_level_1", "political"]);
                                    })

                                    console.log(e)
                                    props.setIntraCityPickupDropLocation('place.placeId', e.place_id)
                                    props.setIntraCityPickupDropLocation('place.coordinate.latitude', e.geometry.location.lat)
                                    props.setIntraCityPickupDropLocation('place.coordinate.longitude', e.geometry.location.lng)
                                    props.setIntraCityPickupDropLocation('place.placeValue', e.formatted_address)
                                    props.setIntraCityPickupDropLocation('place.formatted_address', e.formatted_address)
                                    props.setIntraCityPickupDropLocation('place.name', e.name)
                                    props.setIntraCityPickupDropLocation('place.area', area.long_name)
                                    props.setIntraCityPickupDropLocation('place.pincode', pincode.long_name)
                                    props.setIntraCityPickupDropLocation('place.city', city.long_name)
                                    props.setIntraCityPickupDropLocation('place.state', state.long_name)

                                    props.setIntraCityPickupDropLocation('contactPerson.state', state.long_name)
                                    props.setIntraCityPickupDropLocation('contactPerson.pincode', pincode.long_name)
                                    props.setIntraCityPickupDropLocation('contactPerson.city', city.long_name)
                                  }
                                  } placeHolder="Source Location" general={false}></AutoComplete>
                                </div>
                              </div>

                              {destinations.map((val: string, i: any) => {
                                console.log(val)
                                return (
                                  <div key={i} className="row dflex ml-170 mt10Mobile">
                                    <div className="autoSearchLocation">
                                      <AutoComplete general={false} onChange={(e: any) => { handleChangeDestinations(e.formatted_address, i) }} placeHolder="Destination Location"></AutoComplete>
                                    </div>
                                    {i === destinations.length - 1 && i < 2 &&
                                      <div className="plusMinusIcons">
                                        <AiOutlinePlus onClick={() => { handleAdd() }} className="plus" />
                                      </div>
                                    }
                                    {i !== 0 &&
                                      <div className="plusMinusIcons">
                                        <AiOutlineMinus onClick={() => { handleRemove(i) }} className="minus" />
                                      </div>
                                    }
                                  </div>
                                )
                              })}

                              {/* {formDetails === false ? (
                              <PackageForm val={(res:any)=>{setFomrDetails(res)}} 
                                              pacgeArr={(res:any)=>{setPackageCount(res)}}
                                              titleName={"intracity"}/>  
                            ) : (<div className="showPkg">{packageCount.length > 0 &&
                              <>
                              <h6>Package Details</h6>
                              <div className={(packageCount.length === 1 ? "result1" : packageCount.length === 2 ? "result2" : "result3")}>
                                <table>
                                  <tr>
                                    <th>Qty</th>
                                    <th>Material</th>
                                    <th>Size</th>
                                  </tr>
                                  {packageCount.map((ele:any,i:number) => {
                                    return (
                                      <tr key={i}>
                                        <td>{ele.Qty? ele.Qty : null}</td>
                                        <td>{ele.MaterialType? ele.MaterialType : null}
                                        <br/>
                                        Packing :- {ele.PackingType? ele.PackingType : null} 
                                        </td>
                                        <td>
                                        {ele.BoxesType? ele.BoxesType : null}
                                        <br />
                                        {ele.Weight? ele.Weight : null} KG
                                        <br />
                                        {ele.Length? ele.Length : null} x {ele.Breadth? ele.Breadth : null} x {ele.Height? ele.Height : null} {ele.UnitType? ele.UnitType : null}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </table>
                              </div>
                              </>
                                }
                            </div>)} */}

                              {props?.customerBooking?.intraCity?.assetType === "5f0326401bc08749e8a3a6bc" ?
                                <div className="row ml15Mobile mt10Mobile">
                                  <select
                                    id="inputState"
                                    className="form-control form_one mt0"
                                    value={tempoType}
                                    onChange={(e) => {
                                      setTempoType(e.target.value)
                                    }}
                                  >
                                    <option label="Select Tempo" value="" />
                                    {tempoTypeList.map((c: any) => (
                                      <option
                                        key={c.id}
                                        label={c.name}
                                        value={c.name}
                                      />
                                    ))}
                                  </select>
                                </div>
                                : null}


                              <div className="row ml-170">
                                <button
                                  className="btn btn-primary-one text-white form_one ml183 mt5 puBtn"
                                  onClick={!props?.customerBooking?.intraCity?.city ? () => { alert("Kindly Fill All the Details !") } : () => { setIsDialogOpen(!isDialogOpen) }}>
                                  Book Now
                                </button>
                                <Dialog
                                  open={isDialogOpen}
                                  onClose={() => setIsDialogOpen(false)}
                                  maxWidth={"lg"}
                                >
                                  <div className="SigninPopupcontainer">
                                    <div className='defineUsersContainer'>
                                      <div className={tabValue === 0 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(0)}>Registered User</div>
                                      <div className={tabValue === 1 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(1)}>New User</div>
                                    </div>
                                    <DialogContent>
                                      {tabValue === 0 ?
                                        <div className="userDetialsforHomePopup">
                                          <input className='form-control' type={"email"} value={customerMobileNo} placeholder='Contact No' onChange={(e: any) => setCustomerMobileNo(e.target.value)} />
                                          <br />
                                          <input className='form-control' type={"password"} value={customerPassword} placeholder='Password' onChange={(e: any) => setCustomerPassword(e.target.value)} />
                                        </div>
                                        :
                                        <div className="userDetialsforHomePopup">
                                          <input className='form-control' type={"text"} placeholder='First Name' />
                                          <br />
                                          <input className='form-control' type={"text"} placeholder='Last Name' />
                                          <br />
                                          <input className='form-control' type={"email"} placeholder='E-mail' />
                                          <br />
                                          <input className='form-control' type={"password"} placeholder='Password' />
                                          <br />
                                          <input className='form-control' type={"number"} placeholder='Contact No' onChange={(e: any) => { setContactNo(e) }} />
                                          <br />
                                          <button className='btn btn-primary otpButton' onClick={() => { handelgetOtp() }}>Get Otp</button>
                                          <br />
                                          <input className='form-control otpInput' type={"number"} placeholder='Enter Otp' />
                                        </div>
                                      }
                                      <br />
                                      {tabValue === 0 ?
                                        <div className="createAccount">
                                          <Link to="/customerLogin/forgotPassword" className="fp-span">
                                            Forgot Password ?
                                          </Link>
                                        </div>
                                        : <></>
                                      }
                                    </DialogContent>
                                    <DialogActions>
                                      <div className='dflex w100 btns'>
                                        {tabValue === 0 ?
                                          <ButtonPrimary className="mr5 loginForHomePopup" onClick={() => { handleLogin() }}> Login </ButtonPrimary>
                                          :
                                          <ButtonPrimary className="mr5 loginForHomePopup"> Sign Up </ButtonPrimary>
                                        }
                                        <ButtonDefault className="mr5 closeForHomePopup" onClick={() => setIsDialogOpen(false)}> close </ButtonDefault>
                                      </div>
                                    </DialogActions>
                                  </div>
                                </Dialog>
                              </div>
                            </div>
                          </div>
                          {loading === true && (
                            <span className="tac">
                              <Loader />
                            </span>
                          )}
                          {result && loading === false && (
                            // <div className="table-responsive">
                            //   <table className="table">
                            //     <thead>
                            //       <tr>
                            //         <th>Type</th>
                            //         <th>Estimated Time</th>
                            //         <th>Estimated Amount</th>
                            //       </tr>
                            //     </thead>
                            //     {result.length > 0 ? (
                            //       <tbody>
                            //         {result.map((r: any) => (
                            //           <tr key={r.type}>
                            //             <td>
                            //               <p>{`${serviceType} Intra City`}</p>
                            //             </td>
                            //             <td>
                            //               <p>Currently We are not Available !</p>
                            //             </td>
                            //             <td>
                            //               <p>We Will Connect You !</p>
                            //             </td>
                            //           </tr>
                            //         ))}
                            //       </tbody>
                            //     ) : (
                            //       <tbody>
                            //         <tr>
                            //           <td
                            //             colSpan={3}
                            //             className="tac"
                            //           >
                            //             {/* No service found! */}
                            //             <button
                            //               className="btn btn-primary-one text-white form_one"
                            //               onClick={() => {
                            //                 handleResults();
                            //               }}
                            //             > 
                            //                 Get Details
                            //             </button>
                            //           </td>
                            //         </tr>
                            //       </tbody>
                            //     )}
                            //   </table>
                            // </div>
                            null
                          )}
                        </div>
                      </div>
                      <div data-aos="fade-up" data-aos-delay="800"></div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="feturesTabs">
            <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Our Features</h2>
              </div>
              <div className="centalisedFeatures">
                <OurFeatures data={intraCity} />
              </div>
            </div>
            <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>How It Works</h2>
              </div>
              <div className="centalisedHowItWorks">
                <HowItWorks data={intraCity} />
              </div>
            </div>
            <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
              <div className="section-title" data-wow-delay="0.1s">
                <h2>Testimonials</h2>
              </div>
              <div className="centalisedTestimonials">
                <Testimonials data={intraCity} />
              </div>
            </div>
            <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
              <section className="faq">
                <div className="container">
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>FAQ</h2>
                  </div>
                  <FAQ data={intraCity} />
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <div className="intracityContainer">
          <div className="scrollableContainer">
            <div className="sideNav" onMouseEnter={() => { document.body.style.overflowY = 'hidden' }} onMouseLeave={() => document.body.style.overflowY = 'scroll'}>
              <div className={tabActive === 0 ? "activeNew" : ""} onClick={() => { topFunction2("book"), setTabActive(0) }}>Book Now</div>
              <div className={tabActive === 1 ? "activeNew" : ""} onClick={() => { topFunction2("feature"), setTabActive(1) }}>Features</div>
              <div className={tabActive === 2 ? "activeNew" : ""} onClick={() => { topFunction2("how"), setTabActive(2) }}>How It Works</div>
              <div className={tabActive === 3 ? "activeNew" : ""} onClick={() => { topFunction2("test"), setTabActive(3) }}>Testimonials</div>
              <div className={tabActive === 4 ? "activeNew" : ""} onClick={() => { topFunction2("faq's"), setTabActive(4) }}>FAQ's</div>
            </div>
            <div className="intraCity">
              <div className="intro-content formAndPhoto commonBoxShadow" onMouseOver={() => { setTabActive(0) }}>
                <div className="col-lg-6 firm_1 wow fadeInUp commonPaddingHeight" data-wow-delay="0.1s">
                  {/* <div className="bttn mt0">
                        <div className="ownBtn">
                        <button className={props?.customerBooking?.intraCity?.assetType === "635f8465e50d888000e48839" ? "activeButton" : ""} onClick={()=> props.setIntraCity("assetType", "635f8465e50d888000e48839")}> Bike </button>
                              <button className={props?.customerBooking?.intraCity?.assetType === "5f0326401bc08749e8a3a6bc" ? "activeButton" : ""} onClick={()=> props.setIntraCity("assetType", "5f0326401bc08749e8a3a6bc")}> Tempo </button>
                        </div>
                      </div> */}
                  <div className="row ml-170">
                    <select
                      id="inputState"
                      className="form-control form_one ml170 mb0"
                      value={props?.customerBooking?.intraCity?.city}
                      onChange={(e) => {
                        props.setIntraCity('city', e.target.value);
                      }}
                    >
                      <option label="Select Your City" value="" />
                      {cities.map((c: any) => (
                        <option
                          key={c._id}
                          label={c.name}
                          value={c._id}
                        />
                      ))}
                    </select>
                  </div>
                  {/* <IntracityFormik /> */}
                  {/* {
                    props.customerBooking.intraCity.pickDropLocation.map((x: any, i: number) => {
                      return (
                        <div key={i} className="row dflex ml-170 mt10Mobile">
                          <div className="autoSearchLocation">
                            <AutoComplete value={x.place.name}
                              onChange={(e: any) => {
                                let area = e.address_components.find((x: any) => {
                                  return multipleExist(x.types, [
                                    "sublocality_level_1",
                                    "sublocality",
                                    "political",
                                  ]);
                                });

                                let pincode = e.address_components.find((x: any) => {
                                  return multipleExist(x.types, [
                                    'postal_code'
                                  ]);
                                })

                                let city = e.address_components.find((x: any) => {
                                  return x.types.length === 2 && multipleExist(x.types, ["locality", "political"]);
                                })

                                let state = e.address_components.find((x: any) => {
                                  return multipleExist(x.types, ["administrative_area_level_1", "political"]);
                                })

                                console.log(e)
                                props.setIntraCityPickupDropLocation('place.placeId', e.place_id, i)
                                props.setIntraCityPickupDropLocation('place.coordinate.latitude', e.geometry.location.lat, i)
                                props.setIntraCityPickupDropLocation('place.coordinate.longitude', e.geometry.location.lng, i)
                                props.setIntraCityPickupDropLocation('place.placeValue', e.formatted_address, i)
                                props.setIntraCityPickupDropLocation('place.formatted_address', e.formatted_address, i)
                                props.setIntraCityPickupDropLocation('place.name', e.name, i)
                                props.setIntraCityPickupDropLocation('place.area', area.long_name, i)
                                props.setIntraCityPickupDropLocation('place.pincode', pincode.long_name, i)
                                props.setIntraCityPickupDropLocation('place.city', city.long_name, i)
                                props.setIntraCityPickupDropLocation('place.state', state.long_name, i)

                                props.setIntraCityPickupDropLocation('contactPerson.state', state.long_name, i)
                                props.setIntraCityPickupDropLocation('contactPerson.pincode', pincode.long_name, i)
                                props.setIntraCityPickupDropLocation('contactPerson.city', city.long_name, i)
                              }} placeHolder={i === 0 ? "Source Location" : 'Destination Location'}
                              general={false}
                            >
                            </AutoComplete>
                          </div>
                          {props.customerBooking.intraCity.pickDropLocation.length - 1 === i && i < 3 &&
                            <div className="plusMinusIcons">
                              <AiOutlinePlus onClick={() => props.addIntraCityPickupDropLocation()} className="plus" />
                            </div>
                          }
                          {i !== 0 &&
                            <div className="plusMinusIcons">
                              <AiOutlineMinus onClick={() => props.removeIntraCityPickupDropLocation()} className="minus" />
                            </div>
                          }
                        </div>
                      )
                    })
                  } */}
                  {/* {formDetails === false ? (
                        <PackageForm val={(res:any)=>{setFomrDetails(res)}} 
                                        pacgeArr={(res:any)=>{setPackageCount(res)}}
                                        titleName={"intracity"}/>  
                      ) : (<div className="showPkg">{packageCount.length > 0 &&
                        <>
                        <h6>Package Details</h6>
                        <div className={(packageCount.length === 1 ? "result1" : packageCount.length === 2 ? "result2" : "result3")}>
                          <table>
                            <tr>
                              <th>Qty</th>
                              <th>Material</th>
                              <th>Size</th>
                            </tr>
                            {packageCount.map((ele:any,i:number) => {
                              return (
                                <tr key={i}>
                                  <td>{ele.Qty? ele.Qty : null}</td>
                                  <td>{ele.MaterialType? ele.MaterialType : null}
                                  <br/>
                                  Packing :- {ele.PackingType? ele.PackingType : null} 
                                  </td>
                                  <td>
                                  {ele.BoxesType? ele.BoxesType : null}
                                  <br />
                                  {ele.Weight? ele.Weight : null} KG
                                  <br />
                                  {ele.Length? ele.Length : null} x {ele.Breadth? ele.Breadth : null} x {ele.Height? ele.Height : null} {ele.UnitType? ele.UnitType : null}
                                  </td>
                                </tr>
                              )
                            })}
                          </table>
                        </div>
                        </>
                          }
                      </div>)} */}

                  {/* {props?.customerBooking?.intraCity?.assetType === "5f0326401bc08749e8a3a6bc" ?
                    <div className="row ml0Mobile mt10Mobile ml155 ml-170">
                      <select
                        id="inputState"
                        className="form-control form_one mt10"
                        value={tempoType}
                        onChange={(e) => {
                          setTempoType(e.target.value)
                        }}
                      >
                        <option label="Select Tempo" value="" />
                        {tempoTypeList.map((c: any) => (
                          <option
                            key={c.id}
                            label={c.name}
                            value={c.name}
                          />
                        ))}
                      </select>
                    </div>
                    : null} */}

                  {/* <div className="row ml-170">
                    <button
                      className="btn btn-primary-one  text-white form_one ml170 mt5 puBtn"
                      onClick={!props?.customerBooking?.intraCity?.city ? () => { alert("Kindly Fill All the Details !") } : () => { setIsDialogOpen(!isDialogOpen) }}>
                      Book Now
                    </button>
                    <Dialog
                      open={isDialogOpen}
                      onClose={() => setIsDialogOpen(false)}
                      maxWidth={"lg"}
                    >
                      <div className="SigninPopupcontainer">
                        <div className='defineUsersContainer'>
                          <div className={tabValue === 0 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(0)}>Registered User</div>
                          <div className={tabValue === 1 ? "signinTabs activeNew" : "signinTabs"} onClick={() => setTabValue(1)}>New User</div>
                        </div>
                        <DialogContent>
                          {tabValue === 0 ?
                            <div className="userDetialsforHomePopup">
                              <input className='form-control' type={"email"} value={customerMobileNo} placeholder='Contact No' onChange={(e: any) => setCustomerMobileNo(e.target.value)} />
                              <br />
                              <input className='form-control' type={"password"} value={customerPassword} placeholder='Password' onChange={(e: any) => setCustomerPassword(e.target.value)} />
                            </div>
                            :
                            <div className="userDetialsforHomePopup">
                              <input className='form-control' type={"text"} placeholder='First Name' />
                              <br />
                              <input className='form-control' type={"text"} placeholder='Last Name' />
                              <br />
                              <input className='form-control' type={"email"} placeholder='E-mail' />
                              <br />
                              <input className='form-control' type={"password"} placeholder='Password' />
                              <br />
                              <input className='form-control' type={"number"} placeholder='Contact No' onChange={(e: any) => { setContactNo(e) }} />
                              <br />
                              <button className='btn btn-primary otpButton' onClick={() => { handelgetOtp() }}>Get Otp</button>
                              <br />
                              <input className='form-control otpInput' type={"number"} placeholder='Enter Otp' />
                            </div>
                          }
                          <br />
                          {tabValue === 0 ?
                            <div className="createAccount">
                              <Link to="/customerLogin/forgotPassword" className="fp-span">
                                Forgot Password ?
                              </Link>
                            </div>
                            : <></>
                          }
                        </DialogContent>
                        <DialogActions>
                          <div className='dflex w100 btns'>
                            {tabValue === 0 ?
                              <ButtonPrimary className="mr5 loginForHomePopup" onClick={() => { handleLogin() }}> Login </ButtonPrimary>
                              :
                              <ButtonPrimary className="mr5 loginForHomePopup"> Sign Up </ButtonPrimary>
                            }
                            <ButtonDefault className="mr5 closeForHomePopup" onClick={() => setIsDialogOpen(false)}> close </ButtonDefault>
                          </div>
                        </DialogActions>
                      </div>
                    </Dialog>
                  </div> */}
                  {/* {loading === true && (
                    <span className="tac">
                      <Loader />
                    </span>
                  )} */}
                  {/* {result && loading === false && (
                    null
                  )}
                  <div data-aos="fade-up" data-aos-delay="800"></div> */}
                </div>
                <div className="col-lg-6 commonPaddingHeight" data-wow-duration="2s" data-wow-delay="0.2s">
                  <img src="img/21.png" alt="" height={"80%"} />
                </div>
              </div>
              <div className="feturesTabs">
                <div className="features commonBoxShadow" onMouseOver={() => { setTabActive(1) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Our Features</h2>
                  </div>
                  <div className="centalisedFeatures">
                    <OurFeatures data={intraCity} />
                  </div>
                </div>
                <div className="howItWorks commonBoxShadow" onMouseOver={() => { setTabActive(2) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>How It Works</h2>
                  </div>
                  <div className="centalisedHowItWorks">
                    <HowItWorks data={intraCity} />
                  </div>
                </div>
                <div className="trestimonials commonBoxShadow" onMouseOver={() => { setTabActive(3) }}>
                  <div className="section-title" data-wow-delay="0.1s">
                    <h2>Testimonials</h2>
                  </div>
                  <div className="centalisedTestimonials">
                    <Testimonials data={intraCity} />
                  </div>
                </div>
                <div className="faqIntracity commonBoxShadow" onMouseOver={() => { setTabActive(4) }}>
                  <section className="faq">
                    <div className="container">
                      <div className="section-title wow fadeInUp" data-wow-delay="0.1s">
                        <h2>Frequently Asked Questions</h2>
                      </div>
                      <FAQ data={intraCity} />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    customerReducer: state.customerLoginReducer,
    customerBooking: state.customerBooking,
  };
};

const mapDispatchToProps = {
  customerLogin,
  addIntraCityPickupDropLocation,
  removeIntraCityPickupDropLocation,
  setIntraCityPickupDropLocation,
  setIntraCity,
  hideLoader,
  showLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(IntraCityForm)