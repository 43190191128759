import { useState, useLayoutEffect } from "react"
import { createPortal } from "react-dom"

interface Props {
	children: JSX.Element |JSX.Element[];
	wrapperclass: string
}

const PortalModal  = ({ children, wrapperclass = "TapTap-Modal-Root"}: Props) => {
	const [portalElement, setPortalElement] = useState<HTMLElement | null>(null)

	useLayoutEffect(() => {
		let portalCreated = false
		// create and append to body
			let element = createWrapperAndAppendToBody(wrapperclass)
			portalCreated = true
		

		setPortalElement(element)

		// cleaning up the portal element
		return () => {
			// delete the programatically created element
			if (portalCreated && element.parentNode) {
				element.parentNode.removeChild(element)
			}
		}
	}, [wrapperclass])

	const createWrapperAndAppendToBody = (elementClass: string) => {
		const element = document.createElement("div")
		element.setAttribute("class", elementClass)
        element.style.zIndex="1300";
        element.style.position="fixed";
		// element.style.height="100%"
		// element.style.width="100%"
        element.style.inset="0px";
		document.body.appendChild(element)
		return element
	}

	// portalElement state will be null on the very first render.
	if (!portalElement) return null

	return createPortal(children, portalElement)
}

export default PortalModal